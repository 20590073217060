import React, { useEffect, useState } from 'react'
import { CloseButton, Loader, MultiSelect, SelectItem } from '@mantine/core'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import './filtersComponent.scss'
import { useDebouncedValue } from '@mantine/hooks'
import { MultiSelectAutoCompleteItem } from '../../types'
import { useAppDispatch } from '../../store/hooks'
import { t } from 'i18next'

// component for multiselect with dynamic data fetching
const MultiSelectAutoCompleteComponent = (multiSelectProps: MultiSelectProps) => {
    const dispatch = useAppDispatch()

    // fetch suggestions list, which is then propagated into multiSelectProps.data from higher components
    const [searchValue, setSearchValue] = useState<string>('')
    const [searchValueDebounced] = useDebouncedValue(searchValue, 200)
    useEffect(() => {
        if (searchValueDebounced && searchValueDebounced.length > 2) {
            dispatch(multiSelectProps.handleActionFetch(searchValueDebounced))
        }
    }, [searchValueDebounced])

    // reset search value if searchClicked is specified when user search the header
    useEffect(() => {
        setSearchValue('')
    }, [multiSelectProps.searchClicked])

    const [multiSelData, setMultiSelData] = useState<SelectItem[]>([])

    // multiSelectProps.previousData are the values selected from previous data fetched
    const [multiSelValues, setMultiSelValues] = useState<string[]>(multiSelectProps.previousData.map(d => d.value))


    const addSelectedValuesToData = (data: MultiSelectAutoCompleteItem[], selectedValues: MultiSelectAutoCompleteItem[]) => {
        selectedValues.forEach(selectedValue => {
            if (!data.some(item => item.code === selectedValue.code)) {
                data.push(selectedValue)
            }
        })
    }
    // since data props from MultiSelect component is used to list all suggestions, we add the values already selected to the new fecthed data
    useEffect(() => {
        const newDataWithSelectedValues = multiSelectProps.data.slice()
        addSelectedValuesToData(newDataWithSelectedValues, multiSelectProps.previousData)
        setMultiSelData(newDataWithSelectedValues)
    }, [multiSelectProps.data, multiSelectProps.previousData])

    const handleOnChange = (selectedFilters: string[]) => {
        setMultiSelValues(selectedFilters)

        // dispatch the current selected values
        if (multiSelectProps.onSelected) {
            const selectedItems = multiSelData.filter(
                (data: any) => selectedFilters.indexOf(data.value) > -1
            )
            multiSelectProps.onSelected(selectedItems)
        }
    }

    const displayNothingFound = () => (
        searchValue &&
        multiSelectProps.status > -1 &&
        (multiSelectProps.noDataMessage || t('search-data.no-data'))
    )

    return (
        <MultiSelect
            data={multiSelData}
            value={multiSelValues}
            searchValue={searchValue}
            onChange={handleOnChange}
            onSearchChange={setSearchValue}
            placeholder={multiSelectProps.textPlaceholder}
            rightSection={
                multiSelectProps.status === -1 ? (
                    <Loader size='1.5rem' color={'#363981'} />
                ) : multiSelValues.length === 0 ? (
                    <span className={'filter-arrow-down cursor-pointer'} />
                ) : (
                    <div className={'d-flex pe-4'}>
                        {multiSelValues.length > 2 ? (
                            <OverlayTrigger
                                placement={'right'}
                                overlay={
                                    <Tooltip id='button-tooltip'>
                                        {multiSelValues.map((val, index) => {
                                            return (
                                                <div
                                                    className={'text-start px-2 text-nowrap'}
                                                    key={`${val}-${index}`}
                                                >
                                                    {val}
                                                </div>
                                            )
                                        })}
                                    </Tooltip>
                                }
                            >
                                <Badge
                                    bg={'secondary'}
                                    pill
                                    className='badge-icon float-start'
                                    text={'light'}
                                    style={{ background: '#363981 !important' }}
                                >
                                    {multiSelValues.length}
                                </Badge>
                            </OverlayTrigger>
                        ) : (
                            <Badge
                                bg={'secondary'}
                                pill
                                className='badge-icon float-start'
                                text={'light'}
                                style={{ background: '#363981 !important' }}
                            >
                                {multiSelValues.length}
                            </Badge>
                        )}
                        <CloseButton
                            onClick={() => handleOnChange([])}
                            className={'float-start'}
                        />
                    </div>
                )
            }
            styles={{
                rightSection: {
                    pointerEvents: multiSelValues.length === 0 ? 'none' : 'auto',
                },
                defaultValue: {
                    maxWidth: '80px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipse',
                },
            }}
            maxDropdownHeight={190}
            searchable={true}
            disableSelectedItemFiltering
            disabled={multiSelectProps.searchIsLoading || multiSelectProps.disabled}
            style={
                multiSelectProps.widthFilter !== null
                    ? { width: multiSelectProps.widthFilter }
                    : { width: '280px' }
            }
            nothingFound={displayNothingFound()}
        />
    )
}

interface MultiSelectProps {
    searchIsLoading?: boolean
    titleFieldset?: string
    textPlaceholder?: string
    widthFilter?: string
    data: Array<MultiSelectAutoCompleteItem>
    previousData: Array<MultiSelectAutoCompleteItem>
    onSelected?: Function
    status: number
    disabled?: boolean
    handleActionFetch?: any
    searchClicked?: boolean
    noDataMessage?: string
}

export default MultiSelectAutoCompleteComponent
