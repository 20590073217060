import React, { useEffect, useState } from 'react';
import AuthService from './authService';
import { useAppDispatch } from '../store/hooks';
import { expirationToken } from '../slices/appContextSlice';
import {User} from "oidc-client/dist/oidc-client";


const AuthContext = React.createContext({
	signinRedirectCallback: () => ({}),
	logout: () => ({}),
	signoutRedirectCallback: () => ({}),
	isAuthenticated: () => ({}),
	signinRedirect: () => ({}),
	signinSilentCallback: () => ({}),
	createSigninRequest: () => ({}),
	getUser: () =>({}),
});

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = (props) => {
	const dispatch = useAppDispatch();

	const [authService, setAuthService] = useState();

	useEffect(() => {
		const authServiceInstance = new AuthService();
		setAuthService(authServiceInstance);
		authServiceInstance.addEventListener('redirect signin', () => {
			dispatch(expirationToken());
		});
	}, []);
	return authService != null ? <AuthContext.Provider value={authService}>{props.children}</AuthContext.Provider> : <></>;
};
