export const EDITOR_CODE = 'E';
export const DEFAULT_SIZE_PER_PAGE = 10;
export const NOT_CANCELLABLE_EVENT = [
	'Répartie',
	'Envoyée en Répartition',
	'Annulée',
];
export const CHEF_ORCHESTER = 'CO';
export const AUTHOR_CODE = 'A';
export const COMPO_CODE = 'C';
export const AUTHOR_COMPO_CODE = 'CA';
export const AUTHOR_REALISATOR = 'AR';
export const AUTHOR_INTERPRETE = 'AI';
export const INTERPRETE = 'IN';
export const DEFAULT_QUERY_PARAM_SIZE = 500;

export const EVENT_TYPE = {
	FESTIVAL: 'FESTIVAL',
	MULTI_STAGE: 'MULTI_PLATEAU',
	ONE_PART: '1_PARTIE',
	CONCERT: 'CONCERT',
	MAIN_SHOW: 'SPECTACLE_UNIQUE'
};
export const OEUVRES = 'OEUVRES';
export const REPERTOIRE_OEUVRES = 'REPERTOIRE_OEUVRES';

export const UNSUPPORTED_TERRITORY = 'unsupported'

export const vectorMapAllowedRegions: number[] = [
  50, 56, 854, 100, 70, 96, 68, 392, 108, 204, 64, 388, 72, 76, 44, 112, 84,
  643, 646, 688, 626, 795, 762, 642, 624, 320, 300, 226, 328, 268, 826, 266,
  324, 270, 304, 288, 512, 788, 400, 191, 332, 348, 340, 630, 275, 620, 600,
  591, 598, 604, 586, 608, 616, 894, 732, 233, 818, 710, 218, 380, 704, 90, 231,
  706, 716, 724, 232, 499, 498, 450, 504, 860, 104, 466, 496, 807, 454, 478,
  800, 458, 484, 376, 250, 246, 242, 238, 558, 528, 578, 516, 548, 540, 562,
  566, 554, 524, 384, 756, 170, 156, 120, 152, 124, 178, 140, 180, 203, 196,
  188, 192, 748, 760, 417, 404, 728, 740, 116, 222, 703, 410, 705, 408, 414,
  686, 694, 398, 682, 752, 729, 214, 262, 208, 276, 887, 12, 840, 858, 422, 418,
  158, 780, 792, 144, 428, 440, 442, 430, 426, 764, 260, 768, 148, 434, 784,
  862, 4, 368, 352, 364, 51, 8, 24, 32, 36, 40, 356, 834, 31, 372, 360, 804,
  634, 508,
]