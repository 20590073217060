export const colorTicks: {
  [key: string]: string
} = {
  color: '#12164A',
}

export const colorGrid: {
  [key: string]: string
} = {
  color: '#BBC2D4',
}

export const borderColorGrid: {
  [key: string]: string
} = {
  borderColor: '#12164A',
}
