import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SupraNav from '../../components/SupraNav/SupraNav';
import Breadcrumb from '../../components/Breadcrumb';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppContextState, AppPropsType } from '../../types';
import './index.scss';
import { ROUTES } from '../../constants';
import {
	ConfigurationContext,
	getFeatureFlag,
} from '../../providers/configurationProvider'

import './index.scss';


interface Props{
	app: AppContextState
}

const Header = (props: Props) => {
	const { app: { breadcrumb: { title, buttonLabel, buttonAction, showHome, parent, featKey, isVisible } } } = props;
	const { t } = useTranslation();

	const navigate = useNavigate();

	const config = useContext(ConfigurationContext);

	const flag = featKey ? getFeatureFlag(config, featKey) : undefined

	return (
		<>
			<SupraNav />
			{ isVisible !== false && <Breadcrumb
				title={title}
				showHome={showHome}
				homeOnclick={() => {
					navigate(ROUTES.LANDING);
				}}
				showBtn={buttonAction ? buttonAction?.length > 0 : false}
				btnTitle={buttonLabel && t(buttonLabel)}
				buttonAction={buttonAction && t(buttonAction)}
				cssBtn={'btn btn-outline-light button-header'}
				parent={parent && t(parent)}
				onClick={() => {
					//check button action then redirect to the correct page
				}}
				featFlag={flag}
			/> }
		</>


	);
};

const mapStateToProps = (state: AppPropsType) => ({
	app: state.appContext,
});
export default connect(mapStateToProps)(Header);


