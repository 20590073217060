import React, { useEffect, useState } from 'react'
import { DatePickerInput } from '@mantine/dates'
import moment from 'moment'
//import { SelectItem } from '@mantine/core'

const DateRangeComponent = (dateRangeProps: DateRangeProps) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ])
  useEffect(() => {
    let defaultDateRange: [Date | null, Date | null] = [null, null]
    if (
      dateRangeProps.startDate != null &&
      dateRangeProps.startDate !== '' &&
      dateRangeProps.endDate != null &&
      dateRangeProps.endDate != ''
    ) {
      defaultDateRange = [
        moment(dateRangeProps.startDate, 'YYYYMMDD').toDate(),
        moment(dateRangeProps.endDate, 'YYYYMMDD').toDate(),
      ]
    } else {
      defaultDateRange = [
        moment().subtract(3, 'months').toDate(),
        moment().subtract(1, 'days').toDate(),
      ]
    }

    handleOnChange(defaultDateRange)
  }, [])

  const handleOnChange = (dateRange: [Date | null, Date | null]) => {
    setDateRange(dateRange)
    // handel parent on change function
    if (
      dateRangeProps.handleComponentChange &&
      dateRange[0] != null &&
      dateRange[1] != null
    ){
      dateRangeProps.handleComponentChange({
        startingDate: moment(dateRange[0]).format('YYYYMMDD'),
        endingDate: moment(dateRange[1]).format('YYYYMMDD'),
      })
    }
  }
  return (
    <DatePickerInput
      type='range'
      value={dateRange}
      onChange={handleOnChange}
      rightSection={
        !dateRange[0] ? <span className={'filter-arrow-down'} /> : null
      }
      valueFormat={'MM/DD/YYYY'}
      maxDate={dateRangeProps.maxDate}
      minDate={dateRangeProps.minDate}
      disabled={dateRangeProps.searchIsLoading}
      style={
        dateRangeProps.widthFilter !== null
          ? { width: dateRangeProps.widthFilter }
          : { width: '280px' }
      }
    />
  )
}
interface DateRangeProps {
  searchIsLoading?: boolean
  textPlaceholder?: string
  widthFilter?: string
  startDate?: string
  endDate?: string
  handleComponentChange?: Function
  maxDate?: Date
  minDate?: Date
  dateFormat?: string
}
export default DateRangeComponent
