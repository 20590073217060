import React from 'react'
import { DistributionWork } from '../../../types/distributionsRoyaltiesTypes'
import { useTranslation } from 'react-i18next'
import { formatCurrencyNumber } from '../../../helpers/formatter'

import '../distributionRoyalties.scss'

const DistributionWorks = ({ works }: DistributionWorksProps) => {
  const { i18n } = useTranslation()
  return (
    <>
      {works.map((distribution, index) => {
        return (
          <div
            className='distribution-works-box'
            key={distribution.code + '-' + index}
          >
            <div className='distribution-works-box-item'>
              <div className='item-title' id={'dist-works-' + index}>
                {distribution.title}
              </div>
              {/* </OverlayTrigger> */}

              <div className='item-amount'>
                {formatCurrencyNumber(i18n.language, distribution.generatedAmount)}
              </div>
            </div>
            {index + 1 !== works.length && <div className='separator'></div>}
          </div>
        )
      })}
    </>
  )
}
export default DistributionWorks

interface DistributionWorksProps {
  works: DistributionWork[]
}
