import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

/**
 * Custom tooltip wrapper
 * add tooltip for too long labels into div
 * default max-width : 350px
 * @param refElem : element reference
 * @constructor
 */

interface customTooltipPropType {
  childId: string | number
  children: any
  refElem?: any
}

const CustomTooltip = ({
  childId,
  refElem,
  children,
}: customTooltipPropType) => {
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)
  const [isListOfRef, setIsListOfRef] = useState<boolean>(false)

  const isEllipsisActive = (e: HTMLElement) => {
    return e.clientWidth < e.scrollWidth
  }

  useEffect(() => {
    if (refElem.current != null) {
      let isListRefTrue = false
      if (refElem.current != null && refElem.current instanceof Array)
        isListRefTrue = true
      setIsListOfRef(isListRefTrue)

      const childNode = isListRefTrue
        ? refElem.current[childId].childNodes[0]
        : refElem.current
      setIsOverflowing((prev) => {
        const isEll = isEllipsisActive(childNode)
        return isEll
      })
    }
  }, [refElem, children, isListOfRef])

  const TooltipElem = () => {
    return isListOfRef ? (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id='long-label-tooltip'>
            {refElem.current[childId].childNodes[0].innerText}
          </Tooltip>
        }
      >
        <div
          className={'long-label-tooltip etc'}
          dangerouslySetInnerHTML={{
            __html: refElem.current[childId].childNodes[0].innerHTML,
          }}
        />
      </OverlayTrigger>
    ) : (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id='long-label-tooltip'>{children.props.children}</Tooltip>
        }
      >
        {children}
      </OverlayTrigger>
    )
  }

  return isOverflowing ? <TooltipElem /> : <>{children}</>
}

export default CustomTooltip
