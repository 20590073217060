import React  from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import {useNavigate} from "react-router";

const Breadcrumb = (props: Props) => {
	const {
		title,
		homeOnclick,
		showBtn,
		btnTitle,
		buttonAction,
		cssBtn,
		showHome,
		parent,
		featFlag
	} = props;
	const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

	return (
		<div className='breadcrumb'>
			<div className='container d-flex justify-content-between align-items-center'>
				<div className='d-flex justify-content-between'>
					{showHome && (
						<>
							<div className='home' onClick={homeOnclick} />
							<div className='separator' />
						</>
					)}
					{parent && (
						<>
							<div className='title link' onClick={goBack}>{parent}</div>
							<div className='separator' />
						</>
					)}
					<div className='title'>{title} 
						{(featFlag && ['alpha', 'beta'].includes(featFlag)) &&
						<sup>
							<span className="badge beta-badge flag-tag">{featFlag ? featFlag : ''}</span>
						</sup>
						}
					</div>
				</div>
				{showBtn && buttonAction && (
					<div>
						<Link to={buttonAction}>
							<div className={cssBtn}>
								<div>{btnTitle}</div>
							</div>
						</Link>
					</div>
				)}
			</div>
		</div>
	);
};

export default Breadcrumb;

interface Props {
	buttonAction?: string
  title: string
  homeOnclick?: any
  dataTestId?: string
  btnTitle?: string
  onClick?: any
  showBtn?: boolean
  disableBtn?:boolean
  cssBtn?: string
  showHome?: boolean
  parent?: string
  featFlag?: string
}
