import React from 'react';
import Form from 'react-bootstrap/Form';

import './index.scss';

const InputSelect: React.FC<InputSimpleProps> = ({
	error,
	id,
	name,
	onChange,
	onBlur,
	label,
	value,
	required,
	touched,
	emptyOption,
	options,
	className,
	optionValue = 'value',
	optionLabel = 'label',
	withMessageUnder = true
}) => {
	return (
		<>
			<Form.Group>
				{label && (<Form.Label>{label}</Form.Label>)}
				<Form.Select
					id={id}
					name={name}
					onChange={onChange}
					value={value}
					className={`${className} ${error && touched && 'is-invalid'}`}
					onBlur={onBlur}
					required={required}
				>
					{emptyOption &&
                        <option value="" key={emptyOption?.key}>
                        	{emptyOption.label}
                        </option>
					}
					{options.map((option: any, i) => {
						if(optionLabel.includes('.')){
							const obj = optionLabel.split('.');
							const s1 = option[obj[0]];
							return (<option value={option[optionValue]} key={`${id}-${i}`}>
								{s1[obj[1]]}
							</option>);
						} else {
							return (<option value={option[optionValue]} key={`${id}-${i}`}>

								{option[optionLabel]}
							</option>);
						}

					})}

				</Form.Select>
				{error && touched && (
					<div className="fade error-field show">{withMessageUnder && error}</div>)
				}
			</Form.Group>
		</>
	);
};

export default InputSelect;

interface InputSimpleProps {
    label?: string;
    id?: string;
    name: string;
    value?: any;
    onChange: any;
    onBlur?: any;
    error?: string;
    required?: boolean;
    touched?: boolean;
    disabled?: boolean;
    className?: string;
    emptyOption?: {
        label: string
        value: string | undefined
        key?: string
    },
    options: any[]
    optionValue?: string | number;
    optionLabel?: string
    withMessageUnder? : boolean
}
