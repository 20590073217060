import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './index.scss';

interface ModalPropsType {
  open: boolean;
  content?: string;
  onValidate?: () => void;
  onClose: () => void;
  alignButtons?: 'right' | 'bottom'
  confirmLabel?: string
  cancelLabel?: string
  withAction?: boolean
	isErrorModal?: boolean
	disableValidate?: boolean
	children? : React.ReactElement[]
}

const ConfirmationModal: React.FC<ModalPropsType>  = ({
	open,
	content,
	alignButtons = 'bottom',
	confirmLabel,
	cancelLabel,
	onClose,
	onValidate,
	isErrorModal = false,
	withAction = true,
	disableValidate = false,
	children
}) => {
	const { t } = useTranslation();
	if (!open) {
		return null;
	}

	return (
		<div className={ `overlay-modal confirmation-modal ${isErrorModal ? 'error-modal' : ''}`}>
			<div className="modal-container">

				<div className={`modal-content ${alignButtons === 'bottom' ? 'flex-row' : ''}`}>
					{ children ? children : (
						<>
							{ content && (
								<div
									className="modal-text mt-3 px-2"
									dangerouslySetInnerHTML={{ __html: content }}
								/>
							)}
							{!withAction && (
								<div className="modal-actions px-1 mx-0">
									<button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
								</div>
							)}
						</>
					)}
				</div>

				{withAction && (
					<div className={`modal-actions ${alignButtons === 'bottom' ? 'mt footer-bg' : 'ml'}`}>
						<Button onClick={onClose} className='btn-cancel'>{cancelLabel ? cancelLabel : t('modal.no')}</Button>
						<Button
							onClick={onValidate}
							disabled={disableValidate}
							className='btn-validate'
						>
							{confirmLabel ? confirmLabel : t('modal.yes')}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default ConfirmationModal;
