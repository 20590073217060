export const formatNumber = (language: string, value: number, keepFractionDigits: boolean = true): string => {
  if (value === null || value === undefined) return ''
  if (!keepFractionDigits) {
    keepFractionDigits = value % 1 !== 0
  }
  const locale = language === 'en' ? 'en-US' : 'fr-FR'
  return value
    .toLocaleString(locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: keepFractionDigits ? 2 : 0,
    })
    .replaceAll(' ', '  ')
}

export const formatCurrencyNumber = (language: string, value: number): string => {
  const locale = language === 'en' ? 'en-US' : 'fr-FR'
  return new Intl.NumberFormat(
    locale,
    { style: 'currency', currency: 'EUR' }
  ).format(value)
}
