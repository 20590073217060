import { ChartDataset, ScaleOptionsByType } from "chart.js";

import { UsageDataStreamPerWorkDetails } from "../../types";
import { PlatformColors } from "./platformColors";
import moment from 'moment'

const DATE_FORMAT = "YYYYMM"

const dateDisplayFormat = {
    day: 'MM/YYYY',
    week: 'MM/YYYY',
    month: 'MMM YYYY',
    quarter: 'MM/YYYY',
    year: 'YYYY',
}

export function getXOptions(startDate: string, endDate: string, lang : 'fr' | 'en' = 'en') {
    moment.locale(lang)
    return {
        type: "time",
        min: moment(startDate, DATE_FORMAT).format('YYYY-MM-DD'),
        max: moment(endDate, DATE_FORMAT).format('YYYY-MM-DD'),
        time: {
            parser: DATE_FORMAT,
            unit: 'month',
            displayFormats: dateDisplayFormat,
            tooltipFormat: dateDisplayFormat.month,
        },
        ticks: {
            autoSkip: false,
            maxRotation: 35,
            minRotation: 35,
        },
    }
}

export function transformData(platformData: UsageDataStreamPerWorkDetails, idx: number): ChartDataset<'line', { x: string; y: number }[]> {
    return {
        label: platformData.title,
        borderColor: PlatformColors[idx % PlatformColors.length],
        tension: 0.5,
        hidden: false,
        data: platformData.usagePerMonth.map((item) => {
            return {
                x: String(item.date),
                y: item.total,
            }
        }),
    } as ChartDataset<'line', { x: string; y: number }[]>
}