import React, { Fragment, useEffect, useState } from 'react';
import {connect, useDispatch} from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { addMonths, subMonths } from 'date-fns';
import moment from 'moment';
import InputDate from '../../components/inputDate';
import InputSelect from '../../components/inputSelect';
import {CHEF_ORCHESTER, EVENT_TYPE} from '../../types/constants';
import {fetchRepresentations, getRepresentationsTypes, postRepresentations} from '../../api/representationsAPI';
import DynamicField from './components/DynamicField';
import NavigateBackButton from '../../components/navigateBack';
import Overlay from '../../components/overlay';
import { COUNTRIES } from '../../helpers/countries';
import iconInfoBlack from './../../assets/img/icon-info-bg-black.svg';
import ConfirmationModal from '../../components/confirmationModal';
import ToggleSwitch from '../../components/ToggleSwitch';
import TracoRgpd from '../../components/tracoRgpd';
import { fetchPlaceDetails, fetchPlacesSuggest } from '../../api/commonsApi';
import { setBreadcrumb } from '../../slices/appContextSlice';
import { fetchTypicalPrograms } from '../../api/typicalProgramsAPI';
import { ROLES, ROUTES } from '../../constants';

import './index.scss';
import {getDelegationsLink} from "../../helpers";
import {AppPropsType, SisterSociety} from "../../types";
import useRoleRedirect from '../../hooks/useRoleRedirect';

const CreateDate = (props: Props) => {
	useRoleRedirect(ROLES.WEBSO_WEB_ONLINE);
	const {selectedSisterSocitieId} = props;
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [optionsAssociatedPrograms, setOptionsAssociatedPrograms] = useState<any[]>([]);
	const [optionsEventType, setOptionsEventType] = useState<any[]>([]);
	const [selected, setSelected] = useState<any[]>([]);
	const [optionsAddress, setOptionsAddress] = useState<any[]>([]);
	const [isLoadingSearch, setIsLoadingSearch] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [eventTypeSelected, setEventTypeSelected] = useState<string | undefined>('');
	const [showPopupConfirm, setShowPopupConfirm] = useState(false);
	const [showPopupWarning, setShowPopupWarning] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [contentToast, setContentToast] = useState('');
	const [manualFill, setManualFill] = useState(false);
	const emptyFieldsLabel = t('errors.empty-fields');
	const invalidClassName = 'is-invalid';
	const emptyOptionKey = 'event-type-empty';
	const confirmButtonLabel = t('form.btn.confirm');

	const validationSchema = Yup.object().shape({
		eventTitle: Yup.string().required(emptyFieldsLabel).max(50, t('errors.fiftyLimit-Date')),
		associatedPrograms: Yup.string().required(emptyFieldsLabel),
		eventDate: Yup.string().required(emptyFieldsLabel),
		performers: Yup.string().required(emptyFieldsLabel),
		customers: Yup.string(),
		location: Yup.string().required(emptyFieldsLabel).max(100, t('errors.field-max-length', {
		  field: i18n.language === "en" ? "Location/Venue" : "Nom de salle",
		  maxLength: "100",
		})),
		StreetName: Yup.string().required(emptyFieldsLabel),
		city: Yup.string().required(emptyFieldsLabel),
		zipCode: Yup.string().required(emptyFieldsLabel),
		country: Yup.string().required(emptyFieldsLabel),
		eventType: Yup.string().required(emptyFieldsLabel),
		onePart: Yup.string().test(
			'not empty', emptyFieldsLabel, (value => {
				const check = true;
				/*if (eventTypeSelected === EVENT_TYPE.ONE_PART && !value) {
					check = false;
				}*/
				return check;
			})
		),
	});

	const initialValues = {
		eventTitle: '',
		associatedPrograms: '',
		eventDate: '',
		performers: '',
		customers: '',
		location: '',
		address: '',
		StreetNumber: '',
		StreetName: '',
		postBox: '',
		additionalInfo: '',
		zipCode: '',
		city: '',
		country: '',
		eventType: '',
		optionsAssociatedPrograms: [],
		multiStage: [{ value: '' }],
		festivalName: '',
		onePart: '',
		memberId: '',
		representationComplements: [''],
	};

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit(values) {
			if (values.eventType === EVENT_TYPE.FESTIVAL) {
				values.representationComplements = [values.festivalName];
			} else if (values.eventType === EVENT_TYPE.ONE_PART) {
				values.representationComplements = [values.onePart];
			} else if (values.eventType === EVENT_TYPE.MULTI_STAGE) {
				values.representationComplements = values.multiStage.map((item: any) => item.value);
			}
			values.representationComplements = values.representationComplements.filter((n: any) => n !== '');

			const { optionsAssociatedPrograms, ...otherValues } = values;
			const isBeforeDate = moment(values.eventDate, 'YYYY-MM-DD').isSameOrBefore(moment());
			postForm(otherValues)
				.then(() => navigate(`${ROUTES.GET_EVENTS}?toast=${otherValues.eventTitle}&toast-before-date=${isBeforeDate ? 1 : 0}`))
				.catch(() => {
					setShowToast(true);
					setContentToast(t('errors.generic'));
				});
		},
		validationSchema: validationSchema,
	});

	useEffect(() => {
		if (formik.touched.zipCode) {
			formik.setFieldTouched('zipCode', true);
		}
	}, [formik.values.country]);

	const postForm = (async (values: any) => await postRepresentations(values));

	const fetchTypicalProgram = () => {
		fetchTypicalPrograms({
			pagination: {
				page: 1,
				size: 500
			},
			societyId: selectedSisterSocitieId ? selectedSisterSocitieId.sisterSocietyId.toString() : ''
		}).then(({ typicalPrograms, typicalProgramsCount }) => {
			const results = typicalPrograms?.map((item: any) => (
				({
					value: item.typicalProgramId,
					typicalProgramCode: item.typicalProgramType.typicalProgramTypeCode,
					memberId: item.typicalProgramOwner.memberId,
					label: `${item.typicalProgramId} - ${item.typicalProgramTitle}`,
				})
			)
			).filter((elem: any) => elem.typicalProgramCode !== CHEF_ORCHESTER);
			setOptionsAssociatedPrograms(results);
			formik.setFieldValue('optionsAssociatedPrograms', results);
			setIsLoading(false);
			const selectedProgram = searchParams.get('programId');
			if (selectedProgram) {
				const typicalProgram: any = typicalPrograms.find((item: any) => (item.typicalProgramId.toString() === selectedProgram));
				setEventTypes(typicalProgram.typicalProgramId, typicalProgram.typicalProgramType?.typicalProgramTypeCode);
				formik.setFieldValue('associatedPrograms', selectedProgram);
				formik.setFieldValue('memberId', typicalProgram.typicalProgramOwner?.memberId);
			}
		})
			.catch(e => console.error('Fail to get setlists'));
	};

	useEffect(() => {
		dispatch(setBreadcrumb({
			title: t('page.create-date'),
			showHome: true
		}));
		fetchTypicalProgram();
	}, []);

	const checkExistedDate = async () => {

		fetchRepresentations({
			societyId: selectedSisterSocitieId ? selectedSisterSocitieId.sisterSocietyId : undefined,
			pagination: {
				page: 1,
				size: 1
			},
			sortOption: {
				sortField: 'startDate',
				sortOrder: 'desc'
			},
			filters: {
				startDate: formik.values.eventDate,
				endDate: formik.values.eventDate,
				programId: formik.values.associatedPrograms
			},
		}).then((data: any) => {
			if (data.representations && data.representations.length &&
				data.representations[0].typicalProgram.typicalProgramId.toString() === formik.values.associatedPrograms) {
				setShowPopupWarning(true)
			} else {
				setShowPopupConfirm(true);
			}
		}).catch((e: any) => {
			console.error(e)
		})
	}

	const setEventTypes = (programId: string, programTypeCode: string) => {
		if (programId && programTypeCode) {
			const societyId = selectedSisterSocitieId ? selectedSisterSocitieId.sisterSocietyId.toString() : ''
			getRepresentationsTypes(societyId, programTypeCode)
				.then((data) => {
					setOptionsEventType(data);
				})
				.catch(e => console.error('Fail to get event types'))
		}
	};

	const selectAssociatedPrograms = async (e: React.ChangeEvent<{ value: string | undefined }>) => {
		await formik.setFieldValue('associatedPrograms', e.target.value);

		if (e.target.value) {
			const typicalProgram: any = optionsAssociatedPrograms.find((item) => (item.value.toString() === e.target.value));
			setEventTypes(typicalProgram.value, typicalProgram.typicalProgramCode);
			await formik.setFieldValue('memberId', typicalProgram.memberId);
		} else {
			await formik.setFieldError('associatedPrograms', emptyFieldsLabel);
		}
	};

	const selectEventType = async (e: React.ChangeEvent<{ value: string | undefined }>) => {
		await formik.setFieldValue('eventType', e.target.value);
		setEventTypeSelected(e.target.value);

		async function conditionalDynamicFieldsValues() {
			await formik.setFieldValue('onePart', '');
			await formik.setFieldValue('multiStage', [{ value: '' }]);
			await formik.setFieldValue('festivalName', '');
		}

		if (e.target.value === EVENT_TYPE.ONE_PART && !formik.values.onePart) {
			formik.setFieldError('onePart', emptyFieldsLabel);
			await conditionalDynamicFieldsValues();
		} else if (e.target.value && ![EVENT_TYPE.MAIN_SHOW, EVENT_TYPE.CONCERT].includes(e.target.value)) {
			await conditionalDynamicFieldsValues();
			const { onePart, eventType, ...errors } = formik.errors;
			formik.setErrors(errors);
		} else if (e.target.value && [EVENT_TYPE.MAIN_SHOW, EVENT_TYPE.CONCERT].includes(e.target.value)) {
			await conditionalDynamicFieldsValues();
			// eslint-disable-next-line no-prototype-builtins
			if (formik.errors.hasOwnProperty('onePart')) {
				delete formik.errors.onePart;
			}
			const { eventType, ...errorsFormik } = formik.errors;
			formik.setErrors(errorsFormik);
		}
		if (!e.target.value) {
			formik.setFieldError('eventType', emptyFieldsLabel);
		}
	};

	const handleSearch = async (query: string) => {
		if (query.length >= 5) {
			setIsLoadingSearch(true);
			fetchPlacesSuggest(query, 10)
				.then(res => {
					setOptionsAddress(res);

				})
				.catch(e => console.error('Fail to get places suggest'))
				.finally(() => setIsLoadingSearch(false));
		}
	};

	const handleChange = (selected: any) => {
		if (selected.length === 0){
			return;
		}
		const id = selected[0].id;
		fetchPlaceDetails(id)
			.then(res => {
				const { address } = res;

				const address1 = address?.address1;
				const spaceCharIndex = address1?.indexOf(' ');
				const number = address1?.substr(0, spaceCharIndex);
				const street = address1?.substr(spaceCharIndex + 1);
				const isNumeric = !isNaN(Number(number));
				formik.setFieldValue('StreetName', isNumeric ? street : address1, true);
				formik.setFieldValue('StreetNumber', isNumeric ? number : '', true);
				formik.setFieldValue('postBox', '', true);
				formik.setFieldValue('additionalInfo', '', true);
				formik.setFieldValue('city', address.city, true);
				formik.setFieldValue('zipCode', address.zipCode, true);
				formik.setFieldValue('country', address.country.code, true); // handle exceptions function
				setManualFill(true);
			})
			.catch(e => console.error('Fail to get place detail'));
	};

	const handleFocusAddress = () => {
		setSelected([]);
		formik.setFieldValue('address', '');
	};

	const handleBlurAddress = (e: any) => {
		if (!e.target.value) {
			formik.setFieldError('address', emptyFieldsLabel);
			const TouchedField: any = {};
			TouchedField[e.target.name] = true;
			formik.setFieldTouched('address', true);
		}
	};
	const handleBlurProgram = (e: any) => {
		if (!e.target.value) {
			formik.setFieldError('associatedPrograms', emptyFieldsLabel);
			formik.setFieldTouched('associatedPrograms', true);
		}
	};
	const handleBlurEventType = (e: any) => {
		if (!e.target.value) {
			formik.setFieldError('eventType', emptyFieldsLabel);
			formik.setFieldTouched('eventType', true);
		}
	};
	const handleBlurCountry = (e: any) => {
		const value = e.target.value;
		formik.setFieldValue('country', value);
		if (!value) {
			formik.setFieldTouched('country', true);
		}
	};
	const additionalField = () => {
		switch (formik.values.eventType) {
			case EVENT_TYPE.FESTIVAL:
				return <DynamicField formik={formik}
														 label={t('create-date.form-festival')}
														 fieldName="festivalName"
														 placeholder={t('create-date.form-festival')}
				/>;
			case EVENT_TYPE.MULTI_STAGE:
				return <DynamicField formik={formik}
														 label={t('create-date.form-multi-stage')}
														 fieldName="multiStage"
														 multipleFields={true}
														 placeholder={t('create-date.form-multi-stage')}
				/>;
			case EVENT_TYPE.ONE_PART:
				return <DynamicField formik={formik}
														 label={t('create-date.form-one-part')}
														 fieldName="onePart"
														 placeholder={t('create-date.form-one-part')}
				/>;
		}
	};
	const handleInputError = (field: any) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const error = formik.errors[field];
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const touched = formik.touched[field];

		if (touched && error) {
			return <div className="fade error-field show">{error}</div>;
		}

		return null;
	};

	const filterBy = () => true;
	const handleReset = () => {
		setManualFill(false);
		setSelected([]);
		formik.resetForm();
		formik.setValues(initialValues);
	};
	const selectCountry = (e: any) => {
		const value = e.target.value;
		formik.setFieldValue('country', value);
		if (!value) {
			formik.setFieldTouched('country', true);
		}
	};

	const handleClose = () => setShowPopupConfirm(false);

	const handleConfirm = async () => {
		setShowPopupConfirm(false);
		await formik.handleSubmit();
	};
	const onChangeSwitch = () => {
		formik.setFieldValue('country', '');
		formik.setFieldValue('StreetName', '');
		formik.setFieldValue('postBox', '');
		formik.setFieldValue('StreetNumber', '');
		formik.setFieldValue('additionalInfo', '');
		formik.setFieldValue('city', '');
		formik.setFieldValue('zipCode', '');
		setManualFill(!manualFill);

		if (!formik.values.country) {
			formik.setFieldValue('country', 'FR');
		}
	};
	const handleConfirmWarning = async () => {
		setShowPopupWarning(false);
		setShowPopupConfirm(true);
	};

	if (!isLoading && !optionsAssociatedPrograms?.length) {
		return (
			<Container>
				<div className="no-programs">
					<p>{t('errors.no-programs')}</p>
					<Link to="/creer-programme">
						{t('page.title.createProgram')}
					</Link>
				</div>
			</Container>
		);
	}

	const renderEventTitleFormGroup = () => {
		return (
		<Form.Group className="mb-4">
			<Form.Label>{t('create-date.form-field-title')}</Form.Label>
			<Form.Control
				className={`form-control ${formik.errors.eventTitle && formik.touched.eventTitle && invalidClassName}`}
				id="eventTitle"
				name="eventTitle"
				type="text"
				placeholder={`${t('create-date.placeholder.event-title')}`}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values.eventTitle}
				autoComplete="false"
			/>

			{handleInputError('eventTitle')}

		</Form.Group>)
	}

	const renderAssociatedSetlistsFormGroup = () => {
		return (
			<Form.Group className="mb-4">
				<InputSelect
					id="associatedPrograms"
					name="associatedPrograms"
					label={`${t('create-date.form-associated-programs')}`}
					onChange={selectAssociatedPrograms}
					value={formik.values.associatedPrograms}
					error={formik.errors.associatedPrograms}
					touched={formik.touched.associatedPrograms}
					className={formik.errors.associatedPrograms && formik.touched.associatedPrograms ? invalidClassName : ''}
					onBlur={handleBlurProgram}
					required
					emptyOption={
						{
							label: t('create-date.placeholder.associated-programs'),
							value: '',
							key: emptyOptionKey
						}
					}
					options={optionsAssociatedPrograms || []}
					optionValue="value"
					optionLabel="label"
				/>
			</Form.Group>
		)
	}

	const renderDateFormGroup = () => {
		return(
			<Form.Group className="mb-4">
				<div className="d-flex align-items-center justify-content-between mb-2">
					<Form.Label className="m-0">
						{t('create-date.form-event-date')}
					</Form.Label>
					<Overlay content={t('create-date.tooltip-date')} placement="top">
						<img src={iconInfoBlack} alt="info"/>
					</Overlay>
				</div>
				<InputDate
					id="eventDate"
					name="eventDate"
					className="diffusion-date"
					required={true}
					includeDateIntervals={[{ start: subMonths(new Date(), 9), end: addMonths(new Date(), 6) }]}
					onChange={(value: any) => {
						const date = moment(value);
						formik.setFieldValue('eventDate', date.format('YYYY-MM-DD'));
					}}
					onBlur={formik.handleBlur}
					value={formik.values.eventDate}
					error={formik.errors.eventDate}
					touched={formik.touched.eventDate}
					placeholder={t('create-date.placeholder.event-date')}
					minDate={subMonths(new Date(), 9)}
					maxDate={addMonths(new Date(), 6)}
				/>
			</Form.Group>
		)
	}

	const renderPerformersFormGroup = () => {
		return (
			<Form.Group className="mb-4">
				<Form.Label>{t('create-date.form-performers')}</Form.Label>
				<Form.Control
					className={`form-control ${formik.errors.performers && formik.touched.performers && invalidClassName}`}
					id="performers"
					name="performers"
					type="text"
					placeholder={t('create-date.placeholder.performers')}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.performers}
					autoComplete="false"
				/>
				{handleInputError('performers')}
			</Form.Group>
		)
	}

	const renderCustomersFormGroup = () => {
		return (
			<Form.Group className="mb-4">
				<div className="d-flex align-items-center justify-content-between mb-1">
					<Form.Label>{t('create-date.form-organiser')}</Form.Label>
					<Overlay content={t('create-date.tooltip-organiser')} placement="top">
						<img src={iconInfoBlack} alt="info"/>
					</Overlay>
				</div>
				<Form.Control
					className={`form-control ${formik.errors.customers && formik.touched.customers && invalidClassName}`}
					id="customers"
					name="customers"
					type="text"
					placeholder={t('create-date.placeholder.customers')}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.customers}
					autoComplete="false"
				/>
				{handleInputError('customers')}

			</Form.Group>
		)
	}

	const renderLocationVenueFormGroup = () => {
		return (
			<Form.Group className="mb-4">
				<Form.Label>{t('create-date.form-location')}</Form.Label>
				<Form.Control
					className={`form-control ${formik.errors.location && formik.touched.location && invalidClassName}`}
					id="location"
					name="location"
					type="text"
					placeholder={t('create-date.placeholder.location')}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.location}
					autoComplete="false"
				/>
				{handleInputError('location')}
			</Form.Group>
		)
	}

	const renderAutomaticAddressFormGroup = () => {
		return (<Form.Group>
			<Form.Label>
				{t('create-date.form-address')}
			</Form.Label>
			<AsyncTypeahead
				selected={selected}
				className={`address-autocomplete form-select
${(
formik.errors.StreetName || formik.touched.zipCode || formik.errors.city || formik.errors.country) && formik.touched.address && invalidClassName}`}
				labelKey="description"
				filterBy={filterBy}
				id="address"
				isLoading={isLoadingSearch}
				minLength={3}
				promptText={t('create-date.autocomplete-search-prompt')}
				searchText={t('create-date.autocomplete-search-text')}
				emptyLabel={t('create-date.form-address-not-found')}
				onSearch={handleSearch}
				onChange={handleChange}
				onFocus={handleFocusAddress}
				onBlur={handleBlurAddress}
				options={optionsAddress}
				placeholder={t('create-date.placeholder.address')}
				renderMenuItemChildren={(option: any) => (
					<Fragment>
						<span>{option.description}</span>
					</Fragment>
				)}
			/>
			{handleInputError('address')}

		</Form.Group>)
	}

	const renderManualAddressFormGroup = () => {
		return (<div className="FormCard">
			<Form.Group className="d-flex flex-row justify-content-between mb-4 ">
				<Form.Control
					className={`case1 form-control ${formik.errors.StreetNumber && formik.touched.StreetNumber && invalidClassName}`}
					id="StreetNumber"
					name="StreetNumber"
					type="text"
					pattern="[0-9]"
					placeholder={t('ManualAddress.placeholder.number')}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.StreetNumber}
					autoComplete="off"
				/>

				<Form.Control
					className={`case2 form-control ${formik.errors.StreetName && formik.touched.StreetName && invalidClassName}`}
					id="StreetName"
					name="StreetName"
					type="text"
					placeholder={t('ManualAddress.placeholder.street')}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.StreetName}
					autoComplete="off"
				/>
			</Form.Group>

			<Form.Group className="mb-4">
				<Form.Control
					className={`form-control ${formik.errors.postBox && formik.touched.postBox && invalidClassName}`}
					id="postBox"
					name="postBox"
					type="text"
					placeholder={t('ManualAddress.placeholder.post-box')}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.postBox}
					autoComplete="off"
				/>
			</Form.Group>

			<Form.Group className="mb-4">
				<Form.Control
					className={`form-control ${formik.errors.additionalInfo && formik.touched.additionalInfo && invalidClassName}`}
					id="additionalInfo"
					name="additionalInfo"
					type="text"
					placeholder={t('ManualAddress.placeholder.additional-info')}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.additionalInfo}
					autoComplete="off"
				/>

			</Form.Group>

			<Form.Group className="d-flex flex-row justify-content-between mb-4">
				<Form.Control
					className={`case1 form-control ${formik.errors.zipCode && formik.touched.zipCode && invalidClassName}`}
					id="zipCode"
					name="zipCode"
					type="text"
					placeholder={t('ManualAddress.placeholder.zip-code')}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.zipCode}
					autoComplete="off"

				/>
				<Form.Control
					className={`case2 form-control ${formik.errors.city && formik.touched.city && invalidClassName}`}
					id="city"
					name="city"
					type="text"
					placeholder={t('ManualAddress.placeholder.city')}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.city}
					autoComplete="off"
				/>

			</Form.Group>
			<Form.Group className="mb-4">
				<InputSelect
					id="country"
					name="country"
					className="form-control"
					onChange={selectCountry}
					value={formik.values.country}
					error={formik.errors.country}
					touched={formik.touched.country}
					onBlur={handleBlurCountry}
					withMessageUnder={false}
					required
					emptyOption={
						{
							label: t('ManualAddress.placeholder.country'),
							value: 'FR',
							key: emptyOptionKey
						}
					}
					options={COUNTRIES}
					optionValue={'iso'}
					optionLabel={i18n.language === 'en' ? 'translations.EN' : 'translations.FR'}
				/>
			</Form.Group>
		</div>)
	}

	const renderEventTypeFormGroup = () => {
		return (<Form.Group className="mb-4">
			<InputSelect
				id="eventType"
				name="eventType"
				label={t('create-date.form-event-type')}
				onChange={selectEventType}
				value={formik.values.eventType}
				error={formik.errors.eventType}
				touched={formik.touched.eventType}
				onBlur={handleBlurEventType}
				required
				emptyOption={
					{
						label: t('create-date.placeholder.event-type'),
						value: '',
						key: emptyOptionKey
					}
				}
				options={optionsEventType}
				optionValue="representationTypeQualificationCode"
				optionLabel="representationTypeQualificationLabel"
			/>
		</Form.Group>)
	}

	return (
		<div className="bg-date">
			<Container>
				<Row>
					<Col xs lg="6" className='mt-2'>
						<NavigateBackButton url={ROUTES.GET_EVENTS}/>
					</Col>
				</Row>
			</Container>

			<Container>
				<Row className="justify-content-md-center">
					<Col md="auto">
						<Card className="date-card">
							<div className="header-card">
								{t('create-date.title')}
							</div>
							<Card.Body>
								<Form className="form-add-date">
									{renderEventTitleFormGroup()}
									{renderAssociatedSetlistsFormGroup()}
									{renderDateFormGroup()}
									{renderPerformersFormGroup()}
									{renderCustomersFormGroup()}
									{renderLocationVenueFormGroup()}

									{!manualFill && renderAutomaticAddressFormGroup()}
									<Form.Group className={`d-flex flex-row ${!manualFill ? 'mb-2' : ''}`}>
										<ToggleSwitch
											action={onChangeSwitch}
											checked={manualFill}
											label={t('ManualAddress.autocomplete-toggle')}
										/>
									</Form.Group>
									{manualFill && renderManualAddressFormGroup()}

									{renderEventTypeFormGroup()}
									{additionalField()}

									<div className="container-btn-date">
										<Button type="reset" className="cancel-btn" onClick={() => {
											handleReset();
										}}>
											{t('form.btn.cancel')}
										</Button>
										<Button type="button" disabled={!formik.isValid} onClick={checkExistedDate}>
											{confirmButtonLabel}
										</Button>
									</div>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
			<ConfirmationModal
				open={showPopupConfirm}
				content={getDelegationsLink(t, i18n.language)}
				onValidate={handleConfirm}
				onClose={handleClose}
				confirmLabel={confirmButtonLabel}
				alignButtons="bottom"
			/>
			<ConfirmationModal
			open={showPopupWarning}
			content={t('create-date.warning-existed-date')}
			onValidate={handleConfirmWarning}
			onClose={() => setShowPopupWarning(false)}
			confirmLabel={confirmButtonLabel}
			alignButtons="bottom"
		/>
			<Container>
				<div className="row justify-content-md-center rgpd-bottom">
					<Col xs={12} md={10} className="mx-auto">
						<TracoRgpd
							className="col-12 mx-auto"
							classNameRgpd="no-gutters"
						/>
					</Col>
				</div>
			</Container>

			<ToastContainer className="p-3" position="top-center">
				<Toast show={showToast} onClose={() => setShowToast(false)} delay={10000} autohide>
					<Toast.Body >{t(contentToast)}</Toast.Body>
				</Toast>
			</ToastContainer>
		</div>
	);
};

const mapStateToProps = (state: AppPropsType) => ({
	selectedSisterSocitieId: state.appContext.selectedSisterSociety
});

export default connect(mapStateToProps)(CreateDate);

interface Props{
	selectedSisterSocitieId: SisterSociety|null
}
