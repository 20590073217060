import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row, Form, Popover, OverlayTrigger } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useFormik } from 'formik';

import { deleteWorkFromWorksListAsync, patchWorkInWorksListAsync } from '../../../../slices/setListsSlice';
import ConfirmationModal from '../../../../components/confirmationModal';
import InputDurationGroup, {InputISWCGroup} from '../../../../components/inputGroup/InputGroup';
import WorkTitle from '../../../../components/workTitle';
import TimeLine from '../../../../components/timeLine';
import Overlay from '../../../../components/overlay';
import { formatWorksListWorkInterestedParties, secondsToMinutes } from '../../../../helpers';
import { ReferencedWorkValidation, NonReferencedWorkValidation } from './Validation';
import { fetchIpisSugest } from '../../../../api/commonsApi';
import { IPI } from '../../../../types';

import iconInfoBlack from './../../../../assets/img/icon-info-bg-black.svg';
import './index.scss';

interface Props {
    item: any;
    index: number;
    lengthList: number;
    worksListId: number;
		societyId?: number;
		responseCode?: string;
}

const CustomCollapse = (props: Props) => {
	const { societyId, worksListId, item, index, lengthList, responseCode } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [showMoreDetails, setShowMoreDetails] = useState(false);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [options, setOptions] = useState<any[]>([]);
	const [errorIpiCodes, setErrorIpiCodes] = useState<boolean>(false);
	const [isValid, setIsValid] = useState<boolean>(true);
	const tooltipDateLabel = t('page.new-program.tooltip-date');
	const cardDurationLabel = t('find-oeuvre.card.duration');
	const invalidClassName = 'is-invalid';

	const formattedData = formatWorksListWorkInterestedParties(item);
	useEffect(()=>{
		if (!item.workCode && item.ipiCodes) {
			setOptions(item.ipiCodes);
			formikWork.setFieldValue('ipiCodes', item.ipiCodes);
		} else {
			setOptions(formattedData.rightHolders);
			formikWork.setFieldValue('ipiCodes', formattedData.rightHolders);
		}
	}, [item, responseCode]);
	const initialValues = {
		workCode: formattedData.workCode,
		workISWC: formattedData.workISWCCode,
		title: formattedData.title,
		author: formattedData.strAuthors,
		composer: formattedData.strComposers,
		genre: item.worksListWorkGenre.worksListWorkGenreCode,
		duration: item.worksListWorkDuration !== 0 ? secondsToMinutes(item.worksListWorkDuration) : secondsToMinutes(item.workDuration),
		genreLabel: item.worksListWorkGenre.worksListWorkGenreLabel,
		nrRightholders: '',
		ipiCodes: options
	};

	const formikWork = useFormik({
		initialValues: initialValues,
		validationSchema: item.workCode ? ReferencedWorkValidation(t) : NonReferencedWorkValidation(t),
		onSubmit() {
		},
	});

	useEffect(()=>{
		setErrorIpiCodes(formikWork.values.ipiCodes.length === 0);
		if (formikWork.values.ipiCodes.length) {
			delete formikWork.errors['ipiCodes'];
		} else {
			formikWork.errors.ipiCodes = t('errors.empty-field', { field: "IPI code" });
		}
		setIsValid(formikWork.isValid)
	}, [formikWork]);

	const resetWork = () => {
		formikWork.resetForm({
			values: initialValues,
		});
	};
	const deleteWork = () => {
		const params: any = {
			worksListWorkId: item.worksListWorkId,
			worksListId,
			societyId
		};
		dispatch(deleteWorkFromWorksListAsync(params));
		setShowConfirmDelete(false);
	};

	const displayDetails = () => {
		return (
			<>
				{!editMode && item.workISWCCode &&
					<div className="name-list">
						<Col xs={8} className="font-data">
							<span>{item.workISWCCode}</span>
						</Col>
						<Col xs={4} className="display-label">
							<h5> {t('find-oeuvre.card.code-iswc')}</h5>
						</Col>
					</div>
				}

				{item.workCode &&
					<div className="name-list">
						<Col xs={8} className="font-data">
							<span>{formikWork.values.genreLabel}</span>
						</Col>
						<Col xs={4} className="display-label">
							<h5> {t('find-oeuvre.card.gendre')}</h5>
						</Col>
					</div>
				}
				{
					!editMode &&
					<div className="name-list">
						<Col xs={2}>
							<span className="duration-badge font-data">
								{item.worksListWorkDuration !== 0 ? secondsToMinutes(item.worksListWorkDuration) : secondsToMinutes(item.workDuration)}
							</span>
						</Col>
						<Col xs={7}>
							<Overlay content={tooltipDateLabel} placement="top">
								<img className='info-Program-impro'
									 src={iconInfoBlack} alt="info"/>
							</Overlay>

						</Col>
						<Col xs={3} className="display-label">
							<h5>{cardDurationLabel}</h5>
						</Col>
					</div>}
				{formikWork.values.ipiCodes?.length > 0 && (
					<>
						<Row className="artwork-body" id="Interested-parties">
							<Col className="interested-party-title" xs="auto">
								{t('find-oeuvre.non-referenced-work.label.rightholders')}
							</Col>
							<Col>
								<span className="separator"/>
							</Col>
						</Row>

						<Row>
							<TimeLine
								items={formikWork.values.ipiCodes}
								editorDisplay={false}
								ipiDisplay={!item.workCode}
								index={`rightHolders-${index}`}
							/>
						</Row>
					</>
				)}
				{formattedData.editors?.length > 0 && (
					<>
						<Row className="artwork-body mt-2" id="Editors-interested-parties">
							<Col className="interested-party-title" xs="auto">
								{t('find-oeuvre.card.publisher')}
							</Col>
							<Col>
								<span className="separator"/>
							</Col>
						</Row>

						<Row>
							<TimeLine
								items={formattedData.editors}
								editorDisplay={true}
								index={`editors-${index}`}
							/>
						</Row>
					</>
				)}
				{formattedData.subEditors?.length > 0 && (
					<>
						<Row className="artwork-body mt-2" id="Editors-interested-parties">
							<Col className="interested-party-title" xs="auto">
								{t('find-oeuvre.card.publisher')}
							</Col>
							<Col>
								<span className="separator"/>
							</Col>
						</Row>

						<Row>
							<TimeLine
								items={formattedData.subEditors}
								editorDisplay={true}
								index={`editors-${index}`}
							/>
						</Row>
					</>
				)}
			</>

		);
	};
	const popover = (
		<Popover className="custom-popover" id="popover-action">
			<Popover.Body>
				<ul className="list-group custom-list">
					{(
						<li
							className="list-group-item edit"
							data-item={index}
							onClick={() => {
								setEditMode(true);
								setShowMoreDetails(true);
								document.body.click();
							}}
						>
							{t('form.btn.edit')}
						</li>
					)}
					<li
						className="list-group-item delete"
						onClick={() => {
							setShowConfirmDelete(true);
							document.body.click();
						}
						}
					>
						{t('form.btn.remove')}
					</li>
				</ul>
			</Popover.Body>
		</Popover>
	);
	const filterBy = () => true;
	const handleSearch = (query: string) => {
		fetchIpisSugest(query, 10)
			.then(res => {
				const options : IPI[] = [];
				if (res.ipiNames) {
					res.ipiNames.map(ipiSuggest => {
						return ipiSuggest.numbers.map(code => {
							options.push({
								ipiName: ipiSuggest.displayName,
								ipiCode: code.toString()
							});
						});
					});
					setOptions(options);
				}
			});
	};

	const handelChangeIpi = async (selectedOptions: any[]) => {
		setOptions([]);
		const uniqueSelectedOption = selectedOptions.filter((obj, index) => {
			return index === selectedOptions.findIndex(o => obj.ipiCode === o.ipiCode);
		});
		await formikWork.setFieldValue('ipiCodes', uniqueSelectedOption, true);
	};

	const handleSubmit = () => {
		dispatch(
			patchWorkInWorksListAsync({
				work: formikWork.values,
				worksListWorkId: item.worksListWorkId,
				worksListId,
				societyId
			})
		);
		setEditMode(false);
		setShowMoreDetails(false);
	};

	const renderWorkTitle = () => {
		return (<div key={`work-${item.worksListWorkId}`} id="work-list-title-editable">
			<WorkTitle editable={true} referenced={item.workCode} wrapper={true}>
				<Col className="work-name" xs={5}>
					{editMode && !item.workCode ? (
						<Form.Control
							className={`form-control ${
								!formikWork.values.title && invalidClassName
							}`}
							id="title"
							name="title"
							type="text"
							placeholder={t('non-referenced-work.label.title')}
							value={formikWork.values.title}
							onChange={formikWork.handleChange}
							onBlur={formikWork.handleBlur}
						/>
					) : (
						item.worksListWorkTitle
					)}
					{editMode && formikWork.errors.title &&
				  <div className="error-field">{formikWork.errors.title.toString()}</div>}
				</Col>
				<Col xs={3} md={2} className="work-numbers">{`${index + 1} / ${lengthList}`}</Col>
				{!editMode ? (
					<OverlayTrigger
						trigger={['click']}
						rootClose
						placement="bottom-end"
						overlay={popover}
						rootCloseEvent={'click'}
					>
						<div className="col-md-1 icon-three-dots"> </div>
					</OverlayTrigger>
				) : (
					<div className="col-md-1 icon-three-dots"> </div>
				)}
			</WorkTitle>
		</div>)
	}

	const renderWorkComposer = () => {
		return (<>{editMode && !item.workCode ?
			<>
				<div className="name-list" id="render-composers-list">
					<AsyncTypeahead
						inputProps={{
							id: 'ipiCodes',
							name: 'ipiCodes'
						}}
						selected={formikWork.values.ipiCodes}
						className={`col-8 input-block-autocomplete ${
							formikWork.values.ipiCodes.length < 1 ? invalidClassName : ''
						}`}
						multiple
						labelKey={ (option: Option) =>  {
						//@ts-ignore
							return option.ipiName + ' [ ' + option.ipiCode + ' ] ';
						} }
						filterBy={filterBy}
						id="ipiCodes"
						isLoading={false}
						minLength={3}
						onSearch={handleSearch}
						onChange={handelChangeIpi}
						onBlur={formikWork.handleBlur}
						options={options}
						placeholder={t('find-oeuvre.non-referenced-work.placeholder.ipiCode')}
						searchText={t('form.autocomplete-search-text')}
						emptyLabel={t('form.autocomplete-empty-result')}
						renderMenuItemChildren={(option: any) => (
							<Fragment>
								<span>{option.ipiName + ' [ ' + option.ipiCode + ' ] '}</span>
							</Fragment>
						)}
					/>

					<Col xs={4} className="display-label">
						<h5>{t('find-oeuvre.card.composer')}</h5>
					</Col>

				</div>
				{errorIpiCodes && (
					<div className="fade input-error show">{t('errors.empty-field', { field: "IPI code" })}</div>
				)}
			</>
			:
			formikWork.values.composer.length ?
			<div className="name-list" id="render-composers-list">
				<Col xs={8} className="font-data">
					{formikWork.values.composer}
				</Col>
				<Col xs={4} className="display-label">
					<h5>{t('find-oeuvre.card.composer')}</h5>
				</Col>
			</div>
			: <></>
		}</>)
	}

	const renderWorkAuthors = () => {
		return (<>
			{item.workCode &&
				<div className="name-list" id="render-authors-list">
					<Col xs={8} className="font-data">
						{formikWork.values.author}
					</Col>

					{formattedData.authors.length ? (
						<Col xs={4} className="display-label">
							<h5>{t('find-oeuvre.card.author')}</h5>
						</Col>
					) : (
						''
					)}
				</div>
			}
			{editMode && formikWork.errors.author && formikWork.touched.author && (
				<div className="fade error-field show">{formikWork.errors.author.toString()}</div>)
			}
		</>)
	}

	const renderWorkDuration = () => {
		return (
			<div className="name-list" id="render-work-duration">
				{editMode ? (
					<>
						<Col xs={4}>
							<InputDurationGroup
								className={`form-control ${
									item.workCode && 'duration-width'
								}`}
								id="duration"
								name="duration"
								placeholder={t('find-oeuvre.non-referenced-work.placeholder.duration')}
								value={formikWork.values.duration}
								onChange={formikWork.handleChange}
								error={formikWork?.errors?.duration}
								onBlur={formikWork.handleBlur}
							/>
						</Col>
						<Col xs={4}>
							<Overlay content={tooltipDateLabel} placement="top">
								<img className='info-Program-date'
									src={iconInfoBlack} alt="info"/>
							</Overlay>

						</Col>
						<Col xs={4} className="display-label">
							<h5>{cardDurationLabel}</h5>
						</Col>
					</>
				) : !showMoreDetails && (
					<>
						<Col xs={2}>
							<span className="duration-badge font-data">
								{item.worksListWorkDuration !== 0 ? secondsToMinutes(item.worksListWorkDuration) : secondsToMinutes(item.workDuration)}
							</span>
						</Col>
						<Col xs={7}>
							<Overlay content={tooltipDateLabel} placement="top">
								<img className='info-Program-impro'
									src={iconInfoBlack} alt="info"/>
							</Overlay>

						</Col>
						<Col xs={3} className="display-label">
							<h5>{cardDurationLabel}</h5>
						</Col>
					</>
				)
				}
			</div>
		)
	}

	const renderWorkISWC = () => {
		return (<div className="name-list" id="render-work-iswc-code">
			{editMode && !item.workCode && (
				<>
					<Col xs={8}>
						<InputISWCGroup
							className={`form-control ${
								!formikWork.values.workISWC && invalidClassName
							}`}
							id="workISWC"
							name="workISWC"
							placeholder={t('find-oeuvre.non-referenced-work.placeholder.iswc')}
							value={formikWork.values.workISWC}
							error={formikWork?.errors?.workISWC}
							onChange={formikWork.handleChange}
							onBlur={formikWork.handleBlur}
						/>
					</Col>
					<Col xs={1}>
						<Overlay content={t('find-oeuvre.non-referenced-work.tooltip')} placement="top">
							<img className='info-Program-impro' src={iconInfoBlack} alt="info"/>
						</Overlay>
					</Col>
					<Col xs={3} className="display-label">
						<h5>
							{t('find-oeuvre.card.code-iswc')}
						</h5>
					</Col>
				</>
			)
			}
		</div>)
	}

	const renderEditionButtons = () => {
		return (<div className="form-footer text-right">
			<div className="container-btn mb-4 mt-1">
				<Button
					variant="light"
					className="btn-cancel"
					onClick={() => {
						setEditMode(false);
						setShowMoreDetails(false);
						resetWork();
					}}
				>
					{t('actions.cancel')}
				</Button>
				<Button
					className="btn-submit"
					type="button"
					disabled={!isValid}
					onClick={handleSubmit}
				>
					{t('form.btn.confirm')}
				</Button>
			</div>
		</div>)
	}

	return (
		<div className="step-three">
			<form>
				{renderWorkTitle()}
				<Container>
					{renderWorkComposer()}
					{renderWorkAuthors()}
					{renderWorkDuration()}
					{renderWorkISWC()}

					{/*  DISPLAY DETAILS START */}
					{showMoreDetails && displayDetails()}

					{editMode && renderEditionButtons()}

					<Row>
						<div
							data-index={index}
							className={`icon-showMore ${!showMoreDetails && 'rotateIcon'}`}
							onClick={() => {
								setShowMoreDetails(!showMoreDetails);
							}}
						/>
					</Row>

				</Container>
				<ConfirmationModal
					open={showConfirmDelete}
					confirmLabel={t('actions.delete')}
					cancelLabel={t('actions.cancel')}
					content={t('modal.workList-work')}
					onValidate={deleteWork}
					onClose={() => setShowConfirmDelete(false)}
				/>
			</form>
		</div>
	);
};

export default CustomCollapse;