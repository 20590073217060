import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { formatRGPDContent } from '../../helpers';
import './index.scss';

export const TracoRgpd = (props: Props) => {
	const {
		contentInfo,
		className = 'col-md-10',
		classNameRgpd = 'col-md-10',
	} = props;
	const { t, i18n } = useTranslation();
	return (
		<Container>
			<div className="row justify-content-md-center rgpd-bottom">
				{contentInfo && (
					<div
						className={`${className} col-sm-12 align-self-center info-bottom`}
					>
						{contentInfo}
					</div>
				)}
				<div className={`${classNameRgpd} col-sm-12 align-self-center rgpd`}>
					<div className="rgpd">
						<p
							dangerouslySetInnerHTML={{
								__html: formatRGPDContent(t, i18n.language),
							}}
						/>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default TracoRgpd;

interface Props {
  contentInfo?: string;
  className?: string;
  classNameRgpd?: string;
}
