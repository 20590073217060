import React, { useState, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Collapse from 'react-bootstrap/Collapse';
import { ProgramArtworks } from '../../types';
import { secondsToMinutes, getPartyName } from '../../helpers';
import {
	EDITOR_CODE,
	AUTHOR_CODE,
	COMPO_CODE,
	AUTHOR_COMPO_CODE
} from '../../constants/constants';


import InterestedParties from '../nameList';
import EditorInterestedParty from '../nameList/name-list-editor';
import WorkTitle from '../workTitle';

import './index.scss';


//@ts-ignore
import arrowUpDark from '../../assets/img/arrow-up-dark.svg';
//@ts-ignore
import arrowDownDark from '../../assets/img/arrow-down-dark.svg';
import TimeLine from "../timeLine";

interface WorksPropType {
  work: ProgramArtworks;
  id: number;
  count?: number;
}

export default function Works(props: WorksPropType) {
	const { work, id, count } = props;
	const { t } = useTranslation();
	const [showDetails, setShowDetails] = useState(false);

	const authors: string[] = [];
	const composers: string[] = [];
	const editors: string[] = [];

	for (let i = 0; i < work.typicalProgramWorkInterestedParties.length; i++) {
		const songwriter = getPartyName(
			work.typicalProgramWorkInterestedParties[i],
			AUTHOR_CODE
		);
		if (songwriter) {
			authors.push(songwriter!);
		}

		const composer = getPartyName(
			work.typicalProgramWorkInterestedParties[i],
			COMPO_CODE
		);

		if (composer) {
			composers.push(composer!);
		}

		const authorcomposer = getPartyName(
			work.typicalProgramWorkInterestedParties[i],
			AUTHOR_COMPO_CODE
		);
		if (authorcomposer) {
			authors.push(authorcomposer!);
			composers.push(authorcomposer!);
		}
		const editor = getPartyName(
			work.typicalProgramWorkInterestedParties[i],
			EDITOR_CODE
		);
		if (editor) {
			editors.push(editor!);
		}
	}

	const renderSongwriters = useMemo(() => {
		return authors.map((name, index) => (
			<Col key={index.toString()}> {name} </Col>
		));
	}, [authors]);

	const renderComposer = useMemo(() => {
		return composers.map((name, index) => (
			<Col key={index.toString()}> {name} </Col>
		));
	}, [composers]);

	const workDuration = secondsToMinutes(work.typicalProgramWorkDuration);

	const editorsInterestedPartyData =
    work.typicalProgramWorkInterestedParties.filter((party) => {
    	if (
    		party.typicalProgramWorkInterestedPartyRoles[0]
    			.typicalProgramWorkInterestedPartyRoleCode === 'E'
    	) {
    		return party;
		}
    });

	const ACInterestedPartyData = work.typicalProgramWorkInterestedParties.filter(
		(party) => {
			if (
				party.typicalProgramWorkInterestedPartyRoles[0]
					.typicalProgramWorkInterestedPartyRoleCode !== 'E'
			) {
				return party;
			}
		}
	);

	return (
		<>
			<Container className="work-container mt-2">
				<WorkTitle lengthList={count!} index={id} referenced={work.workCode}>{work.typicalProgramWorkTitle}</WorkTitle>
				{composers.length > 0 ? (
					<div className="artwork-body d-flex justify-content-between" id="Composers">
						<div className="value-bold">{renderComposer}</div>
						<div className="align-right display-label">{t('set-lists.works.composers')}</div>
					</div>
				) : (
					''
				)}

				{authors.length > 0 ? (
					<div className="artwork-body d-flex justify-content-between" id="song-writers">
						<div className="value-bold">{renderSongwriters}</div>
						<div className="align-right display-label">{t('set-lists.works.autors')}</div>
					</div>
				) : (
					''
				)}

				{workDuration ? (
					<div className="artwork-body d-flex justify-content-between" id="artwork-time-duration">
						<div className="duration-badge font-data">{workDuration}</div>
						<div className="align-right display-label">{t('set-lists.works.duration')}</div>
					</div>
				) : (
					''
				)}

				<Collapse in={showDetails}>
					<div>
						{work.workISWCCode && (
							<div className="artwork-body d-flex justify-content-between" id="ISWC-code">
								<div className="value-bold"> {work.workISWCCode} </div>
								<div className="align-right display-label">{t('set-lists.works.iswc')}</div>
							</div>
						)}

						<Row className="artwork-body" id="Interested-parties">
							<Col className="interested-party-title" xs="auto">
								{t('set-lists.works.party-title')}
							</Col>
							<Col>
								<span className="separator" />
							</Col>
						</Row>

						{ACInterestedPartyData.map((party, index) => {
							const role =
                party.typicalProgramWorkInterestedPartyRoles[0]
                	.typicalProgramWorkInterestedPartyRoleCode;
							return (
								<div key={index.toString()} id="Interested-parties">
									<InterestedParties
										party={party}
										role={role}
										key={index.toString()}
									/>
								</div>
							);
						})}

						{editors.length > 0 ? (
							<Row className="artwork-body" id="editors-parties">
								<Col className="interested-party-title" xs="auto">
									{t('set-lists.works.editors-title')}{' '}
								</Col>
								<Col>
									{' '}
									<span className="separator" />{' '}
								</Col>
							</Row>
						) : (
							''
						)}

						{editorsInterestedPartyData.length > 0 &&
              editorsInterestedPartyData.map((editorsData, index) => {
              	return (
              		<div key={index.toString()} id="Editor">
              			<EditorInterestedParty
              				party={editorsData}
              				key={index.toString()}
              			/>
              		</div>
              	);

              })
						}
					</div>
				</Collapse>

				<Row id="arrow-control">
					<span
						onClick={() => {
							setShowDetails(!showDetails);
						}}
						className="control"
					>
						<img
							src={showDetails ? arrowUpDark : arrowDownDark}
							className="arrow"
						/>
					</span>
				</Row>
			</Container>
		</>
	);
}
