import axios from 'axios';

const config = {
	data: {
		API_URL: '',
		API_KEY: '',
	},
};

let callbacks: any[] = [];

export const loadConfig = async () => {
	if (process.env.NODE_ENV === 'development') {
		config.data.API_URL = process.env.REACT_APP_API_URL;
		config.data.API_KEY = process.env.REACT_APP_API_KEY ?? 0;
	} else {
		const { data } = await axios.get(process.env.PUBLIC_URL + '/config.json');
		const configData = {
			API_KEY: data.API_KEY,
			API_URL: data.API_URL
		};
		if (process.env.REACT_APP_DYNAMIC_API_URL) {
			//console.log('is Reverse Proxy URL: ', document.documentURI, document.documentURI.startsWith('https://int.sacem.fr/'));

			if (document.documentURI.startsWith('https://int.sacem.fr/')) {
				console.log('Use reverse proxy API URL');
				configData.API_URL = data.REVERSE_PROXY_API_URL;
			}
		}
		config.data = configData;
	}
	callbacks.forEach((callback) => {
		callback(config.data);
	});
	return config.data;
};

export const onConfigLoaded = (callback: any) => {
	callbacks = [...callbacks, callback];
};

export const getConfig = () => config.data;
