import React, { useState, useEffect } from 'react';
import { Container, Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-GB';
import moment from 'moment';
import { RepresentationFilters } from '../../../../types';

import './react-datepicker.scss';
import './index.scss';



interface FiltersPropsType {
  onSearch: (filter: any) => void
  statutes: any[],
  pastEvents: boolean,
}

const Filters = (props: FiltersPropsType) => {
	const { statutes, pastEvents, onSearch } = props;
	const defaultFilters = { };
	const getWindowSize = () => window.innerWidth;
	const [filters, setFilters] = useState<RepresentationFilters>(defaultFilters);
	const [collapse, setCollapse] = useState(getWindowSize() > 576);
	const { t } = useTranslation();

	useEffect(() => {
		window.addEventListener('resize', () => {
			setCollapse(getWindowSize() > 576);

			return () => {
				window.removeEventListener('resize', () => {
					return;
				});
			};
		});
	}, []);

	useEffect(() => {
		setFilters(defaultFilters);
	}, [pastEvents]);

	const handleSearch = () => {
		onSearch(filters);
	};

	const handleReset = () => {
		setFilters(defaultFilters);
		onSearch(defaultFilters);
	};

	const handleFilterChange = (filter: string, value: string) => {
		setFilters({
			...filters,
			[filter]: value
		});
	};

	return (
		<div className='filters-bar'>
			<Container>
				<div className="filters-rows">
					<div className="d-flex align-items-center justify-content-between">
						<label className="filters-label">{t('representation.filters.label')}</label>
						<button
							className={`btn-collapse d-block d-sm-none ${collapse ? 'out' : 'in'}`}
							onClick={() => setCollapse(!collapse)}
						/>
					</div>
					<Collapse in={collapse}>
						<div className="fil-coll">
							<Filter
								filterName="programTitle"
								type="text"
								value={filters.programTitle}
								onChange={handleFilterChange}
								icon="works-list"
								placeholder={t('representation.filters.program-name')}
							/>
							<Filter
								filterName="programId"
								type="text"
								value={filters.programId}
								onChange={handleFilterChange}
								icon="number"
								placeholder={t('representation.filters.program-id')}
							/>
							<Filter
								filterName="startDate"
								type="date"
								pastEvents={pastEvents}
								value={filters.startDate}
								onChange={handleFilterChange}
								icon="calendar"
								placeholder={t('representation.filters.start-date')}
							/>
							<Filter
								filterName="endDate"
								type="date"
								pastEvents={pastEvents}
								value={filters.endDate}
								onChange={handleFilterChange}
								icon="calendar"
								placeholder={t('representation.filters.end-date')}
							/>
							<Filter
								filterName="status"
								type="list"
								value={filters.status}
								onChange={handleFilterChange}
								icon="status"
								items={statutes.filter(s => s.code !== 'PCA')}
								placeholder={t('representation.filters.status')}
							/>
							<div className="filter-actions">
								<button className="btn-search" onClick={handleSearch}>{t('actions.search')}</button>
								<div className="divider" />
								<button className="btn-reset" onClick={handleReset}/>
							</div>
						</div>
					</Collapse>

				</div>
			</Container>
		</div>
	);
};

const Filter = (props: {
  filterName: string,
  type: string,
  value: string | undefined,
  placeholder: string,
  icon?: string,
  items?: any[],
  pastEvents?: boolean
  onChange: (name: string, value: string) => void
}) => {
	const { i18n: { language } } = useTranslation();
	if (props.type === 'text') {
		return (
			<div className="filter-col">
				<input
					type="text"
					value={props.value ? props.value : ''}
					className={`filter-input icon-${props.icon}`}
					placeholder={props.placeholder}
					onChange={(e) => props.onChange(props.filterName, e.target.value)}
				/>
			</div>
		);
	}

	if (props.type === 'list') {
		return (
			<div className="filter-col">
				<select
					className={`filter-input icon-${props.icon}`}
					value={props.value ? props.value : ''}
					onChange={(e) => props.onChange(props.filterName, e.target.value)}
				>
					<option value="">{props.placeholder}</option>
					{props.items?.map(i => (
						<option key={`opt-${i.code}`} value={i.code}>{i.label}</option>
					))}
				</select>
			</div>
		);
	}

	if (props.type === 'date') {
		const customProps: { maxDate?: Date, minDate?: Date } = {};
		if (props.pastEvents) {
			customProps.maxDate = moment().subtract(1, 'day').toDate();
		} else {
			customProps.minDate = moment().toDate();
		}
		return (
			<div className="filter-col">
				<DatePicker
					placeholderText={props.placeholder}
					locale={en}
					dateFormat={moment(props.value).format('DD/MM/YYYY')}
					showPreviousMonths={false}
					selected={props.value ? moment(props.value).toDate() : null}
					onChange={(date: Date) => props.onChange(props.filterName, moment(date).format('YYYY-MM-DD'))}
					onKeyDown={(e:any) => e.preventDefault()}
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
					autoComplete="off"
					{...customProps}
				/>
			</div>
		);
	}

	return <></>;
};

export default Filters;