import React from 'react'
import { RightsType } from '../../../types/distributionsRoyaltiesTypes'
import { Doughnut } from 'react-chartjs-2'
import { Col, Container, Row } from 'react-bootstrap'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { useTranslation } from 'react-i18next'
import { formatCurrencyNumber } from '../../../helpers/formatter'

ChartJS.register(ArcElement, Tooltip, Legend)

const legendMap = ['Performance Rights (PPR)', 'Mechanical Rights (MRR)']

const CustomLegendItem = ({
  legendMapIndex,
  generatedAmount,
}: {
  legendMapIndex: number
  generatedAmount: number
}) => {
  const { i18n } = useTranslation()
  return (
    <Container fluid className='pt-20'>
      <Row>
        <Col
          xs={2}
          className='align-items-center d-flex'
          style={{ paddingRight: '0px' }}
        >
          <div
            style={{
              width: '12px',
              height: '12px',
              backgroundColor: legendMapIndex === 1 ? '#C41C89' : '#0FA3B1',
              borderRadius: '50%',
            }}
          ></div>
        </Col>
        <Col xs={10} className='pr-0'>
          <div>
            <div className='font-weight-bold'>
              {legendMap[legendMapIndex]}
            </div>
            <div>
              {formatCurrencyNumber(i18n.language, generatedAmount)}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

const RightsTypesBox = ({
  rightsTypes,
  rightsTypesTotalAmount,
}: RightsTypesBoxProps) => {
  const { i18n } = useTranslation()
  return (
    <Container fluid className='right-box-container'>
      <Row style={{ height: '353px', paddingTop: '15%' }}>
        <Col xs={6} style={{ padding: 'unset' }}>
          {rightsTypes.map((e) => {
            return (
              <CustomLegendItem
                key={e.code}
                legendMapIndex={e.code === 'PH' ? 1 : 0}
                generatedAmount={e.generatedAmount}
              />
            )
          })}
        </Col>

        <Col xs={6} style={{ padding: 'unset' }}>
          {' '}
          <div
            style={{
              paddingInline: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Doughnut
              height={'150px'}
              width={'150px'}
              options={{
                cutout: '40%',
                responsive: false,
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context: any) {
                        let label = context.label || ''
                        if (label) {
                          label += '%'
                        }

                        return label
                      },
                    },
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
              data={{
                labels: rightsTypes.map((e) => {
                  return e.percentage
                }),
                datasets: [
                  {
                    data: rightsTypes.map((e) => {
                      return e.percentage
                    }),
                    backgroundColor: ['#0FA3B1', '#C41C89'],

                    hoverBorderColor: ['#0FA3B1', '#C41C89'],
                  },
                ],
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                color: '#5256B7',
                width: 'fit-content',
                border: '1px solid',
                borderRadius: '5px',
                position: 'absolute',
                bottom: '30px',
                paddingInline: '10px',
              }}
            >
              {formatCurrencyNumber(i18n.language, rightsTypesTotalAmount)}{' '}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default RightsTypesBox

interface RightsTypesBoxProps {
  rightsTypes: RightsType[]
  rightsTypesTotalAmount: number
}
