import {ProgressBarItem} from "../../components/progressbar";

export const getItemsToDisplay = (maxNumberOfElements: number, data: ProgressBarItem[], otherName: string) => {
    const items = [...data]
    items.sort((a, b) => b.value - a.value)
    if (maxNumberOfElements <= 0) {
        return items;
    }
    const top5 = items.slice(0, maxNumberOfElements)
    const others: ProgressBarItem = {
        code: 'OTHER',
        value: items
            .slice(5, items.length)
            .reduce((sum, current) => sum + current.value, 0),
        displayName: otherName,
        percentage: items
            .slice(5, items.length)
            .reduce((sum, current) => sum + current.percentage, 0),
    }
    if (others.percentage > 0) {
        return [...top5, others]
    } else {
        return top5;
    }
}