import React, {ChangeEvent, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from "react-redux";
import moment from "moment";

import ConfirmationModal from '../../../../components/confirmationModal';
import {AppContextState, AppPropsType, CancellationReason, Representation} from "../../../../types";
import {
	deleteRepresentationsAsync,
	fetchRepresentationCancellationReasonsAsync,
} from "../../../../slices/representationsSlice";


interface PropsType {
	appcontext: AppContextState
	cancellationReasons: CancellationReason[]
	event: Representation
	open: boolean;
	onClose: () => void;
}

const RepresentationCancel = (props: PropsType) => {
	const { appcontext, cancellationReasons, event, open, onClose } = props
	const societyId = appcontext.selectedSisterSociety?.sisterSocietyId;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [reason, setReason] = useState<string>("")

	useEffect(() => {
		if (!cancellationReasons?.length) {
			dispatch(fetchRepresentationCancellationReasonsAsync({
				societyId,
			}));
		}
	},[]);

	const handleReasonChange = (e: ChangeEvent<HTMLSelectElement>) => {
		setReason(e.target.value)
	}

	const cancelEvent = () => {
		if (reason) {
			dispatch(deleteRepresentationsAsync({
				representationId: event.representationId,
				societyId,
				cancellationReason: reason
			}));
		}
	};

	if (!open) {
		return null;
	}

	return (
		<ConfirmationModal
			open={open}
			onValidate={cancelEvent}
			onClose={onClose}
			alignButtons="bottom"
			disableValidate={!reason}
			confirmLabel={t('modal.delete')}
			cancelLabel={t('modal.cancel')}
		>
			<div className="modal-text">
				<p>{t('representation.delete-confirmation')}</p>
				<p><b>{`“${moment(event.representationDate).format('DD/MM/YYYY')} ${event.representationTitle}“.`}</b></p>
			</div>

			<select onChange={handleReasonChange}>
				<option value="">{t('representation.default-delete-reason')}</option>
				{ cancellationReasons.map((r) => (
					<option value={r.code}>{r.label}</option>
				))}
			</select>
		</ConfirmationModal>
	);
};

const mapStateToProps = (state: AppPropsType) => ({
	appcontext: state.appContext,
	cancellationReasons: state.representations.cancellationReasons,
});
export default connect(mapStateToProps)(RepresentationCancel);