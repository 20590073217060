import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../components/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import BackToTop from './backToTop'
import './index.scss';

const PrivacyCookiesPolicy = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

    return (<>
        <Breadcrumb
            title={t('breadcrumb.privacy-cookies-policy')}
            showHome={true}
            homeOnclick={() => {navigate('/')}}
        />
        <Container className='privacy-cookies-policy'>
            <BackToTop minScrollPosition={300} />
            <h2>LAST UPDATE: 24TH APRIL 2024</h2>

            <div className='text-block'>
                <p>
                    Sacem's privacy policy is intended to provide you with transparent and comprehensible information about how your personal data
                    is processed, depending on the nature of the relationship that you have with Sacem (i.e. member, project leader, client
                    or potential client, mandator, etc.). This privacy policy also explains the rights you have under the regulations and
                    how you can exercise these rights with Sacem using the dedicated forms that are available on this page.
                </p>
                <p>
                    Please refer to Section&nbsp;
                    <a href='#personal_data_section'>
                        1. PROTECTION OF YOUR PERSONAL DATA
                    </a>
                    . For any enquiries other than those concerning the exercise of your
                    rights, please use the{' '}
                    <a className='red-link' href='https://www.sacem.fr/en/contact?langue=en' target="_blank" rel="noopener noreferrer">
                        Contact us
                    </a>
                    &nbsp;link.
                </p>
                <p>
                    You will also find information on the cookies used on the Sacem
                    website and how to set your cookie preferences in Section&nbsp;
                    <a href='#cookies_section'>
                        2. COOKIES.
                    </a>
                </p>
            </div>

            <h3 id='personal_data_section'>1. PROTECTION OF YOUR PERSONAL DATA :</h3>
            <div className='text-block'>
                <p>
                    Personal data is information that makes it possible for you to be identified as a natural person, either directly
                    (e.g. your surname, first name etc.), or indirectly by means of a reference number (e.g. your membership access code
                    (COAD), your client reference numbers, etc.).
                </p>
                <p>
                    Sacem is committed to the protection of your personal data. It makes sure that it is in compliance with the regulations in force,
                    as provided in the General Data Protection Regulations of 27 April 2016 (hereinafter the "GDPR"), which is applicable since
                    on 25 May 2018, and the amended law of 6 January 1978, known as the "French Data Protection Act".
                </p>
                <p>For this purpose, Sacem has appointed a Data Protection Officer ("DPO").</p>
                <p>
                    In compliance with the regulations in force, we remind you that any personal data made available on the Sacem website must not be
                    collected and processed without the prior written consent of the person concerned. In particular, it is absolutely prohibited to
                    collect and use this data, manually or automatically, for the sending of unsolicited marketing email.
                </p>
            </div>

            <h3>1.1 HOW DOES SACEM PROCESS THE PERSONAL DATA ENTRUSTED TO IT ?</h3>
            <table>
                <tr>
                    <td>For what purposes are my data processed ?</td>
                    <td>
                        <ul>
                            <li>
                                for collecting and distributing authors' rights, and more generally, to manage the interests of your beneficiaries
                                (access reserved for sister societies)
                            </li>
                            <li>for managing the repertoire of works and associated documentation</li>
                            <li>for managing the declaration of your works and concerts</li>
                            <li>for processing your claims</li>
                            <li>for reviewing and verifying works in the process of being identified</li>
                            <li>for managing your usage authorisations</li>
                            <li>for proposing and/or managing service platforms that are dedicated to you, such as Urights</li>
                            <li>for sending you newsletters and other communications</li>
                            <li>for improving our quality approach and the training of our employees to ensure better service for our members </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>What is the legal basis?</td>
                    <td>
                        <ul>
                            <li>pursuant to the agreement you signed when you became a sister society</li>
                            <li>
                                the provisions laid down by the IPC, and in accordance with Sacem's Articles of Association
                                and the General Regulations
                            </li>
                            <li>
                                the legitimate interest of Sacem to contact you, in the context of your mandate, improve its services,
                                and to keep you informed of Sacem's various projects and activities.
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>With whom do you share my data?</td>
                    <td>
                        <ul>
                            <li>Sacem's internal departments</li>
                            <li>
                                other collective management organisations with which Sacem has signed representation agreements (for more details,
                                consult the section&nbsp;
                                <a className='red-link' href='https://societe.sacem.fr/en/network' target="_blank" rel="noopener noreferrer">
                                    Who are We - Our Network
                                </a>).
                                The exchange of data with other collective management organisations around the world ensures greater efficiency in
                                the distribution of your rights.
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>For how long do you keep my data ?</td>
                    <td>
                        <ul>
                            <li>for as long as you are a sister society</li>
                            <li>
                                for the statutory time periods to which Sacem is subject with regard to its business activities; these time
                                periods will be communicated to you depending on the activities concerned
                            </li>
                            <li>for the duration of your subscription to our newsletters</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>How can I exercise my data protection rights ?</td>
                    <td>
                        <ul>
                            <li>
                                you can log in to&nbsp;
                                <a className='red-link' href='https://www.auth.sacem.fr/am/XUI/?realm=/externes&authIndexType=service&authIndexValue=OtpMailFactor&goto=https://www.auth.sacem.fr:443/am/oauth2/realms/root/realms/externes/authorize?client_id%3D134fd4d7-23ec-44d1-a80c-3b6168ab3a13%26redirect_uri%3Dhttps://www.partner-societies.sacem.com/partner-societies/signin-oidc%26response_type%3Dcode%26scope%3Dopenid%2520default%2520profile%2520email%2520roles%26state%3D57fbfaf0cf3d42d78a3e242447dbd0e7%26code_challenge%3Dx5R5U0zWnFUAjT5D2_ojwq84hqZDsJSlrENeJPgqg_c%26code_challenge_method%3DS256%26response_mode%3Dquery%26acr%3DotpMail%26acr_sig%3D7PuL6lDtDtuLdWnh1YY9t9CebEjMgBcydEoi8ju9gc4#login/'
                                    target="_blank" rel="noopener noreferrer">
                                    your password protected/members-only area
                                </a>: there you can access your data and carry out a number of operations.
                            </li>
                            <li>
                                Otherwise, you can use our dedicated form :&nbsp;
                                <a className="red-link" href="https://privacyportal-eu.onetrust.com/webform/806f5b29-5483-4474-b647-d70e0e53df96/f12ef946-566b-4986-8232-f0ff5d471634"
                                target="_blank" rel="noopener noreferrer">
                                    Click here
                                </a>
                            </li>
                            <p>
                                If you do not wish to use the online forms made available to you to exercise your rights, you can send your request,
                                together with a copy of your identity document, by post to: GDPR Requests - Sacem, 225 avenue Charles de Gaulle,7
                                92200 Neuilly-sur-Seine.
                            </p>
                        </ul>
                    </td>
                </tr>
            </table>
            <div className='text-block'>
                <p>
                    We draw your attention to the fact that, under the regulations on the
                    protection of personal data, you can only exercise your data
                    protection rights as they apply to your own personal data and not the
                    personal data of the member you represent.
                </p>
            </div>

            <h4>Combating piracy - HADOPI</h4>
            <div className='text-block'>
                <p>
                    On the basis of the so-called HADOPI laws of 2009 and the regulations applicable to the protection of personal data (GDPR),
                    Sacem, la Société Civile des Producteurs Phonographiques  (SCPP - Civil Society of Phonographic Producers), la Société
                    pour l'Administration du Droit de Reproduction Mécanique des Auteurs, Compositeurs et Éditeurs (SDRM - Society for the
                    Administration of Mechanical Reproduction Rights of authors, composers, publishers, dubbing and subtitles authors), the
                    Société Civile des Producteurs de Phonogrammes en France (SPPF - Civil Society for French Producers of Phonograms), and
                    the Association de Lutte contre la Piraterie Audiovisuelle (ALPA - Association for the Fight against Audiovisual Piracy)
                    may process personal data, via a joint system specially set up for this purpose, in order to record the unlawful posting
                    of protected works and objects committed via peer-to-peer networks. This data may then be transmitted to the Haute Autorité
                    pour la diffusion des œuvres et la protection des droits sur internet (HADOPI - Supreme Authority for the Distribution and
                    Protection of Intellectual Property on the Internet).
                </p>
                <p>
                    However, these organisations do not process your contact details (surname, first name, Internet address); only Hadopi can obtain
                    those from the Internet service providers involved. To exercise your rights as a data subject or for more information on the
                    processing carried out by HADOPI and the graduated response mechanism, please consult its website: https://www.hadopi.fr/la-faq.
                </p>
                <p>
                    Within the framework of the joint system they are putting in place, Sacem, SDRM, SPPF, SCCP and ALPA are "joint data controllers",
                    as defined by the GDPR: an agreement between them thus defines their respective obligations and provides for the rules allowing
                    for the monitoring of compliance of this processing with data protection regulations, in order to guarantee enhanced data
                    protection for the long term.
                </p>
            </div>


            <h3>1.2 WHAT ARE MY RIGHTS ?</h3>
            <div className='text-block'>
                <p>In compliance with the relevant regulations on personal data, you have the following rights :</p>
                <ul>
                    <li>
                        the right of access: gives you the right to access your personal data and check their accuracy
                    </li>
                    <li>
                        the right to rectification: gives you the right to to modify or complete your personal data
                    </li>
                    <li>
                        the right to erasure: gives you the right to request the deletion of your data if it is inaccurate or out of date
                    </li>
                    <li>
                        the right to restriction of processing: gives you the right to request to limit processing of any inaccurate, contested or
                        outdated personal data
                    </li>
                    <li>
                        the right to object: gives you the right, on grounds relating to your particular situation, to object to the processing
                        of your personal data
                    </li>
                    <li>
                        the right to the withdrawal of consent: in cases where the processing of your data has required your consent, you may
                        withdraw it at any time.
                    </li>
                    <li>
                        the right to data portability: gives you the right to request a copy of any personal data that you have submitted to
                        Sacem, in a structured, commonly used and machine-readable format, and the right to transmit those data to another controller.
                    </li>
                    <li>
                        In addition, under the amended act of 6 January 1978, you have the right to set out specific instructions on how your personal
                        data should be handled after your death. Please use this form&nbsp;
                        <a className="red-link" href="https://privacyportal-eu.onetrust.com/webform/806f5b29-5483-4474-b647-d70e0e53df96/f12ef946-566b-4986-8232-f0ff5d471634"
                            target="_blank" rel="noopener noreferrer">
                            Click here.
                        </a>
                    </li>
                </ul>

                <p>The application of these rights may vary according to the type of processing in question.</p>
                <p>To help you exercise these rights, Sacem has developed some useful tools:</p>
                <ul>
                    <li>
                        In your password protected/members-only area: you have access to your data and can carry out a number of operations
                    </li>
                    <li>
                        you can fill out a form to exercise your rights, depending on the nature of the relationship you have with Sacem
                        (member, client, project leader, etc.). Please refer to the relevant section shown above
                    </li>
                </ul>

                <p>
                    If you do not wish to use the online forms made available to you to exercise your rights, you can send your request,
                    together with a copy of your identity document, by post to : GDPR Requests - Sacem, 225 avenue Charles de Gaulle,
                    92200 Neuilly-sur-Seine.
                </p>

                <p>
                    When you exercise your rights, Sacem may ask you for additional information to confirm your identity, as well as,
                    if necessary, your identity card to avoid any risk of homonymity. This documentation allows Sacem to verify that
                    you are the person making the request and will be deleted when your request has been processed. In the case of a
                    manifestly unfounded or excessive request, Sacem may be obliged to charge a reasonable fee to process your request.
                    It may also refuse to process such requests (particularly, if they are repetitive in nature).
                </p>

                <p>
                    In the event that you do not obtain a response from Sacem, even after sending reminders, you can contact the Data
                    Protection Officer (DPO) directly at: DPO, "exercez vos droits", Sacem - 225 Avenue Charles de Gaulles - 92528
                    Neuilly-sur-Seine Cedex or lodge a complaint with the Commission Nationale Informatique et Libertés (CNIL).
                </p>
            </div>

            <h3>1.3 HOW DOES SACEM KEEP YOUR DATA SECURE ?</h3>
            <div className="text-block">
                <p>
                    Sacem, as a data controller, ensures the protection of the personal data you entrust to it by implementing
                    appropriate technical and organisational measures, which guarantee a level of security commensurate with
                    the risk.
                </p>
                <p>
                    To guarantee the security of the personal data contained in your password protected/members-only area,
                    it is your responsibility to follow Sacem's recommendation (choosing a strong password, which is hard
                    for third parties to guess or to be cracked by an automatic tool, and change it regularly), especially
                    with regard to the uniqueness and confidentiality of your username and password.
                </p>
                <p>
                    Any activity on the Sacem website carried out using your username and password will be considered to
                    have been carried out by you. The computer record of these activities, or their reproduction on a
                    digital storage device or as hard-copy, shall be binding with regard to your interaction with Sacem.
                </p>
                <p>
                    In the case of any unauthorised use of your username or password, you must inform Sacem immediately&nbsp;
                    <a className='red-link' href='https://www.sacem.fr/en/contact?langue=en' target="_blank" rel="noopener noreferrer">
                        Contact us
                    </a>.
                </p>
                <p>
                    By logging into your member account, you acknowledge that you have read the conditions of access to such
                    password protected/members-only areas and services and accept the rules of use and evidence.
                </p>
            </div>

            <h3>1.4 SACEM ALSO PROCESSES PERSONAL DATA ON BEHALF OF THE SRDM</h3>
            <div className="text-block">
                <p>
                    Sacem undertakes various administrative and accounting tasks on behalf of the SDRM. To this end, it collects
                    personal data in certain sections of its website, particularly when the rights of representation and
                    mechanical reproduction are concerned.
                </p>
                <p>
                    In accordance with the regulations, you have a right to be informed about any data processing carried out by
                    the SDRM. For more details and, if necessary, to exercise your rights with the SDRM, consult the SDRM
                    "Privacy Policy" on their website&nbsp;
                    <a className='red-link' href='https://sdrm.sacem.fr/en/confidentialite-et-cookies' target="_blank" rel="noopener noreferrer">
                        Click here
                    </a>.
                </p>
            </div>

            <h3 id='cookies_section'>2. COOKIES :</h3>
            <div className="text-block">
                <p>
                    When you first visit "https://www.partner-societies.sacem.com/partner-societies", a banner informs you that one or more
                    browser cookies may be automatically installed on your terminal and invites you to indicate your choice.
                </p>
            </div>

            <h3>2.1 WHAT IS A COOKIE?</h3>
            <div className="text-block">
                <p>
                    A cookie is a small text file that may be placed on your terminal (computer, tablet, mobile phone, etc.) when you visit
                    a website or application via your browser. A cookie file allows its issuer to identify the terminal in which it is
                    stored, for the duration of the validity or storage of the cookie.
                </p>

                <p>
                    This file is stored for a specific time on your device and your browser sends it back to the web server each time
                    you log in to the site.
                </p>
            </div>

            <h3>2.2 WHAT TYPES OF COOKIES DOES SACEM USE ON THIS WEBSITE? </h3>
            <div className="text-block">
                <ul>
                    <li>
                        We use only "Strictly necessary cookies" (that are required for this website to work properly)
                    </li>
                </ul>
                <p>
                    For details on the cookies used on our website, purpose by purpose, please consult our&nbsp;
                    <span id='ot-sdk-btn' className="ot-sdk-show-settings">Cookie Settings tool.</span>
                </p>
            </div>

            <h3>2.3 COOKIE SETTINGS</h3>
            <div className="text-block">
                <p>
                    Thanks to our cookies banner, you can accept or refuse the placement of all cookies on your terminal.
                </p>
                <p>
                    Please note that strictly necessary cookies do not require your express consent and are placed on your terminal by default.
                </p>
                <p>
                    We would also like to inform you that you can configure your browser in such a way as to make it impossible to deposit
                    strictly necessary cookies.
                </p>
                <p>
                    However, we draw your attention to the fact that, if you have a password protected/members-only area on the Sacem website,
                    these cookies are necessary to guarantee that this area is secure and operates properly. As a result, if you deactivate
                    these cookies, you will no longer be able to browse your password protected/members-only areas.
                </p>
                <p>
                    If you want to continue to use this feature, we recommend that you keep the default setting "strictly necessary cookies".
                </p>
                <p>
                    For more details on the cookies we use, and on how to manage your authorisation and cookie settings, we recommend that
                    you use our <span id='ot-sdk-btn' className="ot-sdk-show-settings">Cookies Settings tool</span>.
                </p>
            </div>

        </Container>
    </>)
  }

export default PrivacyCookiesPolicy