import {Mandator, UserData} from '../types';
import { httpCaller } from './httpCaller';

export function getSisterSocieties() {
	return new Promise<UserData>((resolve, reject) => {
		httpCaller
			.get('societies')
			.then((response) => response.status === 200
				? resolve(response.data)
				: reject(response.status))
			.catch((error) => {
				console.error('API error', error);
				window.location.href = error?.status ?? 500
			});
	});
}
export function getMandators() {
	return new Promise<any>((resolve, reject) => {
		httpCaller
			.get('mandators')
			.then((response) => response.status === 200
				? resolve(response.data)
				: reject(response.status))
			.catch((error) => {
				console.error('API error', error);
			});
	});
}