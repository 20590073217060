import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import './tabsComponent.scss'
import { tag } from '../../helpers/PianoAnalyticsService'
import { useAppSelector } from '../../store/hooks'

type Props = {
  elements: {
    serviceTitle: string
    title: string
    jsxFilters: (activeButtonIndex: number) => JSX.Element
    jsxPage: JSX.Element
    tabIndex: number
  }[]
}

const TabsComponent = ({ elements }: Props) => {
  const [activeButtonIndex, setActiveButtonIndex] = useState<number>(
    elements[0].tabIndex
  )
  const mandatorId = useAppSelector((state) => state.appContext.mandator)
  const societyId = useAppSelector((state) => state.appContext.selectedSisterSociety.sisterSocietyId)
  const sisterSocietyLabel = useAppSelector((state) => state.appContext.selectedSisterSociety.name)
  const onButtonClick = (index: number) => {
    tag.paSendActionClick({
      click: elements[index].title,
      click_chapter1: elements[index].serviceTitle,
      click_chapter2: elements[index].title,
      mandator_id: mandatorId,
      society_id: societyId,
      sister_society_label: sisterSocietyLabel,
    })
    setActiveButtonIndex(index)
  }
  const activeFilters = elements[activeButtonIndex].jsxFilters

  return (
    <>
      {activeFilters(activeButtonIndex)}

      <Container fluid className='tabs-main-container'>
        <div className='tabs-button-container'>
          {elements.map((element, index) => (
            <button
              key={element.title}
              className='tabs-button'
              onClick={() => onButtonClick(index)}
              style={{
                backgroundColor:
                  index === activeButtonIndex ? '#C9BBDD' : 'initial',
              }}
            >
              <span style={{ fontSize: '14px', lineHeight: '24px' }}>
                {element.title}
              </span>
            </button>
          ))}
        </div>
      </Container>
      {elements[activeButtonIndex].jsxPage}
    </>
  )
}

export default TabsComponent
