import React from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from "react-bootstrap";

import { resetContext } from "../../slices/appContextSlice";
import { useAuthService } from "../../oidcAuthentication/hooks";
import { ROUTES } from '../../constants';

import "./index.scss"

const ErrorPage = (props: Props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const authService = useAuthService();
	const { t } = useTranslation();
	const { errorCode } = props;

	const titleError = t(`error-page.${errorCode}.title`);
	const contentError = t(`error-page.${errorCode}.content`);
	const link = t(`error-page.${errorCode}.link`);

	const handleClick = () => {
		if (errorCode === 403) {
			authService.logout();
			dispatch(resetContext())
		} else {
			const url = authService.isAuthenticated() ? ROUTES.LANDING : '/'
			navigate(url);
		}
	};
	return (
		<>
			<Container className='exception-page-container mt-8 col-md-6'>
				<Row className='exception-page-header'>
					<h2>
						{titleError}
					</h2>
				</Row>
				<Row className='error-code mt-4'>
					<p>{`Error ${errorCode}`}</p>
				</Row>
				<Row className='error-msg-box'>
					<div className="mt-3">
						<p dangerouslySetInnerHTML={{ __html: contentError }}></p>
					</div>

					{errorCode !== 500 && <Col xs={12} className='d-flex justify-content-center'>
						<Button className='redirect-button' onClick={handleClick}>
							{link}
						</Button>
					</Col>
					}
				</Row>
			</Container>
		</>
	);
};

export default ErrorPage;

interface Props {
  errorCode: number
}