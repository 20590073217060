import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, InputGroup, ListGroup, ProgressBar, Row} from "react-bootstrap";
import PageStatus from "../../../components/pageStatus";
import CustomTooltip from "../../../wrappers/customTooltipWrapper";
import {formatNumber} from "../../../helpers/formatter";
import i18n, {t} from "i18next";
import {UsageDataWorksPerIDE12Response, WorkByIDE12} from "../../../types";
import {fetchStreamPerMonthAsync, fetchStreamPerWorkPerOfferAsync} from "../../../slices/usageDataSlice"
import {useAppDispatch} from "../../../store/hooks";
import {ProgressBarItem} from "../../../components/progressbar";

interface ResourceIdPropType {
    worksPerIDE12Status: number
    worksPerIDE12?: UsageDataWorksPerIDE12Response
    streamPerOfferPerWorkIdStatus: number
    streamPerMonthPerWorkIdStatus: number
    streamPerOfferPerWorkIdList: ProgressBarItem[]
    getBaseParams: Function
}

const ResourceId = ({
                        worksPerIDE12Status,
                        worksPerIDE12,
                        streamPerOfferPerWorkIdStatus,
                        streamPerMonthPerWorkIdStatus,
                        streamPerOfferPerWorkIdList,
                        getBaseParams
                    }: ResourceIdPropType) => {
    const workTitleRefs = useRef<Array<HTMLDivElement | null>>([]);
    const defaultLabel = t('usage_data.details.block.statusMessage.default')
    const errorLabel = 'usage_data.details.block.statusMessage.500'
    const noDataLabel = t('usage_data.details.block.statusMessage.204')
    const dispatch = useAppDispatch()


    const [filteredWorks, setFilteredWorks] = useState<WorkByIDE12[]>([])
    const [selectedResourceId, setSelectedResourceId] = useState<string>('')
    const [textSearch, setTextSearch] = useState<string>('')
    useEffect(() => {
        if (worksPerIDE12 != null && worksPerIDE12.works_per_ide12.length) {
            setFilteredWorks(worksPerIDE12.works_per_ide12)
            setSelectedResourceId(worksPerIDE12.works_per_ide12[0].resourceId)
        }

    }, [worksPerIDE12]);

    useEffect(() => {
        if (worksPerIDE12) {
            const filtered = worksPerIDE12.works_per_ide12.filter(w =>
                w.title.toLowerCase().indexOf(textSearch.toLowerCase()) > -1 ||
                w.resourceId.toLowerCase().indexOf(textSearch.toLowerCase()) > -1
            )
            setFilteredWorks(filtered)
        }
    }, [textSearch]);

    useEffect(() => {
        if (selectedResourceId) {
            const params = {...{workId: selectedResourceId}, ...getBaseParams()}
            dispatch(fetchStreamPerWorkPerOfferAsync(params))
            dispatch(fetchStreamPerMonthAsync(params))
        }

    }, [selectedResourceId])

    const ResourceIdListHeader = () => {
        return (
            <ListGroup.Item>
                <Row className={'text-center'}>
                    <Col xs={4}><strong>Resource ID</strong></Col>
                    <Col xs={5}><strong>Title</strong></Col>
                    <Col xs={3}><strong>Usage</strong></Col>
                </Row>
            </ListGroup.Item>
        )
    }

    return (
        <Row className="usage-data-resource-id usage-data-container">
            {worksPerIDE12Status === 200 && worksPerIDE12 != null ? (
                <>
                    <Col
                        xs={12} sm={7}
                        className={
                            'p-3 border-end border-secondary border-opacity-25'
                        }
                    >
                        <InputGroup className="ud-search ressource-font mb-3">
                            <InputGroup.Text>
                                <i className='fa fa fa-search fa-0x fa-inverse'></i>
                            </InputGroup.Text>
                            <Form.Control
                                placeholder={t('usage_data.details.search.placeholder')}
                                aria-label

                                    ="Search"
                                onChange={(e) => setTextSearch(e.target.value)}
                                value={textSearch}
                            />
                            <Button variant="outline-secondary" id="button-addon2"
                                    onClick={() => setTextSearch('')}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </Button>
                        </InputGroup>
                        <ListGroup defaultActiveKey={selectedResourceId}>
                            {filteredWorks.length === 0 && worksPerIDE12Status === 200 ?
                                <PageStatus
                                    status={204}
                                    defaultMessage={defaultLabel}
                                    noDataFoundMessage={t('usage_data.details.search.empty')}
                                    unexpectedErrorMessage={errorLabel}
                                />
                                :
                                <>
                                    <ResourceIdListHeader />
                                    <div
                                        className='scroll-style'
                                        style={{maxHeight: '350px'}}
                                    >
                                        {filteredWorks.map((item, index) => (
                                            <ListGroup.Item action key={"#link" + index}
                                                            active={item.resourceId === selectedResourceId ? true : false}
                                                            onClick={() => setSelectedResourceId(item.resourceId)}
                                                            disabled={streamPerOfferPerWorkIdStatus === -1 || streamPerMonthPerWorkIdStatus === -1 ? true : false}
                                            >
                                                <Row className={'py-1'}>
                                                    <Col className={'resource-font'}
                                                         xs={5}>{item.resourceId}</Col>
                                                    <Col className={'work-title'} xs={5}
                                                         ref={(el: any) => workTitleRefs.current[index] = el}>
                                                        <CustomTooltip childId={index}
                                                                       refElem={workTitleRefs}>
                                                            <div
                                                                className={'mw-210 long-label-tooltip'}
                                                                id={"work-" + index}>
                                                                {item.title.toLowerCase()}
                                                            </div>
                                                        </CustomTooltip>
                                                    </Col>
                                                    <Col className={'resource-font text-center'} xs={2}>
                                                        {Intl.NumberFormat(i18n.language === 'en' ? 'en-US' : 'fr-FR', {'maximumFractionDigits': 0}).format(item.streams)}
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        ))
                                        }
                                    </div>
                                </>
                            }
                        </ListGroup>
                    </Col>
                    <Col xs={12} sm={5}>
                        {streamPerOfferPerWorkIdStatus === 200 ? (
                            <div className='p-4'>
                                {streamPerOfferPerWorkIdList.map((item, i) => (
                                    <React.Fragment
                                        key={`rf-family-overlay-${i}-${item.code}`}
                                    >
                                        <div
                                            key={`family-bar-${i}-${item.code}`}
                                            className='bar-container'
                                        >
                                                            <span className='bar-label'>
                                                        {item.displayName}
                                                            </span>
                                            <span className='bar-amount'>
                                                        {formatNumber(i18n.language, item.value)}
                                                            </span>
                                            <ProgressBar
                                                now={item.percentage}
                                                color={'#fd54e2'}
                                            />
                                            <span className='bar-percent'>{`${formatNumber(
                                                i18n.language,
                                                item.percentage
                                            )}%`}</span>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        ) : (
                            <PageStatus
                                status={streamPerOfferPerWorkIdStatus}
                                defaultMessage={defaultLabel}
                                noDataFoundMessage={noDataLabel}
                                unexpectedErrorMessage={errorLabel}
                            />
                        )}
                    </Col>
                </>
            ) : (
                <PageStatus
                    status={worksPerIDE12Status}
                    defaultMessage={t(
                        'usage_data.details.resourceId.statusMessage.default'
                    )}
                    noDataFoundMessage={t(
                        'usage_data.details.resourceId.statusMessage.204'
                    )}
                    unexpectedErrorMessage={
                        'usage_data.details.resourceId.statusMessage.500'
                    }
                />
            )}
        </Row>
    )
};

export default ResourceId;
