import { httpCaller } from './httpCaller';
import { RepresentationsRequest, RepresentationsResponse, Representation, RepresentationDetailsParam } from '../types';



export function fetchRepresentations(params: RepresentationsRequest) {

	const { pagination, sortOption, filters, societyId } = params;

	return new Promise<RepresentationsResponse>((resolve, reject) =>
		httpCaller
			.get(`/societies/${societyId}/representations`,
				{
					params: {
						...pagination,
						...sortOption,
						...filters,
					}
				})
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}

export function fetchRepresentationDetails(params: RepresentationDetailsParam) {
	const { societyId, representationId } = params;
	return new Promise<Representation>((resolve, reject) =>
		httpCaller
			.get(`/societies/${societyId}/representations/${representationId}`)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}

export function cancelRepresentation(representationId: number, societyId: number, cancellationReason: string) {
	return new Promise<number>((resolve, reject) =>
		httpCaller
			.delete(`/societies/${societyId}/representations/${representationId}`, {
				params: {
					cancellationReason,
				}
			})
			.then((response) => {
				return resolve(response.status);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}

export function fetchRepresentationsStatutes(societyId : string) {
	return new Promise<any[]>((resolve, reject) =>
		httpCaller
			.get(`representations/statutes`, { params: { societyId }})
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}

export function getRepresentationsTypes(societyId : string, typicalProgramTypeCode: string) {
	return new Promise<any[]>((resolve, reject) =>
		httpCaller
			.get(`/representations/types`, { params: { typicalProgramTypeCode, societyId } })
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}

export function fetchRepresentationCancellationReasons(societyId : string) {
	return new Promise<any[]>((resolve, reject) =>
		httpCaller
			.get(`representations/cancellation-reasons`, { params: { societyId }})
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}

export function postRepresentations(values: any) {
	const postValues = {
		representationAddress: {
			representationAddress1: values.StreetNumber + ' ' + values.StreetName,
			representationAddress2: values.postBox,
			representationAddress3: values.additionalInfo,
			representationAddressCity: values.city,
			representationAddressZipCode: values.zipCode,
			representationAddressCountryCode: values.country,
			representationLieu: values.location
		},
		representationComplements: values.representationComplements,
		representationDate: values.eventDate,
		representationPerformers: [values.performers],
		representationCustomers: [values.customers],
		representationTitle: values.eventTitle,
		representationTypeQualificationCode: values.eventType,
		typicalProgramId: parseInt(values.associatedPrograms),
	};
	return new Promise<any[]>((resolve, reject) =>
		httpCaller
			.post(`/societies/${parseInt(values.memberId)}/representations`, postValues)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}
