import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import './index.scss'

const BackToTop = ({ minScrollPosition }: { minScrollPosition?: number }) => {
  const { t } = useTranslation()

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      className={`fixed-bottom ${
        minScrollPosition != null && scrollPosition < minScrollPosition
          ? 'd-none'
          : ''
      }`}
    >
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip>{t<string>('back-to-top')}</Tooltip>}
      >
        <button
          className='fa arrow-up fa-lg back-top'
          onClick={scrollTop}
        />
      </OverlayTrigger>
    </div>
  )
}

export default BackToTop
