import React, { useCallback, useEffect } from 'react'
import { ListGroup } from 'react-bootstrap'
import {
  loadUsageDataTerritoryList,
  loadUsageDataDate,
  loadUsageDataDspList,
  resetUsageSearchDataState,
  fetchStreamPerOfferAsync,
  fetchMapDataUsageDataAsync,
  fetchStreamPerWorkAsync,
  fetchDspListAsync, fetchTerritoryListAsync,
  resetLastSearchValues
} from '../../../slices/usageDataSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

import countries from 'i18n-iso-countries'
import { allowedStatus } from '../../../components/topStatusBar'
import FiltersComponent from '../../../components/filtersComponent'
import { t } from 'i18next'
import ApplyButtonComponent from '../../../components/filtersComponent/applyButtonComponent'
import { TerritoryLicenses } from '../../../types'
import { subDays, subMonths } from 'date-fns'
import {RootState} from "../../../store/store";
import {setBreadcrumb, setToasterState, setSelectedRepertoire, fetchRepertoiresAsync} from '../../../slices/appContextSlice';
import { FF_USAGE_DATA } from '../../../providers/configurationProvider';
import { SelectItem } from '@mantine/core/lib/Select/types'
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))


const UsageDataHeader = () => {
  const dispatch = useAppDispatch()
  const mandatorId = useAppSelector((state: RootState) => state.appContext.mandator)
  const repertoiresStatus = useAppSelector(
    (state) => state.appContext.repertoires.status
  )

  const repertoiresData = useAppSelector(
    (state) => state.appContext.repertoires.data
  )

  const selectedRepertoire = useAppSelector(
    (state) => state.appContext.selectedRepertoire
  )

  const allRepertoiresOptionsData: any[] = [{
    code: 'all-repertoires',
    label: 'All repertoires',
    value: 'All repertoires',
  }]

  const mapDataStatus = useAppSelector(
    (state) => state.usageData.status.mapData
  )

  const streamsPerOfferStatus = useAppSelector(
    (state) => state.usageData.status.streamPerOffer
  )

  const streamsPerWorkStatus = useAppSelector(
    (state) => state.usageData.status.streamPerWork
  )

  const usageDataDspStatus = useAppSelector(
    (state) => state.usageData.status.dsp
  )
  const usageDataTerritoryStatus = useAppSelector(
    (state) => state.usageData.status.territory
  )

  const sisterSocietyEntityData = useAppSelector((state) => state.appContext.selectedSisterSociety)
  const sisterSocietyEntityName = sisterSocietyEntityData?.name
  const sisterSocietyEntityId = sisterSocietyEntityData?.sisterSocietyId

  const selectedTerritories = useAppSelector(
    (state) => state.usageData.usageDataSearchParams.territories
  )
  const selectedDsp = useAppSelector(
    (state) => state.usageData.usageDataSearchParams.dsp
  )
  const dspList = useAppSelector((state) => state.usageData.dspList)

  const territoryList = useAppSelector((state) => state.usageData.territoryList)
  const usageDataSearchParams = useAppSelector(
    (state) => state.usageData.usageDataSearchParams
  )
  const pageComponentsStatusArray: number[] = [
    streamsPerWorkStatus,
    mapDataStatus,
    streamsPerOfferStatus,
    usageDataDspStatus,
    usageDataTerritoryStatus,
  ]


  useEffect(() => {
    dispatch(setBreadcrumb({
      title: t('breadcrumb.usage-data'),
      showHome: true,
      featKey: FF_USAGE_DATA
    }));
  }, []);

  const handleDateChange = useCallback(
    (date: { startingDate: string; endingDate: string }) => {
      dispatch(loadUsageDataDate(date))
    },
    [dispatch]
  )

  const handleDspChange = useCallback(
    (selectedItem: any) => {
      dispatch(loadUsageDataDspList(selectedItem.code))
    },
    [dispatch]
  )

  const handleTerritoryChange = useCallback(
    (selectedItems: any[]) => {
      dispatch(
        loadUsageDataTerritoryList(
          selectedItems.map((item) => parseInt(item.code))
        )
      )
    },
    [dispatch]
  )

  function canSearchStreamPerWork(): boolean {
    return (
      usageDataSearchParams.territories != null &&
      usageDataSearchParams.territories.length === 1 &&
      usageDataSearchParams.dsp != null &&
      usageDataSearchParams.usageDataDate.startingDate != null &&
      usageDataSearchParams.usageDataDate.endingDate != null
    )
  }

  const handleClick = () => {
    dispatch(resetUsageSearchDataState())
    if (mandatorId == null) return

    if (canSearchStreamPerWork()) {
      //fetchStreamPerWork:
      dispatch(
        fetchStreamPerWorkAsync({
          sisterSocietyEntityName,
          startDate: usageDataSearchParams.usageDataDate.startingDate.slice(
            0,
            6
          ),
          endDate: usageDataSearchParams.usageDataDate.endingDate.slice(0, 6),
          dsp: usageDataSearchParams.dsp,
          // @ts-ignore
          territories: countries.numericToAlpha2(usageDataSearchParams.territories[0]),
          repertoireId: selectedRepertoire,
          societyId: sisterSocietyEntityId,
        })
      )
    } else {
      dispatch(resetLastSearchValues())
    }

    const searchParams = {
      sisterSocietyEntityName,
      startDate: usageDataSearchParams.usageDataDate.startingDate,
      endDate: usageDataSearchParams.usageDataDate.endingDate,
      dsp: usageDataSearchParams.dsp,
      repertoireId: selectedRepertoire,
      societyId: sisterSocietyEntityId
    }
    if (usageDataSearchParams?.territories.length > 0) {
      searchParams['territories'] = usageDataSearchParams.territories.map((territory) =>
        countries.numericToAlpha2(territory)
      ).join(', ')
    }
    dispatch(fetchStreamPerOfferAsync(searchParams))
    dispatch(fetchMapDataUsageDataAsync(searchParams))
  }

  const handleRepertoireOnClick = (repertoire) => {
    dispatch(setSelectedRepertoire(repertoire.code))
  }

  useEffect(() => {
    if (mandatorId == null || usageDataDspStatus === 200) return
    dispatch(fetchRepertoiresAsync({ societyId: sisterSocietyEntityId }))
  }, [dispatch, mandatorId])

  useEffect(() => {
    if (mandatorId == null || usageDataDspStatus === 200) return
    dispatch(fetchDspListAsync({ mandatorId }))
  }, [dispatch, mandatorId])

  useEffect(() => {
    if (mandatorId == null || usageDataTerritoryStatus === 200) return
    dispatch(fetchTerritoryListAsync({ mandatorId }))
  }, [dispatch, mandatorId])

  useEffect(() => {
    if (!mandatorId) {
      return;
    }
    if (selectedDsp.length === 0 && dspList.length > 0) {
      dispatch(loadUsageDataDspList(dspList[0].code))
    } else if (dspList.find((i) => selectedDsp === i.code)) {
      dispatch(loadUsageDataDspList(selectedDsp))
    }
  }, [dispatch, dspList, mandatorId])
  const filtersStatusArray = [usageDataDspStatus, usageDataTerritoryStatus]

  const filtersError = !filtersStatusArray?.every((status) => {
    return allowedStatus.includes(status)
  })

  useEffect(() => {
    dispatch(
      setToasterState({
        status: filtersError,
        nature: 'error',
        messageTranslationKey: 'errorPage.500.message',
      })
    )
    return () => {
      dispatch(
        setToasterState({
          status: false,
        })
      )
    }
  }, [filtersError])

  return (
    <ListGroup horizontal={'sm'} className={'w-100 usage-data'}>
      <ListGroup.Item className={'flex-fill d-flex justify-content-center p-1'}>
        <FiltersComponent
          statusArray={pageComponentsStatusArray}
          textPlaceholder={`${t('filters.providers-placeholder')}`}
          data={dspList.map((item: any) => {
            const mappedData = {
              label: item.label,
              value: item.label,
              code: item.code,
            }
            return mappedData
          })}
          filterType={'monoSelect'}
          handleOnClick={handleDspChange}
          status={usageDataDspStatus}
          selectedValue={selectedDsp}
          widthFilter={'300px'}
        />
      </ListGroup.Item>
      <ListGroup.Item className={'flex-fill d-flex justify-content-center p-1'}>
        <FiltersComponent
          statusArray={pageComponentsStatusArray}
          textPlaceholder={`${t('filters.territories-placeholder')}`}
          data={territoryList.map((item: TerritoryLicenses) => {
            const mappedData = {
              label: item.label,
              value: item.label,
              code: item.code.toString(),
            }
            return mappedData
          })}
          filterType={'multiSelect'}
          handleComponentChange={handleTerritoryChange}
          status={usageDataTerritoryStatus}
          selectedValues={selectedTerritories.map(String)}
          widthFilter={'300px'}
        />
      </ListGroup.Item>
      <ListGroup.Item className={'flex-fill d-flex justify-content-center p-1 filter-date'}>
        <FiltersComponent
          statusArray={pageComponentsStatusArray}
          textPlaceholder={`${t('usage_data.time-period')}`}
          handleComponentChange={handleDateChange}
          startDate={usageDataSearchParams.usageDataDate?.startingDate}
          endDate={usageDataSearchParams.usageDataDate?.endingDate}
          maxDate={subDays(new Date(), 1)}
          minDate={subMonths(new Date(), 18)}
          filterType={'quarterDateRange'}
          widthFilter={'300px'}
        />
      </ListGroup.Item>
      <ListGroup.Item
          className={'flex-fill d-flex justify-content-center p-1'}
        >
          <FiltersComponent
            keepInitialDataSort
            statusArray={pageComponentsStatusArray}
            textPlaceholder={`${t(
              'form.filters.repertoires'
            )}`}
            data={repertoiresData ? allRepertoiresOptionsData.concat(repertoiresData.map((item) => {
              const mappedData: SelectItem = {
                label: item.label,
                value: item.label,
                code: item.code,
              }
              return mappedData
            })) : allRepertoiresOptionsData}
            status={repertoiresStatus}
            selectedValue={selectedRepertoire}
            filterType={'monoSelect'}
            widthFilter={'330px'}
            handleOnClick={handleRepertoireOnClick}
          />
        </ListGroup.Item>
      <ListGroup.Item
        className={'flex-column text-center justify-content-end p-1'}
      >
        <ApplyButtonComponent
          onClickHandler={handleClick}
          disabled={
            pageComponentsStatusArray
              ? pageComponentsStatusArray.indexOf(-1) !== -1
              : false
          }
        />
      </ListGroup.Item>
    </ListGroup>
  )
}
export default UsageDataHeader
