import {invokeRemoteGet} from "./api.utils";
import { KPIQueryParamModel, MultiSelectFilterType } from "../types/marketShareTypes";
import {ExploitationPeriod} from "../types/distributionsRoyaltiesTypes"
import { LineChartInputData, RepertoirePerformanceTotalAmountData, MarketShareByDSP } from "../types/marketShareTypes"

export function getTerritoriesMarketShare(params: { mandatorId: number }) {
    return invokeRemoteGet<
        { territoryList: MultiSelectFilterType<number>[] },
        { mandatorId: number }
        >('usage-data/territories', params)
}

export function getDspsMarketShare(params: { mandatorId: number, referential: string }) {
    return invokeRemoteGet<
        { dspList: MultiSelectFilterType<string>[] },
        { mandatorId: number, referential: string }
        >('dsps', params)
}

export function getGroupedOffersMarketShare(params: any) {
    return invokeRemoteGet<{ dspList: MultiSelectFilterType<string>[] }, any>(
        'kpi/grouped-offers',
        params
    )
}

export function getMarketKpiDeclaredRevenue(params: KPIQueryParamModel) {
    return invokeRemoteGet<
        { declared_revenue: ExploitationPeriod[] },
        KPIQueryParamModel
    >('kpi/declared-revenue', params)
}

export function getMarketKpiStreamsPerCommercialModels (params: KPIQueryParamModel) {
    return invokeRemoteGet<
        { streams_per_commercial_models : ExploitationPeriod[] },
        KPIQueryParamModel
    >('kpi/streams-per-commercial-model', params)
}

export function getMarketKpiNumberOfUsers (params: KPIQueryParamModel) {
    return invokeRemoteGet<
        { number_of_users : ExploitationPeriod[] },
        KPIQueryParamModel
    >('kpi/number-of-users', params)
}

export function getMarketKpiAvgDeclaredRevenuePerUser (params: KPIQueryParamModel) {
    return invokeRemoteGet<
        { averageDeclaredRevenuePerUser : LineChartInputData[] },
        KPIQueryParamModel
    >('kpi/average-declared-revenue-per-user', params)
}

export function getRepertoirePerformanceTotalAmount (params: KPIQueryParamModel) {
    return invokeRemoteGet<
        RepertoirePerformanceTotalAmountData[],
        KPIQueryParamModel
    >('kpi/repertoire-performance-total-amount', params)
}

export function getMarketShareByCommercialModel (params: KPIQueryParamModel) {
    return invokeRemoteGet<
        MarketShareByDSP[],
        KPIQueryParamModel
    >('kpi/market-share-by-commercial-model', params)
}

export function getMarketShareByClaimType (params: KPIQueryParamModel) {
    return invokeRemoteGet<
        MarketShareByDSP[],
        KPIQueryParamModel
    >('kpi/market-share-by-claim-type', params)
}