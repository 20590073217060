import React from 'react';
import {AppPropsType, SisterSociety, UserData} from '../../types';
import { connect, useDispatch } from 'react-redux';
import { resetContext, setSwitchAccount} from '../../slices/appContextSlice';
import { useAuthService } from '../../oidcAuthentication/hooks';
import { NavDropdown } from 'react-bootstrap'

import './Supranav.scss';
import {useTranslation} from "react-i18next";

const SupraNav = (props: Props) => {
	const { sisterSocieties, switchAccount, selectedSisterSociety } = props;
	const dispatch = useDispatch();
	const authService = useAuthService();
	const {t} = useTranslation()

	const hasMultipleAccounts = !switchAccount && sisterSocieties && sisterSocieties.identities.length > 1;
	const showNavBar = () => {
		if (sisterSocieties && sisterSocieties.identities.length > 1){
			return !switchAccount && selectedSisterSociety;
		}else {
			return !switchAccount
		}

	};
	const redirectToSwitch = () => {
		dispatch(setSwitchAccount({ switchAccount: true }));
	};
	const logOut = () => {
		dispatch(resetContext())
		authService.logout();
	};

	const renderSelectedUserName = () => {
		return sisterSocieties?.firstName + " " + sisterSocieties?.lastName;
	};

	const renderSelectedSisterSocieteName = () => {
		return selectedSisterSociety?.name;
	};

	return (
		showNavBar() ? (
			<div className="supra-nav">
				<div className="nav-left">
					{sisterSocieties && <>
						<span className="user"> Hello {renderSelectedUserName()} !</span>
						</>
					}
				</div>
				<div className="nav-right">
					{hasMultipleAccounts && (
						<div className="switch-account" onClick={() => redirectToSwitch()}>
							{t('header.switch-account')} : {renderSelectedSisterSocieteName()}
						</div>
					)}
					<NavDropdown
						title={
							<span>
									<i className='account'></i>
								</span>
						}
						id='basic-nav-dropdown'
						align={'end'}
						style={!hasMultipleAccounts ? { marginLeft: '270px' } : {}}
					>
						<NavDropdown.ItemText style={{ width: '225px' }}>
								<div className='d-flex justify-content-start'>
									<i className='user-connected'></i>
									<div>
										{t('header.connected-as')}
										<b> {selectedSisterSociety?.name}</b>
									</div>
								</div>
						</NavDropdown.ItemText>
						<NavDropdown.Divider />
						<NavDropdown.Item onClick={() => logOut()}>
							<div className='d-flex justify-content-start'>
									<i className='log-out'></i> {t('header.logout')}
								</div>
						</NavDropdown.Item>
					</NavDropdown>
				</div>
			</div>
		) : <></>
	);
};


const mapStateToProps = (state: AppPropsType) => ({
	sisterSocieties: state.appContext.sisterSocities,
	switchAccount: state.appContext.switchAccount,
	selectedSisterSociety: state.appContext.selectedSisterSociety
});
export default connect(mapStateToProps)(SupraNav);

interface Props {
	sisterSocieties: UserData | null
	switchAccount: boolean
	selectedSisterSociety: SisterSociety|null
}
