import React from 'react';
import { Form } from 'react-bootstrap';
import './index.scss';

interface Props {
  action: ()=> void
  checked: boolean
  label: any
}

export default function ToggleSwitch(props: Props) {
	const { action, checked, label } = props;
	return (
		<>
			<Form className='d-flex flex-column align-items-end justify-content-end p-md-2 toggle-switch-wrapper'>
				<Form.Switch
					onChange={() => action()}
					id='events-switch'
					label={label}
					checked={checked}
				/>
			</Form>
		</>
	);
}
