import React from 'react';
import { default as links } from '../i18n/links.json';
import moment from 'moment';
import {
	AddedWork,
	Work,
	WorkCardType,
	WorkFormatted,
	WorkInterestedParty,
	WorksListWorkInterestedParties,
	WorksListWorkJazzImproviser,WorksListWorkPerformers,
	TypicalProgramWorkInterestedParties
} from '../types';
import { EDITOR_CODE } from "../constants/constants";
import LinksFR from '../i18n/menu-links-fr.json';
import LinksEN from '../i18n/menu-links-en.json';


export const getDashboardLink = (lang: string): string => {
	const nav = lang === 'en' ? links.en : links.fr;
	const baseUrl = process.env.REACT_APP_SOCIETAIRE_BASE_URL;

	return `${baseUrl}${nav['my-account']}`;
};

export const formatRGPDContent = (t: any, lang: string): string => {
	const links = lang === 'en' ? LinksEN : LinksFR;
	const env: string = process.env.REACT_APP_ENV_NAME.toString();
	const environmentLinks = (links as any)[env];
	return t('rgpd')
		.replace('{0}', environmentLinks['privacy-policy'])
		.replace('{1}', environmentLinks['sacem_hub']);
};


export const capitalize = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getArrayFromString = (str: string | undefined, splitter: string): string[] => {
	if (str !== undefined) {
		return str.split(splitter).filter(s => s.trim()).map(s => s.trim());
	}
	return [];
};

export const getWorkDuration = (str: string): number => moment.duration(`00:${str}`).asSeconds();

const formatWorkToString = (authors: (WorkFormatted|undefined)[], addIPI: boolean = false)=> {
	return authors
		.map(author => `${author?.firstName ? author?.firstName : '' } ${author?.lastName} ${(addIPI && author?.ipiCode) ? '(' + author.ipiCode + ')' : ''}`).toString();
};

const getRightholders = (authors: (WorkFormatted|undefined)[], composers:(WorkFormatted|undefined)[],
												 arrangers:(WorkFormatted|undefined)[], performers:(WorkFormatted|undefined)[]) => {
	const result : (WorkFormatted | undefined)[] = [];
	result.push(...authors, ...composers, ...arrangers,...performers);
	return  result.map(composer => composer?.firstName + ' ' + composer?.lastName + ', ' + composer?.role);
};

export const getNumbersInTowDigits = (num: number) => {
	return num.toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false });
};

export const formatDuration = (time: number) => {
	const minutes = Math.floor(time / 60);
	const seconds = time - minutes * 60;
	return getNumbersInTowDigits(minutes) + ' : ' + getNumbersInTowDigits(seconds);
};

const getWorkFormattedIntersetedParty = (workInterestedParties: WorkInterestedParty[], codeRoles: string[]) => {
	return workInterestedParties.map(intersetedPartyFiltered => {
		const roleFound = intersetedPartyFiltered.workInterestedPartyRoles.filter(role => codeRoles.includes(role.workInterestedPartyRoleCode));
		return roleFound.length > 0 ? {
			firstName: intersetedPartyFiltered.workInterestedPartyFirstName,
			lastName: intersetedPartyFiltered.workInterestedPartyLastName,
			role: roleFound[0].workInterestedPartyRoleLabel
		} : undefined;
	}).filter(workFormatted => workFormatted !== undefined);
};

const getWorkFormattedEditorsAndSubEditors = (workInterestedParties: WorkInterestedParty[], codeRoles: string[]) => {
	return workInterestedParties.map(intersetedPartyFiltered => {
		const roleFound = intersetedPartyFiltered.workInterestedPartyRoles.filter(role => codeRoles.includes(role.workInterestedPartyRoleCode));
		return roleFound.length > 0 ? {
			corporateName: intersetedPartyFiltered.workInterestedPartyCorporateName,
			address: intersetedPartyFiltered.workInterestedPartyAddress &&
				intersetedPartyFiltered.workInterestedPartyAddress.workInterestedPartyAddress1 + ' ' +
				intersetedPartyFiltered.workInterestedPartyAddress.workInterestedPartyAddressZipCode + ' ' +
				intersetedPartyFiltered.workInterestedPartyAddress.workInterestedPartyAddressCity,
			country: intersetedPartyFiltered.workInterestedPartyAddress &&
				intersetedPartyFiltered.workInterestedPartyAddress.workInterestedPartyAddressCountry.workInterestedPartyAddressCountryName,
			role: roleFound[0].workInterestedPartyRoleLabel
		} : undefined;
	}).filter(workFormatted => workFormatted !== undefined);
};


export const mapWorkObjectToWorkCardObject = (work: Work) : WorkCardType => {
	const composers: (WorkFormatted|undefined)[] = getWorkFormattedIntersetedParty(work.workInterestedParties, ['C', 'CA']);
	const authors: (WorkFormatted|undefined)[] = getWorkFormattedIntersetedParty(work.workInterestedParties, ['A', 'CA']);
	const arrangers: (WorkFormatted | undefined)[] = getWorkFormattedIntersetedParty(work.workInterestedParties, ['AR']);
	const performers: (WorkFormatted | undefined)[] = getWorkFormattedIntersetedParty(work.workInterestedParties, ['IN']);
	const editors: (WorkFormatted|undefined)[] = getWorkFormattedEditorsAndSubEditors(work.workInterestedParties, ['E']);
	const subEditors: (WorkFormatted | undefined)[] = getWorkFormattedEditorsAndSubEditors(work.workInterestedParties, ['publisher', 'SE']);
	return {
		workToken: work.workToken,
		workCode: work.workCode,
		title: work.workTitle,
		genre: work.workGenre.worksListWorkGenreCode,
		ISWCCode: work.workISWCCode,
		duration: formatDuration(work.workDuration),
		author: formatWorkToString(authors),
		composer: formatWorkToString(composers),
		performer: formatWorkToString(composers),
		rightholders: getRightholders(authors, composers, arrangers, performers),
		editors,
		subEditors
	};
};

export const renderRepresentationAddress = (address: any) => {
	const {
		representationAddress1,
		representationAddressZipCode,
		representationAddressCity,
		representationAddressCountry: {
			representationAddressCountryName,
		}
	} = address;
	return (
		<>
			{ representationAddress1 && (
				<p>{representationAddress1}</p>
			)}
			<p>{`${representationAddressZipCode || ''} ${representationAddressCity || ''}`}</p>
			<p>{representationAddressCountryName || ''}</p>
		</>

	);
};

export const formatDate = (value: string, lang: string = 'fr') => (
	moment(value).format(lang === 'en' ? 'YYYY-MM-DD' : 'DD/MM/YYYY')
);

export const secondsToMinutes = (seconds: number) => {
	if (isNaN(seconds)) {
		return '';
	}
	const duration = moment.duration(seconds, 'seconds');
	return moment.utc(duration.as("milliseconds")).format('mm:ss');
};

const formattedPerformers = (workPerformers: WorksListWorkPerformers[], codeRoles: string[], roleTypeForTranslate:string) => {
	return workPerformers.map(workPerformer => {
		const roleFound = workPerformer.worksListWorkPerformerRoles.filter(
			role => codeRoles.includes(role.worksListWorkPerformerRoleCode));
		return roleFound.length > 0 ? {
			firstName: workPerformer.worksListWorkPerformerFirstName,
			lastName: workPerformer.worksListWorkPerformerLastName,
			role: roleFound[0].worksListWorkPerformerRoleLabel,
			ipiCode: workPerformer.worksListWorkPerformerIPICode,
			roleTypeForTranslate:roleTypeForTranslate
		} : undefined;
	}).filter(workFormatted => workFormatted !== undefined);
};


const formattedEditorsAndSubEditors = (workInterestedParties: WorksListWorkInterestedParties[], codeRoles: string[]) => {
	return workInterestedParties.map(intersetedPartyFiltered => {
		const roleFound = intersetedPartyFiltered.worksListWorkInterestedPartyRoles.filter(role => codeRoles.includes(role.worksListWorkInterestedPartyRoleCode));
		return roleFound.length > 0 ? {
			corporateName: intersetedPartyFiltered.worksListWorkInterestedPartyCorporateName,
			address: intersetedPartyFiltered.worksListWorkInterestedPartyAddress &&
				intersetedPartyFiltered.worksListWorkInterestedPartyAddress.worksListWorkInterestedPartyAddress1 + ' ' +
				intersetedPartyFiltered.worksListWorkInterestedPartyAddress.worksListWorkInterestedPartyAddressZipCode + ' ' +
				intersetedPartyFiltered.worksListWorkInterestedPartyAddress.worksListWorkInterestedPartyAddressCity,
			country: intersetedPartyFiltered.worksListWorkInterestedPartyAddress &&
				intersetedPartyFiltered.worksListWorkInterestedPartyAddress.worksListWorkInterestedPartyAddressCountry.worksListWorkInterestedPartyAddressCountryCode,
			role: roleFound[0].worksListWorkInterestedPartyRoleLabel,
			ipiCode: intersetedPartyFiltered.worksListWorkInterestedPartyIPICode
		} : undefined;
	}).filter(workFormatted => workFormatted !== undefined);
};

const formattedImprovisers = (worksListWorkJazzImprovisers: WorksListWorkJazzImproviser[], codeRoles: string[], roleTypeForTranslate:string) => {
	return worksListWorkJazzImprovisers.map((workImproviser:WorksListWorkJazzImproviser) => {
		const roleFound = workImproviser.worksListWorkJazzImproviserRoles.filter(
			(role: any) => codeRoles.includes(role.worksListWorkJazzImproviserRoleCode));
		return roleFound.length > 0 ? {
			firstName: workImproviser.worksListWorkJazzImproviserFirstName,
			lastName: workImproviser.worksListWorkJazzImproviserLastName,
			role: roleFound[0].worksListWorkJazzImproviserRoleLabel,
			ipiCode: workImproviser.worksListWorkJazzImproviserIPICode,
			roleTypeForTranslate:roleTypeForTranslate
		} : undefined;
	}).filter(workFormatted => workFormatted !== undefined);
};

const formattedIntersetedParty = (workInterestedParties: WorksListWorkInterestedParties[], codeRoles: string[], roleType:string) => {
	if (!workInterestedParties) {
		return [];
	}
	return workInterestedParties.map(intersetedPartyFiltered => {
		const roleFound = intersetedPartyFiltered.worksListWorkInterestedPartyRoles.filter(role => codeRoles.includes(role.worksListWorkInterestedPartyRoleCode));
		return roleFound.length > 0 ? {
			firstName: intersetedPartyFiltered.worksListWorkInterestedPartyFirstName,
			lastName: intersetedPartyFiltered.worksListWorkInterestedPartyLastName,
			role: roleFound[0].worksListWorkInterestedPartyRoleLabel,
			roleCode: roleFound[0].worksListWorkInterestedPartyRoleLabel,
			ipiCode: intersetedPartyFiltered.worksListWorkInterestedPartyIPICode,
			roleTypeForTranslate : roleType
		} : undefined;
	}).filter(workFormatted => workFormatted !== undefined);
};

export const formatWorksListWorkInterestedParties = (work: AddedWork) => {
	const composers: (WorkFormatted|undefined)[] = formattedIntersetedParty(work.worksListWorkInterestedParties, ['C', 'CA'],'CMP');
	const authors: (WorkFormatted|undefined)[] = formattedIntersetedParty(work.worksListWorkInterestedParties, ['A', 'CA'],'ATR');
	const arrangers: (WorkFormatted | undefined)[] = formattedIntersetedParty(work.worksListWorkInterestedParties, ['AR'],'AR');
	const performers: (WorkFormatted | undefined)[] = formattedPerformers(work.worksListWorkPerformers, ['IN'],'INT');
	const improvisers: (WorkFormatted | undefined)[] = work.worksListWorkJazzImprovisers ? formattedImprovisers(work.worksListWorkJazzImprovisers, ['IJ'],'IMJ') : [];
	const editors: (WorkFormatted|undefined)[] = formattedEditorsAndSubEditors(work.worksListWorkInterestedParties, ['E']);
	const subEditors: (WorkFormatted | undefined)[] = formattedEditorsAndSubEditors(work.worksListWorkInterestedParties, ['publisher', 'SE']);
	const resultRightHolders = [...authors, ...composers, ...arrangers, ...performers];

	return {
		workCode: work.workCode,
		title: work.worksListWorkTitle,
		worksListWorkGenre: work.worksListWorkGenre,
		genre: work.worksListWorkGenre.worksListWorkGenreLabel,
		workISWCCode: work.workISWCCode ? work.workISWCCode.toString() : '',
		duration: work.workDuration,
		worksListWorkDuration: work.worksListWorkDuration,
		rightHolders: resultRightHolders,
		strAuthors: formatWorkToString(authors),
		strComposers: formatWorkToString(composers, !work.workCode),
		strImprovisers: improvisers ? formatWorkToString(improvisers) : '',
		strPerformers: performers ? formatWorkToString(performers) : '',
		authors,
		composers,
		performers,
		improvisers,
		editors,
		subEditors
	};
};
export function formatInterestedParties(items: any, code: string, label: string) {
	if (items.length > 0) {
		const arrayItems = items.split(',');
		return arrayItems.map((item: string) => (
			{
				worksListWorkInterestedPartyCorporateName: '',
				worksListWorkInterestedPartyFirstName: '',
				worksListWorkInterestedPartyLastName: item,
				worksListWorkInterestedPartyIPICode: null,
				worksListWorkInterestedPartyType: null,
				worksListWorkInterestedPartyRoles: [
					{
						worksListWorkInterestedPartyRoleCode: code,
						worksListWorkInterestedPartyRoleLabel: label
					}
				]
			}
		));
	} else {
		return [];
	}
}
export function formatPerformers(items: any) {
	if (items.length > 0) {
		return items.length ? [...items.split(',').map((item: string) => ({
			worksListWorkPerformerAddress: '',
			worksListWorkPerformerCorporateName: '',
			worksListWorkPerformerFirstName: '',
			worksListWorkPerformerLastName: item,
			worksListWorkPerformerIPICode: null,
			worksListWorkPerformerRoles: [
				{
					worksListWorkPerformerRoleCode: 'IN',
					worksListWorkPerformerRoleLabel: 'Interprète'
				}
			]
		}))] : [];
	} else {
		return [];
	}
}
export function formatImprovisers(items: any) {
	if (items.length > 0) {
		return items.length ? [...items.split(',').map((item: string) => ({
			worksListWorkJazzImproviserFirstName: '',
			worksListWorkJazzImproviserLastName: item,
			worksListWorkJazzImproviserIPICode: null,
			worksListWorkJazzImproviserRoles: [
				{
					worksListWorkJazzImproviserRoleCode: 'IJ',
					worksListWorkJazzImproviserRoleLabel: 'Improvisateur'
				}
			]
		}))] : [];
	} else {
		return [];
	}
}

export const getPartyName = (party: TypicalProgramWorkInterestedParties, roleCode: string) => {
	const role = party.typicalProgramWorkInterestedPartyRoles[0].typicalProgramWorkInterestedPartyRoleCode;

	if (role === roleCode && role !== EDITOR_CODE) {
		const firstName = party.typicalProgramWorkInterestedPartyFirstName;
		const lastName = party.typicalProgramWorkInterestedPartyLastName;

		if (!firstName) {
			return lastName;
		} else if (!lastName) {
			return firstName;
		} else {
			return `${firstName} ${lastName}`;
		}
	}

	if (role === roleCode && role === EDITOR_CODE) {
		return party.typicalProgramWorkInterestedPartyCorporateName;
	} else {
		return undefined;
	}
};

export const getDelegationsLink = (t: any, lang: string): string => {
	const links = lang === 'en' ? LinksEN : LinksFR
	const env: string = process.env.REACT_APP_ENV_NAME.toString()
	const environmentLinks = (links as any)[env]
	return t('create-date.popup-confirm')
		.replace('{0}', environmentLinks['gdpub'])
}

//usage data
export const formatNumber = (language: string, value: number): string => {
	if (value === null || value === undefined) return ''
	const locale = language === 'en' ? 'en-US' : 'fr-FR'
	return value
		.toLocaleString(locale, {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		})
		.replaceAll(' ', '  ')
}

