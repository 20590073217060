import React from 'react'

import './topStatusBar.scss'
import {useTranslation} from "react-i18next";
export const allowedStatus = [200, 204, -2, -1]
const TopStatusBar = ({
  nature,
  messageTranslationKey,
}: {
  nature: 'error' | 'success' | 'warning'
  messageTranslationKey: string
}) => {
  const { t } = useTranslation();
  const barStyle = () => {
    switch (nature) {
      case 'error':
        return 'top-bar-error'
      case 'success':
        return 'top-bar-success'
      case 'warning':
        return 'top-bar-warning'
      default:
        return 'top-bar-error'
    }
  }
  return <div className={`top-status-bar ${barStyle()}`}>{t(messageTranslationKey)}</div>
}

export default TopStatusBar
