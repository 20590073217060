import { httpCaller } from './httpCaller';

export function getConfiguration() {
    return new Promise<{ data: Record<string, any> }>((resolve, reject) => {
        httpCaller
            .get('config-flag')
            .then((response) => {
                if (response.status === 200) {
                    return resolve({data: response.data})
                } else {
                    return reject({ status: response.status })
                }
            })
            .catch((error) => {
                console.error('API getConfiguration error', error)
                return reject(error)
            })
    })
}