import {
	CreateProgramPayload,
	DeleteProgramParam,
	FormProgramInterface,
	TypicalProgramParam,
	TypicalProgramPayload,
	WorkCardType, WorkCreators,
	WorkPayload,
	TypicalProgram,
	programWorksPram,
	programWorksPayload,
	WorkResponse, WorksListWorksPayload
} from '../types';
import { httpCaller } from './httpCaller';
import { getWorkDuration } from '../helpers';

export function fetchTypicalPrograms(typicalProgramParam: TypicalProgramParam) {
	const { filter, pagination, societyId } = typicalProgramParam;
	return new Promise<TypicalProgramPayload>((resolve, reject) => {
		httpCaller
			.get(`/societies/${societyId}/set-lists`, {
				params: {
					page: pagination?.page,
					size: pagination?.size,
					typicalProgramTitle: filter?.title,
				}
			})
			.then((response) => response.status === 200
				? resolve(response.data)
				: reject(response.status))
			.catch((error) => reject(error));
	});
}


export async function postWorksList (submittedValues: {values : FormProgramInterface, societyId?: number, isDraft: boolean}) {
	const { values, societyId } = submittedValues;
	if (values.worksListId && values.worksListId !== 0) {
		const worksListId = values.worksListId;
		return new Promise<{ worksListId: number }>((resolve, reject) => {
			httpCaller
				.patch(`/societies/${societyId}/works-list/${worksListId}`,{
					worksListTitle: values.worksListTitle
				})
				.then((response) => resolve(response.data))
				.catch((error) => reject(error.response.data));
		});
	} else {
		return new Promise<{ worksListId: number }>((resolve, reject) =>
			httpCaller
				.post(`/societies/${societyId}/works-list`, values, { })
				.then((response) => {
					return resolve(response.data);
				})
				.catch((error) => {
					console.error({ error });
					return reject(error);
				})
		);
	}

}

export function deleteWorkLists(deleteProgParam: DeleteProgramParam) {
	return new Promise<number>((resolve, reject) =>
		httpCaller
			.delete(`/societies/${deleteProgParam.societyId}/works-list/${deleteProgParam.id}`,
				{	})
			.then((response) => {
				return resolve(response.status);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}

export const deleteWorkFromWorksList = (worksListWorkId: number, worksListId: number, societyId: number) => {
	const url = `/societies/${societyId}/works-list/${worksListId}/works/${worksListWorkId}`;

	return new Promise<number>((resolve, reject) =>
		httpCaller
			.delete(url)
			.then((response) => {
				return resolve(response.status);
			})
			.catch((error) => {
				return reject(error);
			})
	);
};

export const addWorkToWorksList = (work: WorkCardType, worksListId: number,  isNRWork: boolean, societyId: number) => {
	const url = `/societies/${societyId}/works-list/${worksListId}/works`;

	let data: WorkPayload = {};
	if (!isNRWork) {
		data = {
			work: {
				workCode: work.workCode,
				workToken: work.workToken,
				workISWC: work.ISWCCode,
				worksListWorkDuration: getWorkDuration(work.duration)
			}
		};
	} else {
		let workCreators: WorkCreators[] = [];
		if (work.ipiCodes) {
			 workCreators = work.ipiCodes?.map(ipi => {
				return {
					ipiNameNumber: ipi.ipiCode,
					name: ipi.ipiName
				};
			});
		}

		data = {
			worksListWork: {
				workCreators: workCreators,
				worksListWorkTitle: work.title,
				workListWorkIswc: work.ISWCCode,
				worksListWorkTypeCode: 'MS',
				worksListWorkDuration: getWorkDuration(work.duration),
			}
		};
	}

	return new Promise<WorkResponse>((resolve, reject) =>
		httpCaller
			.post(url, data, { })
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
};

export function fetchWorksOfWorksList(worksListId: number, societyId?: number) {
	const url = `/societies/${societyId}/works-list/${worksListId}/works`;
	return new Promise<WorksListWorksPayload>((resolve, reject) =>
		httpCaller
			.get(url)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}

export const patchWorkInWorksList = (work: WorkCardType, worksListWorkId: number, worksListId: number, societyId?: number) => {
	const url = `/societies/${societyId}/works-list/${worksListId}/works/${worksListWorkId}`;
	const data = {
		worksListWorkDuration: getWorkDuration(work.duration),
	};
	if (!work.workCode) {
		let workCreators: WorkCreators[] = [];
		if (work.ipiCodes) {
			workCreators = work.ipiCodes?.map(ipi => {
				return {
					ipiNameNumber: ipi.ipiCode,
					name: ipi.ipiName
				};
			});
		}

		data['workCreators'] =  workCreators;
		data['worksListWorkTitle'] = work.title;
		data['workISWC'] =  work.workISWC || '';
		data['worksListWorkTypeCode'] =  work.genre || '';
	}


	return new Promise<WorkResponse>((resolve, reject) =>
		httpCaller
			.patch(url, data)
			.then((response) => {
				return resolve(response?.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
};

export const createTypicalProgram = (values: CreateProgramPayload) => {
	if (values.worksListId !== 0) {
		const data:CreateProgramPayload = {
			worksListId: values.worksListId,
			worksListTitle: values.worksListTitle
		};

		return new Promise<{ typicalProgramId: number }>((resolve, reject) =>
			httpCaller
				.post(`/societies/${values.societyId}/set-lists`, data)
				.then((response) => {
					return resolve(response.data);
				})
				.catch((error) => {
					console.error({ error });
					return reject({ message : error.data.message });
				})
		);
	}
	return null;
};
export function fetchTypicalProgramsDetails(requestParam: programWorksPram) {
	const programId = requestParam.programId;
	const societyId = requestParam.societyId;
	const options = {};

	///societies/${societyId}/set-lists
	return new Promise<TypicalProgram>((resolve, reject) =>
		httpCaller
			.get(`societies/${societyId}/set-lists/${programId}` ,
				{
					params: options
				})
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}

export function fetchTypicalProgramsWorks(requestParam: programWorksPram) {
	const typicalProgramId = requestParam.programId;
	const societyId = requestParam.societyId;
	const options: any = {
		page: requestParam.page,
		size: requestParam.size,
	};

	return new Promise<programWorksPayload>((resolve, reject) =>
		httpCaller
			.get(`societies/${societyId}/set-lists/${typicalProgramId}/works`, { params: options })
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}
