import React from 'react'
import { formatNumber } from '../../helpers/formatter'
import { useTranslation } from 'react-i18next'
import './progressBarGroup.scss'

export interface ProgressBarGroupItem {
    code: string,
    displayName: string,
    value: string,
    percentage: number,
    color?: string
}

interface ProgressBarGroupProps {
  items: ProgressBarGroupItem[]
}

export const ProgressBarGroup = ({ items }: ProgressBarGroupProps) => {
  const { i18n } = useTranslation()

  return (
    <>
      {items.map((item) => (
        <div key={item.code} className='progress-bar-group-container'>
          <div className='first-row'>
            <span className='bar-label'>{item.displayName}</span>
            <span className='bar-amount'>{item.value}</span>
          </div>
          <div className='progress'>
            <div
              role='progressbar'
              className='progress-bar'
              aria-valuenow={item.percentage}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{
                width: `${item.percentage}%`,
                backgroundColor: item.color,
              }}
            ></div>
          </div>

          <span className='bar-percent'>{`${formatNumber(
            i18n.language,
            item.percentage
          )}%`}</span>
        </div>
      ))}
    </>
  )
}
