import { IMapObject, OnRegionTipShow } from '@react-jvectormap/core/dist/types'
import React, { useEffect, useRef, useState } from 'react'
import WorldMap from '../../../components/world-map'
import { Col, Container, Row } from 'react-bootstrap'
import { t } from 'i18next'
import { abbreviateNumber } from 'js-abbreviation-number'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  ExploitationTerritoriesPropType,
  RoyaltiesPerTerritoryType,
} from '../../../types/distributionsRoyaltiesTypes'
import { MAX_SCREEN_WIDTH, vectorMapAllowedRegions } from '../../../constants/constants'
import './distributionRoyaltiesBoxes.scss'
import countries from 'i18n-iso-countries'
import { formatNumber } from '../../../helpers/formatter'
import { useTranslation } from 'react-i18next'
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

const blueDegradedColors: string[] = [
  '#E4EAFF',
  '#C5D4FC',
  '#A6BCFC',
  '#8FA9FA',
  '#6687FA',
  '#6885de',
  '#4B6CDA',
  '#3251BA',
  '#1E3997',
  '#03175e',
]
const maxOfArray = (arr: number[]) => {
  return Math.max(...arr)
}
const numberAbbreviation = (number: number) => {
  const symbolsList: string[] = ['', ...t('utils.symbols_list').split(' ')]
  return abbreviateNumber(number, 1, { symbols: symbolsList })
}
const ExploitationTerritories = ({
  distributionsTerritories,
  royaltiesPerTerritory,
}: ExploitationTerritoriesPropType) => {
  const { i18n } = useTranslation()

  const mapRef = useRef<IMapObject>(null)

  const { screenWidth } = useWindowDimensions()
  const [isMobile, setIsMobile] = useState<boolean>(screenWidth < MAX_SCREEN_WIDTH)

  const maxDistributedAmount: number = maxOfArray(
    royaltiesPerTerritory.map((royalty: RoyaltiesPerTerritoryType) => {
      return royalty.distributedAmount
    })
  )
  const getDistributionRatioPerTerritory = (code: string) => {
    const currentRoyalty: RoyaltiesPerTerritoryType =
      royaltiesPerTerritory.filter((royalty: RoyaltiesPerTerritoryType) => {
        return royalty.territory_alpha2_code === code
      })[0]
    return {
      distributedAmount: currentRoyalty?.distributedAmount,
      distributedAmountPercentage: currentRoyalty?.distributedAmountPercentage,
    }
  }

  const getTerritoryColor = (code: string) => {
    const distributedAmountPercentageToMax: string = Math.round(
      (getDistributionRatioPerTerritory(code).distributedAmount /
        maxDistributedAmount) *
      100
    ).toString()

    const colorIndex: number =
      Number(distributedAmountPercentageToMax) == 100
        ? blueDegradedColors.length - 1
        : Number(distributedAmountPercentageToMax) < 10
          ? 0
          : Number(distributedAmountPercentageToMax.charAt(0))

    return blueDegradedColors[colorIndex]
  }

  const onRegionTipShow: OnRegionTipShow = (event, el: any, code) => {
    if (!distributionsTerritories.includes(code)) {
      event.preventDefault()
      return
    }

    const countryFullName = mapRef?.current?.getRegionName(
      code
    ) as any as string
    const distributionsRatio: DistributionsRatio =
      getDistributionRatioPerTerritory(code)
    if (!isMobile) {
      return el.html(
        `<div class='country-hover-info'>${t(
          'distribution-royalties.boxes.exploitation-territory.map_tip_text',
          {
            country_name: countryFullName,
            share_value: distributionsRatio.distributedAmountPercentage,
            distributed_amount: formatNumber(i18n.language,
              distributionsRatio.distributedAmount
            ),
          }
        )}</div>`
      )
    } else {
      event.preventDefault()
    }
  }
  const regionStyle = (code: string) => {
    //return a style object for regions states initial, hover
    if (distributionsTerritories.includes(code)) {
      return {
        initial: {
          fill: getTerritoryColor(code),

          cursor: 'pointer',
          'stroke-width': 0.2,
          'stroke-opacity': 0.6,
          stroke: 'blue',
        },
        hover: {
          fill: '#abdbe3',
        },
      }
    } else {
      return {}
    }
  }
  const zoomableTerritories: string[] = distributionsTerritories.filter(
    (terr) => {
      const alphaNumeric = countries.alpha2ToNumeric(terr)
      if (alphaNumeric) {
        return vectorMapAllowedRegions.includes(
          parseInt(alphaNumeric)
        )
      }
    }
  )
  useEffect(() => {
    if (zoomableTerritories.length > 0) {
      mapRef?.current?.setFocus({
        regions: zoomableTerritories,
        animate: true,
      })
    }
  }, [])
  useEffect(() => {
    setIsMobile(screenWidth < MAX_SCREEN_WIDTH)
  }, [screenWidth])
  return (
    <Container fluid>
      <Row className='world-map'>
        <Col className='px-0'>
          <WorldMap
            mapRef={mapRef}
            regionStyle={regionStyle}
            onRegionTipShow={onRegionTipShow}
            backgroundColor='#CAD7F4'
            regionsSelectable={true}
            regionsSelectableOne={true}
            zoomAnimate={true}
            className='vector-map-license-scope'
          />
        </Col>
      </Row>

      <Row className='distributions-territories-map-footer-row justify-content-center'>
        <Col className='map-legend-container-distributions'>
          {blueDegradedColors.map((color: string, index: number) => {
            return (
              <div
                className='legend-segment-container-distributions'
                key={index}
              >
                <div
                  className='colored-legend-element-distributions'
                  style={{
                    background: color,
                  }}
                />
                <div className='legend-max-value-element-distributions'>
                  {numberAbbreviation(
                    Math.round(((index + 1) * maxDistributedAmount) / 10)
                  )}
                </div>
              </div>
            )
          })}
        </Col>
      </Row>
    </Container>
  )
}

export default ExploitationTerritories

interface DistributionsRatio {
  distributedAmount: number
  distributedAmountPercentage: number
}
