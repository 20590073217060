import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './index.scss';
import { Button, Form } from 'react-bootstrap';
import { fetchRepertoiresAsync, setSwitchAccount } from '../../slices/appContextSlice';
import { connect, useDispatch } from 'react-redux';
import { useAuthService } from '../../oidcAuthentication/hooks';
import { AppPropsType, SisterSociety, UserData } from '../../types';

import { resetMarketShareState } from '../../slices/marketShareSlice'

const SwitchAccount = (props: Props) => {
	const { sisterSocieties, selectedSisterSociety, responseCode } = props;
	const [selectedId, setSelectedId] = useState<SisterSociety | null>(null);
	const dispatch = useDispatch();
	const [t] = useTranslation();
	const auth = useAuthService();

	useEffect(() => {
		//init local state selected id for radio-box pre-selection
		if (selectedSisterSociety) {
			setSelectedId(selectedSisterSociety)
		};
	}, []);


	const handleSwitchSisterId = () => {
		dispatch(setSwitchAccount({ selectedSisterSociety: selectedId, switchAccount: false }));
		dispatch(resetMarketShareState())
		if (selectedId) {
			dispatch(fetchRepertoiresAsync({ societyId: selectedId.sisterSocietyId.toString() }));
		}
	};

	const handleCancel = () => {
		dispatch(setSwitchAccount({ switchAccount: false }));
	};


	const renderSisterSocieties = () => {
		const hasSisterSocieties = sisterSocieties && sisterSocieties.identities.length > 0;
		const errorMessageVisible = responseCode === '500' && sisterSocieties && sisterSocieties.identities.length === 0;

		return (
			<>
				{hasSisterSocieties && sisterSocieties?.identities.map((sisterSociety: SisterSociety) => {
					const { sisterSocietyId, name } = sisterSociety;
					const label = `${sisterSocietyId}${name ? ' - ' + name : ''}`;
					return (
						<Form.Check
							type="radio"
							name="group"
							id={`default-${sisterSocietyId}`}
							label={label}
							value={name}
							onChange={() => setSelectedId(sisterSociety)}
							key={sisterSocietyId}
							className="switch-box-radio"
							disabled={!name}
							checked={selectedId?.sisterSocietyId === sisterSocietyId}
						/>
					);
				})}

				{errorMessageVisible && (
					<div className="switch-alert-msg p-3">
						<Trans i18nKey="page.switch-box.error" components={{ mailto: <a /> }} />
					</div>
				)}
			</>
		);
	};

	return (
		<div className="switch-background">
			<div className="box-page-container">
				<div className="select-box">
					<div className="accounts">
						<div className='title'>
							{t('page.switch-component.title')}
						</div>
						<Form className="form-content">
							{renderSisterSocieties()}
						</Form>
					</div>

					<div className="d-flex justify-content-between">
						<Button
							className='box-button confirm'
							variant='secondary'
							onClick={() => handleSwitchSisterId()}
							disabled={!selectedId}
						>
							{t('page.button.validate')}
						</Button>
						 <Button
							className='box-button logout-cancel'
							onClick={() => selectedSisterSociety ? handleCancel() : auth.logout()}
						>
							{selectedSisterSociety ? t('page.button.cancel') : t('page.button.logout')}
						</Button>
					</div>
				</div>
				<div className="background-box">
					<div className="sacem-logo" />
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: AppPropsType) => ({
	sisterSocieties: state.appContext.sisterSocities,
	loading: state.appContext.loading,
	responseCode: state.appContext.responseCode,
	selectedSisterSociety: state.appContext.selectedSisterSociety
});

export default connect(mapStateToProps)(SwitchAccount);

interface Props {
	sisterSocieties: UserData | null;
	loading: boolean;
	responseCode: string;
	selectedSisterSociety: SisterSociety| null

}