export const IDENTITY_CONFIG = {
	authority: process.env.REACT_APP_AUTH_URL, //(string): The URL of the OIDC provider.
	client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
	redirect_uri: window.location.origin +  process.env.PUBLIC_URL + '/signin-oidc', //The URI of your client application to receive a response from the OIDC provider.
	login: window.location.origin + process.env.PUBLIC_URL + '/signin-oidc',
	automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
	loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
	silent_redirect_uri: window.location.origin + process.env.PUBLIC_URL +  '/silentrenew', //(string): The URL for the page containing the code handling the silent renew.
	post_logout_redirect_uri: window.location.origin + process.env.PUBLIC_URL +  '/logout/callback', // (string): The OIDC post-logout redirect URI.
	audience: window.location.origin + process.env.PUBLIC_URL, //is there a way to specific the audience when making the jwt
	response_type: 'code', //(string, default: 'id_token'): The type of response desired from the OIDC provider.
	grantType: 'authorization_code',
	scope: 'openid default profile email roles',
};


export const METADATA_OIDC = {
	issuer: process.env.REACT_APP_AUTH_URL,
	jwks_uri: process.env.REACT_APP_AUTH_URL + '/.well-known/openid-configuration/jwks',
	authorization_endpoint: process.env.REACT_APP_AUTH_URL + '/authorize',
	token_endpoint: process.env.REACT_APP_AUTH_URL + '/access_token',
	userinfo_endpoint: process.env.REACT_APP_AUTH_URL + '/userinfo',
	end_session_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/endSession',
	check_session_iframe: process.env.REACT_APP_AUTH_URL + '/connect/checkSession',
	revocation_endpoint: process.env.REACT_APP_AUTH_URL + '/token/revoke',
	introspection_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/introspect',
	device_authorization_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/deviceauthorization'
};
