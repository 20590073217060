import i18n from '../i18n/config';

interface Country {
  iso: string
  translations: { FR: string, EN: string }
  phone: string
  iso3: string
  iso4?: string
}

export const COUNTRIES: Country[] = [
	{
		iso: 'AF',
		translations: { FR: 'Afghanistan', EN: 'Afghanistan' },
		phone: '93',
		iso3: 'AFG',
		iso4: '4',
	},
	{
		iso: 'ZA',
		translations: { FR: 'Afrique du Sud', EN: 'South africa' },
		phone: '27',
		iso3: 'ZAF',
		iso4: '710',
	},
	{
		iso: 'AX',
		translations: { FR: 'Åland', EN: 'Aland islands' },
		phone: '358',
		iso3: 'ALA',
		iso4: '248',
	},
	{
		iso: 'AL',
		translations: { FR: 'Albanie', EN: 'Albania' },
		phone: '355',
		iso3: 'ALB',
		iso4: '8',
	},
	{
		iso: 'DZ',
		translations: { FR: 'Algérie', EN: 'Algeria' },
		phone: '213',
		iso3: 'DZA',
		iso4: '12',
	},
	{
		iso: 'DE',
		translations: { FR: 'Allemagne', EN: 'Germany' },
		phone: '49',
		iso3: 'DEU',
		iso4: '276',
	},
	{
		iso: 'AD',
		translations: { FR: 'Andorre', EN: 'Andorra' },
		phone: '376',
		iso3: 'AND',
		iso4: '20',
	},
	{
		iso: 'AO',
		translations: { FR: 'Angola', EN: 'Angola' },
		phone: '244',
		iso3: 'AGO',
		iso4: '24',
	},
	{
		iso: 'AI',
		translations: { FR: 'Anguilla', EN: 'Anguilla' },
		phone: '1264',
		iso3: 'AIA',
		iso4: '660',
	},
	{
		iso: 'AQ',
		translations: { FR: 'Antarctique', EN: 'Antarctica' },
		phone: '672',
		iso3: 'ATA',
		iso4: '10',
	},
	{
		iso: 'AG',
		translations: { FR: 'Antigua-et-Barbuda', EN: 'Antigua and barbuda' },
		phone: '1268',
		iso3: 'ATG',
		iso4: '28',
	},
	{
		iso: 'SA',
		translations: { FR: 'Arabie Saoudite', EN: 'Saudi arabia' },
		phone: '966',
		iso3: 'SAU',
		iso4: '682',
	},
	{
		iso: 'AR',
		translations: { FR: 'Argentine', EN: 'Argentina' },
		phone: '54',
		iso3: 'ARG',
		iso4: '32',
	},
	{
		iso: 'AM',
		translations: { FR: 'Arménie', EN: 'Armenia' },
		phone: '374',
		iso3: 'ARM',
		iso4: '51',
	},
	{
		iso: 'AW',
		translations: { FR: 'Aruba', EN: 'Aruba' },
		phone: '297',
		iso3: 'ABW',
		iso4: '533',
	},
	{
		iso: 'AU',
		translations: { FR: 'Australie', EN: 'Australia' },
		phone: '61',
		iso3: 'AUS',
		iso4: '36',
	},
	{
		iso: 'AT',
		translations: { FR: 'Autriche', EN: 'Austria' },
		phone: '43',
		iso3: 'AUT',
		iso4: '40',
	},
	{
		iso: 'AZ',
		translations: { FR: 'Azerbaïdjan', EN: 'Azerbaijan' },
		phone: '994',
		iso3: 'AZE',
		iso4: '31',
	},
	{
		iso: 'BS',
		translations: { FR: 'Bahamas', EN: 'Bahamas' },
		phone: '1242',
		iso3: 'BHS',
		iso4: '44',
	},
	{
		iso: 'BH',
		translations: { FR: 'Bahreïn', EN: 'Bahrain' },
		phone: '973',
		iso3: 'BHR',
		iso4: '48',
	},
	{
		iso: 'BD',
		translations: { FR: 'Bangladesh', EN: 'Bangladesh' },
		phone: '880',
		iso3: 'BGD',
		iso4: '50',
	},
	{
		iso: 'BB',
		translations: { FR: 'Barbade', EN: 'Barbados' },
		phone: '1246',
		iso3: 'BRB',
		iso4: '52',
	},
	{
		iso: 'BE',
		translations: { FR: 'Belgique', EN: 'Belgium' },
		phone: '32',
		iso3: 'BEL',
		iso4: '56',
	},
	{
		iso: 'BZ',
		translations: { FR: 'Belize', EN: 'Belize' },
		phone: '501',
		iso3: 'BLZ',
		iso4: '84',
	},
	{
		iso: 'BJ',
		translations: { FR: 'Bénin', EN: 'Benin' },
		phone: '229',
		iso3: 'BEN',
		iso4: '204',
	},
	{
		iso: 'BM',
		translations: { FR: 'Bermudes', EN: 'Bermuda' },
		phone: '1441',
		iso3: 'BMU',
		iso4: '60',
	},
	{
		iso: 'BT',
		translations: { FR: 'Bhoutan', EN: 'Bhutan' },
		phone: '975',
		iso3: 'BTN',
		iso4: '64',
	},
	{
		iso: 'BY',
		translations: { FR: 'Biélorussie', EN: 'Belarus' },
		phone: '375',
		iso3: 'BLR',
		iso4: '112',
	},
	{
		iso: 'BO',
		translations: { FR: 'Bolivie', EN: 'Bolivia' },
		phone: '591',
		iso3: 'BOL',
		iso4: '68',
	},
	{
		iso: 'BQ',
		translations: {
			FR: 'Bonaire, Saint-Eustache et Saba',
			EN: 'Bonaire, Saint Eustatius and Saba',
		},
		phone: '5997',
		iso3: 'BES',
		iso4: '535',
	},
	{
		iso: 'BA',
		translations: { FR: 'Bosnie-Herzégovine', EN: 'Bosnia and herzegovina' },
		phone: '387',
		iso3: 'BIH',
		iso4: '70',
	},
	{
		iso: 'BW',
		translations: { FR: 'Botswana', EN: 'Botswana' },
		phone: '267',
		iso3: 'BWA',
		iso4: '72',
	},
	{
		iso: 'BR',
		translations: { FR: 'Brésil', EN: 'Brazil' },
		phone: '55',
		iso3: 'BRA',
		iso4: '76',
	},
	{
		iso: 'BN',
		translations: { FR: 'Brunei', EN: 'Brunei darussalam' },
		phone: '673',
		iso3: 'BRN',
		iso4: '96',
	},
	{
		iso: 'BG',
		translations: { FR: 'Bulgarie', EN: 'Bulgaria' },
		phone: '359',
		iso3: 'BGR',
		iso4: '100',
	},
	{
		iso: 'BF',
		translations: { FR: 'Burkina Faso', EN: 'Burkina faso' },
		phone: '226',
		iso3: 'BFA',
		iso4: '854',
	},
	{
		iso: 'BI',
		translations: { FR: 'Burundi', EN: 'Burundi' },
		phone: '257',
		iso3: 'BDI',
		iso4: '108',
	},
	{
		iso: 'KH',
		translations: { FR: 'Cambodge', EN: 'Cambodia' },
		phone: '855',
		iso3: 'KHM',
		iso4: '116',
	},
	{
		iso: 'CM',
		translations: { FR: 'Cameroun', EN: 'Cameroon' },
		phone: '237',
		iso3: 'CMR',
		iso4: '120',
	},
	{
		iso: 'CA',
		translations: { FR: 'Canada', EN: 'Canada' },
		phone: '1',
		iso3: 'CAN',
		iso4: '124',
	},
	{
		iso: 'CV',
		translations: { FR: 'Cap Vert', EN: 'Cape verde' },
		phone: '238',
		iso3: 'CPV',
		iso4: '132',
	},
	{
		iso: 'CL',
		translations: { FR: 'Chili', EN: 'Chile' },
		phone: '56',
		iso3: 'CHL',
		iso4: '152',
	},
	{
		iso: 'CN',
		translations: { FR: 'Chine', EN: 'China' },
		phone: '86',
		iso3: 'CHN',
		iso4: '156',
	},
	{
		iso: 'CY',
		translations: { FR: 'Chypre', EN: 'Cyprus' },
		phone: '357',
		iso3: 'CYP',
		iso4: '196',
	},
	{
		iso: 'CO',
		translations: { FR: 'Colombie', EN: 'Colombia' },
		phone: '57',
		iso3: 'COL',
		iso4: '170',
	},
	{
		iso: 'KM',
		translations: { FR: 'Comores', EN: 'Union of the comoros' },
		phone: '269',
		iso3: 'COM',
		iso4: '174',
	},
	{
		iso: 'CG',
		translations: { FR: 'Congo', EN: 'Congo' },
		phone: '242',
		iso3: 'COG',
		iso4: '178',
	},
	{
		iso: 'CD',
		translations: {
			FR: 'Congo (Rép. dém.)',
			EN: 'Congo, the democratic republic of the',
		},
		phone: '243',
		iso3: 'COD',
		iso4: '180',
	},
	{
		iso: 'KR',
		translations: { FR: 'Corée du Sud', EN: 'Korea (south)' },
		phone: '850',
		iso3: 'KOR',
		iso4: '410',
	},
	{
		iso: 'KP',
		translations: { FR: 'Corée du Nord', EN: 'Korea (north)' },
		phone: '82',
		iso3: 'PRK',
		iso4: '408',
	},
	{
		iso: 'CR',
		translations: { FR: 'Costa Rica', EN: 'Costa rica' },
		phone: '506',
		iso3: 'CRI',
		iso4: '188',
	},
	{
		iso: 'CI',
		translations: { FR: "Côte d'Ivoire", EN: 'Ivory coast' },
		phone: '225',
		iso3: 'CIV',
		iso4: '384',
	},
	{
		iso: 'HR',
		translations: { FR: 'Croatie', EN: 'Croatia' },
		phone: '385',
		iso3: 'HRV',
		iso4: '191',
	},
	{
		iso: 'CU',
		translations: { FR: 'Cuba', EN: 'Cuba' },
		phone: '53',
		iso3: 'CUB',
		iso4: '192',
	},
	{
		iso: 'CW',
		translations: { FR: 'Curaçao', EN: 'Curaçao' },
		phone: '599',
		iso3: 'CUW',
		iso4: '531',
	},
	{
		iso: 'DK',
		translations: { FR: 'Danemark', EN: 'Denmark' },
		phone: '45',
		iso3: 'DNK',
		iso4: '208',
	},
	{
		iso: 'DJ',
		translations: { FR: 'Djibouti', EN: 'Djibouti' },
		phone: '253',
		iso3: 'DJI',
		iso4: '262',
	},
	{
		iso: 'DM',
		translations: { FR: 'Dominique', EN: 'Dominica' },
		phone: '1767',
		iso3: 'DMA',
		iso4: '212',
	},
	{
		iso: 'EG',
		translations: { FR: 'Égypte', EN: 'Egypt' },
		phone: '20',
		iso3: 'EGY',
		iso4: '818',
	},
	{
		iso: 'AE',
		translations: { FR: 'Émirats arabes unis', EN: 'United arab emirates' },
		phone: '971',
		iso3: 'ARE',
		iso4: '784',
	},
	{
		iso: 'EC',
		translations: { FR: 'Équateur', EN: 'Ecuador' },
		phone: '593',
		iso3: 'ECU',
		iso4: '218',
	},
	{
		iso: 'ER',
		translations: { FR: 'Érythrée', EN: 'Eritrea' },
		phone: '291',
		iso3: 'ERI',
		iso4: '232',
	},
	{
		iso: 'ES',
		translations: { FR: 'Espagne', EN: 'Spain' },
		phone: '34',
		iso3: 'ESP',
		iso4: '724',
	},
	{
		iso: 'EE',
		translations: { FR: 'Estonie', EN: 'Estonia' },
		phone: '372',
		iso3: 'EST',
		iso4: '233',
	},
	{
		iso: 'US',
		translations: { FR: 'États-Unis', EN: 'United states' },
		phone: '1',
		iso3: 'USA',
		iso4: '840',
	},
	{
		iso: 'ET',
		translations: { FR: 'Éthiopie', EN: 'Ethiopia' },
		phone: '251',
		iso3: 'ETH',
		iso4: '231',
	},
	{
		iso: 'FJ',
		translations: { FR: 'Fidji', EN: 'Fiji' },
		phone: '679',
		iso3: 'FJI',
		iso4: '242',
	},
	{
		iso: 'FI',
		translations: { FR: 'Finlande', EN: 'Finland' },
		phone: '358',
		iso3: 'FIN',
		iso4: '246',
	},
	{
		iso: 'FR',
		translations: { FR: 'France', EN: 'France' },
		phone: '33',
		iso3: 'FRA',
		iso4: '250',
	},
	{
		iso: 'GA',
		translations: { FR: 'Gabon', EN: 'Gabon' },
		phone: '241',
		iso3: 'GAB',
		iso4: '266',
	},
	{
		iso: 'GM',
		translations: { FR: 'Gambie', EN: 'Gambia' },
		phone: '220',
		iso3: 'GMB',
		iso4: '270',
	},
	{
		iso: 'GE',
		translations: { FR: 'Géorgie', EN: 'Georgia' },
		phone: '995',
		iso3: 'GEO',
		iso4: '268',
	},
	{
		iso: 'GS',
		translations: {
			FR: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
			EN: 'South georgia and the south sandwich islands',
		},
		phone: '500',
		iso3: 'SGS',
		iso4: '239',
	},
	{
		iso: 'GH',
		translations: { FR: 'Ghana', EN: 'Ghana' },
		phone: '233',
		iso3: 'GHA',
		iso4: '288',
	},
	{
		iso: 'GI',
		translations: { FR: 'Gibraltar', EN: 'Gibraltar' },
		phone: '350',
		iso3: 'GIB',
		iso4: '292',
	},
	{
		iso: 'GR',
		translations: { FR: 'Grèce', EN: 'Greece' },
		phone: '30',
		iso3: 'GRC',
		iso4: '300',
	},
	{
		iso: 'GD',
		translations: { FR: 'Grenade', EN: 'Grenada' },
		phone: '1473',
		iso3: 'GRD',
		iso4: '308',
	},
	{
		iso: 'GL',
		translations: { FR: 'Groenland', EN: 'Greenland' },
		phone: '299',
		iso3: 'GRL',
		iso4: '304',
	},
	{
		iso: 'GP',
		translations: { FR: 'Guadeloupe', EN: 'Guadeloupe' },
		phone: '590',
		iso3: 'GLP',
		iso4: '250',
	},
	{
		iso: 'GU',
		translations: { FR: 'Guam', EN: 'Guam' },
		phone: '1671',
		iso3: 'GUM',
		iso4: '316',
	},
	{
		iso: 'GT',
		translations: { FR: 'Guatemala', EN: 'Guatemala' },
		phone: '502',
		iso3: 'GTM',
		iso4: '320',
	},
	{
		iso: 'GG',
		translations: { FR: 'Guernesey', EN: 'Guernsey' },
		phone: '44',
		iso3: 'GGY',
		iso4: '831',
	},
	{
		iso: 'GN',
		translations: { FR: 'Guinée', EN: 'Guinea' },
		phone: '224',
		iso3: 'GIN',
		iso4: '324',
	},
	{
		iso: 'GW',
		translations: { FR: 'Guinée-Bissau', EN: 'Guinea-bissau' },
		phone: '245',
		iso3: 'GNB',
		iso4: '624',
	},
	{
		iso: 'GQ',
		translations: { FR: 'Guinée-Équatoriale', EN: 'Equatorial guinea' },
		phone: '240',
		iso3: 'GNQ',
		iso4: '226',
	},
	{
		iso: 'GY',
		translations: { FR: 'Guyana', EN: 'Guyana' },
		phone: '592',
		iso3: 'GUY',
		iso4: '328',
	},
	{
		iso: 'GF',
		translations: { FR: 'Guyane française', EN: 'French guiana' },
		phone: '594',
		iso3: 'GUF',
		iso4: '250',
	},
	{
		iso: 'HT',
		translations: { FR: 'Haïti', EN: 'Haiti' },
		phone: '509',
		iso3: 'HTI',
		iso4: '332',
	},
	{
		iso: 'HN',
		translations: { FR: 'Honduras', EN: 'Honduras' },
		phone: '504',
		iso3: 'HND',
		iso4: '340',
	},
	{
		iso: 'HK',
		translations: { FR: 'Hong Kong', EN: 'Hong kong' },
		phone: '852',
		iso3: 'HKG',
		iso4: '344',
	},
	{
		iso: 'HU',
		translations: { FR: 'Hongrie', EN: 'Hungary' },
		phone: '36',
		iso3: 'HUN',
		iso4: '348',
	},
	{
		iso: 'BV',
		translations: { FR: 'Île Bouvet ', EN: 'Bouvet island' },
		phone: '',
		iso3: 'BVT',
		iso4: '74',
	},
	{
		iso: 'CX',
		translations: { FR: 'Île Christmas', EN: 'Christmas island' },
		phone: '61',
		iso3: 'CXR',
		iso4: '162',
	},
	{
		iso: 'IM',
		translations: { FR: 'Île de Man', EN: 'Isle of man' },
		phone: '44',
		iso3: 'IMN',
		iso4: '833',
	},
	{
		iso: 'NF',
		translations: { FR: 'Île de Norfolk', EN: 'Norfolk island' },
		phone: '672',
		iso3: 'NFK',
		iso4: '574',
	},
	{
		iso: 'MU',
		translations: { FR: 'Île Maurice', EN: 'Mauritius' },
		phone: '230',
		iso3: 'MUS',
		iso4: '480',
	},
	{
		iso: 'KY',
		translations: { FR: 'Îles Caïmans', EN: 'Cayman islands' },
		phone: '1345',
		iso3: 'CYM',
		iso4: '136',
	},
	{
		iso: 'CC',
		translations: { FR: 'Îles Cocos', EN: 'Cocos (keeling) islands' },
		phone: '61',
		iso3: 'CCK',
		iso4: '166',
	},
	{
		iso: 'CK',
		translations: { FR: 'Îles Cook', EN: 'Cook islands' },
		phone: '682',
		iso3: 'COK',
		iso4: '184',
	},
	{
		iso: 'FO',
		translations: { FR: 'Îles Féroé', EN: 'Faroe islands' },
		phone: '298',
		iso3: 'FRO',
		iso4: '234',
	},
	{
		iso: 'HM',
		translations: {
			FR: 'Îles Heard-et-MacDonald',
			EN: 'Heard island and mcdonald islands',
		},
		phone: '672',
		iso3: 'HMD',
		iso4: '334',
	},
	{
		iso: 'FK',
		translations: { FR: 'Îles Malouines', EN: 'Falkland islands (malvinas)' },
		phone: '500',
		iso3: 'FLK',
		iso4: '238',
	},
	{
		iso: 'MP',
		translations: {
			FR: 'Îles Mariannes du Nord',
			EN: 'Northern mariana islands',
		},
		phone: '1670',
		iso3: 'MNP',
		iso4: '580',
	},
	{
		iso: 'MH',
		translations: { FR: 'Îles Marshall', EN: 'Marshall islands' },
		phone: '692',
		iso3: 'MHL',
		iso4: '584',
	},
	{
		iso: 'UM',
		translations: {
			FR: 'Îles mineures éloignées des États-Unis ',
			EN: 'United states minor outlying islands',
		},
		phone: '246',
		iso3: 'UMI',
		iso4: '581',
	},
	{
		iso: 'PN',
		translations: { FR: 'Îles Pitcairn', EN: 'Pitcairn' },
		phone: '64',
		iso3: 'PCN',
		iso4: '612',
	},
	{
		iso: 'SB',
		translations: { FR: 'Îles Salomon', EN: 'Solomon islands' },
		phone: '677',
		iso3: 'SLB',
		iso4: '90',
	},
	{
		iso: 'TC',
		translations: {
			FR: 'Îles Turques-et-Caïques',
			EN: 'Turks and caicos islands',
		},
		phone: '1649',
		iso3: 'TCA',
		iso4: '796',
	},
	{
		iso: 'VG',
		translations: {
			FR: 'Îles Vierges britanniques',
			EN: 'Virgin islands, british',
		},
		phone: '1284',
		iso3: 'VGB',
		iso4: '92',
	},
	{
		iso: 'VI',
		translations: {
			FR: 'Îles Vierges des États-Unis',
			EN: 'Virgin islands, u.s.',
		},
		phone: '340',
		iso3: 'VIR',
		iso4: '850',
	},
	{
		iso: 'IN',
		translations: { FR: 'Inde', EN: 'India' },
		phone: '91',
		iso3: 'IND',
		iso4: '356',
	},
	{
		iso: 'ID',
		translations: { FR: 'Indonésie', EN: 'Indonesia' },
		phone: '62',
		iso3: 'IDN',
		iso4: '360',
	},
	{
		iso: 'IQ',
		translations: { FR: 'Irak', EN: 'Iraq' },
		phone: '964',
		iso3: 'IRQ',
		iso4: '368',
	},
	{
		iso: 'IR',
		translations: { FR: 'Iran', EN: 'Iran, islamic republic of' },
		phone: '98',
		iso3: 'IRN',
		iso4: '364',
	},
	{
		iso: 'IE',
		translations: { FR: 'Irlande', EN: 'Ireland' },
		phone: '353',
		iso3: 'IRL',
		iso4: '372',
	},
	{
		iso: 'IS',
		translations: { FR: 'Islande', EN: 'Iceland' },
		phone: '354',
		iso3: 'ISL',
		iso4: '352',
	},
	{
		iso: 'IL',
		translations: { FR: 'Israël', EN: 'Israel' },
		phone: '972',
		iso3: 'ISR',
		iso4: '376',
	},
	{
		iso: 'IT',
		translations: { FR: 'Italie', EN: 'Italy' },
		phone: '39',
		iso3: 'ITA',
		iso4: '380',
	},
	{
		iso: 'JM',
		translations: { FR: 'Jamaïque', EN: 'Jamaica' },
		phone: '1876',
		iso3: 'JAM',
		iso4: '388',
	},
	{
		iso: 'JP',
		translations: { FR: 'Japon', EN: 'Japan' },
		phone: '81',
		iso3: 'JPN',
		iso4: '392',
	},
	{
		iso: 'JE',
		translations: { FR: 'Jersey', EN: 'Jersey' },
		phone: '44',
		iso3: 'JEY',
		iso4: '832',
	},
	{
		iso: 'JO',
		translations: { FR: 'Jordanie', EN: 'Jordan' },
		phone: '962',
		iso3: 'JOR',
		iso4: '400',
	},
	{
		iso: 'KZ',
		translations: { FR: 'Kazakhstan', EN: 'Kazakhstan' },
		phone: '76',
		iso3: 'KAZ',
		iso4: '398',
	},
	{
		iso: 'KE',
		translations: { FR: 'Kenya', EN: 'Kenya' },
		phone: '254',
		iso3: 'KEN',
		iso4: '404',
	},
	{
		iso: 'KG',
		translations: { FR: 'Kirghizistan', EN: 'Kyrgyzstan' },
		phone: '996',
		iso3: 'KGZ',
		iso4: '417',
	},
	{
		iso: 'KI',
		translations: { FR: 'Kiribati', EN: 'Kiribati' },
		phone: '686',
		iso3: 'KIR',
		iso4: '296',
	},
	{
		iso: 'XK',
		translations: { FR: 'Kosovo', EN: 'Kosovo' },
		phone: '383',
		iso3: 'KOS',
		iso4: '901',
	},
	{
		iso: 'KW',
		translations: { FR: 'Koweït', EN: 'Kuwait' },
		phone: '965',
		iso3: 'KWT',
		iso4: '414',
	},
	{
		iso: 'LA',
		translations: { FR: 'Laos', EN: 'Laos' },
		phone: '856',
		iso3: 'LAO',
		iso4: '418',
	},
	{
		iso: 'VA',
		translations: {
			FR: 'Le Saint-Siège (Etat de la cité du Vatican)',
			EN: 'Holy see (vatican city state)',
		},
		phone: '379',
		iso3: 'VAT',
		iso4: '336',
	},
	{
		iso: 'LS',
		translations: { FR: 'Lesotho', EN: 'Lesotho' },
		phone: '266',
		iso3: 'LSO',
		iso4: '426',
	},
	{
		iso: 'LV',
		translations: { FR: 'Lettonie', EN: 'Latvia' },
		phone: '371',
		iso3: 'LVA',
		iso4: '428',
	},
	{
		iso: 'LB',
		translations: { FR: 'Liban', EN: 'Lebanon' },
		phone: '961',
		iso3: 'LBN',
		iso4: '422',
	},
	{
		iso: 'LR',
		translations: { FR: 'Liberia', EN: 'Liberia' },
		phone: '231',
		iso3: 'LBR',
		iso4: '430',
	},
	{
		iso: 'LY',
		translations: { FR: 'Libye', EN: 'Libyan arab jamahiriya' },
		phone: '218',
		iso3: 'LBY',
		iso4: '434',
	},
	{
		iso: 'LI',
		translations: { FR: 'Liechtenstein', EN: 'Liechtenstein' },
		phone: '423',
		iso3: 'LIE',
		iso4: '438',
	},
	{
		iso: 'LT',
		translations: { FR: 'Lituanie', EN: 'Lithuania' },
		phone: '370',
		iso3: 'LTU',
		iso4: '440',
	},
	{
		iso: 'LU',
		translations: { FR: 'Luxembourg', EN: 'Luxembourg' },
		phone: '352',
		iso3: 'LUX',
		iso4: '442',
	},
	{
		iso: 'MO',
		translations: { FR: 'Macao', EN: 'Macao' },
		phone: '853',
		iso3: 'MAC',
		iso4: '446',
	},
	{
		iso: 'MK',
		translations: {
			FR: 'Macédoine',
			EN: 'Macedonia, the former yugoslav republic of',
		},
		phone: '389',
		iso3: 'MKD',
		iso4: '807',
	},
	{
		iso: 'MG',
		translations: { FR: 'Madagascar', EN: 'Madagascar' },
		phone: '261',
		iso3: 'MDG',
		iso4: '450',
	},
	{
		iso: 'MY',
		translations: { FR: 'Malaisie', EN: 'Malaysia' },
		phone: '60',
		iso3: 'MYS',
		iso4: '458',
	},
	{
		iso: 'MW',
		translations: { FR: 'Malawi', EN: 'Malawi' },
		phone: '265',
		iso3: 'MWI',
		iso4: '454',
	},
	{
		iso: 'MV',
		translations: { FR: 'Maldives', EN: 'Maldives' },
		phone: '960',
		iso3: 'MDV',
		iso4: '462',
	},
	{
		iso: 'ML',
		translations: { FR: 'Mali', EN: 'Mali' },
		phone: '223',
		iso3: 'MLI',
		iso4: '466',
	},
	{
		iso: 'MT',
		translations: { FR: 'Malte', EN: 'Malta' },
		phone: '356',
		iso3: 'MLT',
		iso4: '470',
	},
	{
		iso: 'MA',
		translations: { FR: 'Maroc', EN: 'Morocco' },
		phone: '212',
		iso3: 'MAR',
		iso4: '504',
	},
	{
		iso: 'MQ',
		translations: { FR: 'Martinique', EN: 'Martinique' },
		phone: '596',
		iso3: 'MTQ',
		iso4: '250',
	},
	{
		iso: 'MR',
		translations: { FR: 'Mauritanie', EN: 'Mauritania' },
		phone: '222',
		iso3: 'MRT',
		iso4: '478',
	},
	{
		iso: 'YT',
		translations: { FR: 'Mayotte', EN: 'Mayotte' },
		phone: '262',
		iso3: 'MYT',
		iso4: '250',
	},
	{
		iso: 'MX',
		translations: { FR: 'Mexique', EN: 'Mexico' },
		phone: '52',
		iso3: 'MEX',
		iso4: '484',
	},
	{
		iso: 'FM',
		translations: { FR: 'Micronésie', EN: 'Micronesia, federated states of' },
		phone: '691',
		iso3: 'FSM',
		iso4: '583',
	},
	{
		iso: 'MD',
		translations: { FR: 'Moldavie', EN: 'Moldova, republic of' },
		phone: '373',
		iso3: 'MDA',
		iso4: '498',
	},
	{
		iso: 'MC',
		translations: { FR: 'Monaco', EN: 'Monaco' },
		phone: '377',
		iso3: 'MCO',
		iso4: '250',
	},
	{
		iso: 'MN',
		translations: { FR: 'Mongolie', EN: 'Mongolia' },
		phone: '976',
		iso3: 'MNG',
		iso4: '496',
	},
	{
		iso: 'ME',
		translations: { FR: 'Monténégro', EN: 'Montenegro' },
		phone: '382',
		iso3: 'MNE',
		iso4: '499',
	},
	{
		iso: 'MS',
		translations: { FR: 'Montserrat', EN: 'Montserrat' },
		phone: '1664',
		iso3: 'MSR',
		iso4: '500',
	},
	{
		iso: 'MZ',
		translations: { FR: 'Mozambique', EN: 'Mozambique' },
		phone: '258',
		iso3: 'MOZ',
		iso4: '508',
	},
	{
		iso: 'MM',
		translations: { FR: 'Myanmar', EN: 'Myanmar' },
		phone: '95',
		iso3: 'MMR',
		iso4: '104',
	},
	{
		iso: 'NA',
		translations: { FR: 'Namibie', EN: 'Namibia' },
		phone: '264',
		iso3: 'NAM',
		iso4: '516',
	},
	{
		iso: 'NR',
		translations: { FR: 'Nauru', EN: 'Nauru' },
		phone: '674',
		iso3: 'NRU',
		iso4: '520',
	},
	{
		iso: 'NP',
		translations: { FR: 'Népal', EN: 'Nepal' },
		phone: '977',
		iso3: 'NPL',
		iso4: '524',
	},
	{
		iso: 'NI',
		translations: { FR: 'Nicaragua', EN: 'Nicaragua' },
		phone: '505',
		iso3: 'NIC',
		iso4: '558',
	},
	{
		iso: 'NE',
		translations: { FR: 'Niger', EN: 'Niger' },
		phone: '227',
		iso3: 'NER',
		iso4: '562',
	},
	{
		iso: 'NG',
		translations: { FR: 'Nigéria', EN: 'Nigeria' },
		phone: '234',
		iso3: 'NGA',
		iso4: '566',
	},
	{
		iso: 'NU',
		translations: { FR: 'Niue', EN: 'Niue' },
		phone: '683',
		iso3: 'NIU',
		iso4: '570',
	},
	{
		iso: 'NO',
		translations: { FR: 'Norvège', EN: 'Norway' },
		phone: '47',
		iso3: 'NOR',
		iso4: '578',
	},
	{
		iso: 'NC',
		translations: { FR: 'Nouvelle-Calédonie', EN: 'New caledonia' },
		phone: '687',
		iso3: 'NCL',
		iso4: '250',
	},
	{
		iso: 'NZ',
		translations: { FR: 'Nouvelle-Zélande', EN: 'New zealand' },
		phone: '64',
		iso3: 'NZL',
		iso4: '554',
	},
	{
		iso: 'OM',
		translations: { FR: 'Oman', EN: 'Oman' },
		phone: '968',
		iso3: 'OMN',
		iso4: '512',
	},
	{
		iso: 'UZ',
		translations: { FR: 'Ouzbékistan', EN: 'Uzbekistan' },
		phone: '998',
		iso3: 'UZB',
		iso4: '860',
	},
	{
		iso: 'PK',
		translations: { FR: 'Pakistan', EN: 'Pakistan' },
		phone: '92',
		iso3: 'PAK',
		iso4: '586',
	},
	{
		iso: 'PW',
		translations: { FR: 'Palaos', EN: 'Palau' },
		phone: '680',
		iso3: 'PLW',
		iso4: '585',
	},
	{
		iso: 'PS',
		translations: { FR: 'Palestine', EN: 'Palestine' },
		phone: '970',
		iso3: 'PSE',
		iso4: '275',
	},
	{
		iso: 'PA',
		translations: { FR: 'Panama', EN: 'Panama' },
		phone: '507',
		iso3: 'PAN',
		iso4: '591',
	},
	{
		iso: 'PG',
		translations: { FR: 'Papouasie-Nouvelle-Guinée', EN: 'Papua new guinea' },
		phone: '675',
		iso3: 'PNG',
		iso4: '598',
	},
	{
		iso: 'PY',
		translations: { FR: 'Paraguay', EN: 'Paraguay' },
		phone: '595',
		iso3: 'PRY',
		iso4: '600',
	},
	{
		iso: 'NL',
		translations: { FR: 'Pays-Bas', EN: 'Netherlands' },
		phone: '31',
		iso3: 'NLD',
		iso4: '528',
	},
	{
		iso: 'PE',
		translations: { FR: 'Pérou', EN: 'Peru' },
		phone: '51',
		iso3: 'PER',
		iso4: '604',
	},
	{
		iso: 'PH',
		translations: { FR: 'Philippines', EN: 'Philippines' },
		phone: '63',
		iso3: 'PHL',
		iso4: '608',
	},
	{
		iso: 'PL',
		translations: { FR: 'Pologne', EN: 'Poland' },
		phone: '48',
		iso3: 'POL',
		iso4: '616',
	},
	{
		iso: 'PF',
		translations: { FR: 'Polynésie française', EN: 'French polynesia' },
		phone: '689',
		iso3: 'PYF',
		iso4: '250',
	},
	{
		iso: 'PR',
		translations: { FR: 'Porto Rico', EN: 'Puerto rico' },
		phone: '1787',
		iso3: 'PRI',
		iso4: '630',
	},
	{
		iso: 'PT',
		translations: { FR: 'Portugal', EN: 'Portugal' },
		phone: '351',
		iso3: 'PRT',
		iso4: '620',
	},
	{
		iso: 'QA',
		translations: { FR: 'Qatar', EN: 'Qatar' },
		phone: '974',
		iso3: 'QAT',
		iso4: '634',
	},
	{
		iso: 'CF',
		translations: {
			FR: 'République centrafricaine',
			EN: 'Central african republic',
		},
		phone: '236',
		iso3: 'CAF',
		iso4: '140',
	},
	{
		iso: 'DO',
		translations: { FR: 'République dominicaine', EN: 'Dominican republic' },
		phone: '1809',
		iso3: 'DOM',
		iso4: '214',
	},
	{
		iso: 'CZ',
		translations: { FR: 'République tchèque', EN: 'Czech republic' },
		phone: '420',
		iso3: 'CZE',
		iso4: '203',
	},
	{
		iso: 'RE',
		translations: { FR: 'Réunion', EN: 'Reunion' },
		phone: '262',
		iso3: 'REU',
		iso4: '250',
	},
	{
		iso: 'RO',
		translations: { FR: 'Roumanie', EN: 'Romania' },
		phone: '40',
		iso3: 'ROU',
	},
	{
		iso: 'GB',
		translations: {
			FR: 'Royaume-Uni',
			EN: 'United kingdom of great britain and northern ireland',
		},
		phone: '44',
		iso3: 'GBR',
		iso4: '826',
	},
	{
		iso: 'RU',
		translations: { FR: 'Russie', EN: 'Russian federation' },
		phone: '7',
		iso3: 'RUS',
		iso4: '643',
	},
	{
		iso: 'RW',
		translations: { FR: 'Rwanda', EN: 'Rwanda' },
		phone: '250',
		iso3: 'RWA',
		iso4: '646',
	},
	{
		iso: 'EH',
		translations: { FR: 'Sahara Occidental', EN: 'Western sahara' },
		phone: '212',
		iso3: 'ESH',
		iso4: '732',
	},
	{
		iso: 'SX',
		translations: {
			FR: 'Saint Martin (partie néerlandaise)',
			EN: 'Sint Maarten',
		},
		phone: '1721',
		iso3: 'SXM',
		iso4: '534',
	},
	{
		iso: 'BL',
		translations: { FR: 'Saint-Barthélemy', EN: 'Saint Barthélemy' },
		phone: '590',
		iso3: 'BLM',
		iso4: '250',
	},
	{
		iso: 'KN',
		translations: {
			FR: 'Saint-Christophe-et-Niévès',
			EN: 'Saint kitts and nevis',
		},
		phone: '1869',
		iso3: 'KNA',
		iso4: '659',
	},
	{
		iso: 'SH',
		translations: { FR: 'Sainte-Hélène', EN: 'Saint helena' },
		phone: '290',
		iso3: 'SHN',
		iso4: '654',
	},
	{
		iso: 'LC',
		translations: { FR: 'Saint-Lucie', EN: 'Saint lucia' },
		phone: '1758',
		iso3: 'LCA',
		iso4: '662',
	},
	{
		iso: 'SM',
		translations: { FR: 'Saint-Marin', EN: 'San marino' },
		phone: '378',
		iso3: 'SMR',
		iso4: '674',
	},
	{
		iso: 'MF',
		translations: { FR: 'Saint-Martin', EN: 'Saint Martin' },
		phone: '590',
		iso3: 'MAF',
		iso4: '250',
	},
	// {
	//   iso: 'MF',
	//   translations: {
	//     FR: 'Saint-Pierre-et-Miquelon',
	//     EN: 'Saint Pierre and Miquelon',
	//   },
	//   phone: '508',
	//   iso3: 'MAF',
	//   iso4: '666',
	// },
	{
		iso: 'VC',
		translations: {
			FR: 'Saint-Vincent-et-les-Grenadines',
			EN: 'Saint vincent and the grenadines',
		},
		phone: '1784',
		iso3: 'VCT',
		iso4: '670',
	},
	{
		iso: 'SV',
		translations: { FR: 'Salvador', EN: 'El salvador' },
		phone: '503',
		iso3: 'SLV',
		iso4: '222',
	},
	{
		iso: 'WS',
		translations: { FR: 'Samoa', EN: 'Samoa (independant states) ' },
		phone: '685',
		iso3: 'WSM',
		iso4: '882',
	},
	{
		iso: 'AS',
		translations: { FR: 'Samoa américaines', EN: 'Samoa island united states' },
		phone: '1684',
		iso3: 'ASM',
		iso4: '16',
	},
	{
		iso: 'ST',
		translations: { FR: 'Sao Tomé-et-Principe', EN: 'Sao tome and principe' },
		phone: '239',
		iso3: 'STP',
		iso4: '678',
	},
	{
		iso: 'SN',
		translations: { FR: 'Sénégal', EN: 'Senegal' },
		phone: '221',
		iso3: 'SEN',
		iso4: '686',
	},
	{
		iso: 'RS',
		translations: { FR: 'Serbie', EN: 'Serbia' },
		phone: '381',
		iso3: 'SRB',
		iso4: '688',
	},
	{
		iso: 'SC',
		translations: { FR: 'Seychelles', EN: 'Seychelles' },
		phone: '248',
		iso3: 'SYC',
		iso4: '690',
	},
	{
		iso: 'SL',
		translations: { FR: 'Sierra Leone', EN: 'Sierra leone' },
		phone: '232',
		iso3: 'SLE',
		iso4: '694',
	},
	{
		iso: 'SG',
		translations: { FR: 'Singapour', EN: 'Singapore' },
		phone: '65',
		iso3: 'SGP',
		iso4: '702',
	},
	{
		iso: 'SK',
		translations: { FR: 'Slovaquie', EN: 'Slovakia' },
		phone: '421',
		iso3: 'SVK',
		iso4: '703',
	},
	{
		iso: 'SI',
		translations: { FR: 'Slovénie', EN: 'Slovenia' },
		phone: '386',
		iso3: 'SVN',
		iso4: '705',
	},
	{
		iso: 'SO',
		translations: { FR: 'Somalie', EN: 'Somalia' },
		phone: '252',
		iso3: 'SOM',
		iso4: '706',
	},
	{
		iso: 'SD',
		translations: { FR: 'Soudan', EN: 'Sudan' },
		phone: '249',
		iso3: 'SDN',
		iso4: '736',
	},
	{
		iso: 'SS',
		translations: { FR: 'Soudan du Sud', EN: 'South sudan' },
		phone: '211',
		iso3: 'SSD',
	},
	{
		iso: 'LK',
		translations: { FR: 'Sri Lanka', EN: 'Sri lanka' },
		phone: '94',
		iso3: 'LKA',
		iso4: '144',
	},
	{
		iso: 'SE',
		translations: { FR: 'Suède', EN: 'Sweden' },
		phone: '46',
		iso3: 'SWE',
		iso4: '752',
	},
	{
		iso: 'CH',
		translations: { FR: 'Suisse', EN: 'Switzerland' },
		phone: '41',
		iso3: 'CHE',
		iso4: '756',
	},
	{
		iso: 'SR',
		translations: { FR: 'Surinam', EN: 'Suriname' },
		phone: '597',
		iso3: 'SUR',
		iso4: '740',
	},
	{
		iso: 'SJ',
		translations: {
			FR: 'Svalbard et Jan Mayen',
			EN: 'Svalbard and jan mayen island',
		},
		phone: '4779',
		iso3: 'SJM',
		iso4: '744',
	},
	{
		iso: 'SZ',
		translations: { FR: 'Swaziland', EN: 'Swaziland' },
		phone: '268',
		iso3: 'SWZ',
		iso4: '748',
	},
	{
		iso: 'SY',
		translations: { FR: 'Syrie', EN: 'Syrian arab republic' },
		phone: '963',
		iso3: 'SYR',
		iso4: '760',
	},
	{
		iso: 'TJ',
		translations: { FR: 'Tadjikistan', EN: 'Tajikistan' },
		phone: '992',
		iso3: 'TJK',
		iso4: '762',
	},
	{
		iso: 'TW',
		translations: { FR: 'Taïwan', EN: 'Taiwan' },
		phone: '886',
		iso3: 'TWN',
		iso4: '158',
	},
	{
		iso: 'TZ',
		translations: { FR: 'Tanzanie', EN: 'Tanzania, united republic of' },
		phone: '255',
		iso3: 'TZA',
		iso4: '834',
	},
	{
		iso: 'TD',
		translations: { FR: 'Tchad', EN: 'Chad' },
		phone: '235',
		iso3: 'TCD',
		iso4: '148',
	},
	{
		iso: 'TF',
		translations: {
			FR: 'Terres australes et antarctiques françaises ',
			EN: 'French southen and antactic lands',
		},
		phone: '262',
		iso3: 'ATF',
		iso4: '250',
	},
	{
		iso: 'IO',
		translations: {
			FR: "Territoire britannique de l'océan Indien",
			EN: 'British indian ocean territory',
		},
		phone: '246',
		iso3: 'IOT',
		iso4: '86',
	},
	{
		iso: 'TH',
		translations: { FR: 'Thaïlande', EN: 'Thailand' },
		phone: '66',
		iso3: 'THA',
		iso4: '764',
	},
	{
		iso: 'TL',
		translations: { FR: 'Timor oriental', EN: 'Timor leste' },
		phone: '670',
		iso3: 'TLS',
		iso4: '626',
	},
	{
		iso: 'TG',
		translations: { FR: 'Togo', EN: 'Togo' },
		phone: '228',
		iso3: 'TGO',
		iso4: '768',
	},
	{
		iso: 'TK',
		translations: { FR: 'Tokelau', EN: 'Tokelau' },
		phone: '690',
		iso3: 'TKL',
		iso4: '772',
	},
	{
		iso: 'TO',
		translations: { FR: 'Tonga', EN: 'Tonga' },
		phone: '676',
		iso3: 'TON',
		iso4: '776',
	},
	{
		iso: 'TT',
		translations: { FR: 'Trinité et Tobago', EN: 'Trinidad and tobago' },
		phone: '1868',
		iso3: 'TTO',
		iso4: '780',
	},
	{
		iso: 'TN',
		translations: { FR: 'Tunisie', EN: 'Tunisia' },
		phone: '216',
		iso3: 'TUN',
		iso4: '788',
	},
	{
		iso: 'TM',
		translations: { FR: 'Turkménistan', EN: 'Turkmenistan' },
		phone: '993',
		iso3: 'TKM',
		iso4: '795',
	},
	{
		iso: 'TR',
		translations: { FR: 'Turquie', EN: 'Turkey' },
		phone: '90',
		iso3: 'TUR',
		iso4: '792',
	},
	{
		iso: 'TV',
		translations: { FR: 'Tuvalu', EN: 'Tuvalu' },
		phone: '688',
		iso3: 'TUV',
		iso4: '798',
	},
	{
		iso: 'UG',
		translations: { FR: 'Uganda', EN: 'Uganda' },
		phone: '256',
		iso3: 'UGA',
		iso4: '800',
	},
	{
		iso: 'UA',
		translations: { FR: 'Ukraine', EN: 'Ukraine' },
		phone: '380',
		iso3: 'UKR',
		iso4: '804',
	},
	{
		iso: 'UY',
		translations: { FR: 'Uruguay', EN: 'Uruguay' },
		phone: '598',
		iso3: 'URY',
		iso4: '858',
	},
	{
		iso: 'VU',
		translations: { FR: 'Vanuatu', EN: 'Vanuatu' },
		phone: '678',
		iso3: 'VUT',
		iso4: '548',
	},
	{
		iso: 'VE',
		translations: { FR: 'Venezuela', EN: 'Venezuela' },
		phone: '58',
		iso3: 'VEN',
		iso4: '862',
	},
	{
		iso: 'VN',
		translations: { FR: 'Viêt Nam', EN: 'Viet nam' },
		phone: '84',
		iso3: 'VNM',
		iso4: '704',
	},
	{
		iso: 'WF',
		translations: { FR: 'Wallis-et-Futuna', EN: 'Wallis and Futuna' },
		phone: '681',
		iso3: 'WLF',
		iso4: '250',
	},
	{
		iso: 'YE',
		translations: { FR: 'Yémen', EN: 'Yemen' },
		phone: '967',
		iso3: 'YEM',
		iso4: '887',
	},
	{
		iso: 'ZM',
		translations: { FR: 'Zambie', EN: 'Zambia' },
		phone: '260',
		iso3: 'ZMB',
		iso4: '894',
	},
	{
		iso: 'ZW',
		translations: { FR: 'Zimbabwe', EN: 'Zimbabwe' },
		phone: '263',
		iso3: 'ZWE',
		iso4: '716',
	},
];

const NO_POSTAL_CODE_COUNTRIES = [
	'AS',
	'AO',
	'AI',
	'AG',
	'AW',
	'BS',
	'BZ',
	'BJ',
	'BO',
	'BW',
	'IO',
	'BF',
	'CM',
	'CF',
	'TD',
	'CX',
	'CO',
	'KM',
	'CD',
	'CG',
	'CK',
	'CI',
	'CW',
	'KP',
	'DJ',
	'DM',
	'GQ',
	'ER',
	'FK',
	'FJ',
	'GM',
	'GH',
	'GI',
	'GD',
	'GY',
	'HM',
	'HK',
	'IE',
	'JM',
	'LR',
	'LY',
	'MO',
	'ML',
	'MR',
	'NR',
	'NU',
	'NF',
	'PW',
	'PA',
	'PN',
	'QA',
	'RW',
	'SH',
	'KN',
	'LC',
	'ST',
	'SC',
	'SL',
	'SX',
	'SB',
	'SO',
	'GS',
	'SS',
	'SR',
	'SY',
	'TZ',
	'TL',
	'TL',
	'TK',
	'TO',
	'TC',
	'TV',
	'UG',
	'AE',
	'UM',
	'VU',
	'VA',
	'YE',
	'ZM',
	'ZW',
];

const FRANCE_DOM_TOM_ISO4 = ['250', '312', '474', '254', '638', '492', '666', '175', '652', '663', '260', '876', '258', '540'];
const FRANCE_DOM_TOM_ISO = COUNTRIES
	.filter((c) => c.iso4 && FRANCE_DOM_TOM_ISO4.includes(c.iso4))
	.map(({ iso }) => iso);

export const getIso4FromIso = (iso: string): string => {
	const country = COUNTRIES.find(c => c.iso === iso);
	return country && country.iso4 ? country.iso4 : '';
};

export const isIsoFrenchAddress = (iso: string): boolean => FRANCE_DOM_TOM_ISO.includes(iso);
export const isIso4FrenchAddress = (iso4: string): boolean => FRANCE_DOM_TOM_ISO4.includes(iso4);
export const isZipCodeRequired = (iso: string): boolean => !NO_POSTAL_CODE_COUNTRIES.includes(iso);

export const getCountryLabelFromIso4 = (iso4: string): string => {
	if (FRANCE_DOM_TOM_ISO4.includes(iso4)) {
		iso4 = '250';
	}
	const country = COUNTRIES.find(c => c.iso4 === iso4);
	if (!country && FRANCE_DOM_TOM_ISO4.includes(iso4)) {
		return 'FRANCE';
	} else if (!country) {
		return 'N/A';
	}
	return i18n.language === 'en' ? country.translations.EN : country.translations.FR;
};

export const getPhoneCodes = () => {
	return COUNTRIES
		.filter((el, i) => el.phone && i === COUNTRIES.findIndex(c => c.phone === el.phone))
		.sort((a, b) => Number(a.phone) - Number(b.phone));
};