import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
import { connect, useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { setBreadcrumb } from '../../slices/appContextSlice';
import {
	AppContextState,
	AppPropsType,
	programWorksPayload,
	programWorksPram,
	TypicalProgram,
} from '../../types';
import { fetchTypicalProgramsDetails, fetchTypicalProgramsWorks } from '../../api/typicalProgramsAPI';
import { useParams } from 'react-router-dom';
import CardDetails from '../../components/cardDetails';
import Works from '../../components/workList';
import NavigateBackButton from '../../components/navigateBack';
import './index.scss';
import { ROLES, ROUTES } from '../../constants';
import useRoleRedirect from '../../hooks/useRoleRedirect';

const ProgramsDetail = (props: ProgramsWorksProp) => {
	useRoleRedirect(ROLES.WEBSO_WEB_ONLINE);
	const { appContext } = props;
	const [loading, setLoading] = useState(true);
	const [typicalProgram, setTypicalProgram] = useState<TypicalProgram | undefined>(undefined);
	const { programId } = useParams();
	const [error, setError] = useState<Error | undefined>(undefined);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [works, setWorks] = useState<programWorksPayload | undefined>(undefined);

	const programWorksParam: programWorksPram = {
		programId: parseInt(programId!),
		societyId: appContext.selectedSisterSociety?.sisterSocietyId,
		page: 1,
	};

	useEffect(() => {
		dispatch(setBreadcrumb({
			title: t('breadcrumb.list-setLists'),
			buttonAction: ROUTES.CREATE_SETLIST,
			buttonLabel: 'set-lists.actions.add-setList',
			showHome: true
		})
		);

		fetchTypicalProgramsDetails(programWorksParam)
			.then((response) => {
				setTypicalProgram(response);
				fetchTypicalProgramsWorks({
					...programWorksParam,
					size: response.typicalProgramNumberOfWorks
				})
					.then((response) => {
						setWorks(response);
					})
					.finally(() => setLoading(false))
					.catch((error: Error) => {
						setLoading(false);
						setError(error);
					});
			})
			.catch((error: Error) => {
				setLoading(false);
				setError(error);
			});
	}, []);


	const ProgramDetailsHeader = (typicalProgramTitle: string, typicalProgramId: number) => {
		return (
			<div className="header-typical d-flex justify-content-between align-items-center">
				<div className="header-typical-title">
					{typicalProgramTitle}
				</div>
				<div className="font-title">{typicalProgramId}</div>
			</div>
		);
	};

	return (
		<div className="main-container-artworks">
			<Container>
				<div className="text-center">
					<BeatLoader
						speedMultiplier={1}
						margin={5}
						size={20}
						color="#125FE2"
						loading={loading}
					/>
				</div>
				{!loading && typicalProgram && (
					<>
						<Row>
							<Col className="mb-3">
								<NavigateBackButton
									url={ROUTES.GET_SETLISTS}
								/>
							</Col>
						</Row>
						<div className="d-flex justify-content-center">
							<CardDetails
								worksCount={works && works!.typicalProgramWorksCount}
								header={ProgramDetailsHeader(typicalProgram?.typicalProgramTitle, typicalProgram?.typicalProgramId)}
							>
								{works && works!.typicalProgramWorks.map((artwork, index) => (
									<Works
										key={index.toString()}
										work={artwork}
										id={index}
										count={works?.typicalProgramWorks.length}
									/>
								))}

								{!loading && !works && error?.message && (
									<div className="empty-data" id="empty-worklist">
										{t('set-lists.errors.empty-works')}
									</div>
								)}
							</CardDetails>
						</div>
					</>
				)}

				{!loading && !works &&
        error?.message && (
					<div className="empty-data">
						{t('set-lists.errors.empty-setlist')}
					</div>
				)}

			</Container>
		</div>
	);
};

const mapStateToProps = (state: AppPropsType) => ({
	appContext: state.appContext
});

interface ProgramsWorksProp {
	appContext: AppContextState;
}

export default connect(mapStateToProps)(ProgramsDetail);
