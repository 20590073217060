import React, { useEffect, useState } from 'react'
import { Button, Col, OverlayTrigger, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { PlatformColors } from './platformColors'
import { UsageDataStreamPerWorkResponse } from '../../types'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { useTranslation } from 'react-i18next'

interface LegendPropType {
  status: number
  cradHeaderTitle: string
  workData?: UsageDataStreamPerWorkResponse
  detailRedirection: Function
  updateWorkStates: Function
}
interface DisplayedItem {
  label: string
  code: number
  hidden: boolean
  artists: string[]
  displayAllArtists: boolean
}

const DEFAULT_MAX_ARTISTS = 2
const StreamPerWorksLegend = ({
  status,
  cradHeaderTitle,
  detailRedirection,
  workData,
  updateWorkStates,
}: LegendPropType) => {
  const {t} = useTranslation()
  const [isMobile, setIsMobile] = useState<boolean>()
  const [activeAllWorks, setActiveAllWorks] = useState(false)
  const [showAllArtists, setShowAllArtists] = useState<number | null>(null)
  const [worksStates, setWorksStates] = useState<DisplayedItem[]>([])
  const { screenWidth } = useWindowDimensions()

  useEffect(() => {
    setIsMobile(screenWidth < 770 ? true : false)
  }, [screenWidth])

  useEffect(() => {
    setWorksStates(
      (workData?.items || []).map((item) => {
        return {
          code: item.ide12,
          label: item.title,
          hidden: false,
          artists: item.artists,
          displayAllArtists: false,
        }
      })
    )
  }, [workData])

  useEffect(() => {
    // worksStates.length > 0 is used to avoid having allProviders set to true
    // when the worksStates array is empty
    const status = worksStates.reduce(
      (acc, value) => acc && !value.hidden,
      worksStates.length > 0
    )
    setActiveAllWorks(status)
    updateWorkStates(worksStates)
  }, [worksStates])

  const handleDisplayAllArtists = (displayedItem: DisplayedItem) => {
    setWorksStates(
      (workData?.items || []).map((item) => {
        return {
          code: item.ide12,
          label: item.title,
          hidden: false,
          artists: item.artists,
          displayAllArtists:
            item.ide12 === displayedItem.code
              ? displayedItem.displayAllArtists == null
                ? true
                : !displayedItem.displayAllArtists
              : false,
        }
      })
    )
    setShowAllArtists(
      displayedItem.code === showAllArtists ? null : displayedItem.code
    )
  }

  const handleHiddenProviders = () => {
    const hiddenAllProviders = worksStates?.map((state) => {
      return {
        ...state,
        hidden: activeAllWorks,
      }
    })
    setActiveAllWorks(!activeAllWorks)
    setWorksStates(hiddenAllProviders)
  }

  const handleToggleChange = (dspToggle: DisplayedItem) => {
    const hiddenChart = worksStates?.map((item) => {
      return item.code === dspToggle.code
        ? { ...item, hidden: !item.hidden }
        : item
    })
    setWorksStates(hiddenChart)
  }

  const getArtistListComponent = (displayedItem: DisplayedItem) => {
    const arrowDirection: string =
      showAllArtists !== displayedItem.code ? '' : ' rotate-180'
    return (
      <ul className='artists-list'>
        {displayedItem.artists.map((a, id) => {
          if (
            id + 1 <= DEFAULT_MAX_ARTISTS ||
            showAllArtists === displayedItem.code
          )
            return a !== '-' && <li key={id}>{a}</li>
        })}
        {displayedItem.artists.length > DEFAULT_MAX_ARTISTS && (
          <li style={{ listStyleType: 'none' }}>
            <button
              onClick={(e) => {
                e.preventDefault()
                handleDisplayAllArtists(displayedItem)
              }}
              className={'down-arrow' + arrowDirection}
            />
          </li>
        )}
      </ul>
    )
  }

  return (
    <>
      {status === 200 && (
        <Row>
          <Row>
            <Col className={'legend-header-title my-4'}>
              {cradHeaderTitle}
              <Form className='select-all-works'>
                <Form.Check
                  type='switch'
                  id='custom-switch-all-providers'
                  label={t('usage_data.stream-per-works.select-all')}
                  checked={activeAllWorks}
                  onChange={handleHiddenProviders}
                />
              </Form>
            </Col>
          </Row>
          <Row className='usage-data-legends'>
            {worksStates?.map((x, idx) => {
              return (
                <Col
                  xs={12}
                  md={6}
                  key={idx}
                  className={
                    !isMobile
                      ? idx % 2 === 0
                        ? 'border-end ps-2'
                        : 'ps-2'
                      : ''
                  }
                >
                  <Row>
                    <Col lg={5} xs={6} className={'pe-0'}>
                      <Form className={'title'}>
                        <Form.Check
                          key={x.code}
                          type='switch'
                          className='w-100'
                        >
                          <Form.Check.Input
                            checked={!x.hidden}
                            onClick={() => handleToggleChange(x)}
                            onChange={() => ''}
                            type={'radio'}
                            style={
                              x.hidden
                                ? {}
                                : {
                                    borderColor: PlatformColors[idx],
                                    backgroundColor: PlatformColors[idx],
                                  }
                            }
                          />
                          <Form.Check.Label className='w-100 d-flex'>
                            <OverlayTrigger
                              overlay={
                                <span className='tooltip-title'>
                                  {x.label}
                                </span>
                              }
                            >
                              <span className={'auto-width'}>
                                {idx + 1}. &nbsp;
                                <span className={'title'}>{x.label}</span>
                              </span>
                            </OverlayTrigger>
                          </Form.Check.Label>
                          <div>{x.code}</div>
                        </Form.Check>
                      </Form>
                    </Col>
                    <Col lg={5} xs={6} className={'pe-0'}>
                      {x?.artists?.length > 0 && getArtistListComponent(x)}
                    </Col>
                    {isMobile ? (
                      <></>
                    ) : (
                      <Col lg={2} className={'px-0'}>
                        <Button
                          onClick={() => detailRedirection(x)}
                          variant='outline-primary'
                          className='btn-sm btn-resource-id'
                        >
                          {t('usage_data.stream-per-works.resource-id')}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              )
            })}
          </Row>
        </Row>
      )}
    </>
  )
}

export default StreamPerWorksLegend
