import React from 'react';
import { AuthConsumer } from '../../oidcAuthentication/authProvider';
import LoadingSpinner from '../../components/spinner';


export const LogoutCallback = () => (
	<AuthConsumer>
		{({ signoutRedirectCallback }) => {
			signoutRedirectCallback();
			return <LoadingSpinner isLoading={true}/>;
		}}
	</AuthConsumer>
);
