import React from 'react'
import { Container } from 'react-bootstrap'
import DistributionHeader from './distributionRoyaltiesHeader'
import DistributionContent from './distributionContent'
import './distributionRoyalties.scss'

const DistributionRoyalties = () => {
  return (
    <Container fluid className='distribution-container'>
      <DistributionHeader />
      <DistributionContent />
    </Container>
  )
}

export default DistributionRoyalties
