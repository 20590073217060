import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { PlatformColors } from '../../../components/platformColors'
import { offerBarColors } from '../../../components/stackedBarChart/barColors'

import { LineChartInputData, StringDateRange, ScatterPoint } from '../../../types/marketShareTypes'
import { Col, Container, Form, Row } from 'react-bootstrap'
import i18n from '../../../i18n/i18n'

import { Line } from 'react-chartjs-2'
import {
  Chart,
  ChartData,
  ChartDataset,
  CoreScaleOptions,
  registerables,
  Scale,
  ScaleOptionsByType,
  Tick,
  TooltipItem,
  TooltipModel,
} from 'chart.js'
import { DeepPartial } from 'chart.js/types/utils'
import { useTranslation } from 'react-i18next'
import {
  formatCurrencyNumber,
  formatNumber,
} from '../../../helpers/formatter'
import { t } from 'i18next'
Chart.register(...registerables)

const DATE_FORMAT = 'YYYYMM'
function preTransformData(
  platformData: LineChartInputData,
  idx: number
): ChartDataset<'line', { x: string; y: number }[]> {
  return {
    label: platformData.offer_group_name,
    borderColor: offerBarColors[platformData.offer_group_name],
    tension: 0.5,
    hidden: false,
    data: platformData.coordinates.map((item) => {
      return {
        x: String(item.period),
        y: item.value,
      }
    }),
  } as ChartDataset<'line', { x: string; y: number }[]>
}
function getXOptions(
  startDate: string,
  endDate: string,
  lang: 'fr' | 'en' = 'en'
): DeepPartial<ScaleOptionsByType<'time'>> {
  moment.locale(lang)

  return {
    type: 'time',
    min:
      moment(startDate, DATE_FORMAT).year() +
      moment(startDate, DATE_FORMAT).quarter().toString(),
    max:
      moment(endDate, DATE_FORMAT).year() +
      moment(endDate, DATE_FORMAT).quarter().toString(),
    time: {
      parser: 'YYYYQ',
      unit: 'quarter',
    },
    ticks: {
      autoSkip: false,
      maxRotation: 35,
      minRotation: 35,
      callback: function (value, index, ticks) {
        const momentValue = moment(ticks[index].value)
        const year = momentValue.year()
        const quarter = momentValue.quarter()
        return `${year}Q${quarter}`
      },
    },
  }
}
const KpiLineChartComponentBox = ({
  lineChartData,
  lineChartStatus,
  graphHeight = 9,
  graphWidth = 15,
  dataFormat = '',
  i18marker,
  quarterInterval,
}: {
  lineChartData: LineChartInputData[]
  lineChartStatus: number
  dataFormat?: string
  graphHeight?: number
  graphWidth?: number
  i18marker?: string
  quarterInterval: StringDateRange
}) => {
  const [states, setStates] = useState<DisplayedItem[]>([])

  function transformData(
    platformData: LineChartInputData,
    idx: number
  ): ChartDataset<'line', { x: string; y: number }[]> {
    const ret = preTransformData(platformData, idx)
    ret.hidden =
      states?.find(
        (dataPoint) => dataPoint.code === platformData.offer_group_name
      )?.hidden || false
    return ret
  }

  useEffect(() => {
    setStates(
      (lineChartData || []).map((item) => {
        return {
          code: item.offer_group_name,
          label: item.offer_group_name,
          hidden: false,
        }
      })
    )
  }, [lineChartData])

  return (
    <Container className='py-3'>
      <CoreLineChartKpi<LineChartInputData>
        inputData={lineChartData}
        togglableStates={states}
        transformerFunction={transformData}
        xOptions={getXOptions(
          quarterInterval.startingDate,
          quarterInterval.endingDate,
          'en'
        )}
        ticksFunction={function (value, index, ticks) {
          switch (dataFormat) {
            case 'currency':
              return formatCurrencyNumber(i18n.language, Number(value))
            case 'percentage':
              return t('utils.percentage', {
                percentage: formatNumber(i18n.language, Number(value)),
              })
            default:
              return formatNumber(i18n.language, Number(value))
          }
        }}
        width={graphWidth}
        height={graphHeight}
        graphHoverFormatterTag={`${i18marker}.data-point-hover`}
      />
      <KpiLineChartsLegend
        status={lineChartStatus}
        legendHeaderTitle={t(
          'license-kpi.sections.effective-rate.boxes.per-commercial-model.legend-title'
        )}
        data={lineChartData}
        updateStatus={setStates}
      />
    </Container>
  )
}

const KpiLineChartsLegend = ({
  status,
  legendHeaderTitle,
  data,
  updateStatus,
}: LegendPropType) => {
  const [activeLines, setActiveLines] = useState(false)
  const [dataStates, setDataStates] = useState<DisplayedItem[]>([])

  useEffect(() => {
    setDataStates(
      (data || []).map((item: LineChartInputData) => {
        return {
          code: item.offer_group_name,
          label: item.offer_group_name,
          hidden: false,
        }
      })
    )
  }, [data])

  useEffect(() => {
    const status = dataStates.reduce(
      (acc, value) => acc && !value.hidden,
      dataStates.length > 0
    )
    setActiveLines(status)
    updateStatus(dataStates)
  }, [dataStates])

  const handleHiddenProviders = () => {
    const hiddenAllProviders = dataStates?.map((state) => {
      return {
        ...state,
        hidden: activeLines,
      }
    })
    setActiveLines(!activeLines)
    setDataStates(hiddenAllProviders)
  }

  const handleToggleChange = (dspToggle: DisplayedItem) => {
    const hiddenChart = dataStates?.map((item) => {
      return item.code === dspToggle.code
        ? { ...item, hidden: !item.hidden }
        : item
    })
    setDataStates(hiddenChart)
  }
  const labelCheck = t(
    'license-kpi.sections.effective-rate.boxes.per-commercial-model.select-all'
  )

  return (
    <>
      {status === 200 && (
        <Container>
          <Row>
            <Col className={'legend-header-title my-4'}>
              {legendHeaderTitle}
              <Form className='select-all-offers'>
                <Form.Check
                  type='switch'
                  label={labelCheck}
                  checked={activeLines}
                  onChange={handleHiddenProviders}
                />
              </Form>
            </Col>
          </Row>
          <Row>
            {dataStates?.map((x, idx) => {
              return (
                <Col xs={12} md={3} key={idx}>
                  <Col lg={10} xs={8}>
                    <Form className={'title'}>
                      <Form.Check key={x.code} type='switch' className='w-100'>
                        <Form.Check.Input
                          checked={!x.hidden}
                          onClick={() => handleToggleChange(x)}
                          onChange={() => ''}
                          type={'radio'}
                          style={
                            x.hidden
                              ? {}
                              : {
                                  borderColor: offerBarColors[x.code],
                                  backgroundColor: offerBarColors[x.code],
                                }
                          }
                        />
                        <Form.Check.Label className='w-100 d-flex'>
                          <span className={'auto-width'}>
                            <span className={'title'}>{x.label}</span>
                          </span>
                        </Form.Check.Label>
                      </Form.Check>
                    </Form>
                  </Col>
                </Col>
              )
            })}
          </Row>
        </Container>
      )}
    </>
  )
}

const CoreLineChartKpi = <T extends unknown>({
  inputData,
  transformerFunction,
  togglableStates,
  xOptions,
  width,
  height,
  ticksFunction = function (value, index, ticks) {
    return value.toString()
  },
  graphHoverFormatterTag,
}: {
  graphHoverFormatterTag?: string
  inputData?: T[]
  transformerFunction: (
    item: T,
    index: number
  ) => ChartDataset<'line', { x: string; y: number }[]>
  togglableStates: (TogglableItem & { hidden: boolean })[]
  xOptions?: any
  width: number
  height: number
  ticksFunction?: (
    this: Scale<CoreScaleOptions>,
    value: string | number,
    index: number,
    ticks: Tick[]
  ) => string
}) => {
  const [chartData, setChartData] = useState<DisplayedChartData>()
  const { i18n } = useTranslation()

  const getChartDataPositionByDateRange =
    useCallback((): DisplayedChartData => {
      const chartData: DisplayedChartData = {
        datasets: [],
      }

      chartData.datasets.push(...(inputData || []).map(transformerFunction))
      return chartData
    }, [togglableStates, inputData])

  useEffect(() => {
    setChartData(getChartDataPositionByDateRange())
  }, [inputData, togglableStates, getChartDataPositionByDateRange])

  function titleFunc(this: TooltipModel<'line'>) {
    return ''
  }

  function labelFunc(
    this: TooltipModel<'line'>,
    tooltipItem: TooltipItem<'line'>
  ) {
    const dataIndex = tooltipItem.dataIndex
    const left = tooltipItem.dataset.label
    const right = formatNumber(
      i18n.language,
      (tooltipItem.dataset.data[dataIndex] as ScatterPoint).y
    )
    return graphHoverFormatterTag
      ? t(graphHoverFormatterTag, {
          var: left,
          value: right,
        })
      : `${left}:${right}`
  }
  return (
    <>
      {chartData && (
        <Line
          className={'courbe-style'}
          data={chartData}
          width={width}
          height={height}
          options={{
            locale: i18n.language,
            plugins: {
              tooltip: {
                callbacks: {
                  title: titleFunc,
                  label: labelFunc,
                },
              },

              legend: {
                display: false,
              },
            },
            scales: {
              x: xOptions,
              y: {
                ticks: {
                  callback: ticksFunction,
                },
              },
            },
          }}
        />
      )}
    </>
  )
}

export default KpiLineChartComponentBox

interface DisplayedChartData
  extends ChartData<'line', { x: string; y: number }[], any> {}

interface TogglableItem {
  label: string
  code: string | number
}
interface LegendPropType {
  status: number
  legendHeaderTitle: string
  data?: LineChartInputData[]
  updateStatus: Function
}
interface DisplayedItem {
  label: string
  code: string
  hidden: boolean
}
