
import { menuItems } from './sidenavItems'
import { SidenavMenuItem, SidenavSubmenuItem } from '../../../types/sidenav'
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useContext } from 'react'
import { SidenavComponent } from 'ui-components'
import { ROUTES } from '../../../constants';
import { connect } from 'react-redux';
import { AppPropsType } from '../../../types';
import { useAppDispatch } from '../../../store/hooks'
import { resetUsageDataState } from '../../../slices/usageDataSlice'
import { ConfigurationContext } from '../../../providers/configurationProvider'

import './index.scss'

const WebsoSidenav = ({ userRoles }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch()
	const { t } = useTranslation();
	const configuration = useContext(ConfigurationContext);
	useEffect(() => {
		addBackgroundColorOnRelatedSidenavLink(location.pathname)
	}, [location.pathname]);

	const addBackgroundColorOnRelatedSidenavLink = (path: string) => {
		const portalRoutesNotInSidenav = [ROUTES.CREATE_EVENT]
		const isCurrentRouteInSidenav = portalRoutesNotInSidenav.every(route => path.indexOf(route) === -1)

		if (isCurrentRouteInSidenav) {
			document.querySelectorAll('.sidenav-selected-item').forEach(element => {
				element.classList.remove('sidenav-selected-item');
			});

			let currentIconClass = ''
			menuItems.every(menuItem => {
				if (menuItem.iconClass && menuItem.route && path.indexOf(menuItem.route) !== -1) {
					currentIconClass = menuItem.iconClass
					return false
				}
				if (menuItem.subItems) {
					menuItem.subItems.every(submenuItem => {
						if (path.indexOf(submenuItem.route) !== -1) {
							currentIconClass = submenuItem.iconClass
							return false
						}
						return true
					})
				}
				return true
			})

			if (currentIconClass !== '') {
				let sidenavLine = document.querySelector('.' + currentIconClass)?.closest('.sidenav-item')
				sidenavLine?.classList.add('sidenav-selected-item')
			}
		}
	}

	const redirectHandler = (path?: string) => {
		const currentPage = location.pathname
		if (path != null && currentPage !== path) {

			/* Fix old bug when using search filters on usage-data page, quitting this page,
			switching account and going back to usage data page through sidenav (ORION-328) */
			if (currentPage === ROUTES.USAGE_DATA) {
				dispatch(resetUsageDataState())
			}

			if (path.toLowerCase() === 'onethrust') {
				const oneThrustBtn = document.querySelector('.ot-floating-button__open')
				oneThrustBtn?.dispatchEvent(new MouseEvent('click'))
				return
			}

			const isPortalInternalLink = path.indexOf('https://') === -1 && path.indexOf(ROUTES.PRIVACY_COOKIES_POLICY) === -1
			if (isPortalInternalLink) {
				navigate(path)
			} else {
				window.open(path, '_blank')
			}
		}
	}

	const hasUserAccessToSidenavPart = (item: SidenavMenuItem) => {
		const hasItemExcludedRole = userRoles && userRoles.some((userRole: string) => item.excludeddRoles.includes(userRole))
		if (hasItemExcludedRole) {
			return false;
		}
		const hasItemNoRoleRequired = item.requiredRoles.length === 1 && item.requiredRoles[0] === '*';
		if (hasItemNoRoleRequired) {
			return true;
		}

		const hasUserRequiredRole = userRoles && userRoles.some((userRole: string) => item.requiredRoles.includes(userRole))
		return hasUserRequiredRole;
	}

	const getMenuItems = useMemo(() => {
		return menuItems.filter((sidenavPart: SidenavMenuItem) => hasUserAccessToSidenavPart(sidenavPart)).map(
			(item: SidenavMenuItem) => {
				return {
					...item,
					iconClass: item.iconClass ? "webso-sidenav-icon " + item.iconClass : '',
					level: t("sidenav-items." + item.label),
					subItems: item.subItems.map((subItem: SidenavSubmenuItem) => {
						return {
						...subItem,
						iconClass: "webso-sidenav-icon " + subItem.iconClass,
						name: t("sidenav-items." + subItem.label),
						route: subItem.route,
						}
					}),
				}
			})
	}, [])

    return (
        <SidenavComponent
            menuItems={getMenuItems}
            redirectHandler={redirectHandler}
            navClassName='webso-sidenav'
			logoClassName='webso-sidenav-logo'
            showBackgroundCircle={false}
            hasBoldTitles={false}
			hasCustomItemsHover={true}
            configuration={configuration}
        />
    );
}

const mapStateToProps = (state: AppPropsType) => ({
	userRoles: state.appContext.profile?.roles
});

export default connect(mapStateToProps)(WebsoSidenav);
