import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Representation } from '../../../../types';
import { renderRepresentationAddress } from '../../../../helpers';
import RepresentationCancel from "../representationCancel";
import { deleteRepresentationsAsync } from '../../../../slices/representationsSlice';
import {NOT_CANCELLABLE_EVENT} from "../../../../constants";
import { Link } from 'react-router-dom';

import './index.scss';

interface Props {
  item: Representation;
	societyId: number | undefined;
}

const RepresentationCard = (props: Props) => {
	const { item, societyId } = props;
	const { distribution, typicalProgram } = item;
	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);
	const { t, i18n } = useTranslation();

	const isCancellableEvent = () => !NOT_CANCELLABLE_EVENT.includes(item.status.code);

	return (
		<>
			<RepresentationCancel
				event={item}
				open={openModal}
				onClose={() => setOpenModal(false)}
			/>
			<div className="representation-card">
				<div className="content">
					<div className="header">
						<div className="d-flex flex-column">
							<div className="date">
								{moment(item.representationDate).format('DD MMM YYYY').toUpperCase()}
							</div>
							<div className="title text-truncate">
								{item.representationTitle}
							</div>
						</div>
						<div className={`status ${item.status.code === 'Répartie' ? 'distributed' : ''} text-truncate`}>{item.status.label}</div>
					</div>
					<div className="body">
						<CardLine
							label={t('representation.distribution')}
							value={distribution ? `${distribution.number} ${i18n.language === 'en' ? 'of' : 'du'} ${moment(distribution.date).format('DD MMM YYYY')}` : ''}
						/>
						{ typicalProgram?.typicalProgramId !== 0 && (
							<>
								<CardLine
									label={t('set-lists.setlist-number')}
									value={`${typicalProgram?.typicalProgramId}`}
								/>
								<CardLine
									label={t('representation.set-list')}
									value={typicalProgram?.typicalProgramTitle}
								/>
							</>
						)}

						<CardLine
							label={t('representation.customer')}
							value={item.representationCustomers && item.representationCustomers[0]}
						/>
						<CardLine
							label={t('representation.location')}
							value={item.representationAddress?.representationLieu && item.representationAddress.representationLieu}
						/>
						<CardLine label={t('representation.address')}>
							{renderRepresentationAddress(item.representationAddress)}
						</CardLine>
						<CardLine
							label={t('representation.performer')}
							value={
								item.representationPerformers &&
                item.representationPerformers[0]
							}
						/>
						<CardLine
							label={t('representation.qualification')}
							value={
								item.representationTypeQualification
									?.representationTypeQualificationLabel
							}
						/>
						{ typicalProgram?.typicalProgramId !== 0 && (
							<CardLine
								label={t('representation.works-count')}
								value={`${typicalProgram.typicalProgramNumberOfWorks}`}
							/>
						)}
					</div>
				</div>
				<div className="footer">
					<div
						className={`actions ${
							isCancellableEvent()
								? 'justify-content-between'
								: 'justify-content-end'
						}`}
					>
						{isCancellableEvent() && (
							<button
								className="btn-delete"
								onClick={() => setOpenModal(true)}
							>{t('representation.action.cancel')}</button>
						)}

						<Link
							to={
								`${item.representationId}`
							}
							className="more-info"
						>
							{t('actions.more-info')}
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

const CardLine = (props: {
  label: string;
  value?: string;
  children?: any;
}) =>
	props.value || props.children ? (
		<div className="card-line">
			<div className="label">{props.label}</div>
			<div className="value">{props.value || props.children}</div>
		</div>
	) : (
		<></>
	);

export default RepresentationCard;
