import React, {useMemo} from 'react'
import {Col, Container, ProgressBar, Row} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {ProgressBarItem} from '../../components/progressbar'
import StreamPerWorks from '../../components/worksRanking'

import './usageData.scss'
import StreamsPerTerritory from './StreamsPerTerritory'
import UsageDataHeader from './usageDataHeader'
import {useAppSelector} from '../../store/hooks';
import {formatNumber} from '../../helpers'
import {getItemsToDisplay} from './progressBarHelper'
import BoxSectionComponent from '../../components/boxSectionComponent'

import './chart.scss';

const UsageData = () => {
  const mapData = useAppSelector((state) => state.usageData.mapData)
  const mapDataStatus = useAppSelector(
    (state) => state.usageData.status.mapData
  )
  const usageDataTerritories = mapData.streams_per_territory.map((license) => {
    return license.territory_alpha2_code
  })

  const {t, i18n} = useTranslation()

  const streamsPerOffer = useAppSelector(
    (state) => state.usageData.streamPerOffer
  )
  const streamsPerOfferStatus = useAppSelector(
    (state) => state.usageData.status.streamPerOffer
  )
  const streamsPerWorkStatus = useAppSelector(
    (state) => state.usageData.status.streamPerWork
  )

  const dspList = useAppSelector((state) => state.usageData.dspList)
  const territoriesList = useAppSelector(
    (state) => state.usageData.territoryList
  )

  function getLabel<T>(code: T, list: { code: T; label: string }[]) {
    return list.find((item) => item.code === code)?.label || code
  }

  const lastSearchValues = useAppSelector(
    (state) => state.usageData.lastSearchValues
  )

  const streamPerOffer = useMemo(
    () =>
      getItemsToDisplay(
        -1,
        streamsPerOffer.map((item) => {
          return {
            code: item.code,
            displayName: item.offerGroupName,
            value: item.streams,
            percentage: item.percentage,
          } as ProgressBarItem
        }),
        t('usage_data.stream-per-offer.othergroup')
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [streamsPerOffer]
  )

  return (
    <Container className='usage-data-container page-container' fluid>
      <UsageDataHeader/>
      <Container className='my-4'>
        <Row>
          <Col xs={12} lg={6} className='mb-4'>
            <div style={{height: '500px'}}>
              <BoxSectionComponent
                title={t('usage_data.map_container.title')}
                subtitle=''
                status={{
                  statusCode: mapDataStatus,
                  defaultMessage: t(
                    'usage_data.map_container.statusMessage.default'
                  ),
                  noDataFoundMessage: t(
                    'usage_data.map_container.statusMessage.204'
                  ),
                  unexpectedErrorMessage: t(
                    'usage_data.map_container.statusMessage.500'
                  )
                }}
                bodyContent={
                  <StreamsPerTerritory
                    usageDataTerritories={usageDataTerritories}
                    streamsPerTerritory={mapData.streams_per_territory}
                    totalNumberOfStreams={mapData.total_usage}
                  />
                }/>
            </div>
          </Col>

          <Col xs={12} lg={6} className='mb-4'>
            <div style={{height: '500px'}}>

              <BoxSectionComponent
                title={t('usage_data.stream-per-offer.title')}
                status={{
                  statusCode: streamsPerOfferStatus,
                  defaultMessage: t(
                    'usage_data.stream-per-offer.defaultMessage'
                  ),
                  noDataFoundMessage: t(
                    'usage_data.stream-per-offer.noDateFoundMessage'
                  ),
                  unexpectedErrorMessage: t(
                    'usage_data.stream-per-offer.unexpectedErrorMessage'
                  )
                }}
                bodyContent={
                  <div className='p-4'>
                    {streamPerOffer.map((item, i) => (
                      <React.Fragment
                        key={`rf-family-overlay-${i}-${item.code}`}
                      >
                        <div
                          key={`family-bar-${i}-${item.code}`}
                          className='bar-container'
                        >
                              <span className='bar-label'>
                                {item.displayName}
                              </span>
                          <span className='bar-amount'>
                                {formatNumber(i18n.language, item.value)}
                              </span>
                          <ProgressBar
                            now={item.percentage}
                            color={'#fd54e2'}
                          />
                          <span className='bar-percent'>{`${formatNumber(
                            i18n.language,
                            item.percentage
                          )}%`}</span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                }/>
            </div>
          </Col>
        </Row>
        <Row className='pb-4'>
          {
            lastSearchValues &&
            lastSearchValues.territories.length === 1 ? (
              <div>
                <BoxSectionComponent
                  title={t('usage_data.stream-per-works.title')}
                  subtitle={t('usage_data.stream-per-works.dsp-terr', {
                    dspName: getLabel<string>(lastSearchValues.dsp, dspList),
                    territory: territoriesList
                      .find((itm) => itm.code === lastSearchValues.territories[0])
                      ?.label?.toLowerCase(),
                  })}
                  status={{
                    statusCode: streamsPerWorkStatus,
                    defaultMessage: t('statusMessage.default'),
                    noDataFoundMessage: t('statusMessage.204'),
                    unexpectedErrorMessage: t('statusMessage.500')
                  }}
                  bodyContent={
                    <StreamPerWorks/>
                  }/>
                </div>
            ) : (
              <BoxSectionComponent
                title={t('usage_data.stream-per-works.title')}
                subtitle={t('usage_data.stream-per-works.invalid-selection')}
                status={{
                  statusCode: -3,
                  defaultMessage: t('statusMessage.default'),
                  noDataFoundMessage: t('statusMessage.204'),
                  unexpectedErrorMessage: t('statusMessage.500')
                }}
                bodyContent={<></>}/>
            )
          }
        </Row>
      </Container>
    </Container>
  )
}

export default UsageData
