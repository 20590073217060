import { IMapObject, OnRegionTipShow } from '@react-jvectormap/core/dist/types'
import React, { useEffect, useRef, useState } from 'react'
import WorldMap from '../../components/world-map'
import { Col, Container, Row } from 'react-bootstrap'
import { t as tr } from 'i18next'
import { abbreviateNumber } from 'js-abbreviation-number'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { LicenseType, StreamsPerTerritoryPropType } from '../../types'
import { vectorMapAllowedRegions } from '../../constants/constants'
import countries from "i18n-iso-countries";
import {useTranslation} from "react-i18next";
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

const numberWithSpaces = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
const blueDegradedColors: string[] = [
  '#E4EAFF',
  '#C5D4FC',
  '#A6BCFC',
  '#8FA9FA',
  '#6687FA',
  '#6885de',
  '#4B6CDA',
  '#3251BA',
  '#1E3997',
  '#03175e',
]
const maxOfArray = (arr: number[]) => {
  return Math.max.apply(Math, arr)
}
const numberAbbreviation = (number: number) => {
  const symbolsList: string[] = [
    '',
    ...tr('usage_data.map_container.symbols_list').split(' '),
  ]
  return abbreviateNumber(number, 1, { symbols: symbolsList })
}
const StreamsPerTerritory = ({
  usageDataTerritories,
  streamsPerTerritory,
  totalNumberOfStreams,
}: StreamsPerTerritoryPropType) => {
  const mapRef = useRef<IMapObject>(null)
  const { t } = useTranslation();
  const { screenWidth } = useWindowDimensions()
  const [isMobile, setIsMobile] = useState<boolean>(screenWidth < 1176)
  const [currentCountryName, setCurrentCountryName] = useState<string>('')
  const [currentCountryStreamStats, setCurrentCountryStreamStats] =
    useState<StreamStats>({ streamNumberShare: 0, numberOfStreams: 0 })
  const maxStreams: number = maxOfArray(
    streamsPerTerritory.map((licence: LicenseType) => {
      return licence.total_usage
    })
  )
  const getNumberOfStreamsPerTerritory = (code: string) => {
    const currentLicense: LicenseType = streamsPerTerritory.filter(
      (license: LicenseType) => {
        return license.territory_alpha2_code === code
      }
    )[0]
    const numberOfStreams: number = currentLicense?.total_usage
    const streamNumberShare: number =
      Math.round((numberOfStreams / totalNumberOfStreams) * 100 * 100) / 100
    return {
      numberOfStreams: numberOfStreams,
      streamNumberShare: streamNumberShare,
    }
  }
  const getTerritoryColor = (code: string) => {
    const streamNumberPercentageToMax: string = Math.round(
      (getNumberOfStreamsPerTerritory(code).numberOfStreams / maxStreams) * 100
    ).toString()

    const colorIndex: number =
      Number(streamNumberPercentageToMax) == 100
        ? blueDegradedColors.length - 1
        : Number(streamNumberPercentageToMax) < 10
        ? 0
        : Number(streamNumberPercentageToMax.charAt(0))

    return blueDegradedColors[colorIndex]
  }

  const onRegionTipShow: OnRegionTipShow = (event, el: any, code) => {
    if (!usageDataTerritories.includes(code)) {
      event.preventDefault()
      return
    }

    const countryFullName = mapRef?.current?.getRegionName(
      code
    ) as any as string
    const streamStats: StreamStats = getNumberOfStreamsPerTerritory(code)
    if (!isMobile) {
      return el.html(
        `<div class='country-hover-info'>${t(
          'usage_data.map_container.map_tip_text',
          {
            country_name: countryFullName,
            share_value: streamStats.streamNumberShare,
            number_of_streams: numberWithSpaces(streamStats.numberOfStreams),
          }
        )}</div>`
      )
    } else {
      event.preventDefault()
      setCurrentCountryName(countryFullName)
      setCurrentCountryStreamStats(streamStats)
    }
  }
  const regionStyle = (code: string) => {
    //return a style object for regions states initial, hover
    if (usageDataTerritories.includes(code)) {
      return {
        initial: {
          fill: getTerritoryColor(code),

          cursor: 'pointer',
          'stroke-width': 0.2,
          'stroke-opacity': 0.6,
          stroke: 'blue',
        },
        hover: {
          fill: '#abdbe3',
        },
      }
    } else return {}
  }
  const zoomableTerritories: string[] = usageDataTerritories.filter((terr) => {
    return vectorMapAllowedRegions.includes(
      parseInt(countries.alpha2ToNumeric(terr) as string)
    )
  })
  useEffect(() => {
    if (zoomableTerritories.length > 0)
      mapRef?.current?.setFocus({
        regions: zoomableTerritories,
        animate: true,
      })
  }, [])
  useEffect(() => {
    setIsMobile(screenWidth < 1176)
  }, [screenWidth])
  return (
    <Container fluid>
      <Row style={{
        background: 'white',
        minHeight: '325px'
      }}>
        <Col className='px-0'>
          <WorldMap
            mapRef={mapRef}
            regionStyle={regionStyle}
            onRegionTipShow={onRegionTipShow}
            backgroundColor='#CAD7F4'
            regionsSelectable={true}
            regionsSelectableOne={true}
            zoomAnimate={true}
            className='vector-map-license-scope'
          />
        </Col>
      </Row>

      <Row className='usage-data-map-footer-row justify-content-center'>
        <Col md={12} className='stream-number-label'>
          <>
          {t('usage_data.map_container.streams_number_label')}{' '}
          {isMobile && (
            <div className='country-hover-info-mobile'>
              { currentCountryName ? t('usage_data.map_container.map_tip_text',
                {
                  country_name: currentCountryName,
                  share_value: currentCountryStreamStats?.streamNumberShare,
                  number_of_streams: numberWithSpaces(
                    currentCountryStreamStats?.numberOfStreams
                  ),
                }) : t('usage_data.map_container.map_tip_mobile')}
            </div>
          )}
          </>
        </Col>

        <Col md={12} className='map-legend-container'>
          {blueDegradedColors.map((color: string, index: number) => {
            return (
              <div className='legend-segment-container' key={index}>
                <div
                  className='colored-legend-element'
                  style={{
                    background: color,
                  }}
                />
                <div className='legend-max-value-element'>
                  {numberAbbreviation(
                    Math.round(((index + 1) * maxStreams) / 10)
                  )}
                </div>
              </div>
            )
          })}
        </Col>
      </Row>
    </Container>
  )
}

export default StreamsPerTerritory

interface StreamStats {
  streamNumberShare: number
  numberOfStreams: number
}
