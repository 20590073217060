import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  BarControllerChartOptions,
} from 'chart.js';
import {Chart} from 'react-chartjs-2';
import {Col, Container, Form, Row} from "react-bootstrap";
import {ChartDataProps} from "./type";
import { DeepPartial } from 'chart.js/types/utils';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);


/**
 * Stacked Bar Chat component.
 *
 * labels props : string - Horizontal axis items
 *
 * datasets props : number - Bar Chart Data
 *
 * axis props: The base axis of the dataset. 'x' for vertical bars and 'y' for horizontal bars.
 *
 * xOptions props: the X axis configuration options
 *
 * xOptions props: the Y axis configuration options
 *
 */
export const StackedBarChart = <T extends unknown>({
                                                     labels,
                                                     datasets,
                                                     xOptions,
                                                     yOptions,
                                                     lang,
                                                     stackedBarTooltipCallbacks,
                                                     height = '280px',
                                                   }: ChartDataProps<T>) => {
  const chartRef = useRef(null)
  const [graphData, setGraphData] = useState<{ datasets: ChartDataset<'bar', T[]>[], labels: string[] }>({
    datasets: datasets,
    labels: labels
  })

  const options : DeepPartial<CoreChartOptions<"bar"> & ElementChartOptions<"bar"> & PluginChartOptions<"bar"> & DatasetChartOptions<"bar"> & ScaleChartOptions<"bar"> & BarControllerChartOptions> = useMemo(() => {
    return {
      local: lang,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: stackedBarTooltipCallbacks ? stackedBarTooltipCallbacks : {}
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: xOptions,
        y: yOptions,
      }
    }

  }, [labels, datasets]);

  useEffect(() => {
    const chart: any = chartRef.current
    if (chart) {
      chart.update()
    }
  }, [options])

  const onChangeHandler = (datasetIndex: number, visible: boolean) => {
    setGraphData((oldData: any) => {
      let data = oldData;
      data.datasets[datasetIndex].hidden = !visible
      return {...data}
    })
    const chart: any = chartRef.current;
    if (chart) {
      chart.setDatasetVisibility(datasetIndex, visible)
      chart.update();
    }
  }
  return (
    <Container>
      <Row>
        <Col style={{'height': '320px'}}>
          {graphData && <Chart type={"bar"} ref={chartRef} options={options} data={graphData}/>}
        </Col>
      </Row>
      <Row className="py-4">
        <Col>
          {graphData && graphData.datasets.map((dataSet: any, index: number) => {
            return (
              <Form.Check
                inline
                key={index}
                type='switch'
                label={dataSet.label}
                className={"mx-4"}
              >
                <Form.Check.Input
                  checked={!dataSet.hidden}
                  onClick={() => {
                    onChangeHandler(index, dataSet.hidden)
                  }}
                  onChange={() => ''}
                  type={'radio'}
                  style={!dataSet.hidden ? {
                    "borderColor": dataSet.backgroundColor,
                    "backgroundColor": dataSet.backgroundColor
                  } : {}
                  }
                />
                <Form.Check.Label>{dataSet.label}</Form.Check.Label>
              </Form.Check>
            )

          })
          }
        </Col>
      </Row>

    </Container>)
}
