import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	WorksRequest,
	WorksState
} from '../types';
import { fetchWorks } from '../api/worksAPI';

export const initialState: WorksState = {
	loading: false,
	responseCode: 'OK',
	works: [],
	worksCount: 0
};

export const fetchWorksAsync = createAsyncThunk(
	'works/fetchWorks',
	async (params: WorksRequest, { rejectWithValue }) => {
		try {
			return await fetchWorks(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const worksSlice = createSlice({
	name: 'works',
	initialState,
	reducers: {
		resetWorks: (state) => {
			return {
				...state,
				responseCode: 'RESET_WORKS',
				works: [],
				worksCount: 0,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchWorksAsync.pending, (state) => {
				return {
					...state,
					loading: true,
					responseCode: 'SEARCH_WORKS'
				};
			})
			.addCase(fetchWorksAsync.fulfilled, (state, action) => {
				return {
					loading: false,
					responseCode: 'OK',
					works: action.payload.works !== undefined ? action.payload.works : [],
					worksCount: action.payload.worksCount !== undefined ? action.payload.worksCount : 0
				};
			})
			.addCase(fetchWorksAsync.rejected, (state, action) => {
				return {
					...state,
					responseCode: 'rejected',
					loading: false,
					works: [],
					worksCount: 0
				};
			});
	},
});

export const { resetWorks } = worksSlice.actions;
export default worksSlice.reducer;
