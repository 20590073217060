import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { tag } from '../../helpers/ATIntenetService'

const Page = (props: any) => {
	const [t, i18n] = useTranslation();
	const title = `${t('app-title')}${props.title ? ` - ${t(props.title)}` : ''}`;

	useEffect(() => {
		document.title = title;
		document.documentElement.setAttribute('lang', i18n.language);

		// tracking: page visit
		tag.sendPage({
			name: title,
		})

	}, [props.title]);

	return props.children;
};

export default Page;