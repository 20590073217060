import React, { useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import LoginButton from './components/loginButton/LoginButton';
import { useTranslation } from 'react-i18next';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';
import { Link } from 'react-router-dom';

import './index.scss';

export function HomePage() {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);

	const registerBtnRef = useRef(null);

	const onClickOutside = () => {
		setShow(false);
	};

	useOutsideAlerter(registerBtnRef, onClickOutside);

	return (
		<>
			<div className="bg" />
			<div className="public-home">
				<div className="top">
					<div className="logo" />
					<Container>
						<div className="welcome">{t('home.welcome')}</div>
						<div className="portal">{t('home.portal')}</div>
						<LoginButton />
					</Container>
				</div>
				<div className="bottom">
					<Container>
						<div className="address">{t('home.address')}</div>
						<div className="phone">{t('home.phone')}</div>
						<div className="social-networks">
							<a className="soc-net instagram" href="https://www.instagram.com/sacem/" target="_blank" rel="noreferrer" />
							<a className="soc-net youtube" href="https://www.youtube.com/user/sacem" target="_blank" rel="noreferrer" />
							<a className="soc-net twitter" href="https://twitter.com/sacem" target="_blank" rel="noreferrer" />
							<a className="soc-net facebook" href="https://www.facebook.com/LaSacem/?fref=ts" target="_blank" rel="noreferrer" />
							<a className="soc-net dailymotion" href="https://www.dailymotion.com/SACEM" target="_blank" rel="noreferrer" />

							<div className="register">
								<button
									className={`register-btn${show ? ' active' : ''}`}
									ref={registerBtnRef}
									onClick={() => setShow(!show)}
								>{t('home.register.label')}</button>
								<div className={`info${show ? ' open' : ''}`}>{t('home.register.tooltip')}</div>
							</div>
						</div>
						<div className="cookies">
							<Link to={{ pathname: '/privacy-cookies-policy' }}>
								{t('home.cookies')}
							</Link>
						</div>
					</Container>
				</div>
			</div>
		</>
	);
}
