import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Card } from 'react-bootstrap';

import HeaderCard from '../../../components/header-card';
import FormInputText from '../../../components/FormInputText';
import { FormProgramInterface } from '../../../types';

import '../index.scss';


const FirstStep = (props: Props) => {
	const { values, onSubmit, saveDraft } = props;
	const { t } = useTranslation();
	const [errors, setErrors] = useState<any>({});

	const handleSubmit = () => {
		onSubmit(1, values);
	};
	const handleChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const worksListTitleValue = e.target.value;
		if (worksListTitleValue.length > 50) {
			setErrors({ worksListTitle: t('error.form.limit-characters') });
		} else {
			setErrors({});
		}
		values.worksListTitle = worksListTitleValue;
	};

	return (
		<Card className="program-card">
			<HeaderCard title={t('page.new-program.step1.title')}/>
			<Card.Body>
				<Form className="form-add-program">
					<FormInputText id="worksListTitle"
						label={t('page.new-program.step1.label-field-title')}
						onChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e)}
						placeholder={t('page.new-program.step1.placeholder-field-title')}
						defaultValue={values.worksListTitle}
					 	errors={errors}
					/>
					<div className="container-btn">
						<Button
							type="button"
							disabled={!values.worksListTitle.length || errors?.worksListTitle}
							onClick={saveDraft}>{t('btn.save-draft')}
						</Button>
						<span className="mt-3 d-md-none">{''}</span>
						<Button
							className={values.worksListTitle.length && !errors.length ? 'active' : ''}
							type="button"
							disabled={!values.worksListTitle.length || errors?.worksListTitle}
							onClick={() => handleSubmit()}>{t('btn.next')}
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};

export default FirstStep;

interface Props {
  values: FormProgramInterface
  saveDraft: () => void
  onSubmit: (step:number, values:any) => void
}