import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store/store';
import { DraftsPayload, DraftsState, ProgramParam } from '../types';
import { deleteWorkList, fetchDrafts } from '../api/draftsAPI';

export const initialState: DraftsState = {
	worksLists: [],
	worksListsCount: 0,
	reason: '',
};

export const fetchDraftsAsync = createAsyncThunk(
	'works-lists',
	async (programParam: ProgramParam, { rejectWithValue }) => {
		const draftsPromise: Promise<DraftsPayload> =
      fetchDrafts(programParam);

		const draftsResult: DraftsPayload =
			await draftsPromise
				.then((responseBody) => {
					return Promise.resolve(responseBody);
				})
				.catch((error) => Promise.reject(error));

		return {
			worksLists: draftsResult?.worksLists || [],
			worksListsCount: draftsResult?.worksListsCount || 0,
		};
	}
);

export const deleteWorkListAsync = createAsyncThunk(
	'programs/deleteWorkList',
	async (params: any, { rejectWithValue }) => {
		try {
			const { worksListId, societyId } = params;
			return await deleteWorkList(worksListId, societyId);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const selectDrafts = (state: RootState) => <DraftsState>state.drafts;
export const draftsSlice = createSlice({
	name: 'drafts',
	initialState,
	reducers: {
		setDrafts: (state, action) => ({ ...state, worksLists: action.payload }),
		setDraftsCount: (state, action) =>  ({ ...state, worksListsCount: action.payload }),
		setReason: (state,action)=>({ ...state, reason: action.payload })
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchDraftsAsync.pending, (state) => ({
				...state,
				reason: '0'
			}))
			.addCase(fetchDraftsAsync.fulfilled, (state, action) => ({
				...state,
				reason: '200',
				worksLists: action.payload.worksLists,
				worksListsCount: action.payload.worksListsCount,
			}))
			.addCase(fetchDraftsAsync.rejected, (state, action) => ({
				...state,
				reason: action?.error?.message || '500',
				drafts: [],
				draftsCount: 0
			}))
			.addCase(deleteWorkListAsync.pending, (state) => {
				state.reason = 'DELETE_PENDING';
			})
			.addCase(deleteWorkListAsync.fulfilled, (state, action) => {
				state.reason = 'DELETE_OK';
				if (action.payload === 204) {
					const index = state.worksLists.findIndex(work => work.worksListId === action.meta.arg.worksListId);
					const newList = state.worksLists;
					newList.splice(index, 1);
					state.worksLists = newList;
					state.worksListsCount = state.worksListsCount - 1;
				}
			})
			.addCase(deleteWorkListAsync.rejected, (state, action) => {
				// @ts-ignore
				state.reason = 'DELETE_ERROR';
			});
	}
});

export const { setDrafts, setDraftsCount, setReason } = draftsSlice.actions;

export default draftsSlice.reducer;
