import { IPI, IpiSuggest, PlaceDetails, PlaceSuggest } from '../types';
import { httpCaller } from './httpCaller';

export function fetchIpisSugest(query: string, size: number) {
	return new Promise<IpiSuggest>((resolve, reject) =>
		httpCaller
			.get('ipis-suggest', { params: { query, size } })
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}

export function fetchPlacesSuggest(query: string, size: number) {
	return new Promise<PlaceSuggest[]>((resolve, reject) =>
		httpCaller
			.get('/places', { params: { query, size } })
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}

export function fetchPlaceDetails(placeId: number) {
	return new Promise<PlaceDetails>((resolve, reject) =>
		httpCaller
			.get(`/places/${placeId}`)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}

export function getRepertoires(params: { societyId: string }) {
	return new Promise((resolve, reject) =>
		httpCaller
			.get(`/repertoires`, {params: {societyId: params.societyId}})
			.then((response) => {
				return resolve(response);
			})
			.catch((error) => {
				return reject(error);
			})
	);
  }
  