import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { BeatLoader } from 'react-spinners';
import moment from 'moment';

import { setBreadcrumb } from '../../slices/appContextSlice';
import { fetchRepresentationsAsync, fetchRepresentationsStatutesAsync } from '../../slices/representationsSlice';
import {
	AppContextState,
	AppPropsType, Representation,
	RepresentationFilters,
	RepresentationsState,
	SortParam
} from '../../types';
import { DEFAULT_SIZE_PER_PAGE, ROLES } from '../../constants';
import Filters from './components/filters';
import FilterToggle from '../../components/FilterToggle';
import { SortLabel } from './components/sortButton';
import RepresentationCard from './components/representationCard';
import Pagination from '../../components/pagination';
import { ROUTES } from '../../constants';
import { FF_USAGE_DATA } from '../../providers/configurationProvider';

import './index.scss';
import useRoleRedirect from '../../hooks/useRoleRedirect';


const Events = (props: Props) => {
	useRoleRedirect(ROLES.WEBSO_WEB_ONLINE);
	const {
		representations: { loading, responseCode, representations, representationsCount, statutes },
		appcontext
	} = props;
	const societyId = appcontext.selectedSisterSociety?.sisterSocietyId;
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams({});
	const [filters, setFilters] = useState<RepresentationFilters | undefined>();
	const [pastEvents, setPastEvents] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortParam, setSortParam] = useState<SortParam>({
		sortField: 'startDate',
		sortOrder: 'asc'
	});
	const [showToast, setShowToast] = useState(false);
	const [contentToast, setContentToast] = useState('');
	const topRef = useRef<HTMLDivElement | null>(null);

	const checkFiltersDate = () => {
		if (!pastEvents && !filters?.startDate) {
			return {
				...filters,
				startDate: moment().format('YYYY-MM-DD')
			};
		} else if (pastEvents && !filters?.endDate) {
			return {
				...filters,
				endDate: moment().subtract(1, 'day').format('YYYY-MM-DD')
			};
		}
		return filters;
	};

	useEffect(() => {
		dispatch(fetchRepresentationsAsync({
			pagination: {
				page: currentPage,
				size: DEFAULT_SIZE_PER_PAGE
			},
			sortOption: sortParam,
			filters: checkFiltersDate(),
			societyId: societyId,
		}));
	},[currentPage]);


	useEffect(() => {
		dispatch(fetchRepresentationsAsync({
			pagination: {
				page: 1,
				size: DEFAULT_SIZE_PER_PAGE
			},
			sortOption: sortParam,
			filters: checkFiltersDate(),
			societyId: societyId,
		}));
	},[pastEvents, filters, sortParam]);

	useEffect(() => {
		if (responseCode === 'CANCEL_OK') {
			const totalPages = representationsCount < DEFAULT_SIZE_PER_PAGE ? 1 : Math.ceil(representationsCount / DEFAULT_SIZE_PER_PAGE);
			if (currentPage < totalPages) {
				dispatch(fetchRepresentationsAsync({
					pagination: {
						page: currentPage,
						size: DEFAULT_SIZE_PER_PAGE
					},
					sortOption: sortParam,
					filters: checkFiltersDate(),
					societyId: societyId,
				}));
			} else if (representations.length === 0 && representationsCount > 0) {
				setCurrentPage(currentPage - 1);
			}
		}
	}, [responseCode]);

	useEffect(() => {
		dispatch(setBreadcrumb({
			title: t('breadcrumb.dates-title'),
			buttonAction: ROUTES.CREATE_EVENT,
			buttonLabel: 'representation.create-new',
			showHome: true
		}))
		if (statutes.length <= 0) {
			dispatch(fetchRepresentationsStatutesAsync({ societyId }));
		}
		const contentToast = searchParams.get('toast');
		const isPassedDate = searchParams.get('toast-before-date');
		if (contentToast) {
			const keyTranslation = isPassedDate === '1' ? 'toast.success-create-date-past' : 'toast.success-create-date';
			setShowToast(true);
			setContentToast(t(keyTranslation).replace('{x}', contentToast));
		}
	}, []);

	const onPastEventsSwitch = (checked: boolean) => {
		setPastEvents(checked);
		setCurrentPage(1);
		setFilters(undefined);
		setSortParam({
			sortField: 'startDate',
			sortOrder: checked ? 'desc' : 'asc',
		});
	};

	const onPageClick = (page: number) => {
		setCurrentPage(page);
		topRef?.current?.scrollIntoView();
	};

	return (
		<>
			<Filters
				onSearch={(filters) => setFilters(filters)}
				statutes={statutes}
				pastEvents={pastEvents}
			/>
			{ loading ? (
				<div className="loading-data">
					<BeatLoader speedMultiplier={1} margin={5} size={20} color={'#125FE2'} />
				</div>
			) : (
				<Container ref={topRef}>
					<div className="data-header d-none d-md-flex">
						<div className="count">
							<span className="number">{representationsCount}</span>
							<span className="label">{`${t('representation.count-label')}${representationsCount > 1 ? 's' : ''}`}</span>
						</div>

						<div className="actions">
							<FilterToggle
								items={[
									{
										label: t('representation.toggle.past'),
										icon: 'event-past',
										active: pastEvents,
										onSelect: () => onPastEventsSwitch(true)
									},
									{
										label: t('representation.toggle.upcoming'),
										icon: 'event-upcoming',
										active: !pastEvents,
										onSelect: () => onPastEventsSwitch(false)
									}
								]}
							/>
							<label className="sort-label">{t('actions.sort')}</label>
							<SortLabel
								sortParam={sortParam}
								onChange={(v) => setSortParam(v)}
							/>
						</div>
					</div>
					<div className="data-header d-flex flex-column d-md-none">
						<div className="d-flex justify-content-between">
							<div className="count">
								<span className="number">{representationsCount}</span>
								<span className="label">{`${t('representation.count-label')}${representationsCount > 1 ? 's' : ''}`}</span>
							</div>
							<div className="d-flex">
								<SortLabel
									sortParam={sortParam}
									onChange={(v) => setSortParam(v)}
								/>
							</div>
						</div>
					</div>
					<Row>
						{ representations?.length > 0 ? (
							representations.map((r: Representation) => (
								<Col key={`rep-${r.representationId}`} sm={12} md={6}>
									<RepresentationCard
										item={r}
										societyId={societyId}
									/>
								</Col>
							))
						) : (
							<div className="empty-data">
								{pastEvents ? t('representation.empty.past') : t('representation.empty.upcoming')}
							</div>
						)}
					</Row>
					<Row>
						<Pagination
							currentPage={currentPage}
							totalElements={representationsCount}
							elementsPerPage={DEFAULT_SIZE_PER_PAGE}
							onPageClick={onPageClick}
						/>
					</Row>
				</Container>
			)}
			<ToastContainer position="top-center">
				<Toast className="toast-ctr" show={showToast}
							 onClose={() => {
								 searchParams.delete('toast');
								 searchParams.delete('toast-before-date');
								 setSearchParams(searchParams);
								 setShowToast(false);
							 }}
							 delay={5000} autohide>
					<Toast.Body className="success">{contentToast}</Toast.Body>
				</Toast>
			</ToastContainer>
		</>
	);
};
const mapStateToProps = (state: AppPropsType) => ({
	representations: state.representations,
	appcontext: state.appContext
});
export default connect(mapStateToProps)(Events);

interface Props {
	representations: RepresentationsState,
	appcontext: AppContextState
}
