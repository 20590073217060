import React from 'react';
import './index.scss';

interface Props {
  items: Array<{
    label: string
    icon: string
    active: boolean
    onSelect: () => void
  }>
}

export default function FilterToggle(props: Props) {
	const { items } = props;
	return (
		<div className="toggle-container">
			{ items.map(i => (
				<div
					key={`toggle-${i.icon}`}
					className={`toggle-item ${i.active ? 'active' : ''}`}
					onClick={() => !i.active ? i.onSelect() : null}
				>
					<span className={`icon-toggle-${i.icon} ${i.active ? 'active' : ''}`}>{i.label}</span>
				</div>
			))}
		</div>
	);
}
