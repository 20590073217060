import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthConsumer } from '../../../../oidcAuthentication/authProvider';

import './loginButton.scss';
import { ROUTES } from '../../../../constants';

const LoginButton = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function goToDashboard() {
		navigate(ROUTES.LANDING);
	}

	return (
		<AuthConsumer>
			{({ isAuthenticated, signinRedirect }) => {
				return (
					<div
						className="login-btn"
						onClick={() => !isAuthenticated() ? signinRedirect() : goToDashboard()}
					>
						<span className={isAuthenticated() ? 'portal-access' : 'login-label'}>
							{isAuthenticated() ? t('home.portal-access') : t('home.login')}
						</span>
					</div>
				);
			}}
		</AuthConsumer>
	);
};

export default LoginButton;
