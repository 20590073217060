import React from 'react';
import { HomePage } from '../pages/homePage';
import SetListPage from '../pages/setListsPage';
import CreateProgram from '../pages/createProgram';
import Events from '../pages/representations';
import LandingPage from '../pages/landingPage';
import ProgramsDetail from "../pages/programDetails";
import RepresentationsDetails from "../pages/representationDetails";
import CreateDate from '../pages/createDate/create-date';
import UsageData from '../pages/usageData';
import DetailsPerIDE12 from '../pages/usageData/detailsPerIDE12'
import ErrorPage from "../components/error";
import { ROUTES, ROLES } from '../constants';
import { route } from '../types';
import PrivacyCookiesPolicy from '../pages/privacyCookiesPolicy';
import DistributionRoyalties from '../pages/distributionRoyalties';
import { Kpis } from '../pages/kpis';

const routes: route[] = [
	{
		path: '/',
		component: <HomePage />,
		public: true,
		pageTitle: 'page.title.home'
	},
	{
		path: ROUTES.HOME,
		component: <HomePage />,
		public: true,
		pageTitle: 'page.title.home'
	},
	{
		path: ROUTES.LANDING,
		component: <LandingPage />,
		pageTitle: 'page.title.dashboard',
		public: false,
	},
	{
		path: ROUTES.GET_SETLISTS,
		component: <SetListPage />,
		pageTitle: 'page.title.check-setlists',
		public: false
	},
	{
		path: ROUTES.CREATE_SETLIST,
		component: <CreateProgram />,
		pageTitle: 'page.title.create-setlist',
		public: false
	},
	{
		path: ROUTES.GET_EVENTS,
		component: <Events />,
		pageTitle: 'page.title.check-events',
		public: false
	},
	{
		path: ROUTES.GET_EVENT_DETAILS,
		component: <RepresentationsDetails />,
		pageTitle: 'page.title.event-detail',
		public: false
	},
	{
		path: ROUTES.GET_SETLIST_DETAILS,
		component: <ProgramsDetail />,
		pageTitle: 'page.title.program-detail',
		public: false
	},
	{
		path: ROUTES.CREATE_EVENT,
		component: <CreateDate />,
		pageTitle: 'page.title.create-event',
		public: false
	},
	{
		path: ROUTES.USAGE_DATA,
		component: <UsageData />,
		pageTitle: 'page.title.usage-data',
		public: false,
		confKey: "USAGE_DATA",
		role: ROLES.WEBSO_WEB_ONLINE,
	},
	{
		path: ROUTES.USAGE_DATA_DETAILS,
		component: <DetailsPerIDE12 />,
		public: false,
		pageTitle: 'usage-data-details-title',
		confKey: "USAGE_DATA",
		role: ROLES.WEBSO_WEB_ONLINE,
	},
	{
		path: ROUTES.DISTRIBUTION_ROYALTIES,
		component: <DistributionRoyalties />,
		pageTitle: 'distribution-royalties.title',
		public: false,
		confKey: "DISTRIBUTION_ROYALTIES",
		role: ROLES.WEBSO_WEB_ONLINE,
	},
	{
		path: ROUTES.KPI,
		component: <Kpis />,
		pageTitle: 'KPIs.header-title',
		public: false,
		confKey: "KPI",
		role: ROLES.WEBSO_WEB_ONLINE,
	},
	{
		path: '/privacy-cookies-policy',
		component: <PrivacyCookiesPolicy />,
		pageTitle: 'page.title.privacy-cookies-policy',
		public: true
	},
	{
		path: '/403',
		component: <ErrorPage errorCode={403} />,
		pageTitle: 'error-page.403.title',
		public: true
	},
	{
		path: '/500',
		component: <ErrorPage errorCode={500} />,
		pageTitle: 'error-page.500.title',
		public: true
	},
	{
		path: '*',
		component: <ErrorPage errorCode={404} />,
		pageTitle: 'error-page.404.title',
		public: true
	},
];

export default routes;
