export const PlatformColors: string[] = [
  '#4aa5eb',
  '#f9d936',
  '#c576fb',
  '#258d36',
  '#d8138e',
  '#fd8b2f',
  '#6edaa6',
  '#ee9d88',
  '#d133f2',
  '#0c59be',
]
export const HistogrammeColors: string[] = [
  'rgb(255,0,0)',
  'rgb(255,128,0)',
  'rgb(255,255,0)',
  'rgb(0,255,0)',
  'rgb(0,255,255)',
  'rgb(0,0,255)',
  'rgb(255,0,255)',
  'rgb(0,0,0)',
  'rgb(255,0,127)',
]
export const DspsColors: { [key: string]: string } = {
  // '7DIGITAL': '#',
  AMAZON: '#003CB0',
  // ANGHAMI: '#',
  APPLE: '#F08345',
  BEATPORT: '#01FF95',
  // CARBEC: '#',
  // CHORDIFY: '#',
  DEEZER: '#9C2CE1',
  // DORIANEFILMS: '#',
  // ECINEMA: '#',
  FACEBOOK: '#4606CC',
  // FREE: '#',
  // GISMART: '#',
  GOOGLE: '#FF0000',
  // IDOL: '#',
  // JANGADA: '#',
  // LUCKYVOICE: '#',
  // MEILLEURDUCINEMA: '#',
  // MELODYVR: '#',
  // MIXCLOUD: '#',
  // MMW: '#',
  NAPSTER: '#005CAB',
  // ORANGE: '#',
  // PRIMEPHONICBV: '#',
  QOBUZ: '#020004',
  // RAKUTEN: '#',
  RECISIO: '#9ECA41',
  SOUNDCLOUD: '#F7830F',
  SOUNDTRACKYOURBRAND: '#79FFE3',
  SPOTIFY: '#1CCC5B',
  // STARMAKERINTERACTIVE: '#',
  // THESONGSLABORATORY: '#',
  // TRAXSOURCE: '#',
  VEVO: '#F80130',
  // WAKANIM: '#',
  WIMP: '#669A31',
  YOUSICIAN: '#75CB31',
  // ZOUNDI: '#',
}

/*export const colorTicks: {
  [key: string]: string
} = {
  color: '#12164A',
}

export const colorGrid: {
  [key: string]: string
} = {
  color: '#BBC2D4',
}

export const borderColorGrid: {
  [key: string]: string
} = {
  borderColor: '#12164A',
}*/

export const PlatformColorsMap: {
  [key: string]: string
} = {
  // Below are the 'stop-color' used in the SVG
  SFY: '#16BBC5',
  YOUTUBE: '#FF0000',
  DZR: '#9C2CE1',
  FCE: '#4606CC',
  AMZ: '#003CB0',
  ITU: '#CF68FF',
  QOBUZ: '#020004',
  NPH: '#30B392',
  WIMP: '#669A31',
  YOUSI: '#75CB31',
  GOO: '#F1FF4A',
  APPLEMUSIC: '#F08345',
}

export const colorTicks: {
  [key: string]: string
} = {
  color: '#12164A',
}

export const colorGrid: {
  [key: string]: string
} = {
  color: '#BBC2D4',
}

export const borderColorGrid: {
  [key: string]: string
} = {
  borderColor: '#12164A',
}
