import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { BeatLoader } from 'react-spinners'
import { formatCurrencyNumber } from '../../../helpers/formatter'

const override: CSSProperties = {
  margin: '0 auto',
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
}
const TotalAmountBox = ({
  currentBalanceStatus,
  currentBalance,
}: TotalAmountBoxProps) => {
  const { t, i18n } = useTranslation()
  return (
    <div className='distribution-value-box justify-content-end '>
      {currentBalanceStatus === -1 ? (
        <BeatLoader
          speedMultiplier={1}
          size={15}
          color='white'
          loading={true}
          cssOverride={override}
        />
      ) : currentBalanceStatus === 200 ? (
        <div className='distribution-value-amount'>
          {formatCurrencyNumber(i18n.language, currentBalance)}{' '}
        </div>
      ) : (
        <div className='distribution-value-amount'>
          {t('distribution-royalties.boxes.amount.value', {
            amount: '-',
            currency: '€',
          })}{' '}
        </div>
      )}

      <div className='distribution-value-label  d-none d-lg-block'>
        {t('distribution-royalties.boxes.amount.title')}
      </div>
    </div>
  )
}

export default TotalAmountBox

interface TotalAmountBoxProps {
  currentBalanceStatus: number
  currentBalance: number
}
