export const DEFAULT_SIZE_PER_PAGE = 10;
export const EVENT_DISTRIBUTED_STATUS = [
	'Répartie',
	'DIS',
]
export const EVENT_CANCELLED_STATUS = [
	'Annulée',
	'CAN'
]
export const NOT_CANCELLABLE_EVENT = [
	...EVENT_DISTRIBUTED_STATUS,
	...EVENT_CANCELLED_STATUS,
	'Envoyée en Répartition',
	'PND',
]

export const ROUTES = {
	HOME: '/home',
	LANDING: '/welcome',
	GET_SETLISTS: '/setLists',
	GET_SETLIST_DETAILS: '/setLists/:programId',
	CREATE_SETLIST: '/new-program',
	GET_EVENTS: '/events',
	GET_EVENT_DETAILS: '/events/:representationId',
	CREATE_EVENT: '/create-event',
	USAGE_DATA: '/usage-data',
	USAGE_DATA_DETAILS: '/usage-data/details',
	KPI: '/kpi',
	DISTRIBUTION_ROYALTIES: '/distribution-royalties',
	PRIVACY_COOKIES_POLICY: '/privacy-cookies-policy',
	ERROR_403: '/403',
	ERROR_500: '/500'
}

export const ROLES = {
	WEBSO_WEB_ONLINE: 'ROLE_WEBSO-WEB_ONLINE',
	GLOBAL_SOCIETE_SOEUR: 'ROLE_GLOBAL_SocieteSoeur'
}