import React from 'react'
import { useAppSelector } from '../../../store/hooks'
import { Col, Container, Row } from 'react-bootstrap'
import BoxSectionComponent from '../../../components/boxSectionComponent'
import KpiStackedBarsComponentBox from '../commonComponents/KpiStackedBarsComponent'
import KpiLineChartComponentBox from '../commonComponents/KpiLineChartsComponent'
import { t } from 'i18next'
import { statusMessages } from '../../../utils/statusMessages'
import { ExploitationPeriod } from '../../../types/distributionsRoyaltiesTypes'
import { LineChartInputData, StringDateRange } from '../../../types/marketShareTypes'

const MarketKpi = () => {

    const renderDataBody = (
        barData: ExploitationPeriod[],
        lineData: LineChartInputData[],
        status: number,
        graphQuarterInterval: StringDateRange,
        dataType: 'currency' | 'int',
        i18nMarker: string,
        dataFormStyle: 'bars' | 'lines'
    ) => {
        return (dataFormStyle === 'bars' ?
        <KpiStackedBarsComponentBox
            data={barData}
            dataType={dataType}
            i18marker={i18nMarker}
            quarterInterval={graphQuarterInterval}
        /> :
        <KpiLineChartComponentBox
          quarterInterval={graphQuarterInterval}
          lineChartData={lineData}
          lineChartStatus={status}
          dataFormat={dataType}
          i18marker={'market-kpi.' + i18nMarker}
        />)
    }

    const renderDataBlock = (
        barData: ExploitationPeriod[],
        lineData: LineChartInputData[],
        status: number,
        graphQuarterInterval: StringDateRange,
        dataType: 'currency' | 'int',
        i18nMarker: string,
        dataFormStyle: 'bars' | 'lines'
    ) => {
        return <div style={{height: '560px'}}>
            <BoxSectionComponent
                title={t(
                    'market-kpi.' + i18nMarker + '.title'
                )}
                subtitle={t(
                    'market-kpi.' + i18nMarker + '.subtitle'
                )}
                bodyContent={renderDataBody(barData, lineData, status, graphQuarterInterval, dataType, i18nMarker, dataFormStyle)}
                status={{
                    statusCode: status,
                    defaultMessage: statusMessages.defaultMessage,
                    noDataFoundMessage: statusMessages.noDataFoundMessage,
                    unexpectedErrorMessage: statusMessages.unexpectedErrorMessage
                }}
            />
        </div>
    }

    const declaredRevenueData = useAppSelector(
        (state) => state.marketShare.marketKpisData.declaredRevenue
    )

    const declaredRevenueStatus = useAppSelector(
        (state) => state.marketShare.status.marketKpis.declaredRevenue
    )

    const numberOfStreamsData = useAppSelector(
        (state) => state.marketShare.marketKpisData.numberOfStreams
    )

    const numberOfStreamsStatus = useAppSelector(
        (state) => state.marketShare.status.marketKpis.numberOfStreams
    )

    const numberOfUsersData = useAppSelector(
        (state) => state.marketShare.marketKpisData.numberOfUsers
    )

    const numberOfUsersStatus = useAppSelector(
        (state) => state.marketShare.status.marketKpis.numberOfUsers
    )

    const avgDeclaredRevenuePerUserData = useAppSelector(
        (state) => state.marketShare.marketKpisData.avgDeclaredRevenuePerUser
    )

    const avgDeclaredRevenuePerUserStatus = useAppSelector(
        (state) => state.marketShare.status.marketKpis.avgDeclaredRevenuePerUser
    )

    const graphQuarterInterval = useAppSelector(
        (state) => state.marketShare.lastSearchValues.date.marketKpi
    )

    return (
        <Container>
            <Row style={{ marginTop: '50px' }}>
                <Col md={6}>
                    {renderDataBlock(declaredRevenueData, [], declaredRevenueStatus, graphQuarterInterval, 'currency', 'declared-revenue', 'bars')}
                </Col>
                <Col md={6}>
                    {renderDataBlock(numberOfStreamsData, [], numberOfStreamsStatus, graphQuarterInterval, 'int', 'streams-per-commercial-models', 'bars')}
                </Col>
            </Row>
            <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
                <Col md={6}>
                    {renderDataBlock(numberOfUsersData, [], numberOfUsersStatus, graphQuarterInterval, 'int', 'number-of-users', 'bars')}
                </Col>
                <Col md={6}>
                    {renderDataBlock([], avgDeclaredRevenuePerUserData, avgDeclaredRevenuePerUserStatus, graphQuarterInterval, 'currency', 'average-declared-revenue-per-user', 'lines')}
                </Col>
            </Row>
        </Container>
    )
}

export default MarketKpi