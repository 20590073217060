import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

//@ts-ignore
import iconInterestedPartyName from '../../assets/img/icon-interested-party-name.svg';
//@ts-ignore
import IconInterestedPartyIpi from '../../assets/img/icon-interested-party-ipi.svg';

import './index.scss';

interface Props {
  items: any;
  index: string;
  editorDisplay?: boolean;
	ipiDisplay?: boolean;
}

const TimeLine = (props: Props) => {
	const { items, index, editorDisplay, ipiDisplay } = props;
	const { t } = useTranslation();

	const renderItems = () => {
		return items.map((item: any, index) => {
			return (
				<Row className="event align-items-center" key={index}>
					<Col xs={8} className="font-data display-flex">
						<img src={iconInterestedPartyName} className="timeline" />
						<div className="col-11 timeline-name">
							{`${item.firstName ? item.firstName : ''} ${item.lastName}`}
						</div>
					</Col>

					<Col xs={4} className="role-label">
						<div>
							{t(`page.new-program.step-three.translations-details.${item.roleTypeForTranslate}`)}
						</div>
					</Col>

					{item.ipiCode !== null && item.ipiCode != 0 && (
						<>
							<Col xs={8}>
								<img
									src={IconInterestedPartyIpi}
									className="timeline-ipi-icon"
								/>
								<span className="timeline-ipi-text">{item.ipiCode}</span>
							</Col>
							<Col xs={4} className="role-label">
								<div>
									{t(`page.new-program.step-three.ipi-code`)}
								</div>
							</Col>
						</>
					)}
				</Row>
			);
		});
	};

	const renderIpiItems = () => {
		return items.map((item: any, index) => {
			return (
				<Row className="event align-items-center" key={index}>
					<Col xs={8} className="font-data display-flex">
						<img src={iconInterestedPartyName} className="timeline" />
						<div className="col-11 timeline-name">
							{item.ipiName}
						</div>
					</Col>

					<Col xs={4} className="role-label">
						<div>
							{t(`page.new-program.step-three.translations-details.CMP`)}
						</div>
					</Col>

					{item.ipiCode !== null && item.ipiCode != 0 && (
						<>
							<Col xs={8}>
								<img
									src={IconInterestedPartyIpi}
									className="timeline-ipi-icon"
								/>
								<span className="timeline-ipi-text">{item.ipiCode}</span>
							</Col>
							<Col xs={4} className="role-label">
								<div>
									{t(`page.new-program.step-three.ipi-code`)}
								</div>
							</Col>
						</>
					)}
				</Row>
			);
		});
	};
	const renderEditorsItems = () => {
		return items.map((item: any, index) => {
			return (
				<li className="event d-flex flex-column mb-4" key={index}>
					<div className="font-data">{item.corporateName}</div>
					<div className="font-data">{item.address}</div>
				</li>
			);
		});
	};
	return (
		<div className="interested-parties-body" key={index}>
			<div id={`content-${index}`}>
				{ipiDisplay ? renderIpiItems() : editorDisplay ? renderEditorsItems() : renderItems()}
			</div>
		</div>
	);
};

export default TimeLine;