import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';


export const ReferencedWorkValidation = (t) => {
	const invalidDurationLabel = t('find-oeuvre.non-referenced-work.errors.invalid-duration');

	return Yup.object().shape({
		duration: Yup.string()
			.matches(/^\d{2}:\d{2}$/, invalidDurationLabel)
			.required(invalidDurationLabel),
	});
};

export const NonReferencedWorkValidation = (t) => {
	const invalidDurationLabel = t('find-oeuvre.non-referenced-work.errors.invalid-duration');
	const emptyFieldLabelKey = 'errors.empty-field';

	return Yup.object().shape({
		title: Yup.string().trim()
			.required(t(emptyFieldLabelKey, { field: 'the work title' }))
			.max(50, t('errors.title-max-length')),
		duration: Yup.string()
			.matches(/^\d{2}:\d{2}$/, invalidDurationLabel)
			.notOneOf(['00:00'], t('find-oeuvre.non-referenced-work.errors.equals-zero'))
			.required(invalidDurationLabel),
		workISWC: Yup.string()
			.notOneOf(['T-000 000 000-0'], t('find-oeuvre.non-referenced-work.errors.iswc-not-accepted-format'))
			.matches(
				/^T-\d{3} \d{3} \d{3}-\d$/,
				t(emptyFieldLabelKey, { field: 'ISWC code' })
			)
			.required(t(emptyFieldLabelKey, { field: 'ISWC code' }))
		,
		ipiCodes: Yup.array()
			.required(t(emptyFieldLabelKey, { field: "IPI code" })),
	});
};
