import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import '../../index.scss';

const DynamicField = (props: Props) => {
	const { formik, fieldName, label, multipleFields = false, className = '', placeholder = '' } = props;
	const [formValues, setFormValues] = useState(formik.values.multiStage);
	const { t } = useTranslation();
	const [addingDisabled, setAddingDisabled] = useState(true);

	useEffect(() => {
		const emptyValues = formValues.filter((item:{value:string}) => !item.value);
		emptyValues.length ? setAddingDisabled(true) : setAddingDisabled(false);
	}, [formValues]);
	const addFormField = () => {
		setFormValues([...formValues, { value: '' }]);
		setAddingDisabled(true);
	};
	const removeFormFields = (i: number) => {
		const newFormValues = [...formValues];
		newFormValues.splice(i, 1);
		setFormValues(newFormValues);
		formik.setFieldValue('multiStage', newFormValues);
	};
	const handleChange = (i: number, e: any) => {
		const newFormValues = [...formValues];
		newFormValues[i]['value'] = e.target.value;
		setFormValues(newFormValues);
		formik.setFieldValue('multiStage', newFormValues);
	};
	const handleChangeDynamicField = (e: any) => {
		formik.setFieldValue(fieldName, e.target.value);
	};

	function renderFields(element: string, index: number) {
		return index < 5 && (
			<div className="" key={index}>
				<Form.Group className="mb-4">
					<Form.Label className="label-spacing">{label} </Form.Label>
					<InputGroup>
						<Form.Control
							className={`form-control ${className}`}
							id={`multiStage${index}`}
							name={`multiStage${index}`}
							type="text"
							placeholder={placeholder}
							onChange={e => handleChange(index, e)}
							value={formik.values.multiStage[index] ? formik.values.multiStage[index]['value'] : ''}
						/>
						{index > 0 &&
				<Button variant="outline-secondary"
					id="button-addon2"
					className="btn-delete"
					disabled={index < 1}
					onClick={() => removeFormFields(index)}>
				</Button>
						}
					</InputGroup>
				</Form.Group>
			</div>
		);
	}

	return (
		<>
			{multipleFields ? (
				formValues.map(renderFields)
			) :
				(<><Form.Group className="mb-4">
					<Form.Label className="label-spacing">{label} </Form.Label>
					<Form.Control
						className={`form-control ${className} 
                      ${fieldName === 'onePart' && formik.errors.onePart && formik.touched.onePart && 'is-invalid'}`}
						id={fieldName}
						name={fieldName}
						type="text"
						placeholder={placeholder}
						onChange={handleChangeDynamicField}
						value={formik.values[fieldName]}
					/>
				</Form.Group>
				{fieldName === 'onePart' && formik.errors.onePart && formik.touched.onePart && (
					<div className="fade error-field show">{formik.errors.onePart}</div>)
				}
				</>)
			}
			{multipleFields && formValues.length < 5 &&
				<button type="button"
					disabled={addingDisabled}
					className="btn-secondary-add"
					onClick={addFormField}>+ &nbsp;&nbsp;&nbsp;&nbsp;{t('form.btn.add-artist')}
				</button>
			}
		</>
	);
};

export default DynamicField;

interface Props {
  formik: any
  label: string
  fieldName: string
  multipleFields?: boolean
  className?: string
  placeholder?: string
}