import { DraftsPayload, ProgramParam } from '../types';
import { httpCaller } from './httpCaller';

export const fetchDrafts = (params: ProgramParam) => {
	const { filter, pagination, societyId } = params;
	return new Promise<DraftsPayload>((resolve, reject) => {
		httpCaller
			.get(`/societies/${societyId}/works-list`, {
				params: {
					page: pagination?.page,
					size: pagination?.size,
					worksListTitle: filter?.title,
				},
			})
			.then((response) => {
				if( response.status === 200){
					return resolve(response.data);
				}else if(response.status === 204){
					return resolve({
						worksLists: [],
						worksListsCount: 0
					});
				}
				return null;
			})
			.catch((error) => {
				console.error('API error', error);
				return reject(error);
			});
	});
};

export const deleteWorkList = (worksListId: number, societyId: number) => {
	const url = `/societies/${societyId}/works-list/${worksListId}`;

	return new Promise<number>((resolve, reject) =>
		httpCaller
			.delete(url)
			.then((response) => {
				return resolve(response.status);
			})
			.catch((error) => {
				return reject(error);
			})
	);
};
