import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import {
  Col,
  Row,
  Button,
  InputGroup,
  Alert,
  OverlayTrigger,
  Tooltip,
  Badge,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BeatLoader } from 'react-spinners'
import Form from 'react-bootstrap/Form'
import { useNavigate, useLocation } from 'react-router'
import 'chartjs-adapter-moment'
import countries from 'i18n-iso-countries'
import { Chart, ChartDataset, registerables } from 'chart.js'

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { UsageDataStreamPerWorkDetails, Works } from '../../types'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
  getXOptions,
  transformData as preTransformData,
} from './worksRankingUtils'
import {RootState} from "../../store/store";
import {fetchCustomSearchStreamPerWorkAsync, fetchUsageDataWorkSearchAsync} from "../../slices/usageDataSlice";
import LineChartComponent from "../lineChart";
import StreamPerWorksLegend from "./streamPerWorksLegend";
import { ROUTES } from '../../constants';

import './worksRanking.scss'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

Chart.register(...registerables)

interface DisplayedItem {
  label: string
  code: number
  hidden: boolean
  artists: string[]
  displayAllArtists: boolean
}

const StreamPerWorks = () => {
  const dispatch = useAppDispatch()
  const location:any = useLocation()

  const lastSearchValues = useAppSelector(
    (state) => state.usageData.lastSearchValues
  )
  const loadingStatus = useAppSelector(
    (state) => state.usageData.status.streamPerWork
  )
  const customWorkSearchStatus = useAppSelector(
    (state) => state.usageData.status.customSearchStreamPerWorks
  )
  const streamPerWork = useAppSelector((state) => state.usageData.streamPerWork)
  const customSearchWork = useAppSelector(
    (state) => state.usageData.customSearchStreamPerWorks
  )
  const mandatorId = useAppSelector((state: RootState) => state.appContext.mandator)
  const sisterSocietyEntityData = useAppSelector((state) => state.appContext.selectedSisterSociety)
  const sisterSocietyEntityName = sisterSocietyEntityData?.name
  const societyId = useAppSelector((state) => state.appContext.selectedSisterSociety.sisterSocietyId)
  const selectedRepertoire = useAppSelector(
    (state) => state.appContext.selectedRepertoire
  )

  const searchedWorks = useAppSelector((state) => state.usageData.works)
  const searchedWorksStatus = useAppSelector(
    (state) => state.usageData.status.works
  )
  const usageDataSearchParams = useAppSelector(
    (state) => state.usageData.usageDataSearchParams
  )

  const { screenWidth } = useWindowDimensions()
  const navigate = useNavigate();
  const { t } = useTranslation()
  const [worksStates, setWorksStates] = useState<DisplayedItem[]>([])
  const [isMobile, setIsMobile] = useState<boolean>()
  const [isDisable, setIsDisable] = useState<boolean>(false)
  const [customSearchWorksStates, setCustomSearchWorksStates] = useState<
    DisplayedItem[]
  >([])

  const [showCustomSelection, setShowCustomSelection] = useState<boolean>(
    // @ts-ignore
    location?.state?.showCustomSelection !== undefined ? location?.state?.showCustomSelection
      : false
  )
  const [showSearchWorkBlock, setShowSearchWorkBlock] = useState<boolean>(false)
  const [workSearchKey, setWorkSearchKey] = useState<string>(
    location?.state?.workSearchKey ? location.state?.workSearchKey : ''
  )
  const [selectedWorks, setSelectedWorks] = useState<Works[]>(
    location.state?.selectedWorks ? location.state?.selectedWorks : []
  )
  const [lastSearchedSelectedWorks, setLastSearchedSelectedWorks] = useState<
    Works[]
    >(location.state?.selectedWorks ? location.state?.selectedWorks : [])
  const [lastSearchedWorkSearchKey, setLastSearchedWorkSearchKey] =
    useState<string>(
      location.state?.selectedWorks ? location.state?.selectedWorks : []
    )

  const ref = useRef<HTMLDivElement>(null)
  function transformData(
    platformData: UsageDataStreamPerWorkDetails,
    idx: number
  ): ChartDataset<'line', { x: string; y: number }[]> {
    const ret = preTransformData(platformData, idx)
    ret.hidden =
      worksStates?.find((work) => work.code === platformData.ide12)?.hidden ||
      false
    return ret
  }

  function transformCustomSearchData(
    platformData: UsageDataStreamPerWorkDetails,
    idx: number
  ): ChartDataset<'line', { x: string; y: number }[]> {
    const ret = preTransformData(platformData, idx)
    ret.hidden =
      customSearchWorksStates?.find((work) => work.code === platformData.ide12)
        ?.hidden || false
    return ret
  }

  useEffect(() => {
    if (loadingStatus !== 200 && showCustomSelection) {
      setShowCustomSelection(false)
      setShowSearchWorkBlock(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingStatus])

  useEffect(() => {
    setIsMobile(screenWidth < 770 ? true : false)
  }, [screenWidth])

  useEffect(() => {
    setIsMobile(screenWidth < 770 ? true : false)
  }, [screenWidth])

  useEffect(() => {
    setWorksStates(
      (streamPerWork?.items || []).map((item) => {
        return {
          code: item.ide12,
          label: item.title,
          hidden: false,
          artists: item.artists,
          displayAllArtists: false,
        }
      })
    )
  }, [streamPerWork])

  useEffect(() => {
    setCustomSearchWorksStates(
      (customSearchWork?.items || []).map((item) => {
        return {
          code: item.ide12,
          label: item.title,
          hidden: false,
          artists: item.artists,
          displayAllArtists: false,
        }
      })
    )
  }, [customSearchWork])

  useEffect(() => {
    if (ref.current != null && loadingStatus != 0) {
      window.scrollTo({
        top: ref.current.offsetTop - 40,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [showCustomSelection, customWorkSearchStatus])


  const openDetails = (displayedItem: DisplayedItem) => {
    navigate(
      ROUTES.USAGE_DATA_DETAILS,
      {
        state: {
          mandatorId: mandatorId,
          territoryCode: lastSearchValues?.territories[0],
          dsp: lastSearchValues?.dsp,
          startDate: lastSearchValues?.usageDataDate.startingDate,
          endDate: lastSearchValues?.usageDataDate.endingDate,
          ide12: displayedItem.code,
          title: displayedItem.label,
          showCustomSelection: showCustomSelection,
          selectedWorks: selectedWorks,
          searchedWorks: searchedWorks,
          workSearchKey: workSearchKey,
        },
      }
    )
  }

  const handleSearchWorks = () => {
    if (
      workSearchKey.trim() !== '' &&
      workSearchKey !== lastSearchedWorkSearchKey
    ) {
      setLastSearchedWorkSearchKey(workSearchKey)
      dispatch(
        fetchUsageDataWorkSearchAsync({
          sisterSocietyEntityName,
          // @ts-ignore
          startDate: usageDataSearchParams.usageDataDate.startingDate.slice(0, 6),
          endDate: usageDataSearchParams.usageDataDate.endingDate.slice(0, 6),
          dsp: usageDataSearchParams.dsp,
          territories: countries.numericToAlpha2(usageDataSearchParams.territories[0]),
          work: workSearchKey,
          repertoireId: selectedRepertoire,
          societyId: societyId,
        })
      )
      setShowSearchWorkBlock(true)
    } else {
      setShowSearchWorkBlock(
        searchedWorksStatus === 200 ||
        selectedWorks.length > 0 ||
        searchedWorks.length > 0
      )
    }
  }

  const updateSelectedWorksHandler = (work: Works) => {
    if (selectedWorks.filter((w) => w.ide12 === work.ide12).length > 0) {
      setSelectedWorks(
        selectedWorks.filter((item) => item.ide12 !== work.ide12)
      )
    } else if (selectedWorks.length < 10) {
      setSelectedWorks([...selectedWorks, work])
    }
  }

  const cancelWorkSearchHandler = () => {
    setWorkSearchKey(lastSearchedWorkSearchKey)
    setSelectedWorks(lastSearchedSelectedWorks)
    setShowSearchWorkBlock(false)
  }

  const customSearchLunchHandler = () => {
    if (
      selectedWorks.length > 0 &&
      (selectedWorks !== lastSearchedSelectedWorks ||
        customWorkSearchStatus === 500)
    ) {
      setLastSearchedSelectedWorks(selectedWorks)

      dispatch(
        fetchCustomSearchStreamPerWorkAsync({
          sisterSocietyEntityName,
          startDate: usageDataSearchParams.usageDataDate.startingDate.slice(
            0,
            6
          ),
          endDate: usageDataSearchParams.usageDataDate.endingDate.slice(0, 6),
          dsp: usageDataSearchParams.dsp,
          // @ts-ignore
          territories: [countries.numericToAlpha2(usageDataSearchParams.territories[0])],
          ide12: selectedWorks.map((w) => w.ide12),
        })
      )
    }
    setShowSearchWorkBlock(false)
  }

  const customSelectionSearchItem = (work: Works) => {
    return <OverlayTrigger
      placement={'right'}
      delay={{
        show: 250,
        hide: 400,
      }}
      overlay={
        <Tooltip className='custom-tooltip' id='button-tooltip'>
          {work.tiers.map((elem: string, index: number) => {
            if (index > 0)
              return <div
                  style={{
                    overflowX: 'clip',
                    textOverflow: 'ellipsis',
                    maxWidth: '300px',
                  }}
                  className={'text-start px-2 text-nowrap'}
                  key={`${elem}-${index}`}
                >
                  {elem.toUpperCase()}
                </div>
          })}
        </Tooltip>
      }
    >
      <Badge
        bg={'secondary'}
        pill
        className='ms-2 badge-icon'
        text={'light'}
        style={{
          background: '#12164a !important',
        }}
      >
        {'+ ' + (work.tiers.length - 1)}
      </Badge>
    </OverlayTrigger>
  }
  return (
    <div className="works-ranking">
      {lastSearchValues != null &&
        lastSearchValues.territories.length === 1 &&
        loadingStatus !== 0 &&
				<>
          {loadingStatus === 200 && (
            <Row>
              <Col xs={12} lg={4}>
                <div className={'work-search-switch py-2 ps-3 pe-1 m-2'}>
                  <span
                    className={
                      'top-works py-1 d-inline-block text-center px-4 ' +
                      (!showCustomSelection ? ' active' : '')
                    }
                    onClick={() => {
                      setShowSearchWorkBlock(false)
                      setShowCustomSelection(false)
                    }}
                  >
                    Top 10
                  </span>
                  <span
                    className={
                      'custom-work-search ms- py-1 d-inline-block text-center px-3 ' +
                      (showCustomSelection ? 'active' : '')
                    }
                    onClick={() => setShowCustomSelection(true)}
                  >
                    Custom selection
                  </span>
                </div>
              </Col>
              <Col xs={12} lg={8}>
                {showCustomSelection && (
                  <>
                    <InputGroup className='px-2 my-2 d-flex'>
                      <Form.Control
                        placeholder={t(
                          'usage_data.stream-per-works.custom-search.search-placeholder'
                        )}
                        aria-label={t(
                          'usage_data.stream-per-works.custom-search.search-placeholder'
                        )}
                        aria-describedby='basic-addon2'
                        className={'work-search-input'}
                        value={workSearchKey}
                        onChange={(e) => {
                          if (showCustomSelection)
                            setShowSearchWorkBlock(false)
                          setWorkSearchKey(e.target.value)
                        }}
                        disabled={
                          searchedWorksStatus === -1 ||
                          customWorkSearchStatus === -1
                        }
                      />
                      <Button
                        variant='outline-secondary'
                        id='button-addon2'
                        className={'work-search-button text-center'}
                        onClick={handleSearchWorks}
                        disabled={
                          searchedWorksStatus === -1 ||
                          customWorkSearchStatus === -1 ||
                          isDisable
                        }
                      >
                        <i className='fa fa fa-search fa-lg fa-inverse'></i>
                      </Button>
                    </InputGroup>
                    {showSearchWorkBlock && (
                      <div className={'work-search-list d-block'}>
                        {searchedWorksStatus === -1 ? (
                          <BeatLoader
                            speedMultiplier={1}
                            size={15}
                            color='#563D7C'
                            loading={true}
                            //cssOverride={override}
                          />

                        ) : searchedWorksStatus !== 200 &&
                        searchedWorksStatus !== 204 ? (
                          <>
                            <Alert
                              variant={'danger'}
                              className={'ms-2 mb-0 py-1 text-center'}
                            >
                              {t('errors.error-occure-try-again')}
                            </Alert>
                            <div
                              className={
                                'search-button d-flex justify-content-end'
                              }
                            >
                              <button
                                className={'cancel'}
                                onClick={cancelWorkSearchHandler}
                              >
                                Close
                              </button>
                            </div>
                          </>
                        ) : searchedWorksStatus === 204 ? (
                          <>
                            <Alert
                              variant={'danger'}
                              className={'ms-2 mb-0 py-1 text-center'}
                            >
                              {t('filters.infos.nodatafound')}
                            </Alert>

                            <div
                              className={
                                'search-button d-flex justify-content-end'
                              }
                            >
                              <button
                                className={'cancel'}
                                onClick={cancelWorkSearchHandler}
                              >
                                Close
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            {selectedWorks.length >= 10 && (
                              <Alert
                                variant={'danger'}
                                className={'py-1'}
                              >
                                You can choose at most 10 works
                              </Alert>
                            )}
                            <ul className='scroll-style'>
                              {searchedWorks &&
                              searchedWorks.length > 0 ? (
                                <>
                                  {selectedWorks.map(
                                    (work: Works, index: number) => (
                                      <li
                                        className="custom-item-searched"
                                        key={index}
                                        id={work.ide12}
                                        onClick={() =>
                                          updateSelectedWorksHandler(work)
                                        }
                                      >
                                        <span>
                                          {work.title.toLowerCase() +
                                            ' (' +
                                            work.ide12 +
                                            ')'}
                                        </span>
                                        <span
                                          style={{
                                            color: '#ac3383',
                                          }}
                                        >
                                          {work.tiers.length > 0 &&
                                            ' - ' +
                                            work.tiers[0].toUpperCase()}
                                        </span>

                                        {work.tiers.length > 1 &&
                                          customSelectionSearchItem(work)}
                                      </li>
                                    )
                                  )}
                                  {selectedWorks.length > 0 && (
                                    <>
                                      <div className='d-flex justify-content-end'>
                                        <div
                                          className='clear-all  p-0 ms-1 me-3'
                                          onClick={() =>
                                            setSelectedWorks([])
                                          }
                                        >
                                          {t(
                                            'usage_data.stream-per-works.custom-search.clear-searched-works'
                                          )}
                                        </div>
                                      </div>
                                      <hr className={'my-2'} />
                                    </>
                                  )}
                                  {searchedWorks
                                    .filter(
                                      (w) =>
                                        selectedWorks.filter(
                                          (item) => item.ide12 === w.ide12
                                        ).length === 0
                                    )
                                    .map((work: Works, index: number) => (
                                      <li
                                        key={index}
                                        id={work.ide12}
                                        onClick={() =>
                                          updateSelectedWorksHandler(work)
                                        }
                                      >
                                              <span>
                                                {work.title.toLowerCase() +
                                                  ' (' +
                                                  work.ide12 +
                                                  ')'}
                                              </span>
                                        <span
                                          className="spn-tiers"
                                        >
                                                {work.tiers.length > 0 &&
                                                  ' - ' +
                                                  work.tiers[0].toUpperCase()}
                                              </span>

                                        {work.tiers.length > 1 &&
                                          customSelectionSearchItem(work)}
                                      </li>
                                    ))}{' '}
                                </>
                              ) : (
                                <li>{`length : ${searchedWorks.length}`}</li>
                              )}
                            </ul>
                            <div
                              className={
                                'search-button d-flex justify-content-end'
                              }
                            >
                              <button
                                className={'apply'}
                                disabled={
                                  selectedWorks.length === 0
                                }
                                onClick={customSearchLunchHandler}
                              >
                                {t(
                                  'usage_data.stream-per-works.custom-search.button-apply'
                                )}
                              </button>
                              <button
                                className={'cancel'}
                                onClick={cancelWorkSearchHandler}
                              >
                                {t(
                                  'usage_data.stream-per-works.custom-search.button-cancel'
                                )}
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
              </Col>
            </Row>
          )}
					<Row>
						<LineChartComponent<UsageDataStreamPerWorkDetails>
							inputData={
                showCustomSelection
                  ? customSearchWork?.items || []
                  : streamPerWork?.items || []
              }
							loadingStatus={
                showCustomSelection ? customWorkSearchStatus : loadingStatus
              }
							togglableStates={
                showCustomSelection ? customSearchWorksStates : worksStates
              }
							transformerFunction={
                showCustomSelection
                  ? transformCustomSearchData
                  : transformData
              }
							xOptions={getXOptions(
                lastSearchValues.usageDataDate.startingDate,
                lastSearchValues.usageDataDate.endingDate
              )}
							defaultMessage={t(
                'usage_data.stream-per-works.graph.default'
              )}
							noDataFoundMessage={t(
                'usage_data.map_container.statusMessage.204'
              )}
							unexpectedErrorMessage={
                'usage_data.stream-per-works.graph.error'
              }
							width={15}
							height={5}
						/>
					</Row>
					<StreamPerWorksLegend
						status={
              showCustomSelection ? customWorkSearchStatus : loadingStatus
            }
						cradHeaderTitle={
              showCustomSelection
                ? t('usage_data.stream-per-works.custom-search.title')
                : !isMobile
                  ? t('usage_data.stream-per-works.top-10')
                  : t('usage_data.stream-per-works.top-10-responsive')
            }
						workData={showCustomSelection ? customSearchWork : streamPerWork}
						detailRedirection={openDetails}
						updateWorkStates={
              showCustomSelection ? setCustomSearchWorksStates : setWorksStates
            }
					/>
				</>}
    </div>
  )
}

export default StreamPerWorks
