import React, { useMemo } from 'react'
import {
  UsageDataStreamResponse,
  UsageDataStreamsPerDistributions,
} from '../../../types'
import { ChartDataset } from 'chart.js'
import { PlatformColors } from '../../../components/worksRanking/platformColors'
import { Col } from 'react-bootstrap'
import PageStatus from '../../../components/pageStatus'
import { StackedBarChart } from '../../../components/stackedBarChart'
import { getXOptions, getYOptions } from '../../../helpers/chartHelper'
import i18n, { t } from 'i18next'
import moment from 'moment'

interface UDStreamsPropTypes {
  data?: UsageDataStreamResponse
  status: number
}

export const UsageDataStreamsPerDistributionsStackedBarCard = ({
  data,
  status,
}: UDStreamsPropTypes) => {
  const stackedData: any = useMemo(() => {
    if (data == null || data.items.length === 0)
      return { labels: [], dataset: [] }

    const distinctMonths: any[] = [
      ...new Set(
        data.items.map((d: any) =>
          moment(d.month.toString(), 'YYYYMM').format('MMM YYYY')
        )
      ),
    ].sort((date1: any, date2: any): number => {
      const mdate1 = moment(date1, 'MMM YYYY')
      const mdate2 = moment(date2, 'MMM YYYY')
      if (mdate1 < mdate2) {
        return -1
      } else if (mdate1 > mdate2) {
        return 1
      }
      return 0
    })

    //all distributions
    let allDistributions: string[] = []
    data.items.map((item: UsageDataStreamsPerDistributions) => {
      item.distributions.forEach(
        (dist: { distribution: string; total: number }) => {
          allDistributions.push(dist.distribution)
        }
      )
    })
    //distinct distribution list
    const distinctDistributions = [...new Set(allDistributions)].sort()
    let mappedChartData: ChartDataset<'bar', number[]>[] = []

    distinctDistributions.forEach((distNumber: string, index: number) => {
      //list of streams of the distribution X for all months
      let distStreams: number[] = []
      let labelChart: string = ""
      distinctMonths.forEach((dMonth: any) => {
        const locatedElem = data.items.find(
          (elem: UsageDataStreamsPerDistributions) =>
            moment(elem.month, 'YYYYMM').format('MMM YYYY') === dMonth
        )

        const distPerMonth = locatedElem
          ? locatedElem.distributions.find(
              (distributions: { distribution: string; total: number }) =>
                distributions.distribution === distNumber
            )
          : undefined
        if(distPerMonth){
          const momentDate = moment(locatedElem.month, 'YYYYMM')
          const quarter = Math.floor((momentDate.month() + 1) / 3);
          labelChart = `${momentDate.year()}Q${quarter}`
        }
        //add streams for all months : if distribution exists on the month else 0.
        distStreams.push(distPerMonth ? distPerMonth.total : 0)
      })
      mappedChartData.push({
        label: labelChart,
        data: distStreams,
        borderRadius: 4,
        hidden: false,
        backgroundColor: PlatformColors[index % PlatformColors.length],
      })
    })
    return { labels: distinctMonths, dataset: mappedChartData }
  }, [data])

  return (
      <Col style={{
        height: '420px'
      }}>
        {status === 200 ? (
          stackedData.dataset.length > 0 ? (
            <StackedBarChart<{
              month: string
              percentage: number
              amount: number
            }>
              labels={stackedData.labels}
              datasets={stackedData.dataset}
              xOptions={getXOptions({
                lang: i18n.language,
                autoSkip: true,
              })}
              yOptions={getYOptions({
                autoSkip: false,
                beginAtZero: true,
              })}
              lang={i18n.language}
            />
          ) : (
            <PageStatus
              status={204}
              defaultMessage={t('statusMessage.default')}
              noDataFoundMessage={t('statusMessage.204')}
              unexpectedErrorMessage={t('statusMessage.500')}
            />
          )
        ) : (
          <PageStatus
            status={status}
            defaultMessage={t('statusMessage.default')}
            noDataFoundMessage={t('statusMessage.204')}
            unexpectedErrorMessage={t('statusMessage.500')}
          />
        )}
      </Col>
  )
}
