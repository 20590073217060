import React from 'react';
import { TypicalProgramWorkInterestedParties } from '../../types';
import {
	AUTHOR_CODE,
	COMPO_CODE,
	AUTHOR_COMPO_CODE,
	AUTHOR_REALISATOR,
} from '../../constants/constants';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import './index.scss';

//@ts-ignore
import iconInterestedPartyName from '../../assets/img/icon-interested-party-name.svg';
//@ts-ignore
import IconInterestedPartyIpi from '../../assets/img/icon-interested-party-ipi.svg';

interface InterestedPartiesProps {
  party: TypicalProgramWorkInterestedParties;
  role: string;
}

export default function InterestedParties(props: InterestedPartiesProps) {
	const { party } = props;
	const { t } = useTranslation();

	const roleLabel =
    party.typicalProgramWorkInterestedPartyRoles[0]
    	.typicalProgramWorkInterestedPartyRoleLabel;
	const roleCode =
    party.typicalProgramWorkInterestedPartyRoles[0]
    	.typicalProgramWorkInterestedPartyRoleCode;
	const ipi = party.typicalProgramWorkInterestedPartyIPICode;

	const getRoleNames = (party: TypicalProgramWorkInterestedParties) => {
		let AuthorCompoName = '';
		if (party.typicalProgramWorkInterestedPartyFirstName) {
			AuthorCompoName += `${party.typicalProgramWorkInterestedPartyFirstName} `;
		}
		if (
			party.typicalProgramWorkInterestedPartyLastName ||
      party.typicalProgramWorkInterestedPartyFirstName
		) {
			AuthorCompoName += party.typicalProgramWorkInterestedPartyLastName;
		}
		if (
			roleCode === AUTHOR_CODE ||
      roleCode === AUTHOR_COMPO_CODE ||
      AUTHOR_REALISATOR
		) {
			return AuthorCompoName;
		} else if (roleCode === COMPO_CODE || roleCode === AUTHOR_COMPO_CODE) {
			return AuthorCompoName;
		} else {
			return '';
		}
	};

	return (
		<Row className="interested-parties-body">
			<Col md={8} xs={7}>
				<img
					src={iconInterestedPartyName}
					className="interested-parties-icon name"
				/>
				<span className="interested-parties-name">{getRoleNames(party)}</span>
			</Col>
			<Col md={4} xs={5}>
				<h6 className="role-label align-right">{roleLabel}</h6>
			</Col>
			{ipi! > 0 ? (
				<div className="d-flex align-content-between">
					<div>
						<img
							src={IconInterestedPartyIpi}
							className="interested-parties-icon"
						/>
						<span className="interested-parties-ipi">{ipi}</span>
					</div>
					<div>
						<h6 className="align-right">{t('set-lists.works.ipi')}</h6>
					</div>
				</div>
			) : (
				''
			)}
		</Row>
	);
}
