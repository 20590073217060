import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { capitalize } from '../../helpers';
import './index.scss';


export default function WorkTitle(props: Props) {
	const { index, lengthList, children, referenced, wrapper } = props;
	const workCount = () => `${index! + 1} /  ${lengthList}`;
	return (
		<>
			{wrapper ?
				(<Row className="artwork-title col-12 mx-auto no-gutters">
					<Col className="work-badge">
						<span className={`icon ${referenced ? 'referenced' : 'non-referenced'}`}>&nbsp;</span>
					</Col>
					{children}
				</Row>
				) : (
					<Row className="artwork-title col-12 mx-auto no-gutters">
						<Col className="work-badge">
							<span className={`icon ${referenced ? 'referenced' : 'non-referenced'}`}>&nbsp;</span>
						</Col>
						<Col className="work-name">
							{children}
						</Col>
						{lengthList && <Col xs={3} md={2} className="work-numbers">
							{workCount()}
						</Col>}
					</Row>
				)
			}
		</>
	);
}

interface Props {
    index?: number
    lengthList?: number
    children: any
    editable?: boolean
    referenced?: number
    wrapper?: boolean
}