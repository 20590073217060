declare const window: any

class PianoAnalyticsService {
  private paTag: any

  getPaTag() {
    try {
      if (this.paTag) {
        this.setPrivacyMode(this.paTag).catch((e) => console.log(e))
        return this.paTag
      }

      const pa = window.pianoAnalytics.pianoAnalytics
      this.setPrivacyMode(pa).catch((e) => console.log(e))

      return pa
    } catch (ex) {
      return {
        sendEvent: () => ({}),
      }
    }
  }

  async setPrivacyMode(paTag: any) {
    try {
      if (paTag) {
        const oneTrustActiveGroups = await this.retry(
          3,
          () => window.OnetrustActiveGroups
        )

        if (oneTrustActiveGroups && oneTrustActiveGroups.includes(',C0002,')) {
          //check si l'utilisateur a accepté les cookies performances
          paTag.privacy.setMode('optin')
        } else if (oneTrustActiveGroups) {
          //ce cas quand l'utilisateur n'a pas accepté
          paTag.privacy.setMode('exempt')
        } else {
          console.warn('OnetrustActiveGroups cannot be undefined.')
        }
      } else {
        console.warn('Tag cannot be undefined.')
      }
    } catch (ex) {
      console.error(
        'Could not set Piano Analytics privacy mode',
        this.paTag,
        ex
      )
    }
  }

  paSendPage(info: any) {
    this.paTag = this.getPaTag()
    this.paTag.sendEvent('page.display', info)
  }

  paSendActionClick(info: any) {
    this.paTag = this.getPaTag()
    this.paTag.sendEvent('click.action', info)
  }
  paSendNavigationClick(info: any) {
    this.paTag = this.getPaTag()
    this.paTag.sendEvent('click.navigation', info)
  }
  paSendDownloadClick(info: any) {
    this.paTag = this.getPaTag()
    this.paTag.sendEvent('click.download', info)
  }

  delayExecution(seconds: number) {
    return new Promise((res) => setTimeout(res, seconds * 1000))
  }

  async retry(count: number, func: Function) {
    for (let i = 0; i < count; i++) {
      const value = func()
      if (value === undefined) {
        console.log('retry i', i, 'value', value)
        await this.delayExecution(5)
      } else {
        return value
      }
    }
    return undefined
  }
}

export const tag = new PianoAnalyticsService()
