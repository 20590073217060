import React from 'react'
import { AmountPerTerritory } from '../../../types/distributionsRoyaltiesTypes'
import { useTranslation } from 'react-i18next'
import { formatCurrencyNumber } from '../../../helpers/formatter'
import "./mobile.scss"

const ExploitationTerritoriesMobile = ({
  amountsPerTerritory,
}: {
  amountsPerTerritory: AmountPerTerritory[]
}) => {
  const { i18n } = useTranslation()
  return (
    <div className='exploitation-territories-mobile'>
      {amountsPerTerritory.map((royalty, index) => {
        return (
          <div
            className='territory-label'
            key={royalty.territory.code + index}
          >
            <div>{royalty.territory.label}</div>
            <div className='block-currency'>
              <div className='amount'>
                {formatCurrencyNumber(i18n.language, royalty.amount.value)}
              </div>
              <div className='percent'>
                {royalty.amount.percentage + '%'}
              </div>
            </div>

            {index + 1 !== amountsPerTerritory.length && (
              <div className='separator'></div>
            )}
            {index === 0 && amountsPerTerritory.length === 1 && (
              <div className='separator'></div>
            )}
          </div>
        )
      })}
    </div>
  )
}
export default ExploitationTerritoriesMobile
