import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants';
import { profile } from '../slices/appContextSlice';
import { useAppSelector } from '../store/hooks';

const useRoleRedirect = (excludedRole) => {
    const navigate = useNavigate();
    const profiletState = useAppSelector(profile)
    const roles = profiletState?.roles || [];

    useEffect(() => {
        if (roles.includes(excludedRole)) {
            navigate(ROUTES.LANDING);
        }
    }, [roles, excludedRole, navigate]);
};

export default useRoleRedirect;