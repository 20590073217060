import enGB from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
// import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';
import moment from 'moment';

const InputDate: React.FC<InputDateProps> = ({
	error,
	id,
	name,
	onChange,
	onBlur,
	value,
	required,
	disabled,
	placeholder,
	includeDateIntervals = true,
	className,
	closeOnScroll,
	onCalendarClose,
	touched,
	minDate,
	maxDate
}) => {
	const { i18n: { language } } = useTranslation();
	const [selectedDate, setSelectedDate] = useState(
		value ? new Date(value) : undefined
	);
	const adjustTimezone = (date: any) => {
		return new Date(
			date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
		);
	};

	const dateFormat = language === 'en' ? undefined : 'P';
	useEffect(() => {
		if (language === 'en') {
			registerLocale('en-GB', enGB);
		} else {
			registerLocale('fr', fr);
		}
	}, [language]);

	return (
		<>
			<div className="wrapper-date">
				<DatePicker
					id={id}
					name={name}
					className={`${className} form-control`}
					wrapperClassName={`${error && touched && 'is-invalid'}`}
					placeholderText={placeholder}
					selected={selectedDate}
					value={value && value.length > 0
						? moment(value).format('DD/MM/YYYY')
						: value
					}
					onChange={(date: any) => {
						setSelectedDate(date);
						onChange(adjustTimezone(date));
					}}
					onCalendarClose={onCalendarClose}
					closeOnScroll={closeOnScroll}
					required={required}
					autoComplete='off'
					shouldCloseOnSelect={true}
					locale={language}
					dateFormat={dateFormat}
					peekNextMonth
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
					disabled={disabled}
					onBlur={onBlur}
					includeDateIntervals={includeDateIntervals}
					minDate={minDate}
					maxDate={maxDate}
				/>


			</div>
			{error && touched && <div className="fade error-field show">{error}</div>}
		</>
	);
};

export default InputDate;
export interface InputDateProps extends InputSimpleProps {
    label?: string;
    help?: string;
    includeDateIntervals?: any;
    closeOnScroll?: any;
    onCalendarClose?: any;
    minDate?: Date | null | undefined;
    maxDate?: Date | null | undefined;
}

interface InputSimpleProps {
    id?: string;
    name: string;
    type?: string;
    value?: any;
    onChange: any;
    onBlur?: any;
    error?: string;
    required?: boolean;
    onPaste?: any;
    touched?: boolean;
    tooltip?: string;
    onCopy?: any;
    disabled?: boolean;
    placeholder?: string;
    accept?: string;
    max?: any;
    classLabel?: any;
    className?: string;
}
