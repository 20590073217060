import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'react-bootstrap';
import './index.scss';
import {
	AppPropsType,
	Work,
	WorkCardType,
	WorkList
} from '../../../../types';
import Overlay from '../../../../components/overlay';
import { capitalize, mapWorkObjectToWorkCardObject } from '../../../../helpers';
import { connect, useDispatch } from 'react-redux';
import { addWorkToWorksListAsync } from '../../../../slices/setListsSlice';


const WorkCard = (props: Props) => {
	const { work, selectedWorkList, societyId } = props;
	const { t } = useTranslation();
	const [showMoreDetails, setShowMoreDetails] = useState(false);
	const [workCard, setWorkCard] = useState<WorkCardType>({
		workCode:0,
		workToken:'',
		ISWCCode: '',
		author: '',
		genre: '',
		duration: '',
		composer: '',
		editors: [],
		performer: '',
		title: '',
		rightholders:[],
		subEditors: [],
		ipiCodes: []
	});
	const dispatch = useDispatch();

	useEffect(()=>{
		setWorkCard(mapWorkObjectToWorkCardObject(work));
	},[work]);


	const getEditors = () => {
		const editors: string[]| undefined = workCard.editors?.map(editor => editor?.corporateName + ',' + editor?.address + ',' + editor?.country);
		return  renderListWithAddress(editors);
	};

	const getSubEditors = () => {
		const subEditors: string[]| undefined = workCard.subEditors?.map(subEditor => subEditor?.corporateName + ',' + subEditor?.address + ',' + subEditor?.country);
		return  renderListWithAddress(subEditors);
	};

	const renderList = (list: string[] | string) => {
		const finalList = new Set([...list]);
		return (<ul>
			{Array.from( finalList ).map((element, key) => {
				return <li key={key}><span>{element}</span></li>;
			})}
		</ul>
		);
	};

	const renderListWithAddress = (list: string[]| undefined) => {
		if (list !== undefined){
			const finalList = new Set([...list]);
			return (<ul>
				{Array.from( finalList ).map(element => {
					return element.split(',').filter(splittedElement => splittedElement !== 'null' && splittedElement !== 'undefined')
						.map((splittedElement, index) =>  <li key={index}><span>{splittedElement}</span></li>);}
				)}
			</ul>);
		}
	};

	return (
		<Card className="work-card" key={workCard.workCode}>
			<Card.Header className="header-card">
				<Card.Title className="header-title">{capitalize(work.workTitle)}</Card.Title>
			</Card.Header>
			<Card.Body className="content-card">
				{!showMoreDetails && workCard.ISWCCode !== undefined && workCard.ISWCCode !== '' && <Row>
					<Col xs={5} md={4}>
						<label>{t('find-oeuvre.card.code-iswc')}</label>
					</Col>
					<Col xs={7} md={8}>
						<span className='col-sm-7 col-md-8'>{workCard.ISWCCode}</span>
					</Col>
				</Row>}
				{workCard.author !== undefined  && workCard.author !== '' && <Row>
					<Col xs={5} md={4}>
						<label>{t('find-oeuvre.card.author')}</label>
					</Col>
					<Col xs={7} md={8}>
						<span>{workCard.author}</span>
					</Col>
				</Row>}

				{workCard.composer !== undefined && workCard.composer !== '' && <Row>
					<Col xs={5} md={4}>
						<label>{t('find-oeuvre.card.composer')}</label>
					</Col>
					<Col xs={7} md={8}>
						<span className='col-sm-7 col-md-8'>{workCard.composer}</span>
					</Col>
				</Row>}

				{showMoreDetails && <>
					<Row>
						<Col xs={5} md={4}>
							<label>{t('find-oeuvre.card.gendre')}</label>
						</Col>
						<Col xs={7} md={8}>
							<span>{workCard.genre}</span>
						</Col>
					</Row>
					<Row>
						<Col xs={5} md={4}>
							<label>{t('find-oeuvre.card.code-iswc')}</label>
						</Col>
						<Col xs={7} md={8}>
							<span>{work.workISWCCode}</span>
						</Col>
					</Row>
					{(workCard.rightholders !== undefined  && workCard.rightholders?.length > 0) && <Row>
						<Col xs={5} md={4}>
							<label>{t('find-oeuvre.card.rightholders')}</label>
						</Col>
						<Col xs={7} md={8}>
							{renderList(workCard.rightholders)}
						</Col>
					</Row>}
					{(workCard.editors !== undefined && workCard.editors.length > 0 ) && <Row>
						<Col xs={5} md={4}>
							<label>{t('find-oeuvre.card.publisher')}</label>
						</Col>
						<Col xs={7} md={8}>
							{getEditors()}
						</Col>
					</Row>}
					{(workCard.subEditors !== undefined && workCard.subEditors.length > 0 ) && <Row>
						<Col xs={5} md={4}>
							<label>{t('find-oeuvre.card.sub-publisher')}</label>
						</Col>
						<Col xs={7} md={8}>
							{getSubEditors()}
						</Col>
					</Row>}
				</>}
				<div className='d-flex justify-content-between mt-1'>
					<Overlay content={showMoreDetails ? t('find-oeuvre.card.less-details') : t('find-oeuvre.card.more-details')} placement={'top'}>
						<div className={` icon-showMore ${!showMoreDetails && 'rotateIcon'}`} onClick={()=> setShowMoreDetails(!showMoreDetails)}/>
					</Overlay>

					<div  className='add-work-to-list'
						onClick={()=> {
							dispatch(addWorkToWorksListAsync({
								work: workCard,
								worksListId: selectedWorkList.worksListId,
								isNRWork: false,
								societyId: societyId,
							}));
						}}>{t('find-oeuvre.card.form-btn-add-work-to-list')}</div>
				</div>
			</Card.Body>
		</Card>
	);
};
const mapStateToProps = (state: AppPropsType) => ({
	selectedWorkList: state.setLists.newProgram
});

export default connect(mapStateToProps) (WorkCard);

interface Props {
  work: Work
  selectedWorkList: WorkList
	societyId: number
}