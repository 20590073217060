import { TFunction } from 'react-i18next'
import { MarketShareByClaimType, MarketShareSearchParams } from '../../types/marketShareTypes'
import { CellObject } from 'xlsx-js-style'
import {
  CURRENCY_FORMAT,
  mapEven,
  mapHeader,
  mapOdd,
  PERCENTAGE_FORMAT,
  CELL_TYPE_EMPTY,
  CELL_TYPE_NUMBER,
  CELL_TYPE_STRING,
} from '../../components/exportButton/utils'
import { formatYYYYMMToDate } from '../../utils/dateUtils'
const marketShareExportEmpty = 'market-share.export.empty'
export interface MarketShareSearchParamsSelection
  extends MarketShareSearchParams {
  allTerritories: boolean
  allDsps: boolean
  allGroupedOffers: boolean
  allRepositories: boolean
  repositories: string[]
}
interface ClaimPerQuarter {
  periodExploit: string
  claims: {
    [key: string]: {
      amount: number
      percentage: number
    }
  }
}

function getValue(
  t: TFunction<'translation', undefined>,
  searchParams: MarketShareSearchParamsSelection | undefined,
  itemKey: 'territories' | 'dsps' | 'groupedOffers' | 'repositories',
  isAllValue: boolean | undefined
): string {
  if (
    searchParams == null ||
    searchParams[itemKey] == null ||
    searchParams[itemKey].length === 0
  ) {
    return t(marketShareExportEmpty)
  }
  if (isAllValue != null && isAllValue) {
    return t('market-share.export.all')
  }
  return searchParams[itemKey].join(',')
}

function getExcelHeaders(
  t: TFunction<'translation', undefined>,
  titleKey: string,
  searchParams: MarketShareSearchParamsSelection | undefined
) {
  return [
    [
      { t: CELL_TYPE_EMPTY },
      { v: t(titleKey), t: CELL_TYPE_STRING, s: { font: { bold: true } } },
    ],
    [],
    [
      { t: CELL_TYPE_EMPTY },
      { v: t('market-share.export.headers.timePeriod'), t: CELL_TYPE_STRING },
      { v: t('market-share.export.headers.dsps'), t: CELL_TYPE_STRING },
      { v: t('market-share.export.headers.territories'), t: CELL_TYPE_STRING },
      { v: t('market-share.export.headers.offers'), t: CELL_TYPE_STRING },
      { v: t('market-share.export.headers.repertoires'), t: CELL_TYPE_STRING },
    ].map(mapHeader),
    [
      { t: CELL_TYPE_EMPTY },
      {
        v:
          searchParams == null
            ? t(marketShareExportEmpty)
            : t('market-share.export.timePeriod', {
                fromDate: formatYYYYMMToDate(searchParams.date.startingDate),
                toDate: formatYYYYMMToDate(searchParams.date.endingDate),
              }),
        t: CELL_TYPE_STRING,
        s: { alignment: { wrapText: true } },
      },
      {
        v: getValue(t, searchParams, 'dsps', searchParams?.allDsps),
        t: CELL_TYPE_STRING,
        s: { alignment: { wrapText: true } },
      },
      {
        v: getValue(
          t,
          searchParams,
          'territories',
          searchParams?.allTerritories
        ),
        t: CELL_TYPE_STRING,
        s: { alignment: { wrapText: true } },
      },
      {
        v: getValue(
          t,
          searchParams,
          'groupedOffers',
          searchParams?.allGroupedOffers
        ),
        t: CELL_TYPE_STRING,
        s: { alignment: { wrapText: true } },
      },
      {
        v: getValue(
          t,
          searchParams,
          'repositories',
          searchParams?.allRepositories
        ),
        t: CELL_TYPE_STRING,
        s: { alignment: { wrapText: true } },
      },
    ].map(mapEven),
    [],
    [],
  ]
}

export function prepareExportByCommercialModelData(
  t: TFunction<'translation', undefined>,
  searchParams: MarketShareSearchParamsSelection | undefined,
  msboData: any[]
): CellObject[][] {
  return [
    ...getExcelHeaders(t, 'market-share.export.title_offer', searchParams),
    [
      { t: CELL_TYPE_EMPTY },
      { v: t('market-share.export.headers.dsps'), t: CELL_TYPE_STRING },
      { v: t('market-share.export.headers.total_amount'), t: CELL_TYPE_STRING },
      { v: t('market-share.export.headers.offers'), t: CELL_TYPE_STRING },
      {
        v: t('market-share.export.headers.detailed_amount'),
        t: CELL_TYPE_STRING,
      },

      { v: t('market-share.export.headers.marketShare'), t: CELL_TYPE_STRING },
    ].map(mapHeader),
    ...msboData.map((item, idx) => {
      return (
        [
          { t: CELL_TYPE_EMPTY },
          { v: item.dspCode, t: CELL_TYPE_STRING },
          { v: item.totalAmount, t: CELL_TYPE_STRING },

          {
            v: item.commercialModelName,
            t: CELL_TYPE_STRING,
          },

          {
            v: item.claimedAmountEuros,
            t: CELL_TYPE_STRING,
          },

          {
            v: item.pdm,
            t: CELL_TYPE_STRING,
          },
        ] as CellObject[]
      ).map(idx % 2 === 0 ? mapEven : mapOdd)
    }),
  ] as CellObject[][]
}

export function prepareExportByClaimTypeData(
  t: TFunction<'translation', undefined>,
  searchParams: MarketShareSearchParamsSelection | undefined,
  msctData: MarketShareByClaimType[]
): CellObject[][] {
  const claims = Array.from(
    msctData.reduce<Set<string>>((acc, item) => {
      return acc.add(item.claimType)
    }, new Set())
  )

  return [
    ...getExcelHeaders(t, 'market-share.export.title_claim_type', searchParams),
    [
      { t: CELL_TYPE_EMPTY },
      { v: t('market-share.export.headers.quarter'), t: CELL_TYPE_STRING },
      // List all the claims using the format <claim> <claim percentage>
      ...claims.reduce((acc, item) => {
        acc.push({ v: item, t: CELL_TYPE_STRING })
        acc.push({
          v: t('market-share.export.headers.claimPercentage', {
            claimType: item,
          }),
          t: CELL_TYPE_STRING,
        })
        return acc
      }, [] as CellObject[]),
    ].map(mapHeader),
    // Reduce the array to have data per month instead of splitted values
    ...msctData
      .reduce<ClaimPerQuarter[]>(
        (acc: ClaimPerQuarter[], currentValue: MarketShareByClaimType) => {
          let currentQuarter = acc.find(
            (item) => item.periodExploit === currentValue.periodExploit
          )
          if (currentQuarter == null) {
            currentQuarter = {
              periodExploit: currentValue.periodExploit,
              claims: {},
            } as ClaimPerQuarter
            acc.push(currentQuarter)
            acc.sort((a, b) => a.periodExploit.localeCompare(b.periodExploit))
          }
          currentQuarter.claims[currentValue.claimType] = {
            amount: currentValue.claimedAmount,
            percentage: currentValue.percentage,
          }
          return acc
        },
        [] as ClaimPerQuarter[]
      )
      .map((item, idx) => {
        return [
          { t: CELL_TYPE_EMPTY },
          { v: item.periodExploit, t: CELL_TYPE_STRING },
          // Browse all the claims
          ...claims.reduce((acc: CellObject[], claimType) => {
            const claimsValue = item.claims[claimType]
            if (claimsValue == null) {
              acc.push({ v: t(marketShareExportEmpty), t: CELL_TYPE_STRING })
              acc.push({ v: t(marketShareExportEmpty), t: CELL_TYPE_STRING })
            } else {
              acc.push({
                v: claimsValue.amount,
                t: CELL_TYPE_NUMBER,
                s: { numFmt: CURRENCY_FORMAT },
              })
              acc.push({
                v: claimsValue.percentage / 100,
                t: CELL_TYPE_NUMBER,
                s: { numFmt: PERCENTAGE_FORMAT },
              })
            }
            return acc
          }, [] as CellObject[]),
        ].map(idx % 2 === 0 ? mapEven : mapOdd)
      }),
  ] as CellObject[][]
}
