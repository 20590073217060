import React, { useEffect, useState, useRef } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { BeatLoader } from 'react-spinners';

import HeaderCard from '../../../components/header-card';
import Pagination from '../../../components/pagination';
import FormInputText from '../../../components/FormInputText';
import BasketWork from '../components/basketWork';
import WorkCardType from '../components/workCard';
import { AppPropsType, WorksFilters, WorksRequest, WorksState, WorkList } from '../../../types';
import { DEFAULT_SIZE_PER_PAGE } from '../../../constants/constants';
import { fetchWorksAsync, resetWorks } from "../../../slices/worksSlice";

import '../index.scss';
import NonReferencedWorkForm from '../components/nonReferencedWorkForm';
import { addWorkToWorksListAsync, fetchWorksOfWorksListAsync } from '../../../slices/setListsSlice';
import {InputISWCGroup} from "../../../components/inputGroup/InputGroup";
import InputMask from "react-input-mask";

const defaultFilters = {
	aci: '',
	iswcCode: '',
	workTitle: ''
};

const SecondStep = (props: Props) => {
	const { works, onSubmit, sisterSocietyId, selectedWorkList } = props;
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [currentPage, setCurrentPage] = useState(1);
	const [filters, setFilters] = useState<WorksFilters>(defaultFilters);
	const [height, setHeight] = useState(0);
	const [disabledSearch, setDisabledSearch] = useState(true);
	const [addNonReferencedWork, setAddNonReferencedWork] = useState(false);
	const ref = useRef<any>({ current: { clientHeight: 0 } });

	useEffect(()=>{
		if (selectedWorkList.worksListId){
			dispatch(fetchWorksOfWorksListAsync({ worksListId: selectedWorkList.worksListId, societyId: sisterSocietyId }));
		}
	},[selectedWorkList.worksListId]);
	useEffect(() => {
		if (ref) {
			setHeight(ref?.current?.clientHeight);
		}
	}, [ref, works]);
	useEffect(() => {
		if (filters.aci || filters.workTitle || filters.iswcCode) {
			setDisabledSearch(false);
		} else {
			setDisabledSearch(true);
		}
	}, [filters]);

	useEffect(() => {
		return () => {
			setFilters(defaultFilters)
			dispatch(resetWorks());
		};
	}, []);

	const handleChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setFilters({ ...filters, [e.target.name]: e.target.value })
		if (filters.aci || filters.workTitle || filters.iswcCode) {
			setDisabledSearch(false);
		} else {
			setDisabledSearch(true);
		}
	};

	const handleSubmit = () => {
		onSubmit(1, {});
	};

	const findWorks = (pageNumber: number) => {
		setCurrentPage(1);
		const params: WorksRequest = {
			worksFilter: filters,
			pagination : {
				page: pageNumber,
				size: DEFAULT_SIZE_PER_PAGE
			}
		};
		 dispatch(fetchWorksAsync(params));
	};

	const onPageClick = (pageNumber: number) => {
		findWorks(pageNumber);
		setCurrentPage(pageNumber);
		document.documentElement.scrollTo({
			top: 600,
			left: 0,
			behavior: 'auto',
		});
	};

	return (
		<div className="d-flex justify-content-start flex-column w-100">
			<div className="col-md-12">
				<Card className="program-card shadow-card">
					<HeaderCard title={t('page.new-program.step2.search-box-title')}/>
					<Card.Body>
						<Form className="form-add-program d-flex justify-content-lg-around">
							<FormInputText id="workTitle"
								classNameGroup="col-md-4"
								autoComplete="off"
								onChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e)}
								placeholder={t('page.new-program.step2.placeholder-field-work-title')}
								defaultValue={filters.workTitle}
							/>
							<FormInputText id="aci"
								classNameGroup="col-md-5"
								autoComplete="off"
								onChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e)}
								placeholder={t('page.new-program.step2.placeholder-field-name')}
								defaultValue={filters.aci}
							/>
							<InputISWCGroup
								id="iswcCode"
								name="iswcCode"
								className="my-4 py-2 px-3  form-control"
								placeholder={t('page.new-program.step2.placeholder-field-iswc')}
								value={filters.iswcCode}
								onChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e)}
								onBlur={()=>{}}
								ifSearchForm = {true}
							/>
						</Form>
						<div className="d-flex justify-content-end px-1">
							<Button
								className="px-5 search-btn"
								type="button"
								onClick={() => {
									findWorks(1);
								}}
								disabled={disabledSearch}
							>{t('btn.search')}
							</Button>
						</div>
					</Card.Body>
				</Card>
			</div>
			<div className="col-md-12 d-flex flex-row justify-content-between mt-4">
				<div className="col-md-7">
					<div className="col-sm-12 mb-pagination">
						<div className="d-flex justify-content-start pt-3 pb-3 align-items-center">
							<div className="result-work-count col-md-3">
								{works?.worksCount} {t('find-oeuvre.result')}
							</div>
							{!addNonReferencedWork &&
								<button
									className="btn-bg-white col-md-5"
									onClick={() => setAddNonReferencedWork(true)}
								>{t('find-oeuvre.non-referenced-work.title')}</button>
							}
						</div>
						<div>
							{ addNonReferencedWork && <NonReferencedWorkForm onSubmit={ (work) => {
								dispatch(addWorkToWorksListAsync({
									work,
									worksListId: selectedWorkList.worksListId,
									isNRWork: true,
									societyId: sisterSocietyId,
								}));
								setAddNonReferencedWork(false);
							}} onClose={() => setAddNonReferencedWork(false)}></NonReferencedWorkForm> }
						</div>
						<div className="works-results" ref={ref}>
							<div className="text-center">
								<BeatLoader
									speedMultiplier={1}
									margin={5}
									size={20}
									color="#125FE2"
									loading={ works.loading}
								/>
							</div>
							{sisterSocietyId && works.works && works.works.map((work: any, index) =>
								<WorkCardType work={work} key={index} societyId={sisterSocietyId}/>
							)}
							{works.worksCount === 0 && !works.loading && (
								<div className="text-center empty-msg">
									{t('find-oeuvre.errors.empty-search')}
								</div>
							)}
						</div>
						<div className="d-flex justify-content-end">
							<Pagination
								totalElements={works.worksCount}
								onPageClick={onPageClick}
								elementsPerPage={DEFAULT_SIZE_PER_PAGE}
								currentPage={currentPage}
							/>
						</div>
					</div>
				</div>
				<div className="basket-container d-flex d-md-block">
						<BasketWork onSubmit={handleSubmit} height={height} societyId={sisterSocietyId}/>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: AppPropsType) => ({
	works: state.works,
	selectedWorkList: state.setLists.newProgram,
	sisterSocietyId: state.appContext.selectedSisterSociety?.sisterSocietyId
});

export default connect(mapStateToProps) (SecondStep);

interface Props {
	sisterSocietyId: number | undefined
	works: WorksState
	selectedWorkList: WorkList
	onSubmit: (step: number, values: any) => void
};
