import { css } from '@emotion/react';
import Loader from 'react-spinners/BeatLoader';
import React from 'react';
import './index.scss';


const LoadingSpinner = (props: any) => {
	const { isLoading } = props.isLoading;

	const spinnerCss = css`
    display: block;
    border-color: #3d4366;
  `;
	return (
		<div className='spinner'>
			<Loader
				css={spinnerCss}
				speedMultiplier={1}
				margin={5}
				size={20}
				color={'#125fe2'}
				loading={isLoading}
			/>
		</div>
	);
};

export default LoadingSpinner;