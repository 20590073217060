import { httpCaller } from './httpCaller';
import { WorksPayload, WorksRequest } from '../types';

export function fetchWorks(params: WorksRequest) {

	const { worksFilter, pagination } = params;
	return new Promise<WorksPayload>((resolve, reject) =>
		httpCaller
			.get('works',
				{
					params: {
						work_title: worksFilter.workTitle,
						aci: worksFilter.aci,
						iswc: worksFilter.iswcCode,
						...pagination
					}
				})
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error) => {
				return reject(error);
			})
	);
}
