import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ErrorPage from '../../components/error';

import Page from '../page';
import { SilentRenew } from '../../oidcAuthentication/component/silentRenew';
import { LogoutCallback } from '../../oidcAuthentication/component/logoutCallback';
import { Logout } from '../../oidcAuthentication/component/logout';
import { Callback } from '../../oidcAuthentication/component/callback';
import routes from '../routes';
import SecuredRoute from '../securedRoute';


export function WebsoRouter() {

	return (
		<>
			<Routes>
				<Route path='/signin-oidc' element={<Callback/>} />
				<Route path='/logout' element={<Logout/>} />
				<Route path='/logout/callback' element={<LogoutCallback/>} />
				<Route path='/silentrenew' element={<SilentRenew/>} />
				{
					routes.map((route, idx) => {
						if (route.public) {
							return (
								route.component && (
									<Route
										key={idx}
										path={route.path}
										element={
											<Page title={route.pageTitle}>
												{route.component}
											</Page>
										}
									/>
								)
							);
						} else {
							return (
								route.component && (
									<Route
										key={idx}
										path={route.path}
										element={
											<Page title={route.pageTitle}>
												<SecuredRoute route={route}>
													{route.component}
												</SecuredRoute>
											</Page>
										}/>
								)
							);
						}
					})
				}

				<Route path='/error' element={ <Page><ErrorPage errorCode = {404}/></Page>}/>
			</Routes>
		</>
	);
}
