import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { IPI, NonReferencedWork } from '../../../../types';
import './index.scss';
import ConfirmationModal from '../../../../components/confirmationModal';
import InputDurationGroup, { InputTextGroup, InputISWCGroup } from '../../../../components/inputGroup/InputGroup';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { fetchIpisSugest } from '../../../../api/commonsApi';
import { Option } from 'react-bootstrap-typeahead/types/types';

const defaultValues = {
	title: '',
	duration: '',
	ISWCCode: '',
	ipiCodes: []
};

const NonReferencedWorkForm = (props: Props) => {
	const { onSubmit, onClose } = props;
	const { t } = useTranslation();
	const [openModal, setOpenModal] = useState(false);
	const [options, setOptions] = useState<any[]>([]);
	const [errorIpiCodes, setErrorIpiCodes] = useState<boolean>(false);
	const emptyFieldsLabelKey = 'errors.empty-field';

	const formik = useFormik({
		onSubmit,
		initialValues: defaultValues,
		validationSchema: Yup.object().shape({
			title: Yup.string().trim()
				.required(t(emptyFieldsLabelKey, { field: 'the work title' }))
				.max(50, t('errors.field-max-length' , { field: 'the work title', maxLength: '50' })),
			ISWCCode: Yup.string()
				.notOneOf(['T-000 000 000-0'], t('find-oeuvre.non-referenced-work.errors.iswc-not-accepted-format'))
				.matches(
					/^T-\d{3} \d{3} \d{3}-\d$/,
					t(emptyFieldsLabelKey, { field: 'ISWC' })
				)
				.required(t(emptyFieldsLabelKey, { field: 'ISWC' }))
				,
			ipiCodes: Yup.array().min(1, t(emptyFieldsLabelKey, { field: 'IPI Number' })),
			duration: Yup.string().trim()
				.matches(/^\d{2}:\d{2}$/, t('find-oeuvre.non-referenced-work.errors.invalid-duration'))
				.notOneOf(['00:00'], t('find-oeuvre.non-referenced-work.errors.equals-zero'))
				.required(t('find-oeuvre.non-referenced-work.errors.invalid-duration')),
		}),
		validateOnMount: true
	});

	useEffect(()=>{
		setErrorIpiCodes(formik.touched.ipiCodes !== undefined && formik.values.ipiCodes.length === 0);
	}, [formik.touched]);

	const filterBy = () => true;

	const handleSearch = (query: string) => {
		fetchIpisSugest(query, 10)
			.then(res => {
				const options : IPI[] = [];
				if (res.ipiNames) {
					res.ipiNames.map(ipiSuggest => {
						return ipiSuggest.numbers.map(code => {
							options.push({
								ipiName: ipiSuggest.displayName,
								ipiCode: code.toString()
							});
						});
					});
					setOptions(options);
				}
			});
	};

	const handelChangeIpi = async (selectedOptions: any[]) => {
		setOptions([]);
		const uniqueSelectedOption = selectedOptions.filter((obj, index) => {
			return index === selectedOptions.findIndex(o => obj.ipiCode === o.ipiCode);
		});
		await formik.setFieldValue('ipiCodes', uniqueSelectedOption, true);
	};

	//@ts-ignore
	const handleBlur = (e) => {
		const touched: any = formik.touched;
		touched[e.target.name] = true;
		formik.setTouched(touched, true);
	};

	return (
		<>
			<ConfirmationModal
				open={openModal}
				content={t('find-oeuvre.non-referenced-work.cancel-confirmation')}
				alignButtons="bottom"
				confirmLabel={t('btn.quit')}
				onValidate={onClose}
				onClose={() => setOpenModal(false)}
			/>
			<div className="work-form">
				<div className="form-header">{t('find-oeuvre.non-referenced-work.title')}</div>
				<form onSubmit={formik.handleSubmit}>
					<div className="form-body">
						<InputTextGroup
							id="title"
							name="title"
							label={t('find-oeuvre.non-referenced-work.label.title')}
							placeholder={t('find-oeuvre.non-referenced-work.placeholder.title')}
							value={formik.values.title}
							error={formik.touched.title && formik.errors.title}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<InputISWCGroup
							id="iswc"
							name="ISWCCode"
							label={t('find-oeuvre.non-referenced-work.label.iswc')}
							placeholder={t('find-oeuvre.non-referenced-work.placeholder.iswc')}
							value={formik.values.ISWCCode}
							error={formik.touched.ISWCCode && formik.errors.ISWCCode}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							contentTooltip={t('find-oeuvre.non-referenced-work.tooltip')}
						/>

						<label>{t('find-oeuvre.non-referenced-work.label.ipiCode')}</label>
						<AsyncTypeahead
							inputProps={{
								id: 'ipiCodes',
								name: 'ipiCodes'
							}}
							selected={formik.values.ipiCodes}
							className={'input-block-autocomplete'}
							multiple
							labelKey={ (option: Option) =>  {
								//@ts-ignore
								return option.ipiName + ' [ ' + option.ipiCode + ' ] ';
							} }
							filterBy={filterBy}
							id="ipiCodes"
							isLoading={false}
							minLength={3}
							onSearch={handleSearch}
							onChange={handelChangeIpi}
							onBlur={handleBlur}
							options={options}
							placeholder={t('find-oeuvre.non-referenced-work.placeholder.ipiCode')}
							searchText={t('form.autocomplete-search-text')}
							emptyLabel={t('form.autocomplete-empty-result')}
							renderMenuItemChildren={(option: any) => (
								<Fragment>
									<span>{option.ipiName + ' [ ' + option.ipiCode + ' ] '}</span>
								</Fragment>
							)}
						/>
						{errorIpiCodes && (
							<span className="fade input-error-ipis-code show">{formik.errors.ipiCodes}</span>
						)}
						<InputDurationGroup
							id="duration"
							name="duration"
							label={t('find-oeuvre.non-referenced-work.label.duration')}
							placeholder={t('find-oeuvre.non-referenced-work.placeholder.duration')}
							value={formik.values.duration}
							error={formik.touched.duration && formik.errors.duration}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
					</div>
					<div className="form-footer">
						<Button className="btn-cancel" onClick={() => setOpenModal(true)} >{t('btn.cancel')}</Button>
						<Button
							className="btn-submit"
							disabled={!formik.isValid}
							type="submit"
						>{t('btn.add')}</Button>
					</div>
				</form>
			</div>
		</>
	);
};

export default NonReferencedWorkForm;

interface Props {
  onSubmit: (work: NonReferencedWork) => void
  onClose: () => void
}
