import React from 'react';
import { default as BootstrapPagination } from 'react-bootstrap/Pagination';
import './index.scss';

import { useTranslation } from 'react-i18next';
import iconLast from '../../assets/img/icon-pagination-last.svg';
import iconFirst from '../../assets/img/icon-pagination-first.svg';

interface PaginationPropsType {
  totalElements: number;
  currentPage: number;
  elementsPerPage: number;
  onPageClick: (page: number) => void;
}

const Pagination: React.FC<PaginationPropsType> = ({
	currentPage,
	totalElements,
	elementsPerPage,
	onPageClick
}) => {
	const { t } = useTranslation();
	// const totalPages = Math.floor(totalElements / elementsPerPage) + 1
	const totalPages = totalElements < elementsPerPage ? 1 : Math.ceil(totalElements / elementsPerPage);
	if (totalPages <= 1) {
		return null;
	}
	const paginationItems:any[] = [];
	const next = 'next';
	const previous = 'previous';

	if( totalPages <= 3) {
		paginationItems.push(<BootstrapPagination.Item disabled={currentPage === 1} key={previous}
			onClick={() => onPageClick(1)}><img src={iconFirst} /></BootstrapPagination.Item>);
		for(let i = 1; i <= totalPages;i++) {
			paginationItems.push(<BootstrapPagination.Item active={currentPage === i} key={i} onClick={() => onPageClick(i)}>{i}</BootstrapPagination.Item>);
		}
		paginationItems.push(<BootstrapPagination.Item disabled={currentPage === totalPages} key={next} onClick={() => onPageClick(totalPages)}>
			<img src={iconLast} /></BootstrapPagination.Item>);
	}
	if (totalPages > 3) {
		if (currentPage === 1) {
			paginationItems.push(<BootstrapPagination.Item active key={currentPage} onClick={() => onPageClick(currentPage)}>{currentPage}</BootstrapPagination.Item>);
			paginationItems.push(<BootstrapPagination.Item key={currentPage + 1} onClick={() => onPageClick(currentPage + 1)}>{currentPage + 1}</BootstrapPagination.Item>);
			paginationItems.push(<BootstrapPagination.Item key={currentPage + 2} onClick={() => onPageClick(currentPage + 2)}>{currentPage + 2}</BootstrapPagination.Item>);
			paginationItems.push(<BootstrapPagination.Item disabled={currentPage === totalPages} key={next}
				onClick={() => onPageClick(totalPages)}>
				<img src={iconLast} /></BootstrapPagination.Item>);
		}
		if (currentPage > 1 && currentPage < totalPages) {
			paginationItems.push(<BootstrapPagination.Item disabled={currentPage === 1} key={previous}
				onClick={() => onPageClick(1)}><img src={iconFirst} /></BootstrapPagination.Item>);
			paginationItems.push(<BootstrapPagination.Item key={currentPage - 1} onClick={() => onPageClick(currentPage - 1)}>{currentPage - 1}</BootstrapPagination.Item>);
			paginationItems.push(<BootstrapPagination.Item active onClick={() => onPageClick(currentPage)} key={currentPage}>{currentPage}</BootstrapPagination.Item>);
			paginationItems.push(<BootstrapPagination.Item onClick={() => onPageClick(currentPage + 1)} key={currentPage + 1}>{currentPage + 1}</BootstrapPagination.Item>);
			paginationItems.push(<BootstrapPagination.Item disabled={currentPage === totalPages} key={next} onClick={() => onPageClick(totalPages)}>
				<img src={iconLast} /></BootstrapPagination.Item>);
		}

		if(currentPage === totalPages){
			paginationItems.push(<BootstrapPagination.Item disabled={currentPage === 1} key={previous}
				onClick={() => onPageClick(1)}><img src={iconFirst} /></BootstrapPagination.Item>);
			paginationItems.push(<BootstrapPagination.Item key={currentPage - 2} onClick={() => onPageClick(currentPage - 2)}>{currentPage - 2}</BootstrapPagination.Item>);
			paginationItems.push(<BootstrapPagination.Item key={currentPage - 1} onClick={() => onPageClick(currentPage - 1)}>{currentPage - 1}</BootstrapPagination.Item>);
			paginationItems.push(<BootstrapPagination.Item active key={currentPage} onClick={() => onPageClick(currentPage)}>{currentPage}</BootstrapPagination.Item>);
		}
	}

	return (
		<div className="d-flex align-items-center justify-content-end mb-3">
			{totalPages > 1 && (
				<span className='pagination-text'>{((currentPage - 1) * elementsPerPage + 1 ) + ' - ' + (currentPage === totalPages ? totalElements : currentPage * elementsPerPage) + ' ' + t('pagination.of') + ' ' + totalElements}</span>
			)}
			<BootstrapPagination className='content-pagination'>
				{paginationItems}
			</BootstrapPagination>
		</div>
	);
};
Pagination.defaultProps = {
	elementsPerPage: 10,
	currentPage: 0
};
export default Pagination;