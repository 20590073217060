import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import {
	addWorkToWorksList, createTypicalProgram,
	deleteWorkFromWorksList,
	fetchTypicalPrograms, fetchWorksOfWorksList, patchWorkInWorksList,
	postWorksList
} from '../api/typicalProgramsAPI';
import {
	SetListsState,
	TypicalProgramPayload,
	WorksListPayload,
	TypicalProgramParam, AddedWork, WorkBasket
} from '../types';
import { formatImprovisers, formatInterestedParties, formatPerformers, getWorkDuration } from '../helpers';

export const initialState: SetListsState = {
	typicalPrograms: [],
	typicalProgramsCount: 0,
	reason: '',
	responseCode: '',
	newProgram: {
		worksListId: 0,
		worksListTitle: '',
		workListOwner: '',
		worksListStartDate: moment().format('Y-MM-DD'),
		worksListTypeCode: 'AI'
	},
	error: false,
	loading: false
};

export const fetchTypicalProgramsAsync = createAsyncThunk(
	'typicalPrograms/fetchTypicalPrograms',
	async (typicalProgramsParam:TypicalProgramParam, thunkAPI) => {
		// eslint-disable-next-line no-unused-expressions
		const TypicalProgramsPromise: Promise<TypicalProgramPayload> =
      fetchTypicalPrograms(typicalProgramsParam);

		const typicalProgram: TypicalProgramPayload =
			await TypicalProgramsPromise
				.then((responseBody) => Promise.resolve(responseBody))
				.catch((error) => Promise.reject(error));

		return {
			typicalPrograms: typicalProgram?.typicalPrograms || [],
			typicalProgramsCount: typicalProgram?.typicalProgramsCount || 0,
		};
	}
);

export const postWorksListAsync = createAsyncThunk(
	'programs/postWorksList',
	async (postWorksListValues: WorksListPayload, { rejectWithValue }) => {
		try {
			return await postWorksList(postWorksListValues);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const deleteWorkFromWorksListAsync = createAsyncThunk(
	'programs/deleteWorkFromWorkLists',
	async (params: any, { rejectWithValue }) => {
		try {
			const { worksListWorkId, worksListId, societyId } = params;
			return await deleteWorkFromWorksList(worksListWorkId, worksListId, societyId);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const addWorkToWorksListAsync = createAsyncThunk(
	'programs/addWorkToWorksList',
	async (params: any, { rejectWithValue }) => {
		try {
			const { work, worksListId, societyId, isNRWork } = params;
			return await addWorkToWorksList(work, worksListId, isNRWork, societyId);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const patchWorkInWorksListAsync = createAsyncThunk(
	'programs/patchWorkInWorksList',
	async (params: any, { rejectWithValue }) => {
		try {
			const { work, worksListId, worksListWorkId, societyId } = params;
			return await patchWorkInWorksList(work, worksListWorkId, worksListId, societyId);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchWorksOfWorksListAsync = createAsyncThunk(
	'programs/getWorksOfWorkLists',
	async (params: any, { rejectWithValue }) => {
		try {
			const { worksListId, societyId } = params;
			return await fetchWorksOfWorksList(worksListId, societyId);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const updateSetListAsync = createAsyncThunk(
	'programs/updateSetList',
	async (title: string, { rejectWithValue }) => {
		return { title };
	}
);

export const selectTypicalPrograms = (state) => state.setLists;

export const createTypicalProgramAsync = createAsyncThunk(
	'programs/createTypicalProgram',
	async (params: any, { rejectWithValue }) => {
		try {
			return await createTypicalProgram(params)
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const typicalProgramsSlice = createSlice({
	name: 'typicalPrograms',
	initialState,
	reducers: {
		resetProgramNew: (state) => {
			return {...state, newProgram: initialState.newProgram}
		},
		setSetLists: (state, action) => ({ ...state, typicalPrograms : action.payload }),
		setSetListsCount: (state, action) => ({ ...state, typicalProgramsCount : action.payload }),
		setNewProgram: (state, action) => ({ ...state, newProgram: action.payload }),
	},
	extraReducers: (builder) => {
		builder
			.addCase(updateSetListAsync.fulfilled, (state, action) => ({ ...state, newProgram: { ...state.newProgram, worksListTitle: action.meta.arg } }))
			.addCase(fetchTypicalProgramsAsync.pending, (state) => ({ ...state, reason : '0', loading: true }))
			.addCase(fetchTypicalProgramsAsync.fulfilled, (state, action) => (
				{
					...state,
					responseCode: 'FETCH_PROGRAMS_LIST_OK',
					reason: '200',
					typicalPrograms: action.payload.typicalPrograms,
					typicalProgramsCount: action.payload.typicalProgramsCount,
					loading: false,
				}))
			.addCase(fetchTypicalProgramsAsync.rejected, (state, action) => ({
				...state,
				responseCode: 'FETCH_PROGRAMS_LIST_KO',
				reason: action?.error?.message || '500',
				typicalPrograms: [],
				typicalProgramsCount: 0,
				loading: false
			}))
			.addCase(postWorksListAsync.pending, (state) => ({
				...state,
				responseCode: 'PENDING'
			}))
			.addCase(postWorksListAsync.fulfilled, (state, action) => {
				return{
					...state,
					responseCode: action.meta.arg.isDraft ? 'DRAFT_WORK_LIST_OK' : 'CREATE_WORK_LIST_OK',
					newProgram: {
						...state.newProgram,
						worksListId: action.payload.worksListId,
						worksListTitle: action.meta.arg.values.worksListTitle,
						worksListStartDate: action.meta.arg.values.worksListStartDate,
						worksListTypeCode: action.meta.arg.values.worksListTypeCode,
					},
					error: false
				}
			})
			.addCase(addWorkToWorksListAsync.pending, (state, action) => {
				return {
					...state,
					responseCode: 'BASKET_CHANGING_WORK',
					loading: true
				};
			})
			.addCase(addWorkToWorksListAsync.fulfilled, (state, action) => {
				const works = state.newProgram.works || [];
				return {
					...state,
					loading: false,
					responseCode: 'OK',
					newProgram: {
						...state.newProgram,
						works: [
							{
								worksListWorkId: action.payload.worksListWorkId,
								title: action.meta.arg.work.title
							},
							...works
						],
					}
				};
			})
			.addCase(addWorkToWorksListAsync.rejected, (state, action) => {
				return {
					...state,
					// @ts-ignore
					responseCode: action.payload?.message,
					loading: false
				};
			})
			.addCase(deleteWorkFromWorksListAsync.pending, (state, action) => {
				return {
					...state,
					// @ts-ignore
					responseCode: 'BASKET_CHANGING_WORK',
					loading: true
				};
			})
			.addCase(deleteWorkFromWorksListAsync.rejected, (state, action) => {
				return {
					...state,
					// @ts-ignore
					responseCode: action.payload?.message,
					loading: false
				};
			})
			.addCase(deleteWorkFromWorksListAsync.fulfilled, (state, action) => {
				const works = state.newProgram.works || [];
				const addedWorks = state.newProgram.addedWorks || [];
				if (action.payload === 204) {
					const index = works.findIndex(work => work.worksListWorkId === action.meta.arg.worksListWorkId);
					const newList = works;
					const indexAddedWork = addedWorks.findIndex(work => work.worksListWorkId === action.meta.arg.worksListWorkId);
					const newAddedWorks = addedWorks;
					newAddedWorks.splice(indexAddedWork, 1);
					newList.splice(index, 1);
					state.newProgram.works = newList;
					state.newProgram.addedWorks = newAddedWorks;
					state.loading = false;
					state.responseCode = 'OK';
				}
			})
			.addCase(fetchWorksOfWorksListAsync.pending, (state) => {
				return {
					...state,
					responseCode: 'PENDING_FETCH_WORKS',
					loading: true
				};
			})
			.addCase(fetchWorksOfWorksListAsync.fulfilled, (state, action) => {
				if (action.payload.worksListWorks === undefined) {
					return {
						...state,
						loading: false,
						responseCode: 'OK'
					};
				} else {
					const works = action.payload.worksListWorks.map(work => {
						const addedWork: AddedWork = {
							workCode: work.workCode,
							worksListWorkId: work.worksListWorkId,
							worksListWorkTitle: work.worksListWorkTitle,
							workISWCCode: work.workISWCCode,
							workDuration: work.workDuration,
							worksListWorkDuration: work.worksListWorkDuration,
							workSubtitles: work.workSubtitles,
							worksListWorkType: work.worksListWorkType,
							worksListWorkGenre: work.worksListWorkGenre,
							worksListWorkInterestedParties: work.worksListWorkInterestedParties,
							ipiCodes: work.worksListWorkInterestedParties.map( w => ({
								ipiCode: w.worksListWorkInterestedPartyIPICode,
								ipiName: w.worksListWorkInterestedPartyLastName })),
							worksListWorkPerformers: work.worksListWorkPerformers,
							worksListWorkJazzImprovisers: work.worksListWorkJazzImprovisers
						};
						return addedWork;
					});
					const basketWorks = action.payload.worksListWorks.map(work => {
						const workBasket: WorkBasket = {
							worksListWorkId: work.worksListWorkId,
							title: work.worksListWorkTitle
						};
						return workBasket;
					});
					return {
						...state,
						loading: false,
						responseCode: 'OK',
						newProgram: {
							...state.newProgram,
							works: basketWorks,
							addedWorks: works
						},
						error: false
					};
				}

			})
			.addCase(fetchWorksOfWorksListAsync.rejected, (state, action) => {
				// @ts-ignore
				state.responseCode = action.payload?.message;
				state.loading = false;
			})
			.addCase(patchWorkInWorksListAsync.pending, (state) => {
				return {
					...state,
					responseCode: 'PENDING_UPDATE_WORK',
					loading: true
				};
			})
			.addCase(patchWorkInWorksListAsync.fulfilled, (state, action) => {
				const authors = [...formatInterestedParties(action.meta.arg.work.author, 'A', 'Auteur')];
				const composers = [...formatInterestedParties(action.meta.arg.work.composer, 'C', 'Compositeur')];
				const performers = action.meta.arg.work?.performer ? [...formatPerformers(action.meta.arg.work.performer)] : [];
				const improvisers = action.meta.arg.work?.improviser ? [...formatImprovisers(action.meta.arg.work.improviser)] : [];
				const interestedParties = [...authors, ...composers];
				const patchedWork = {
					workCode: action.meta.arg.work.workCode,
					worksListWorkId: action.meta.arg.worksListWorkId,
					worksListWorkTitle: action.meta.arg.work.title,
					workDuration: getWorkDuration(action.meta.arg.work.duration),
					worksListWorkDuration: getWorkDuration(action.meta.arg.work.workDuration),
					workISWCCode: action.meta.arg.work.workISWC,
					workSubtitles: [''],
					worksListWorkType: action.meta.arg.work.types,
					worksListWorkGenre: {
						worksListWorkGenreCode: action.meta.arg.work.genre,
						worksListWorkGenreLabel: action.meta.arg.work.genreLabel
					},
					ipiCodes: action.meta.arg.work.ipiCodes,
					worksListWorkInterestedParties: interestedParties,
					worksListWorkPerformers: performers,
					worksListWorkJazzImprovisers: improvisers
				};
				const addedWorks = state.newProgram.addedWorks || [];
				const indexAddedWork = addedWorks.findIndex(work => work.worksListWorkId === action.meta.arg.worksListWorkId);

				const newAddedWorks = addedWorks;
				newAddedWorks[indexAddedWork] = patchedWork;

				const works = state.newProgram.works || [];
				const indexWork = works.findIndex(work => work.worksListWorkId === action.meta.arg.worksListWorkId);
				const newWorks = works;
				newWorks[indexWork] = {
					worksListWorkId: patchedWork.worksListWorkId,
					title: patchedWork.worksListWorkTitle
				};
				state.newProgram.addedWorks = newAddedWorks;
				state.newProgram.works = newWorks;
				state.loading = false;
				state.responseCode = 'UPDATED_WORK';
			})

			.addCase(createTypicalProgramAsync.pending, (state, action) => {
				return {
					...state,
					responseCode: 'CREATE_TP_PENDING',
					loading: true
				};
			})
			.addCase(createTypicalProgramAsync.fulfilled, (state, action) => {
				return {
					...state,
					loading: false,
					responseCode: 'CREATE_TP_OK',
					newProgram: {
						...state.newProgram,
						typicalProgramId: action.payload?.typicalProgramId,
						worksListTitle: action.meta.arg.worksListTitle,
					},
					error: false
				}
			})
			.addCase(createTypicalProgramAsync.rejected, (state, action) => {
				return {
					...state,
					loading: false,
					// @ts-ignore
					responseCode: action.payload?.message,
					error: true
				}
			})
		;
	}
});

export const { resetProgramNew, setSetLists, setSetListsCount, setNewProgram } = typicalProgramsSlice.actions;

export default typicalProgramsSlice.reducer;
