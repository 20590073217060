import React from 'react';
import InputMask from 'react-input-mask';
import Overlay from '../../components/overlay';
import { InputGroupProps } from '../../types';
import  iconInfo from '../../assets/img/icon-info-black-small.svg';

import './index.scss';
const InputDurationGroup = (props: InputGroupProps) => {
	const { id, className, name, label, placeholder, value, onChange, onBlur, error } = props;
	return (
		<div className="input-duration">
			<div className="input-block">
				{label && <label>{label}</label>}
				<InputMask
					id={id}
					name={name}
					mask="99:99"
					placeholder={placeholder}
					className={`${className} ${error ? 'error is-invalid' : ''}`}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
				/>
			</div>
			{ error && (
				<span className="fade input-error show">{error}</span>
			)}
		</div>
	);
};

export const InputISWCGroup = (props: InputGroupProps) => {
	const { id, className, name, label, placeholder, value, onChange, onBlur, error, ifSearchForm, contentTooltip } = props;
	return (
			<div className={`${!ifSearchForm ? 'input-block' : ''}`}>
				
				{label && <label>{label} {contentTooltip &&
					<Overlay content={contentTooltip} placement="top">
						<img src={iconInfo} alt="info"/>
					</Overlay>
					}
				</label>}
				<InputMask
					id={id}
					name={name}
					mask="T-999 999 999-9"
					placeholder={placeholder}
					className={`${className} ${error ? 'error is-invalid' : ''}`}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					type='text'
				/>
			{ error && (
				<span className="fade input-error show">{error}</span>
			)}
			
		</div>
	);
};


export const InputTextGroup = (props: InputGroupProps) => {
	const { id, name, label, placeholder, value, onChange, onBlur, error } = props;
	return (
		<div className="input-block">
			<label>{label}</label>
			<input
				id={id}
				name={name}
				type="text"
				placeholder={placeholder}
				className={error ? 'error' : ''}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
			/>
			{ error && (
				<span className="input-error">{error}</span>
			)}
		</div>
	);
};

export const InputSelectGroup = (props: InputGroupProps) => {
	const { id, name, label, placeholder, value, items, onChange, onBlur, error } = props;
	return (
		<div className="input-block">
			<label>{label}</label>
			<select
				id={id}
				name={name}
				className={`${error ? 'error' : ''} ${placeholder === value || !value ? 'holder' : ''}`}
				onChange={onChange}
				onBlur={onBlur}
				value={value || ''}
			>
				<option value="">{placeholder}</option>
				{items && items.map(i =>
					<option
						key={`item-${i.code}`}
						value={i.code}
					>{i.label}</option>
				)}
			</select>
			{ error && (
				<span className="input-error">{error}</span>
			)}
		</div>
	);
};


export default InputDurationGroup;
