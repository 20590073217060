import React from 'react'
import { VectorMap } from '@react-jvectormap/core'
import { worldMill } from '@react-jvectormap/world'
import './world-map.scss'

import JQuery from 'jquery'
import {worldMapPropsType} from "../stackedBarChart/type";

//if you want to interact with the map (zoom, click , or trigger any event) make sure the concerned is supported by the map (full list of supported map called "vectorMapAllowedRegions" could be found in src/consts/constants)
const defaultMapFunction = (event: JQuery.Event) => {
  event.preventDefault()
  return
}
const WorldMap = ({
  regionsSelectable,
  regionsSelectableOne,
  zoomAnimate,
  backgroundColor,
  className = 'default-map-style',
  mapRef,
  onRegionClick = defaultMapFunction,
  onRegionOver = defaultMapFunction,
  onRegionSelected = defaultMapFunction,
  onRegionTipShow = defaultMapFunction,
  regionStyle,
}: worldMapPropsType) => {
  return (
    <VectorMap
      className={`usage-data-world-map ${className}`}
      mapRef={mapRef}
      onRegionClick={onRegionClick}
      onRegionOver={onRegionOver}
      map={worldMill} //params loading the world map not a specific region
      regionsSelectable={regionsSelectable} // Highlight country on click
      regionsSelectableOne={regionsSelectableOne} // highlight only one country
      zoomAnimate={zoomAnimate}
      backgroundColor={backgroundColor}
      onRegionSelected={onRegionSelected}
      onRegionTipShow={onRegionTipShow} //passer une fonction qui retourne de l'html en chaine de caractères
      regionStyle={regionStyle}
    />
  )
}
export default WorldMap
