import React, { forwardRef, useEffect, useState, useCallback } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment/moment'
import './filtersComponent.scss'
const DateQuarterRangeComponent = (dateRangeProps: DateRangeProps) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ])
  const [startDate, endDate] = dateRange
  const parseDate = (date: string | undefined) => {
    if (date !== null && date !== '') {
      const parsedDate = moment(date, 'YYYYMMDD')
      if (parsedDate.isValid()) {
        return parsedDate.toDate()
      }
    }
    return null
  }

  const setDefaultDateRange = useCallback(
    (): [Date | null, Date | null] => {
    let defaultDateRange: [Date | null, Date | null] = [null, null]
    if (dateRangeProps.startDate && dateRangeProps.endDate) {
      defaultDateRange = [
        parseDate(dateRangeProps.startDate),
        parseDate(dateRangeProps.endDate),
      ]
    } else {
      defaultDateRange = [
        moment().subtract(3, 'months').toDate(),
        moment().subtract(1, 'days').toDate(),
      ]
    }

    setDateRange(defaultDateRange)
    return defaultDateRange
  },[dateRangeProps.startDate, dateRangeProps.endDate])

  useEffect(() => {
    setDefaultDateRange()
  }, [setDefaultDateRange])


  // eslint-disable-next-line react/display-name
  const ExampleCustomInput = forwardRef((props: propTypes, ref: any) => (
    <button
      className='date-quarter-range'
      onClick={props.onClick}
      ref={ref}
      style={{
        height: '36px',
        width:
          dateRangeProps.widthFilter !== null
            ? dateRangeProps.widthFilter
            : '280px',
      }}
      disabled={dateRangeProps.searchIsLoading}
    >
      <span>{props.value}</span>
      {!props.value && (
        <span className={'filter-arrow-down quarter-arrow-down'} />
      )}
    </button>
  ))

  const quarterHandleChange = (newDateRange: [Date | null, Date | null]) => {
    const [, endDate] = newDateRange
    if (endDate) {
      endDate.setMonth(endDate.getMonth() + 2)
    }
    setDateRange(newDateRange)
  }

  const updateDateRange = () => {
    if (dateRange.filter((elem) => elem == null).length > 0) {
      handleChangeParentComponent(setDefaultDateRange())
    } else {
      handleChangeParentComponent(dateRange)
    }
  }

  const handleChangeParentComponent = (
    defaultDateRange: [Date | null, Date | null]
  ) => {
    if (
      dateRangeProps.handleComponentChange &&
      defaultDateRange[0] != null &&
      defaultDateRange[1] != null
    ) {
      dateRangeProps.handleComponentChange({
        startingDate: moment(defaultDateRange[0]).format('YYYYMM'),
        endingDate: moment(defaultDateRange[1]).format('YYYYMM'),
      })
    }
  }

  // Quarter is enabled only if min date is set to the first day of the first month
  const convertMinDate = (date: Date | undefined) => {
    if (!date) {
      return null
    }
    const month = date.getMonth()
    const newMonthIndex = Math.floor(month / 3) * 3
    return new Date(date.getFullYear(), newMonthIndex, 1)
  }

  return (
    <DatePicker
      selected={startDate}
      onChange={quarterHandleChange}
      onCalendarClose={updateDateRange}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      dateFormat='yyyy, QQQ'
      showQuarterYearPicker
      customInput={<ExampleCustomInput onClick={onclick} value={''} />}
      maxDate={dateRangeProps.maxDate}
      minDate={convertMinDate(dateRangeProps.minDate)}
      disabled={dateRangeProps.searchIsLoading}
    />
  )
}

interface propTypes {
  value: any
  onClick: any
}
interface DateRangeProps {
  searchIsLoading?: boolean
  textPlaceholder?: string
  widthFilter?: string
  handleComponentChange?: Function
  maxDate?: Date
  minDate?: Date
  startDate?: string
  endDate?: string
  dateFormat?: string
}
export default DateQuarterRangeComponent
