import { IDENTITY_CONFIG, METADATA_OIDC } from './authConst';
import { UserManager, WebStorageStateStore, Log } from 'oidc-client';
import { ROUTES } from '../constants';


class AuthService extends EventTarget {
	UserManager;

	static instance;

	constructor() {
		super();
		if (AuthService.instance == null) {
			this.UserManager = new UserManager({
				...IDENTITY_CONFIG,
				userStore: new WebStorageStateStore({ store: sessionStorage }),
				metadata: {
					...METADATA_OIDC,
				},
			});
			// Logger
			Log.logger = console;
			Log.level = Log.DEBUG;
			this.UserManager.events.addUserLoaded(() => {
				if (window.location.href.indexOf('signin-oidc') !== -1) {
					this.navigateToScreen();
				}
			});
			this.UserManager.events.addSilentRenewError((e) => {
				this.signinRedirectCallback();
			});

			this.UserManager.events.addAccessTokenExpired(() => {
				this.signinSilent();
			});
			AuthService.instance = this;
		}
		return AuthService.instance;
	}


	signinRedirectCallback = () => {
		this.UserManager.signinRedirectCallback()
			.then(() => {
				console.log('signinRedirectCallback then');
			})
			.catch((ex) => {
				console.log('signinRedirectCallback error', ex);
				window.location.href = "500";
			});
	};

	getUser = async () => {
		const user = await this.UserManager.getUser();
		if (!user) {
			return await this.UserManager.signinRedirectCallback();
		}
		return user;
	};

	parseJwt = (token) => {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace('-', '+').replace('_', '/');
		return JSON.parse(window.atob(base64));
	};

	signinRedirect = () => {
		localStorage.setItem('redirectUri', window.location.pathname);
		this.UserManager.signinRedirect({});
	};

	navigateToScreen = (route = ROUTES.LANDING) => {
		window.location.replace(process.env.PUBLIC_URL + route);
	};

	isAuthenticated = () => {
		const sessionData = sessionStorage.getItem(
			`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
		);
		let oidcStorage = undefined;
		if(sessionData) {
			oidcStorage = JSON.parse(sessionData);
		}
		return !!oidcStorage && !!oidcStorage.access_token;
	};

	signinSilent = () => {
		this.UserManager.signinSilent()
			.then((user) => {
				console.log('signed in');
			})
			.catch((err) => {
				console.error(err);
				this.dispatchEvent(new Event('redirect signin'));
				setTimeout(() => {
					this.logout();
				}, 10000);
			});
	};
	signinSilentCallback = () => {
		this.UserManager.signinSilentCallback();
	};

	createSigninRequest = () => {
		return this.UserManager.createSigninRequest();
	};

	logout = () => {
		this.UserManager.signoutRedirect({
			id_token_hint: localStorage.getItem("id_token")
		}).then(data => {
			for (let key in sessionStorage) {
				if (key.indexOf('persist:') != -1) {
					sessionStorage.removeItem(key);
				}
			}
			localStorage.removeItem('switchAccountStorage');
			localStorage.removeItem('userConnected');
			localStorage.removeItem('UserProfile');

		});
		this.UserManager.clearStaleState();
	};

	signoutRedirectCallback = () => {
		this.UserManager.signoutRedirectCallback().then(() => {
			localStorage.clear();
			sessionStorage.clear();
			window.location.replace(process.env.PUBLIC_URL);
		});
		this.UserManager.clearStaleState();
	};
}


export default AuthService;
