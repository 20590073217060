import { httpCaller } from './httpCaller';
import {TerritoryLicenses, Works} from '../types';
import {invokeRemoteGet} from "./api.utils";
const MAX_TRIES = 10

/**
 * Method used to poll for a DLAKE response
 * @param resolve the method used to resolve the parent promise. It will be resolved with the data received
 * @param reject  the method used to reject the parent promise. It will be rejected with the status code
 * @param asyncId the asyncId received in the initial request
 * @param type the type of the request received in the initial request
 * @param tryNumber the number of tries. If superior to MAX_TRIES, the reject method will be invoked with a 500 code. If it is -1 it will be ignored
 * @param timeout The timeout used between tries. Default to 2000
 */
function pollForResponse<T>(
	resolve: (
		value: { data: T | null, status?: number } | PromiseLike<{ data: T | null }>
	) => void,
	reject: (reason?: any) => void,
	asyncId: string,
	type: string,
	tryNumber: number,
	timeout: number
): void {
	if (tryNumber >= MAX_TRIES) {
		reject(500)
	}
	setTimeout(() => {
		httpCaller
			.get<T | null>('usage-data/async?asyncId=' + asyncId + '&type=' + type)
			.then((response) => {
				if (response.status === 202) {
					pollForResponse(
						resolve,
						reject,
						asyncId,
						type,
						tryNumber === -1 ? tryNumber : ++tryNumber,
						timeout
					)
				} else if (response.status === 204 || response.status === 200) {
					resolve({ data: response.status === 204 ? null : response.data, status: response.status })
				} else {
					reject(response.status)
				}
			})
			.catch((error) => {
				console.error('API error', error)
				return reject(error)
			})
	}, timeout)
}

export function fetchDspList(params: { territoryCode?: number | undefined, mandatorId: string}) {
	return new Promise<any>((resolve, reject) =>
		httpCaller
			.get(`dsps`,
			{params}
				)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error: any) => {
				return reject(error);
			})
	);
}

export function fetchStreamPerOffer(params: {
	sisterSocietyEntityName: string
	startDate: string
	endDate: string
	territories?: string | undefined
	dsp: string
	repertoireId: string
	societyId: string
}) {
	const url = `usage-data/stream-per-offer-group`
	return handleResponse(url, params);
}
export function fetchMapDataUsageData(params: {
	sisterSocietyEntityName: string
	dsp: string
	territories?: string | undefined
	startDate: string
	endDate: string
	repertoireId: string
	societyId: string
}) {
	const url = `usage-data/map-data`
	return handleResponse(url, params);
}
export function fetchStreamPerWork(params: {
	sisterSocietyEntityName: string
	dsp: string
	territories?: string
	startDate: string
	endDate: string
	workId?: string
	ide12?: string[]
	repertoireId: string
	societyId: string
}) {
	const url = `usage-data/stream-per-works`
	return handleResponse(url, params, 5000);
}

export function getTerritories(params: { mandatorId: string }) {
	return new Promise<{ territories: TerritoryLicenses[] }>((resolve, reject) =>
		httpCaller
			.get(`usage-data/territories`,
			{params}
			)
			.then((response) => {
				return resolve(response.data);
			})
			.catch((error: any) => {
				return reject(error);
			})
	);
}

export function searchWorks(params: {
	sisterSocietyEntityName: string
	startDate: string
	endDate: string
	territories?: string
	dsp: string
	work: string
	repertoireId: string
	societyId: string
}) {
	return invokeRemoteGet<
		{ data: {works: Works[]}, status: number },
		{
			sisterSocietyEntityName: string
			startDate: string
			endDate: string
			territories?: string
			dsp: string
			work: string
			repertoireId: string
			societyId: string
		}
		>(`usage-data/search-works`, params)
}

export function getStreamsPerOfferPeriod(params: {
	sisterSocietyEntityName: string
	startDate: string
	endDate: string
	territories?: string
	dsp: string
	ide12: string
	repertoireId: string
	societyId: string
}) {
	const url = `usage-data/streams-per-offer-period`
	return handleResponse(url, params);
}

export function getStreamsPerDistributionsPeriod(params: {
	sisterSocietyEntityName: string
	startDate: string
	endDate: string
	territories?: string
	dsp: string
	ide12: string
	repertoireId: string
	societyId: string
}) {
	const url = `usage-data/streams-per-distributions`
	return handleResponse(url, params);
}


export function getWorksPerIDE12(params: {
	sisterSocietyEntityName: string
	startDate: string
	endDate: string
	dsp: string
	territories?: string
	ide12: string
	repertoireId: string
	societyId: string
}) {
	const url = `usage-data/works-per-ide12`
	return handleResponse(url, params);
}

function handleResponse(url: string, params: any, timeout: number = 2000) {
	return new Promise<any>((resolve, reject) =>
		httpCaller
			.get(url,
				{params}
			)
			.then((response) => {
				if (response.status === 202) {
					// If the response code is 202, we can start the polling
					return pollForResponse(
						resolve,
						reject,
						response.data.async_requestid,
						response.data.type,
						0,
						timeout
					)
				} else if (response.status === 204) {
					resolve({data: null})
				} else {
					reject(response.status)
				}
			})
			.catch((error) => {
				return reject(error);
			})
	);
}
