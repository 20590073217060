import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { WebsoRouter } from './containers/Webso-Router';

import './App.scss';
import 'font-awesome/css/font-awesome.min.css'
import {BeatLoader} from "react-spinners";
import {useAppSelector} from "./store/hooks";
import {loadingSociety} from "./slices/appContextSlice";
import { ConfigurationContext } from './providers/configurationProvider';

function App() {
	const loadingSocieties:boolean = useAppSelector(loadingSociety);
	const configuration = useAppSelector((state) => state.appContext.configuration)
	return (
		<ConfigurationContext.Provider value={configuration}>
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<div className='centered-spinner'>
					<BeatLoader
						speedMultiplier={1}
						margin={5}
						size={20}
						color={'#125FE2'}
						loading={loadingSocieties}
					/>
				</div>

				<WebsoRouter/>
				{/* <Footer {...getFooterProps(t, lang)} /> */}
			</BrowserRouter>
		</ConfigurationContext.Provider>
	);
}

export default App;
