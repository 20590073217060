import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Collapse, Col } from 'react-bootstrap';
import { BeatLoader } from 'react-spinners';
import { connect, useDispatch } from 'react-redux';

import { AppPropsType, DeleteWorksFromWorksListPayload, WorkBasket } from '../../../../types';
import { WorksCountBadge } from '../../../../components/WorksCountBadge';
import ConfirmationModal from '../../../../components/confirmationModal';
import { deleteWorkFromWorksListAsync } from '../../../../slices/setListsSlice';
import { ROUTES } from '../../../../constants';

import './index.scss';

const BasketWork = (props: Props) => {
	const { works, worksListId, societyId, onSubmit, loading, responseCode, height } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [open, setOpen] = useState(true);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const [selectedWorksListWorkId, setSelectedWorksListWorkId] = useState<
    number | undefined
  >(0);
	const [heightBasket, setHeightBasket] = useState(750);

	const deleteWork = () => {
		if (selectedWorksListWorkId && worksListId && societyId) {
			const params: DeleteWorksFromWorksListPayload = {
				worksListWorkId: selectedWorksListWorkId,
				worksListId,
				societyId
			};
			dispatch(deleteWorkFromWorksListAsync(params));
			setSelectedWorksListWorkId(0);
		}
		setShowConfirmDelete(false);
	};
	useEffect(() => {
		if (height < 200) {
			setHeightBasket(140);
		} else if (height >= 200 && height < 700) {
			setHeightBasket(320);
		} else {
			setHeightBasket(750);
		}
	}, [height]);
	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		const handleCollapse = () => {
			if (windowWidth > 768 && !open) {
				setOpen(true);
			} else if (windowWidth < 768 && open) {
				setOpen(false);
			}
		};
		return () => {
			handleCollapse();
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
			<ConfirmationModal
				open={showConfirmDelete}
				confirmLabel={t('modal.delete')}
				cancelLabel={t('modal.cancel')}
				content={t('modal.workList-work')}
				onValidate={deleteWork}
				onClose={() => setShowConfirmDelete(false)}
			/>
			<Card className="basket-card">
				<Card.Header className="header-card">
					<div className="d-flex align-items-center justify-content-between">
						<div className="col-md-6">
							<Card.Title className="header-title">
								{t('find-oeuvre.basket.title')}
							</Card.Title>
						</div>
						<div className="d-flex justify-content-end col-2">
							{works && works.length > 0 && (
								<div className="basket-work-list-header">
									<WorksCountBadge count={works?.length || 0} />
								</div>
							)}
						</div>
					</div>
				</Card.Header>
				<Collapse in={open} mountOnEnter={false}>
					<div id="collapse">
						<Card.Body className="content-card">
							<div className="basket-work-list" style={{ maxHeight:heightBasket }}>
								<div className="text-center">
									<BeatLoader
										speedMultiplier={1}
										margin={5} size={12}
										color="#125FE2"
										loading={loading &&
											('BASKET_CHANGING_WORK' === responseCode || 'PENDING_FETCH_WORKS' === responseCode)}
									/>
								</div>
								{works && works.length > 0 ? (
									works.map((w, index) => (
										<div
											key={`work-${index}`}
											className="work-item"
										>
											<Col className="work-title">{w.title}</Col>
											<button
												className="btn-delete"
												onClick={() => {
													setSelectedWorksListWorkId(w.worksListWorkId);
													setShowConfirmDelete(true);

												}}
											/>
										</div>
									))
								) : !loading && (
									<div className="empty-content">
										{t('find-oeuvre.basket.content.empty-basket')}
									</div>
								)}
							</div>
						</Card.Body>
						<Card.Footer className="footer-card">
							<div className="d-flex justify-content-end">
								<Button
									variant="light"
									className="save-draft-button"
									disabled={!works || works.length === 0}
									onClick={() => navigate(ROUTES.GET_SETLISTS + '?draft_mode=true')}
								>
									{t('find-oeuvre.basket.action.save-draft')}
								</Button>
								<Button
									variant="light"
									className="validate-button"
									disabled={!works || works.length === 0}
									onClick={() => onSubmit()}
								>
									{t('find-oeuvre.basket.action.confirm')}
								</Button>
							</div>
						</Card.Footer>
					</div>
				</Collapse>
			</Card>
		</>
	);
};
const mapStateToProps = (state: AppPropsType) => ({
	works: state.setLists.newProgram.works,
	worksListId: state.setLists.newProgram.worksListId,
	memberId: state.setLists.newProgram.worksListOwner?.memberId,
	loading: state.setLists.loading,
	responseCode: state.setLists.responseCode
});
export default connect(mapStateToProps)(BasketWork);

interface Props {
  works: WorkBasket[] | undefined;
  worksListId: number | undefined;
	societyId: number | undefined;
  onSubmit: () => void;
  loading: boolean;
  responseCode: string
  height: number
}
