import React, { useEffect, useState } from 'react';
import './index.scss';
import { Col, Container, Row } from 'react-bootstrap';
import  iconReinitialize from '../../assets/img/icon-reinitialiser.svg';
import  iconFilterProgName from '../../assets/img/icon-filter-program-name.svg';
import { useTranslation } from 'react-i18next';
import InputTextFilter from './filterInputText';

interface FilterPropsType {
    filterTitle: string
    onSearch: (filter: any) => void
    onReinitialize: () => void
    reInitializeFields : boolean
}

const Filter = (props: FilterPropsType) => {
	const { filterTitle, onSearch, onReinitialize, reInitializeFields } = props;
	const [setListName, setSetListName] = useState('');
	const { t } = useTranslation();

	useEffect(()=>{
		setSetListName('');
	},[reInitializeFields]);

	const onInputChange = (e: any) => {
		setSetListName(e.target.value);
	};
	return (
		<div className='filter-bar'>
			<Container >
				<Row className='filter-row'>
					<div className='filter-title filter-col'>
						<span>{filterTitle}</span>
					</div>
					<div className='filter-searchbar col-md-3 col-sm-5 filter-col'>
						<InputTextFilter name={'programTitle'}
							placeholder={t('set-lists.filter.program-name')}
							onChange={onInputChange}
							value={setListName}
							icon={iconFilterProgName}/>
                                         
					</div>
					<Col className="filter-col mx-0">
						<button onClick={() => onSearch(setListName)} className="btn-search">{t('set-lists.filter.search')}</button>
					</Col>
					<Col className="filter-col mx-0">
						<div className='divider' />
					</Col>
					<Col className="filter-col mx-0 p-0">
						<img src={iconReinitialize} className="-icon-reinitialize" onClick={()=>{
							onReinitialize();
							setSetListName('');}}
						/>    </Col>
                  
				</Row>
			</Container>
		</div>
	);
};

export default Filter;