import { Profile } from 'oidc-client/dist/oidc-client';
import { SelectItem } from '@mantine/core'

export interface AppPropsType{
  appContext: AppContextState;
  setLists: SetListsState;
  drafts: DraftsState;
  works: WorksState
  representations: RepresentationsState
}

export interface SisterSocieties {
  sisterSocietiesName: string|null;
  sisterSocietiesId: string;
}

export interface BreadcrumbState{
  showHome: boolean
  title: string
  buttonAction?: string
  buttonLabel?: string
  parent?: string
  featKey?: string
  isVisible?: boolean
}
export interface Identity {
  id: string;
  firstName: string;
  lastName: string;
}
export interface SisterSocietie {
  sisterSocietyId: number;
  types: string[];
  name: string;
}
export interface SisterSociety {
  sisterSocietyId: number;
  name: string;
}
export interface Mandator {
  identitiesMandators: [{mandatorId: string, name: string|null}]
}
export interface UserData {
  email: string;
  lastName: string;
  firstName: string;
  identities: SisterSociety[];
}
export interface AppContextState{
  sisterSocities: UserData | null
  switchAccount: boolean
  loading: boolean
  responseCode: string
  expirationToken: boolean
  selectedSisterSociety: SisterSociety | null
  profile : Profile | null
  breadcrumb: BreadcrumbState
  TopToasterState: TopToasterState
  mandator: number | null
  configuration: Record<string, any> | undefined
  mandatorStatus: number
  mandatorStartDate?: string
  repertoires: {
    status: number,
    data: { code: string; label: string; }[]
  },
  selectedRepertoire?: string
}

export interface SisterSocieties {
  sisterSocietiesName: string|null
  sisterSocietiesId: string
}

export interface OwnerTypicalProgram {
  memberId: string
  memberIPIName: string | null
  memberIPICode: number | null
}

export interface TypicalProgram {
  typicalProgramId: number
  typicalProgramNumberOfWorks: number
  typicalProgramOwner: OwnerTypicalProgram
  typicalProgramStartDate: string
  orchestralFormation?: string | null
  typicalProgramTitle: string
  typicalProgramType: {
    typicalProgramTypeCode: string,
    typicalProgramTypeLabel: string
  }
}

export interface WorkType {
  worksListWorkTypeCode: string
  worksListWorkTypeLabel: string
}
export interface WorksListWorkInterestedPartyAddress {
  worksListWorkInterestedPartyAddress1: string
  worksListWorkInterestedPartyAddress2: string
  worksListWorkInterestedPartyAddress3: string
  worksListWorkInterestedPartyAddress4: string
  worksListWorkInterestedPartyAddress5: string
  worksListWorkInterestedPartyAddressCity: string
  worksListWorkInterestedPartyAddressZipCode: string
  worksListWorkInterestedPartyAddressCountry: {
    worksListWorkInterestedPartyAddressCountryCode: string,
    worksListWorkInterestedPartyAddressCountryName: string
  }
}

export interface WorksListWorkInterestedPartyRoles {
  worksListWorkInterestedPartyRoleCode: string
  worksListWorkInterestedPartyRoleLabel: string
}

export interface WorksListWorkJazzImproviser
{
  worksListWorkJazzImproviserAddress: string
  worksListWorkJazzImproviserCorporateName: string
  worksListWorkJazzImproviserFirstName: string
  worksListWorkJazzImproviserLastName: string
  worksListWorkJazzImproviserIPICode: number
  worksListWorkJazzImproviserRoles: [{
    worksListWorkJazzImproviserRoleCode: string
    worksListWorkJazzImproviserRoleLabel: string
  }]
}
export function formatInterestedParties(items: any, code: string, label: string) {
	if (items.length > 0) {
		const arrayItems = items.split(',');
		return arrayItems.map((item: string) => (
			{
				worksListWorkInterestedPartyCorporateName: '',
				worksListWorkInterestedPartyFirstName: '',
				worksListWorkInterestedPartyLastName: item,
				worksListWorkInterestedPartyIPICode: null,
				worksListWorkInterestedPartyType: null,
				worksListWorkInterestedPartyRoles: [
					{
						worksListWorkInterestedPartyRoleCode: code,
						worksListWorkInterestedPartyRoleLabel: label
					}
				]
			}
		));
	} else {
		return [];
	}
}
export function formatPerformers(items: any) {
	if (items.length > 0) {
		return items.length ? [...items.split(',').map((item: string) => ({
			worksListWorkPerformerAddress: '',
			worksListWorkPerformerCorporateName: '',
			worksListWorkPerformerFirstName: '',
			worksListWorkPerformerLastName: item,
			worksListWorkPerformerIPICode: null,
			worksListWorkPerformerRoles: [
				{
					worksListWorkPerformerRoleCode: 'IN',
					worksListWorkPerformerRoleLabel: 'Interprète'
				}
			]
		}))] : [];
	} else {
		return [];
	}
}

export interface WorksListWorks {
  workCode: number
  workDuration: number
  worksListWorkDuration?: number
  workISWCCode: string
  workSubtitles: string[]
  worksListWorkType:WorkType | null
  worksListWorkGenre: WorkGenre
  worksListWorkInterestedParties: WorksListWorkInterestedParties[]
  worksListWorkPerformers: WorksListWorkPerformers[]
  worksListWorkJazzImprovisers?: any
  worksListWorkTitle: string
  worksListWorkId: number
}

export interface WorksListWorksPayload {
  worksListWorks: WorksListWorks[]
  worksListWorksCount: number
}

export function formatImprovisers(items: any) {
	if (items.length > 0) {
		return items.length ? [...items.split(',').map((item: string) => ({
			worksListWorkJazzImproviserFirstName: '',
			worksListWorkJazzImproviserLastName: item,
			worksListWorkJazzImproviserIPICode: null,
			worksListWorkJazzImproviserRoles: [
				{
					worksListWorkJazzImproviserRoleCode: 'IJ',
					worksListWorkJazzImproviserRoleLabel: 'Improvisateur'
				}
			]
		}))] : [];
	} else {
		return [];
	}
}
export interface WorksListWorkInterestedParties {
  worksListWorkInterestedPartyAddress?: WorksListWorkInterestedPartyAddress,
  worksListWorkInterestedPartyCorporateName: string
  worksListWorkInterestedPartyFirstName: string
  worksListWorkInterestedPartyLastName: string
  worksListWorkInterestedPartyIPICode: number
  worksListWorkInterestedPartyRoles: WorksListWorkInterestedPartyRoles[]
}

export interface WorksListWorkPerformerRoles {
  worksListWorkPerformerRoleCode: string
  worksListWorkPerformerRoleLabel: string
}

export interface WorksListWorkPerformers {
  worksListWorkPerformerAddress: string
  worksListWorkPerformerCorporateName: string
  worksListWorkPerformerFirstName: string
  worksListWorkPerformerLastName: string
  worksListWorkPerformerIPICode: number
  worksListWorkPerformerRoles: WorksListWorkPerformerRoles[]
}

export interface AddedWork {
  workCode: number
  worksListWorkId: number
  worksListWorkTitle: string
  workDuration: number
  worksListWorkDuration?: number
  workISWCCode: string
  workSubtitles: string[]
  worksListWorkType: WorkType | null
  worksListWorkGenre: WorkGenre
  worksListWorkInterestedParties: WorksListWorkInterestedParties[]
  worksListWorkPerformers: WorksListWorkPerformers[]
  worksListWorkJazzImprovisers?: any,
  ipiCodes?:any[]
}

export interface WorkList{
  worksListId: number
  workListOwner: string
  worksListNumberOfWorks?: number,
  worksListOwner?: {
    memberIPIName: string
    memberId: number
    memberIPICode: number
  }
  worksListType?: {
    worksListTypeCode: string,
    worksListTypeLabel: string
  }
  worksListTitle: string
  worksListStartDate?: string
  worksListTypeCode?: string
  works?: WorkBasket[]
  addedWorks?: AddedWork[]
  typicalProgramId?: number
}


export interface SetListsState {
  typicalPrograms: TypicalProgram[]
  typicalProgramsCount: number
  reason: string
  loading: boolean
  responseCode: string
  newProgram: WorkList
  error: boolean
}

export interface DraftsPayload {
  worksLists: WorkList[]
  worksListsCount: number
}
export interface DraftsState {
  worksLists: WorkList[]
  worksListsCount: number
  reason: string
}

export interface TypicalProgramPayload {
  typicalPrograms: TypicalProgram[]
  typicalProgramsCount: number
}

export interface FormProgramInterface {
  worksListId?: number
  worksListTitle: string
  worksListStartDate?: string
  worksListTypeCode?: string
  memberId?: number
}
export interface WorksListPayload {
  values: FormProgramInterface
  societyId?: number
  isDraft: boolean
}

export interface WorkBasket {
  title: string
  worksListWorkId: number
}

export interface DeleteProgramParam {
  id: number,
  societyId?: number
}

export interface DeleteWorksFromWorksListPayload {
  worksListWorkId: number
  worksListId: number
  societyId: number
}

export interface Pagination {
  page: number
  size: number
}

export interface WorksFilters {
  workTitle?: string
  aci?: string
  iswcCode?: string
  [key: string]: string | number| undefined
}

export interface WorksRequest {
  pagination: Pagination
  worksFilter: WorksFilters
}

export interface WorkGenre{
  worksListWorkGenreCode: string
  worksListWorkGenreLabel: string
}

export interface AddressCountry{
  workInterestedPartyAddressCountryCode: string,
  workInterestedPartyAddressCountryName: string
}

export interface WorkAddress{
  workInterestedPartyAddress1: string,
  workInterestedPartyAddress2: null,
  workInterestedPartyAddress3: null,
  workInterestedPartyAddress4: null,
  workInterestedPartyAddress5: null,
  workInterestedPartyAddressZipCode: string,
  workInterestedPartyAddressCity: string,
  workInterestedPartyAddressCountry: AddressCountry
}

export interface WorkInterestedPartyRole {
  workInterestedPartyRoleCode: string
  workInterestedPartyRoleLabel: string
}

export interface WorkInterestedParty {
  workInterestedPartyAddress?: WorkAddress,
  workInterestedPartyCorporateName: string
  workInterestedPartyFirstName: string
  workInterestedPartyLastName: string
  workInterestedPartyIPICode: number
  workInterestedPartyRoles: WorkInterestedPartyRole[]
}

export interface WorkPerformerRole{
  workPerformerRoleCode: string
  workPerformerRoleLabel: string
}

export interface WorkPerformer {
  workPerformerCorporateName: string
  workPerformerFirstName: string
  workPerformerLastName: string
  workPerformerIPICode: number
  workPerformerRoles: WorkPerformerRole[]
}

export interface Work {
  workToken: string
  workCode: number
  workTitle: string
  workDuration: number
  workISWCCode: string
  workSubtitles: string[]
  workType: WorkType | null
  workGenre: WorkGenre
  workInterestedParties: WorkInterestedParty[]
  workPerformers: WorkPerformer[]
}

export interface WorksState {
  loading: boolean
  responseCode: string
  works: Work[]
  worksCount: number
}

export interface WorksPayload {
  works: Work[]
  worksCount: number
}

export interface WorkFormatted {
  corporateName?: string
  firstName?: string
  lastName?: string
  address?: string
  country?: string
  role: string
  ipiCode?: number
}

export interface WorkCardType {
  workToken?: string
  workISWC?: string
  workCode: number
  title: string
  genre: string
  duration: string
  ISWCCode?: string;
  composer?: string
  author?: string
  performer?: string
  rightholders?: string[]
  editors?: (WorkFormatted|undefined)[]
  subEditors?: (WorkFormatted|undefined)[]
  improviser?: string
  worksListWorkId?: number
  nonReferenced?: boolean
  ipiCodes?: IPI[]
}

export interface programWorksPram {
  programId: number
  societyId?: number
  page?: number
  size?: number
}

export interface TypicalProgramWorkInterestedPartyAddress {
  typicalProgramWorkInterestedPartyAddress1:  string | null
  typicalProgramWorkInterestedPartyAddress2:  string | null
  typicalProgramWorkInterestedPartyAddress3:  string | null
  typicalProgramWorkInterestedPartyAddress4:  string | null
  typicalProgramWorkInterestedPartyAddress5:  string | null
  typicalProgramWorkInterestedPartyAddressCity:  string | null
  typicalProgramWorkInterestedPartyAddressCountry:  {
    typicalProgramWorkInterestedPartyAddressCountryCode: string | null
    typicalProgramWorkInterestedPartyAddressCountryName:  string | null
  }
  typicalProgramWorkInterestedPartyAddressZipCode:  string | null
}

export interface TypicalProgramWorkInterestedPartyRoles {
  typicalProgramWorkInterestedPartyRoleCode: string,
  typicalProgramWorkInterestedPartyRoleLabel: string,
}

export interface TypicalProgramWorkInterestedParties {
  typicalProgramWorkInterestedPartyAddress: TypicalProgramWorkInterestedPartyAddress | null
  typicalProgramWorkInterestedPartyCorporateName: string | null
  typicalProgramWorkInterestedPartyFirstName: string  | null
  typicalProgramWorkInterestedPartyIPICode: number | null
  typicalProgramWorkInterestedPartyLastName: string  | null
  typicalProgramWorkInterestedPartyRoles: TypicalProgramWorkInterestedPartyRoles[]
}

export interface ProgramArtworks {
  typicalProgramWorkDuration: number
  typicalProgramWorkGenre: {
    typicalProgramWorkGenreCode: string,
    typicalProgramWorkGenreLabel: string
  }
  typicalProgramWorkInterestedParties: TypicalProgramWorkInterestedParties[]
  typicalProgramWorkPerformers: string[]
  typicalProgramWorkTitle: string
  typicalProgramWorkType: string | null
  workCode: number
  workDuration: number
  workISWCCode: string
  workSubtitles: string | null
}

export interface programWorksPayload {
  links: string[]
  typicalProgramWorks: ProgramArtworks[]
  typicalProgramWorksCount: number
}
export interface WorkPayload {
  work?:{
    workCode: number,
    workToken?: string,
    workISWC?: string,
    worksListWorkDuration?: number
  }
  worksListWork?: {
    workCreators: WorkCreators[]
    worksListWorkAuthors?: string[]
    worksListWorkComposers?: string[]
    worksListWorkDuration: number
    worksListWorkJazzImprovisers?: string[]
    worksListWorkPerformers?: string[]
    worksListWorkTitle: string
    worksListWorkTypeCode: string
    workListWorkIswc?: string
  }
}

export interface WorkCreators{
  ipiNameNumber: string,
  name: string,
  role?: string

}

export interface WorkResponse {
  worksListWorkId: number
}

export interface TypicalProgramParam {
  filter?: TypicalProgramFilterSort
  pagination?: Pagination
  societyId: string
}

export interface ProgramFilterSort {
  title?: string,
}

export interface ProgramParam {
  filter?: ProgramFilterSort
  pagination?: Pagination
  societyId: string
}

export interface TypicalProgramFilterSort {
  title?: string,
}

export const typicalProgramFilterSortDefault: TypicalProgramFilterSort = {
	title: '',
};

export interface Pagination {
  page: number
  size: number
}

export interface RepresentationsResponse {
  representations: Representation[]
  representationsCount: number
}

export interface RepresentationsState {
  loading: boolean
  responseCode: string
  representations: Representation[]
  representationsCount: number
  statutes: any[]
  cancellationReasons: CancellationReason[]
}

export interface CancellationReason {
  code: string
  label: string
}

export interface Representation {
  distribution: {
    date: string
    number: string
  }
  representationId: number
  representationTitle: string
  representationDate: string
  representationPerformers: string[]
  typicalProgram: TypicalProgram
  representationTypeQualification: {
    representationTypeQualificationCode: string
    representationTypeQualificationLabel: string

  }
  representationCustomers: string[]
  status: {
    code: string
    label: string
  }
  representationAddress: RepresentationAddress
  works?: ProgramArtworks[]
}

export interface RepresentationAddress {
  representationAddress1?: string
  representationAddress2?: string
  representationAddress3?: string
  representationAddress4?: string
  representationAddress5?: string
  representationAddressCity: string
  representationAddressCountry: {
    representationAddressCountryCode: string
    representationAddressCountryName: string
  }
  representationAddressZipCode: string
  representationLieu: string
}

export interface RepresentationAddressField {
  representationAddress1?: string
  representationAddress2?: string
  representationAddress3?: string
  representationAddress4?: string
  representationAddress5?: string
  representationAddressCity: string
  representationAddressCountryCode: string
  representationAddressZipCode: string
}

export interface RepresentationsState {
  loading: boolean
  responseCode: string
  representations: Representation[]
  representationsCount: number
  statutes: any[]
}

export interface RepresentationDetailsParam {
  societyId?: number,
  representationId: number,
}

export interface RepresentationsRequest {
  societyId?: number
  pagination: Pagination
  sortOption: SortParam
  filters?: RepresentationFilters
}

export interface RepresentationFilters {
  programTitle?: string
  programId?: string
  startDate?: string
  endDate?: string
  status?: string
}

export interface SortParam {
  sortField: 'startDate' | 'representationTitle'
  sortOrder: string
  label?: string
}


export interface NonReferencedWork {
  title: string
  ISWCCode: string
  ipiCodes: IPI[]
  duration: string
}

export interface IPI{
  ipiName: string
  ipiCode: string
  role?: string
}

export interface IpiSuggest{
  ipiNames: IpiNames[]
}
export interface IpiNames{
  displayName: string,
  numbers: number[]
}

export interface InputGroupProps {
  id: string
  name: string
  className?: string
  label?: string
  placeholder: string
  value: string | undefined
  items?: { code: string, label: string }[]
  onChange: any
  onBlur: any
  lang?: string
  error?: boolean | string
  dataTestId?: string
  ifSearchForm?: boolean
  contentTooltip?: string
}

export interface CreateProgramPayload {
  worksListId: number
  worksListTitle: string,
  societyId?: number
}
export interface InputCheckBoxProps {
  id?: string;
  label?: string;
  isSelected: boolean;
  onCheckboxChange?: any;
  onBlur?: any;
  error?: string;
  required?: boolean;
  touched?: boolean;
  tooltip?: string;
  disabled?: boolean;
  classLabel?: any;
  className?: string;
}
export interface InputGroupProps {
  id: string
  name: string
  className?: string
  label?: string
  placeholder: string
  value: string | undefined
  items?: { code: string, label: string }[]
  onChange: any
  onBlur: any
  lang?: string
  error?: boolean | string
  dataTestId?: string
}
export interface DatePropsType {
}

export interface PlaceSuggest {
  id: number
  description: string
}

export interface PlaceDetails {
  address: {
    address1: string
    address2: string
    address3: string
    address4: string
    address5: string
    city: string
    country: {
      code: string
      name: string
    }
    zipCode: string
  }
  id: string
  name: string
}
export interface SortParam {
  sortField: 'startDate' | 'representationTitle'
  sortOrder: string
  label?: string
}
export interface Pagination {
  page: number
  size: number
}
export interface RepresentationFilters {
  programTitle?: string
  programId?: string
  startDate?: string
  endDate?: string
  status?: string
  [key: string]: string | undefined
}
export interface RepresentationAddress {
  representationAddress1?: string
  representationAddress2?: string
  representationAddress3?: string
  representationAddress4?: string
  representationAddress5?: string
  representationAddressCity: string
  representationAddressCountry: {
    representationAddressCountryCode: string
    representationAddressCountryName: string
  }
  representationAddressZipCode: string
}

export interface RepresentationsRequest {
  pagination: Pagination
  sortOption: SortParam
  filters?: RepresentationFilters
}
export interface RepresentationsResponse {
  representations: Representation[]
  representationsCount: number
}
export interface Representation {
  distribution: {
    date: string
    number: string
  }
  representationId: number
  representationTitle: string
  representationDate: string
  representationPerformers: string[]
  typicalProgram: TypicalProgram
  representationTypeQualification: {
    representationTypeQualificationCode: string
    representationTypeQualificationLabel: string

  }
  representationCustomers: string[]
  status: {
    code: string
    label: string
  }
  representationAddress: RepresentationAddress
}
export interface DSP {
  label: string
  code: string
}
export interface TerritoryLicenses {
  label: string
  code: number
}
export interface LicencingsDataType {
  startDate: string
  dsp: DSP
  territory: TerritoryLicenses
}
export interface LicenseType {
  territory_alpha2_code: string
  total_usage: number
}
export interface StreamsPerTerritoryPropType {
  usageDataTerritories: string[]
  streamsPerTerritory: LicenseType[]
  totalNumberOfStreams: number
}
export interface UsageDataMapType {
  total_usage: number
  streams_per_territory: LicenseType[]
}
export interface UsageDataStatus {
  mapData: number
  dsp: number
  territory: number
  streamPerOffer: number
  streamPerWork: number
  customSearchStreamPerWorks: number
  details: {
    worksPerIDE12: number
    streamPerOfferPerWorkId: number
    streamPerMonthPerWorkId: number
    streamsPerDistributions: number
    streamsPerOffers: number
  }
  works: number
}
export interface StreamPerOffer {
  streams: number
  code: string
  offerGroupName: string
  percentage?: number
}
export interface UsageDataSearchParams {
  usageDataDate: {
    startingDate: string
    endingDate: string
  }
  dsp: string
  territories: number[]
}
export interface UsageDataStreamResponse {
  total: number
  items: any
}
export interface UsageDataStreamsPerOffers {
  month: number
  offers: StreamPerOffer[]
}
export interface UsageDataStreamPerWorkDetails {
  ide12: number
  title: string
  total: number
  artists: string[]
  usagePerMonth: { date: string; total: number }[]
}
export interface UsageDataStreamPerWorkResponse {
  total: number
  items: UsageDataStreamPerWorkDetails[]
}
export interface WorkByIDE12 {
  resourceId: string
  title: string
  streams: number
}
export interface UsageDataStreamsPerDistributions {
  month: number
  distributions: { distribution: string; total: number }[]
}
export interface UsageDataWorksPerIDE12Response {
  total_usage: number
  works_per_ide12: WorkByIDE12[]
}
export interface Works {
  title: string
  ide12: string
  tiers: string[]
}
export interface UsageDataState {
  mapData: UsageDataMapType
  dspList: DSP[]
  loading: boolean
  responseCode?: string
  territoryList: TerritoryLicenses[]
  status: UsageDataStatus
  usageDataSearchParams: UsageDataSearchParams
  streamPerOffer: StreamPerOffer[]
  lastSearchValues?: UsageDataSearchParams
  streamPerWork?: UsageDataStreamPerWorkResponse
  customSearchStreamPerWorks?: UsageDataStreamPerWorkResponse
  details: {
    worksPerIDE12?: UsageDataWorksPerIDE12Response
    streamPerOfferPerWorkId?: StreamPerOffer[]
    streamPerMonthPerWorkId?: UsageDataStreamPerWorkResponse
    streamsPerDistributions?: UsageDataStreamResponse
    streamsPerOffers?: UsageDataStreamResponse
  }
  works: Works[]
}


export interface TopToasterState{
  status: boolean
  messageTranslationKey: string
  nature: 'error' | 'success' | 'warning'
}
export interface WorksDataResponse {
  count: number
  works: Work[]
}
export interface WorksDataState {
  workSearchParams: WorkSearchParams
  status: { worksDataStatus: number; worksDetailsDataStatus: number }
  page: number
  size: number
  workData: WorksDataResponse
  workDetails: WorkDetailsState
}

export interface WorkSearchParams {
  workTitle: string
  ISWCCodeList: string[]
  externalCodeList: string[]

  searchText: string
  ipiNumbers: string[]
  workCodeList: string[]
  ISRCCodeList: string[]
  agreementCodeList: string[]
}
export interface Contributor {
  ipiNameNumber: string
  ipiName: string
  role: {
    code: string
    label: string
  }
  contractCodes: string[]
}
export interface WorksDetailsContributor extends Contributor {
  performingRights: number
  reproductionRights: number
  lettering: string | undefined
}
export interface WorksDetailsTerritory {
  code: number
  label: string
}
export interface SearchedWork {
  titles: {
    label: string
    main: boolean
  }[]
  contributors: Contributor[]
  iswcs: {
    code: string
    main: boolean
  }[]
  isrcs: string[]
  ide12: string
  publisherCodes: string[]
  isComposite: boolean
}
export interface WorksDetailsDataResponse extends SearchedWork {
  contributors: WorksDetailsContributor[]
  publisherWorkCodes: string[]
  dynamicKeys: {
    territory: WorksDetailsTerritory
    contributors: WorksDetailsContributor[]
  }[]
  isrcs: string[]
  isComposite: boolean
}
export interface WorkDetailsState {
  mandatorId: string
  ide12: string
  data: WorksDetailsDataResponse
}
export interface WorkSearchRequestParams {
  mandatorId: string
  title?: string
  isrcs?: string[]
  iswcs?: string[]
  publisherWorkCodes?: string[]
  contractCodes?: string[]
  contributorIpiNameNumbers?: string[]
  ide12?: string[]
  page?: number
  size?: number
}

export interface WorksDataResponseApi {
  data: WorksDataResponse,
  status: number
}

export interface WorkDetailsRequestParams {
  mandatorId: string
  ide12: string
}

export interface route {
    path: string
    component: React.JSX.Element
    public: boolean
    pageTitle: string
    confKey?: string
    role?: string
}

export interface MultiSelectAutoCompleteItem extends SelectItem {
  code: number
}

export type Overflow = 'visible' | 'hidden' | 'scroll' | 'auto' | 'inherit' | 'initial';
