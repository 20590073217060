import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { TypicalProgramWorkInterestedParties } from '../../types';

import './index.scss';

//@ts-ignore
import iconInterestedPartyName from '../../assets/img/icon-interested-party-name.svg';
//@ts-ignore
import IconInterestedPartyIpi from '../../assets/img/icon-interested-party-ipi.svg';

interface EditorInterestedPartyProps {
  party: TypicalProgramWorkInterestedParties
}

export default function EditorInterestedParty(props: EditorInterestedPartyProps) {
	const { party } = props;
	const ipi = party.typicalProgramWorkInterestedPartyIPICode;
	const address = party.typicalProgramWorkInterestedPartyAddress;
	const { t } = useTranslation();

	return (
		<Row className='interested-parties-body h-25'>
			{party.typicalProgramWorkInterestedPartyCorporateName && (
				<Col xs={8}>
					<img src={iconInterestedPartyName} className="interested-parties-icon name"/>
					<span className='interested-parties-name'>
						{party.typicalProgramWorkInterestedPartyCorporateName}
					</span>
				</Col>
			)}

			{address && address.typicalProgramWorkInterestedPartyAddress1 &&
        <Col xs={12} className='address'>
        	<img src={IconInterestedPartyIpi} className="interested-parties-icon"/>
        	<span className='interested-parties-editor'>
        		{address.typicalProgramWorkInterestedPartyAddress1}
        	</span>
        </Col>
			}

			{address &&
        <Col xs={12} md={8} className='address'>
        	<img src={IconInterestedPartyIpi} className="interested-parties-icon"/>
        	<span className='interested-parties-editor'>
        		{address.typicalProgramWorkInterestedPartyAddressZipCode + ' ' + address.typicalProgramWorkInterestedPartyAddressCity}
        	</span>
        </Col>
			}

			{address && address.typicalProgramWorkInterestedPartyAddressCountry.typicalProgramWorkInterestedPartyAddressCountryName &&
        <Col xs={12} md={8} className='address'>
        	<img src={IconInterestedPartyIpi} className="interested-parties-icon"/>
        	<span className='interested-parties-editor'>
        		{address.typicalProgramWorkInterestedPartyAddressCountry.typicalProgramWorkInterestedPartyAddressCountryName}
        	</span>
        </Col>
			}

			{ipi! > 0 ? (
				<>
					<Col xs={8}>
						<img src={IconInterestedPartyIpi} className="interested-parties-icon"/>
						<span className='interested-parties-ipi'>
							{ipi}
						</span>
					</Col>
					<Col xs={4}>
						<h6 className="align-right">
							{t('set-lists.works.ipi')}
						</h6>
					</Col>
				</>
			) : ('')}

		</Row>
	);
}
