import React from 'react';
import './filterInputText.scss';

interface FilterPropsType {
    placeholder: string
    onChange: (e: any) => void
    value: string
    icon?: any
    name: string
}

const InputTextFilter = (props: FilterPropsType) => {
	const { placeholder, value, icon, name, onChange } = props;
	return (
		<div className="filter-input-progs d-flex">
			<input className="d-flex withoutBorder" type="text" placeholder={placeholder}
				onChange={onChange} value={value} name={name} autoComplete="off" />
			{icon && <img src={icon}/>}
		</div>
	);
};

export default InputTextFilter;