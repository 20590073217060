import { invokeRemoteGet, pollGet } from './api.utils'
import {
  DistributionRoyaltiesFiltersParams,
  DistributionRoyaltiesSearchParams,
  DistributionsAmountPayload,
  DistributionsWorksPayload,
  ExploitationTerritoriesPayload,
} from '../types/distributionsRoyaltiesTypes'

export function getDistributions(params: { mandatorId: string, startDate: string }) {
  return invokeRemoteGet<any, any>(
    'distribution-royalties/distributions',
    params
  )
}


export function getMapDataDistributionRoyalties(params: ExploitationTerritoriesPayload) {
  const path = `distribution-royalties/exploitationTerritories`
  return pollGet<
    any,
    ExploitationTerritoriesPayload
  >(path, 'distribution-royalties/async', params)
}

export function getDistributionsAmount(params: DistributionsAmountPayload) {
  const path = `distribution-royalties/amount`
  return pollGet<
    any,
    DistributionsAmountPayload
  >(path, 'distribution-royalties/async', params)
}

export function getCatalogues(params: any) {
  return pollGet<any, any>(
    'distribution-royalties/catalogues',
    'distribution-royalties/async',
    params,
    4000
  )
}
export function getExploitationPeriods(
  params: DistributionRoyaltiesSearchParams
) {
  return pollGet<any, DistributionRoyaltiesSearchParams>(
    'distribution-royalties/exploitationPeriods',
    'distribution-royalties/async',
    params,
    4000
  )
}

export function getWorksSuggest(params: DistributionRoyaltiesFiltersParams) {
  return invokeRemoteGet<any, any>(
    '/distribution-royalties/workSuggests',
    params
  )
}

export function getCatalogsSuggest(params: DistributionRoyaltiesFiltersParams) {
  return invokeRemoteGet<any, any>(
    '/distribution-royalties/originalCatalogSuggests',
    params
  )
}

export function getRightsTypes(params: DistributionRoyaltiesSearchParams) {
  return pollGet<any, DistributionRoyaltiesSearchParams>(
    'distribution-royalties/rights-types',
    'distribution-royalties/async',

    params,
    4000
  )
}
export function getWorks(params: DistributionsWorksPayload) {
  return pollGet<any, any>(
    'distribution-royalties/works',
    'distribution-royalties/async',
    params,
    4000
  )
}
