declare const window: any;

class ATInternetService {
	private tag: any;

	constructor() {
		this.tag = this.newTag({ secure: true });
	}

	newTag(options: any) {
		try {
			const tag = new window.ATInternet.Tracker.Tag(options);
			this.setPrivacyMode(tag);
			return tag;
		} catch(ex) {
			return {
				dispatch: () => ({}),
				page: { set: () => ({}) },
				click: { send: () => ({}) }
			};
		}
	}

	setPrivacyMode(tag: any) {
		try {
			if (!tag) {
				tag = this.newTag({ secure: true });
			}

			if (window.OnetrustActiveGroups.includes(',C0002,')) { //check si l'utilisateur a accepté les cookies performances
				tag.privacy.setVisitorOptin(); // Visiteur défini en Opt-In
			} else { //ce cas quand l'utilisateur n'a pas accepté
				tag.privacy.setVisitorMode('cnil', 'exempt'); // Visiteur défini en Exempt
			}
		} catch(ex) {
			console.error('could not set ATInternet privacy mode', this.tag);
		}
	}

	//@param info: {name: string, level2?: string, chapter1?: string, chapter2?: string, chapter3?: string, customObject?: any}
	sendPage(info: any) {
		this.setPrivacyMode(this.tag);
		this.tag.page.set(info);
		this.tag.dispatch();
	}

	//@param info: {elem: any, name: string, level2?: string, chapter1?: string, chapter2?: string, chapter3?: string, type: string, customObject?: any}
	sendClick(info: any) {
		this.setPrivacyMode(this.tag);
		this.tag.click.send(info);
	}

}

export const tag = new ATInternetService();