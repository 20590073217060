import { CellObject, ExcelDataType } from "xlsx-js-style"

export const HEADER_BG_COLOR = '4472C4'
export const HEADER_FONT_COLOR = 'FFFFFF'
export const ODD_LINE_COLOR = 'D9E1F2'
export const EVEN_LINE_COLOR = 'EEF1F5'
export const PERCENTAGE_FORMAT = "0.00%"
export const CURRENCY_FORMAT = "€#,##0.00"

export const CELL_TYPE_STRING = 's' as ExcelDataType
export const CELL_TYPE_EMPTY = 'z' as ExcelDataType
export const CELL_TYPE_NUMBER = 'n' as ExcelDataType
export const CELL_TYPE_DATE = 'd' as ExcelDataType

export function mapHeader(item: CellObject): CellObject {
    item.s = { ... item.s, ... { font: { color: { rgb: HEADER_FONT_COLOR } },  fill: { fgColor: { rgb: HEADER_BG_COLOR } } } }
    return item;
}

export function mapOdd(item: CellObject): CellObject {
    item.s = { ... item.s, ... { fill: { fgColor: { rgb: ODD_LINE_COLOR } } } }
    return item;
}

export function mapEven(item: CellObject): CellObject {
    item.s = { ... item.s, ... { fill: { fgColor: { rgb: EVEN_LINE_COLOR } } } }
    return item;
}