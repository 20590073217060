import React from 'react'
import { Catalogue } from '../../../types/distributionsRoyaltiesTypes'
import { formatCurrencyNumber } from '../../../helpers/formatter'
import { useTranslation } from 'react-i18next'

interface CataloguesBoxProps {
  catalogues: Catalogue[]
}

const CataloguesBox = ({ catalogues }: CataloguesBoxProps) => {
  const { i18n } = useTranslation()
  return (
    <>
      {catalogues.map((catalogue, index) => {
        return (
          <div
            style={{ fontFamily: 'avenir', paddingTop: '15px' }}
            key={catalogue.id}
          >
            <div>{catalogue.name}</div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBlock: '5px',
              }}
            >
              <div style={{ width: 'fit-content', color: '#495057' }}>
                {formatCurrencyNumber(i18n.language, catalogue.generatedAmount)}
              </div>
              <div style={{ width: 'fit-content', color: '#C41C89' }}>
                {catalogue.percentage + '%'}
              </div>
            </div>

            {index + 1 !== catalogues.length && (
              <div className='separator'></div>
            )}
            {index === 0 && catalogues.length === 1 && (
              <div className='separator'></div>
            )}
          </div>
        )
      })}
    </>
  )
}
export default CataloguesBox
