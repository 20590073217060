import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './boxSection.scss'
import Form from 'react-bootstrap/Form'
import ExportButton from '../exportButton'
import { CellObject, JSON2SheetOpts } from 'xlsx-js-style'
import PageStatus from '../pageStatus'
import { BeatLoader } from 'react-spinners';
import { Overflow } from '../../types'
const BoxSectionComponent = ({
  disabled = false,
  bodyContent = <div className='under-contruction'>Under construction</div>,
  title,
  exclusiveSort,
  status = {
    statusCode: -2,
    defaultMessage: '',
    noDataFoundMessage: '',
    unexpectedErrorMessage: '',
  },
  subtitle = '',
  exportButtonOptions = {
    exportType: '',
    titleExport: 'Export',
    opts: {},
    colOptions: [],
    getData: () => {
      return []
    },
  },
  showExportButton = false,
  hasMoneySort = false,
  hasAlphaNumSort = false,
  hasHeaderSwitch = false,
  headerSwitchText = 'default',
  headerSwitchisChecked = false,
  onAlphaNumSort,
  onMoneySort,
  onSwitch,
  overflowY = 'auto',
}: BoxSectionPropTypes) => {
  const hasSortOptions = hasMoneySort || hasAlphaNumSort

  const headerHeight = '47px'
  const footerHeight = '47px'
  const boxHeight = '100%'

  const contentHeight = () => {
    if (hasSortOptions)
      return `calc(${boxHeight} - ${headerHeight} - ${footerHeight} )`
    return boxHeight
  }

  const [alphaNumSortActive, setAlphaNumSortActive] = useState(false)
  const [moneySortActive, setMoneySortActive] = useState(true)
  const [isChecked, setIsChecked] = useState(headerSwitchisChecked)

  const handleAlphaNumSort = () => {
    setAlphaNumSortActive(!alphaNumSortActive)
    exclusiveSort && setMoneySortActive(false)
    onAlphaNumSort && onAlphaNumSort(!alphaNumSortActive)
  }

  const handleMoneySort = () => {
    setMoneySortActive(!moneySortActive)
    exclusiveSort && setAlphaNumSortActive(false)
    onMoneySort && onMoneySort(!moneySortActive)
  }

  const handleSwitch = () => {
    setIsChecked(!isChecked)
    onSwitch && onSwitch(!isChecked)
  }

  return (
    <Container fluid className='box-section'>
      <Row>
        <Col className='box-section-header'>
          <div className='d-flex align-items-center'>
            {' '}
            <div className='title'>{title}</div>
            <span className='sub-title'>{subtitle}</span>
          </div>

          {hasHeaderSwitch && (
            <Form.Check
              type='switch'
              id='custom-switch'
              label={headerSwitchText}
              className='switch custom-control-input-lg'
              checked={isChecked}
              onChange={handleSwitch}
              disabled={disabled}
            ></Form.Check>
          )}
          {exportButtonOptions && showExportButton && (
            <ExportButton
              getData={exportButtonOptions.getData}
              exportType={exportButtonOptions.exportType}
              titleExport={exportButtonOptions.titleExport}
              opts={exportButtonOptions.opts}
              colOptions={exportButtonOptions.colOptions}
            />
          )}
        </Col>
      </Row>
      <Row
        style={{
          overflowY: overflowY,
          maxHeight: contentHeight(),
        }}
      >
        
        {status.statusCode === -1 ?
          <div className="custom-spinner">
            <BeatLoader
              speedMultiplier={1}
              margin={5}
              size={20}
              color="#125FE2"
              loading={true}
            />
          </div>
          :
          status.statusCode !== 200 && status.statusCode !== -3 ? (
            <PageStatus
              status={status.statusCode}
              defaultMessage={status.defaultMessage}
              noDataFoundMessage={status.noDataFoundMessage}
              unexpectedErrorMessage={status.unexpectedErrorMessage}
              contentHeight={contentHeight()}
            />
          ) : status.statusCode === 200 || status.statusCode === -3 ? (
            bodyContent
          ) : (
            <div>something went wrong</div>
          )}
      </Row>
      {hasSortOptions && (
        <Row className="box-section-footer">
          <Col className="d-flex align-items-center justify-content-between">
            <div style={{ whiteSpace: "nowrap" }}>Sort by</div>
            <div className='sort-line'></div>

            <div className='d-flex'>
              {hasAlphaNumSort && (
                <div
                  onClick={handleAlphaNumSort}
                  className={
                    alphaNumSortActive
                      ? 'sort-alpha-icon-on'
                      : 'sort-alpha-icon-off'
                  }
                ></div>
              )}
              {hasMoneySort && (
                <div
                  onClick={handleMoneySort}
                  className={
                    moneySortActive
                      ? 'sort-money-icon-on'
                      : 'sort-money-icon-off'
                  }
                ></div>
              )}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default BoxSectionComponent

interface BoxSectionPropTypes {
  disabled?: boolean
  headerSwitchisChecked?: boolean
  status: Status
  hasMoneySort?: boolean
  hasAlphaNumSort?: boolean
  exclusiveSort?: boolean
  onSwitch?: (isChecked: boolean) => void
  onAlphaNumSort?: (isAlphaNumSortActive: boolean) => void
  onMoneySort?: (isMoneySortActive: boolean) => void
  bodyContent?: React.ReactNode
  headerSwitchText?: string
  hasHeaderSwitch?: boolean
  showExportButton?: boolean
  exportButtonOptions?: ExportButtonOptions
  title: string
  subtitle?: string,
  overflowY?: Overflow | undefined,
}

interface ExportButtonOptions {
  exportType: string
  titleExport?: string
  opts: JSON2SheetOpts
  colOptions: { wch?: number }[]
  getData: () => CellObject[][]
}

interface Status {
  statusCode: number
  defaultMessage: string
  noDataFoundMessage: string
  unexpectedErrorMessage: string
}
