import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import moment from 'moment/moment'
import {UsageDataState, UsageDataStreamPerWorkResponse, UsageDataWorksPerIDE12Response} from '../types';
import {
	fetchDspList,
	fetchMapDataUsageData,
	fetchStreamPerOffer,
	fetchStreamPerWork,
	getStreamsPerDistributionsPeriod,
	getStreamsPerOfferPeriod,
	getTerritories,
	getWorksPerIDE12,
	searchWorks
} from '../api/usageDataAPI';


export const initialState: UsageDataState = {
	status: {
		dsp: -1,
		territory: -1,
		mapData: -2,
		streamPerOffer: -2,
		streamPerWork: -2,
		customSearchStreamPerWorks: -2,
		details: {
			worksPerIDE12: -2,
			streamPerMonthPerWorkId: -2,
			streamPerOfferPerWorkId: -2,
			streamsPerDistributions: -2,
			streamsPerOffers: -2,
		},
		works: -2,
	},
	loading: false,
	responseCode: '0',
	dspList: [],
	territoryList: [],
	mapData: { total_usage: 0, streams_per_territory: [] },
	streamPerOffer: [],
	streamPerWork: undefined,
	usageDataSearchParams: {
		usageDataDate: {
			startingDate: moment().subtract(6, 'months').format('YYYYMM'),
			endingDate: moment().subtract(3, 'months').format('YYYYMM')
		},
		dsp: '',
		territories: [],
	},
	details: {},
	works: [],
}

export const fetchStreamPerOfferAsync = createAsyncThunk(
	'usageData-dsps/stream-per-offer/fetch',
	async (params: {
		sisterSocietyEntityName: string
		startDate: string
		endDate: string
		territories?: string | undefined
		dsp: string
		repertoireId: string
		societyId: string
	}, { rejectWithValue }) => {
		try {
			return await fetchStreamPerOffer(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const fetchMapDataUsageDataAsync = createAsyncThunk(
	'usage-data-map-data/fetch',
	async (params: {
		sisterSocietyEntityName: string
		dsp: string
		territories?: string | undefined
		startDate: string
		endDate: string
		repertoireId: string
		societyId: string
	}, { rejectWithValue }) => {
		try {
			return await fetchMapDataUsageData(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const fetchCustomSearchStreamPerWorkAsync = createAsyncThunk(
	'usage-data/custom-stream-per-work/fetch',
	async (params: {
		sisterSocietyEntityName: string
		dsp: string
		territories?: string
		startDate: string
		endDate: string
		workId?: string
		ide12?: string[]
		repertoireId: string
		societyId: string
	}, { rejectWithValue }) => {
		try {
			return await fetchStreamPerWork(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const fetchStreamPerWorkAsync = createAsyncThunk(
	'usage-data/stream-per-work/fetch',
	async (params: {
		sisterSocietyEntityName: string
		dsp: string
		territories?: string
		startDate: string
		endDate: string
		workId?: string
		ide12?: string[]
		repertoireId: string
		societyId: string
	}, { rejectWithValue }) => {
		try {
			return await fetchStreamPerWork(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const fetchDspListAsync = createAsyncThunk(
	'usageData-dsps/fetch',
	async (params: {
		mandatorId: string
		territoryCode?: number
	}, { rejectWithValue }) => {
		try {
			return await fetchDspList(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const fetchTerritoryListAsync = createAsyncThunk(
	'usageData-territories/fetch',
	async (params: {
		mandatorId: string
	}, { rejectWithValue }) => {
		try {
			return await getTerritories(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchUsageDataWorkSearchAsync = createAsyncThunk(
	'usage-data-search-works/fetch',
	async (params: {
		sisterSocietyEntityName: string
		startDate: string
		endDate: string
		territories?: string
		dsp: string
		work: string
		repertoireId: string
		societyId: string
	}, { rejectWithValue }) => {
		try {
			return await searchWorks(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchUsageDataStreamsPerOffersAsync = createAsyncThunk(
	'usage-data-streams-per-offers/load',
	async (params: {
		sisterSocietyEntityName: string
		startDate: string
		endDate: string
		territories?: string
		dsp: string
		ide12: string
		repertoireId: string
		societyId: string
	}, { rejectWithValue }) => {
		try {
			return await getStreamsPerOfferPeriod(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const fetchUsageDataStreamsPerDistributionsAsync = createAsyncThunk(
	'usage-data-streams-per-distributions/fetch',
	async (params: {
		sisterSocietyEntityName: string
		startDate: string
		endDate: string
		territories?: string
		dsp: string
		ide12: string
		repertoireId: string
		societyId: string
	}, { rejectWithValue }) => {
		try {
			return await getStreamsPerDistributionsPeriod(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const getWorksPerIDE12Async = createAsyncThunk(
	'usage-data-works-per-ide12/load',
	async (params: {
		sisterSocietyEntityName: string
		startDate: string
		endDate: string
		dsp: string
		territories?: string
		ide12: string
		repertoireId: string
		societyId: string
	}, { rejectWithValue }) => {
		try {
			return await getWorksPerIDE12(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const fetchStreamPerMonthAsync = createAsyncThunk(
	'usage-data/stream-per-month/load',
	async (params: {
		sisterSocietyEntityName: string
		dsp: string
		territories?: string
		startDate: string
		endDate: string
		workId?: string
		ide12?: string[]
		repertoireId: string
		societyId: string
	}, { rejectWithValue }) => {
		try {
			return await fetchStreamPerWork(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);
export const fetchStreamPerWorkPerOfferAsync = createAsyncThunk(
	'usage-data/stream-per-work-per-offer/fetch',
	async (params: {
		sisterSocietyEntityName: string
		startDate: string
		endDate: string
		territories?: string | undefined
		dsp: string
		repertoireId: string
		societyId: string
	}, { rejectWithValue }) => {
		try {
			return await fetchStreamPerOffer(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);


export const usageDataSlice = createSlice({
	name: 'usageData',
	initialState,
	reducers: {
		resetUsageDataDetailsState:(state) => {
			return {...state,
				status:{
				...state.status,
					details: {
					...state.details,
						worksPerIDE12: state.status.details.worksPerIDE12,
						streamPerMonthPerWorkId: -2,
						streamPerOfferPerWorkId: -2,
						streamsPerDistributions: -2,
						streamsPerOffers: -2,
					}
				},
				details: {
					...state.details,
					streamPerMonthPerWorkId: undefined,
					streamPerOfferPerWorkId: undefined,
					worksPerIDE12: undefined,
				}
			}
		},
		resetUsageSearchDataState:(state) => {
			state.mapData = { total_usage: 0, streams_per_territory: [] }
			state.streamPerOffer = []
			state.details = {}
			state.status.streamPerWork = -2
			state.status.customSearchStreamPerWorks = -2
			state.status.works = -2
			state.customSearchStreamPerWorks = undefined
			state.works = []
		},
		resetLastSearchValues:(state) => {
			state.lastSearchValues = undefined
		},
		resetUsageDataState:(state) => {
			state.status = {
				...initialState.status,
			}
			state.mapData = { ...initialState.mapData }
			state.streamPerOffer = []
			state.usageDataSearchParams = initialState.usageDataSearchParams
			state.details = {}
			state.lastSearchValues = undefined
			state.customSearchStreamPerWorks = undefined
			state.works = []
		},
		loadUsageDataTerritoryList: (state,action) => {
			return ({ ...state,
			usageDataSearchParams: {...state.usageDataSearchParams, territories: action.payload} })},
		loadUsageDataDate: (state,action)=> {
			return ({ ...state,
			usageDataSearchParams: {...state.usageDataSearchParams, usageDataDate: action.payload} })},
		loadUsageDataDspList: (state,action)=>({ ...state,
			usageDataSearchParams: {...state.usageDataSearchParams, dsp: action.payload} }),
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchStreamPerOfferAsync.pending, (state: UsageDataState, action) => {
				state.loading = true;
				state.responseCode = 'OK';
				state.status.streamPerOffer = -1
			})
			.addCase(fetchStreamPerOfferAsync.fulfilled, (state: UsageDataState, action: any) => {
				state.streamPerOffer = action.payload.data ? action.payload.data.items : []
				state.status.streamPerOffer = action.payload.status

			})
			.addCase(fetchStreamPerOfferAsync.rejected, (state:UsageDataState, action: any) => {
				return {
					...state,
					// @ts-ignore
					responseCode: action.payload?.message,
					loading: false
				};
			})
			.addCase(fetchMapDataUsageDataAsync.pending, (state: UsageDataState, action) => {
				state.loading = true
				state.responseCode = 'OK'
				state.status.mapData = -1
			})
			.addCase(fetchMapDataUsageDataAsync.fulfilled, (state: UsageDataState, action: any) => {
				if (action.payload.data) {
					state.mapData = action.payload.data
				}
				state.status.mapData = action.payload.status
			})
			.addCase(fetchMapDataUsageDataAsync.rejected, (state: UsageDataState, action: any) => {
				return {
					...state,
					// @ts-ignore
					responseCode: action.payload?.message,
					loading: false
				};
			})
			.addCase(fetchStreamPerWorkAsync.pending, (state: UsageDataState) => {
				state.loading = true
				state.responseCode = 'OK'
				state.status.streamPerWork = -1
				state.lastSearchValues = state.usageDataSearchParams
			})
			.addCase(fetchStreamPerWorkAsync.fulfilled, (state: UsageDataState, action) => {
				state.streamPerWork = action.payload.data
				state.status.streamPerWork = action.payload.status ?? 204
			})
			.addCase(fetchStreamPerWorkAsync.rejected, (state: UsageDataState, action: any) => {
				return {
					...state,
					// @ts-ignore
					responseCode: action.payload?.message,
					loading: false
				};
			})
			.addCase(fetchCustomSearchStreamPerWorkAsync.pending, (state: UsageDataState, action) => {
				state.loading = true
				state.status.customSearchStreamPerWorks = -1
			})
			.addCase(fetchCustomSearchStreamPerWorkAsync.fulfilled, (state: UsageDataState, action) => {
				state.customSearchStreamPerWorks = action.payload.data
				state.status.customSearchStreamPerWorks = action.payload.status
			})
			.addCase(fetchCustomSearchStreamPerWorkAsync.rejected, (state: UsageDataState, action: any) => {
				state.status.customSearchStreamPerWorks = action.payload?.status ? action.payload.status : 500
			})
			.addCase(fetchDspListAsync.pending, (state: UsageDataState, action) => {
				state.loading = true
				state.responseCode = 'OK'
			})
			.addCase(fetchDspListAsync.fulfilled, (state: UsageDataState, action) => {
				state.dspList = action.payload.dsps
				// Keep only the existing items in the searchParams
				if (state.dspList?.length === 0) {
					state.usageDataSearchParams.dsp = ''
				} else if (state.dspList) {
					if (
						!state.dspList
							.map((item) => item.code)
							.includes(state.usageDataSearchParams.dsp)
					) {
						state.usageDataSearchParams.dsp = ''
					}
				} else {
					state.dspList = []
				}
				state.status.dsp = 200
			})
			.addCase(fetchDspListAsync.rejected, (state, action) => {
				return {
					...state,
					// @ts-ignore
					responseCode: action.payload?.message,
					loading: false,
					status: {
						...state.status,
						dsp: -2
					}
				};
			})
			.addCase(fetchTerritoryListAsync.pending, (state: UsageDataState, action) => {
				state.loading = true
				state.responseCode = 'OK'
			})
			.addCase(fetchTerritoryListAsync.fulfilled, (state: UsageDataState, action) => {
				if (action.payload.territories) {
					state.territoryList = action.payload.territories
					state.usageDataSearchParams.territories =
						state.usageDataSearchParams.territories.filter((item) =>
							state.territoryList.map((item) => item.code).includes(item)
						)
				}
				state.status.territory = 200
			})
			.addCase(fetchTerritoryListAsync.rejected, (state, action) => {
				return {
					...state,
					// @ts-ignore
					responseCode: action.payload?.message,
					loading: false
				};
			})
			.addCase(fetchUsageDataWorkSearchAsync.pending, (state: UsageDataState, action) => {
				state.loading = true
				state.status.works = -1
			})
			.addCase(fetchUsageDataWorkSearchAsync.fulfilled, (state: UsageDataState, action: any) => {
				state.works = action.payload.data.works
				state.status.works = action.payload.status
			})
			.addCase(fetchUsageDataWorkSearchAsync.rejected, (state, action: any) => {
				state.status.works = action.payload?.status
			})
			.addCase(fetchUsageDataStreamsPerOffersAsync.pending, (state: UsageDataState, action) => {
				state.loading = true
				state.responseCode = 'OK'
				state.status.details.streamsPerOffers = -1
			})
			.addCase(fetchUsageDataStreamsPerOffersAsync.fulfilled, (state: UsageDataState, action) => {
				const sortedStreamsByOffersResponse: UsageDataStreamPerWorkResponse = action.payload.data
				if (sortedStreamsByOffersResponse) {
					sortedStreamsByOffersResponse.items.sort((a: any, b: any) =>
						a.month > b.month ? -1 : 1
					)
					state.details.streamsPerOffers = sortedStreamsByOffersResponse
				}
				state.status.details.streamsPerOffers = action.payload.status
			})
			.addCase(fetchUsageDataStreamsPerOffersAsync.rejected, (state, action) => {
				return {
					...state,
					// @ts-ignore
					responseCode: action.payload?.message,
					loading: false
				};
			})
			.addCase(fetchUsageDataStreamsPerDistributionsAsync.pending, (state: UsageDataState, action) => {
				state.loading = true
				state.responseCode = 'OK'
				state.status.details.streamsPerDistributions = -1
			})
			.addCase(fetchUsageDataStreamsPerDistributionsAsync.fulfilled, (state: UsageDataState, action) => {
				const sortedStreamsByDistributionsResponse: UsageDataStreamPerWorkResponse = action.payload.data
				if (sortedStreamsByDistributionsResponse) {
					sortedStreamsByDistributionsResponse.items.sort((a: any, b: any) =>
						a.month > b.month ? -1 : 1
					)
					state.details.streamsPerDistributions = sortedStreamsByDistributionsResponse
				}
				state.status.details.streamsPerDistributions = action.payload.status
			})
			.addCase(fetchUsageDataStreamsPerDistributionsAsync.rejected, (state, action) => {
				return {
					...state,
					// @ts-ignore
					responseCode: action.payload?.message,
					loading: false
				};
			})
			.addCase(getWorksPerIDE12Async.pending, (state: UsageDataState, action) => {
				state.loading = true
				state.responseCode = 'OK'
				state.status.details.worksPerIDE12 = -1
			})
			.addCase(getWorksPerIDE12Async.fulfilled, (state: UsageDataState, action) => {
				const sortedWorksPerIDE12Response: UsageDataWorksPerIDE12Response =
					action.payload.data
				sortedWorksPerIDE12Response.works_per_ide12.sort((a, b) =>
					a.streams > b.streams ? -1 : 1
				)
				state.details.worksPerIDE12 = sortedWorksPerIDE12Response
				state.status.details.worksPerIDE12 = action.payload.status
			})
			.addCase(getWorksPerIDE12Async.rejected, (state, action) => {
				return {
					...state,
					// @ts-ignore
					responseCode: action.payload?.message,
					loading: false
				};
			})
			.addCase(fetchStreamPerMonthAsync.pending, (state: UsageDataState, action) => {
				state.loading = true
				state.responseCode = 'OK'
				state.status.details.streamPerMonthPerWorkId = -1
			})
			.addCase(fetchStreamPerMonthAsync.fulfilled, (state: UsageDataState, action) => {
				state.details.streamPerMonthPerWorkId = action.payload.data
				state.status.details.streamPerMonthPerWorkId = action.payload.status
			})
			.addCase(fetchStreamPerMonthAsync.rejected, (state, action) => {
				return {
					...state,
					// @ts-ignore
					responseCode: action.payload?.message,
					loading: false
				};
			})
			.addCase(fetchStreamPerWorkPerOfferAsync.pending, (state: UsageDataState, action) => {
				state.loading = true
				state.status.details.streamPerOfferPerWorkId = -1
			})
			.addCase(fetchStreamPerWorkPerOfferAsync.fulfilled, (state: UsageDataState, action) => {
				if (action.payload.data) state.details.streamPerOfferPerWorkId = action.payload.data.items
				state.status.details.streamPerOfferPerWorkId = action.payload.status
			})
			.addCase(fetchStreamPerWorkPerOfferAsync.rejected, (state, action) => {
				return {
					...state,
					// @ts-ignore
					responseCode: action.payload?.message,
					loading: false
				};
			})
	},
});
export const {
	loadUsageDataTerritoryList,
	loadUsageDataDate,
	loadUsageDataDspList,
	resetUsageDataDetailsState,
	resetUsageSearchDataState,
	resetLastSearchValues,
	resetUsageDataState
} = usageDataSlice.actions;

export default usageDataSlice.reducer;
