import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Form, Row, Button, Modal, ToastContainer, Toast } from 'react-bootstrap';
import { BeatLoader } from 'react-spinners';

import { AppPropsType, WorkList } from '../../../types';
import {
	createTypicalProgramAsync,
	fetchWorksOfWorksListAsync, postWorksListAsync, updateSetListAsync
} from '../../../slices/setListsSlice';

import CustomCollapse from '../components/collapse';

import '../index.scss';
import ConfirmationModal from "../../../components/confirmationModal";
import { ROUTES } from '../../../constants';

const ThirdStep = (props: Props) => {
	const { sisterSocietyId , selectedWorkList, saveDraft, responseCode, setStep, valuesProgram } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [showModal, setShowModal] = useState(false);
	const [contentmentChecked, setContentmentChecked] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [contentToast, setContentToast] = useState('');
	const [errors, setErrors] = useState<any>({});
	const [title, setTitle] = useState<string>(selectedWorkList.worksListTitle);

	useEffect(() => {
		if ('CREATE_TP_OK' === responseCode) {
			navigate(ROUTES.GET_SETLISTS);
		}
		if ('existed.title' === responseCode) {
			setContentToast(t('errors.existedTitle'));
			setShowToast(true);
		}
	}, [responseCode]);

	useEffect(() => {
		if (selectedWorkList.worksListId !== 0) {
			dispatch(fetchWorksOfWorksListAsync({
				societyId: sisterSocietyId,
				worksListId: selectedWorkList.worksListId
			}));
		}
	},[selectedWorkList.worksListId]);

	const handleSubmit = () => {
		if (!contentmentChecked) {
			setShowModal(true);
		} else if (!errors.worksListTitle) {
			const values = {
				worksListId: selectedWorkList.worksListId,
				worksListTitle: title,
				societyId: sisterSocietyId,
			};
			dispatch(createTypicalProgramAsync(values));
		}
	};

	const handleClose = () => setShowModal(false);

	const handleChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const worksListTitleValue = e.target.value;
		if (worksListTitleValue.length > 50 ) {
			setErrors({ worksListTitle: t('error.form.limit-characters') });
		}
		else if (worksListTitleValue.length === 0) {
			setErrors({ worksListTitle: t('errors.empty-field', { field: 'the program title' }) });
		} else {
			setErrors({});
		}
		setTitle(worksListTitleValue);

	};
	const handleBlurTitle = () => {
		valuesProgram.worksListTitle = title;
		if (title.length > 0) {
			setIsEditMode(false);
			if (title !== selectedWorkList.worksListTitle){
				const patchWorkList = {
					worksListId: selectedWorkList.worksListId,
					worksListTitle: title,
				}
				dispatch(postWorksListAsync({
					values: patchWorkList,
					societyId: sisterSocietyId,
					isDraft: false
				}))
			}
			//dispatch(updateSetListAsync(title));
		} else {
			setIsEditMode(true);
			errors.worksListTitle = t('errors.empty-field', { field: 'the program title' });
		}
	};
	return (
		<>
			<div className="card-box step-three mx-auto">
				<div className="header col-12 d-flex align-items-center" id="Card-header">
					<Col className="header-title col-9 d-flex">
						{!isEditMode ? (
							<div className="col-12 d-flex justify-content-start align-items-center">
								<div className="set-list-title">{title}</div>
								<div className="edit-icon">
									<div className="pen" onClick={() => setIsEditMode(true)}> </div>
								</div>
							</div>
						) : <div className={`${
							errors.worksListTitle && 'd-flex flex-wrap'
						}`}>
							<div className="">
								<Form.Control
									className={`form-control ${
										errors.worksListTitle && 'is-invalid'
									}`}
									id="title"
									name="title"
									type="text"
									defaultValue={title}
									onChange={handleChange}
									onBlur={handleBlurTitle}
								/>
							</div>

						</div>}
					</Col>
					<Col className="header-btn col-3">
						<Button
							className="custom-btn-add d-flex"
							type="button"
							onClick={() => setStep(1)}
							data-orig={t('page.new-program.step-three.btn-add-works')}
						> <div>{t('page.new-program.step-three.btn-add-works')}</div> </Button>
					</Col>
				</div>
				<Card.Body>
					<Col className="program-info mx-auto" xs={11}  onClick={() => setIsEditMode(false)}>
						<div className="d-md-block">
							<div>
								<div className="basket-work-list-header row d-flex ">
									<div className="subtitle col-10">
										{t('find-oeuvre.basket.content.title')}
									</div>
									<div className="col-2 display-flex align-items-center justify-content-end">
										<div className="works-badge">
											<div className="header-nbr-oeuvre">
												{selectedWorkList?.works?.length}
											</div>
										</div>
									</div>
									<div className="p-10">
										<div className="dashed-line" />
									</div>
								</div>
								{selectedWorkList.worksListId && selectedWorkList.addedWorks && selectedWorkList.addedWorks.length > 0 ? (
									selectedWorkList.addedWorks.map(
										(item: any, index: number) => (
											<CustomCollapse
												key={`work-${index}`}
												item={item}
												index={index}
												lengthList={selectedWorkList.works && selectedWorkList.works.length ? selectedWorkList.works.length : 0}
												societyId={sisterSocietyId}
												worksListId={selectedWorkList?.worksListId}
												responseCode={responseCode}
											/>
										)
									)
								) : responseCode === 'PENDING_FETCH_WORKS' ?
									<div className="text-center">
										<BeatLoader
											speedMultiplier={1}
											margin={5} size={12}
											color="#125FE2"
										/>
									</div> : (
										<div className="empty-content">
											{t('find-oeuvre.basket.content.empty-basket')}
										</div>
									)}
							</div>
						</div>

						<Row className="card-body-bottom"  onClick={() => setIsEditMode(false)}>
							<Col xs={12} md={12}>
								<Form.Check
									type="checkbox"
									label={t('page.new-program.step-three.consentement')}
									id="checkbox-consentement"
									className="agreement"
									onChange={(e) => setContentmentChecked(e.target.checked)}
								/>
							</Col>
						</Row>
					</Col>
				</Card.Body>
				<Card.Footer  onClick={() => setIsEditMode(false)}>
					{responseCode === 'CREATE_TP_PENDING' &&
						<div className="text-center">
							<BeatLoader
								speedMultiplier={1}
								margin={5} size={12}
								color="#125FE2"
							/>
						</div>
					}
					<div className="container-btn">
						<Button
							type="button"
							disabled={!valuesProgram.worksListTitle}
							onClick={saveDraft}
						>
							{t('btn.save-draft')}
						</Button>
						<span className="mt-3 d-md-none">{''}</span>
						<Button
							type="button"
							disabled={
								errors.worksListTitle ||
								!contentmentChecked
							}
							onClick={handleSubmit}
						>
							{t('form.btn.submit')}
						</Button>
					</div>
				</Card.Footer>
			</div>

			<Modal show={showModal} onHide={handleClose} size="lg" centered>
				<Modal.Header closeButton>
					<Modal.Title>{t('form.btn.confirm')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('page.new-program.step-three.consntement-not-checked')}
				</Modal.Body>
				<Modal.Footer className="container-btn">
					<Button onClick={handleClose}>{t('form.btn.cancel')}</Button>
				</Modal.Footer>
			</Modal>

			<ConfirmationModal
				open={showToast}
				content={t(contentToast)}
				confirmLabel={t('actions.quit')}
				onClose={() => setShowToast(false)}
				withAction={false}
			/>
		</>
	);
};
const mapStateToProps = (state: AppPropsType) => ({
	selectedWorkList: state.setLists.newProgram,
	sisterSocietyId: state.appContext.selectedSisterSociety?.sisterSocietyId,
	responseCode: state.setLists.responseCode
});
export default connect(mapStateToProps) (ThirdStep);

interface Props {
	sisterSocietyId: number | undefined
	selectedWorkList: WorkList
	saveDraft: () => void,
	valuesProgram: {
		worksListTitle: string,
		worksListStartDate?: string,
		worksListTypeCode?: string,
		memberId?: string | number
	},
	setStep: (i:number) => void,
	responseCode: string,
};
