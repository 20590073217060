import React from 'react';
import './index.scss';

interface Props {
  icon?: string
  title: string
}

const HeaderCard = (props:Props) => {
	const { title, icon } = props;

	return (
		<div className={`header-card ${icon ? `icon-${icon}` : ''}`}>
			{title}
		</div>
	);
};

export default HeaderCard;