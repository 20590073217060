import React  from 'react';
import Form from 'react-bootstrap/Form';

const FormInputText = (props: Props) => {
	const {
		label,
		className,
		classNameGroup,
		classNameLabel,
		id,
		errors,
		placeholder,
		onChange,
		defaultValue,
		autoComplete,
	} = props;

	return (
		<Form.Group className={`my-4 px-1 ${classNameGroup ? classNameGroup : ''}`}>
			{label && <Form.Label className={classNameLabel ?? ''}>{label}</Form.Label>}
			<Form.Control
				className={`py-2 px-3 ${className ?? ''}`}
				id={id}
				name={id}
				type="text"
				placeholder={placeholder ?? ''}
				onChange={(e) => onChange(e)}
				defaultValue={defaultValue ?? ''}
				autoComplete={autoComplete ?? 'off'}
			/>
			{errors && (<div className="fade error-field show">{errors[id]}</div>)}
		</Form.Group>
	);
};

export default FormInputText;

interface Props {
	id: string
	onChange: (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
	className?: string
	classNameLabel?: string
	classNameGroup?: string
	label?: string
	errors?: any
	placeholder?: string
	defaultValue?: string,
  disableBtn?:boolean,
	autoComplete?: string
	dataTestId?: string
}
