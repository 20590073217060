import moment from "moment";
import { formatCurrencyNumber, formatNumber } from './formatter'
import { ScaleOptionsByType } from 'chart.js'
import { DeepPartial } from 'chart.js/types/utils'

const DATE_FORMAT = "YYYYMM"

// const dateDisplayFormat = {
//     day: 'DD/MM/YYYY',
//     week: 'DD/MM/YYYY',
//     month: 'MMM YYYY',
//     quarter: 'MM/YYYY',
//     year: 'YYYY',
// }
interface XAxisOptionsProps{
    startDate?: string
    endDate?: string
    lang: string
    xAxisType?: string
    frequency?: "day" | "week" | "month" | "quarter" | "year"
    autoSkip?: boolean
}

interface YAxisOptionsProps{
    yMin?: number
    yMax?: number
    autoSkip: boolean,
    beginAtZero: boolean
    lang?: string

}


export function getXOptions(props : XAxisOptionsProps) {
    moment.locale(props.lang)
    let xOptions: any = {}
    if (props.xAxisType && props.xAxisType === 'time')
        xOptions = {
            type: "time",
            min: moment(props.startDate, DATE_FORMAT).format('YYYY-MM-DD'),
            max: moment(props.endDate, DATE_FORMAT).format('YYYY-MM-DD'),
            ticks: {
                autoSkip: props.autoSkip,
                maxRotation: 35,
                minRotation: 35
            },
        }
    else
        xOptions = {
            stacked: true,
            ticks: {
                autoSkip: props.autoSkip,
                maxRotation: 30,
                minRotation: 30
            }
        }
    return xOptions
}

export function getYOptions(props : YAxisOptionsProps): DeepPartial<ScaleOptionsByType<'linear'>> {
    const yOptions: DeepPartial<ScaleOptionsByType> =  {
        stacked: true,
        grid: {
            offset: true
        },
        ticks: {
            autoSkip: true,
            callback: (value) => {
              return formatNumber(props.lang || 'en', Number(value), false)
            },
        },
        offset: true,
        beginAtZero: true
    }
    if(props.yMin)
        yOptions.min = props.yMin
    if(props.yMax)
        yOptions.max = props.yMax

    return yOptions
}

export function getCurrencyYOptions(props : YAxisOptionsProps): DeepPartial<ScaleOptionsByType<'linear'>> {

    const lang = props.lang ? props.lang : "en"
    const yOptions: DeepPartial<ScaleOptionsByType> =  {
        stacked: true,
        grid: {
            offset: true
        },
        ticks: {
            autoSkip: props.autoSkip,
            callback: (value) => {
                return formatCurrencyNumber(lang, parseFloat(value.toString()))
            }
        },
        offset: true,
        beginAtZero: props.beginAtZero
    }
    if(props.yMin)
        yOptions.min = props.yMin
    if(props.yMax)
        yOptions.max = props.yMax

    return yOptions
}

export function getPercentageYOptions(
    props: YAxisOptionsProps
  ): DeepPartial<ScaleOptionsByType<'linear'>> {
    //const lang = props.lang ? props.lang : "en"
    const yOptions: DeepPartial<ScaleOptionsByType> = {
      stacked: true,
      grid: {
        offset: true,
      },
      ticks: {
        autoSkip: true,
        callback: (value) => {
          return value + '%'
        },
      },
      offset: true,
      beginAtZero: props.beginAtZero,
    }
    if (props.yMin) {
      yOptions.min = props.yMin
    }
    if (props.yMax) {
      yOptions.max = props.yMax
    }
  
    return yOptions
  }