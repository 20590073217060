import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TypicalProgram } from '../../../../types';
import Overlay from '../../../../components/overlay';
import { WorksCountBadge } from '../../../../components/WorksCountBadge';

import { ROUTES } from '../../../../constants';
import './index.scss';

interface propType {
  typicalProgram: TypicalProgram
}

const SetListCard = (props: propType) => {
	const { typicalProgram } = props;
	const { t } = useTranslation();

	return (
		<div className="setListCard">
			<div className="d-flex justify-content-between col-12 align-items-center">
				<div className='col-3'>
					<label>{typicalProgram.typicalProgramTitle}</label>
				</div>
				<div className='col-3'>
					<label>{typicalProgram.typicalProgramStartDate}</label>
				</div>
				<div className='col-3 d-flex justify-content-start'>
					<div className='setLists-id'>N°</div>
					<label>{typicalProgram.typicalProgramId}</label>
				</div>
				<div className='col-3'>
					<div className='d-flex justify-content-between'>

						<WorksCountBadge count={typicalProgram.typicalProgramNumberOfWorks} />

						<div className='Separate-vertical'/>

						{/*<Overlay content={t('set-lists.card.duplicate')} placement="top">
							<div className='on-hover icon-files' onClick={()=>{
								//implement onclick action
							}}/>
						</Overlay>*/}
						<Link to={`${ROUTES.CREATE_EVENT}?programId=${typicalProgram.typicalProgramId}`}>
							<Overlay content={t('set-lists.card.associate')} placement="top">
								<div className='icon-calendar on-hover'/>
							</Overlay>
						</Link>

						<Link to={`${typicalProgram?.typicalProgramId}`}>
							<Overlay content={t('set-lists.card.showdetails')} placement="top">
								<div className='on-hover arrow-right-dark'/>
							</Overlay>
						</Link>
					</div>
				</div>
			</div>

		</div>
	);
};

export default SetListCard;
