import React, { useEffect, useState } from 'react';
import { fetchRepresentationDetails } from '../../api/representationsAPI';
import { useParams } from 'react-router-dom';
import BigCard from '../../components/cardDetails';
import { Col, Container, Row } from 'react-bootstrap';
import { setBreadcrumb } from '../../slices/appContextSlice';
import { connect, useDispatch } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { renderRepresentationAddress } from '../../helpers';
import { useTranslation } from 'react-i18next';
import {
	AppContextState,
	AppPropsType,
	Representation,
	RepresentationsState,
} from '../../types';
import Works from '../../components/workList';
import moment from 'moment';
import NavigateBackButton from '../../components/navigateBack';
import { ROLES, ROUTES } from '../../constants';

import './index.scss';
import useRoleRedirect from '../../hooks/useRoleRedirect';


const RepresentationsDetails = (props: RepresentationDetailsProps) => {
	useRoleRedirect(ROLES.WEBSO_WEB_ONLINE);
	const { appContext } = props;
	const { representationId } = useParams();
	const [representation, setRepresentation] = useState<Representation | undefined>(undefined);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<Error | undefined>(undefined);
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();

	const params = {
		representationId: parseInt(representationId!),
		societyId: appContext.selectedSisterSociety?.sisterSocietyId,
	};


	useEffect(() => {
		dispatch(
			setBreadcrumb({
				title: `${t('breadcrumb.dates-title')} - ${representation?.representationTitle || ''}`,
				buttonAction: ROUTES.CREATE_EVENT,
				buttonLabel: 'representation.create-new',
				showHome: true
			})
		);

		if (representationId && !representation ) {
			fetchRepresentationDetails(params)
				.then(res => setRepresentation(res))
				.catch(e => setError(e.message))
				.finally(() => setLoading(false));
		}

	}, [representation]);

	const representationHeader = (
		date: string,
		title: string,
		status: any
	) => {
		return (
			<div className="header-representation">
				<div className="d-flex flex-column align-items-start">
					<div className="date">{moment(date).format('DD MMM YYYY').toUpperCase()}</div>
					<div className="title">{title}</div>
				</div>
				<div className="d-flex justify-content-end">
					<div className={`status ${status.code === 'Répartie' ? 'distributed' : ''}`}>
						{status.label}
					</div>
				</div>
			</div>
		);
	};

	const representationDetails = (date: Representation) => {
		const { typicalProgram, distribution } = date;
		return (
			<Row className="body-representation">
				<CardLine
					label={t('representation.distribution')}
					value={distribution ? `${distribution.number} ${i18n.language === 'en' ? 'of' : 'du'} ${moment(distribution.date).format('DD MMM YYYY')}` : ''}
				/>
				{ typicalProgram?.typicalProgramId !== 0 && (
					<>
						<CardLine
							label={t('set-lists.setlist-number')}
							value={`${typicalProgram?.typicalProgramId}`}
						/>
						<CardLine
							label={t('representation.set-list')}
							value={typicalProgram?.typicalProgramTitle}
						/>
					</>
				)}
				<CardLine
					label={t('representation.customer')}
					value={date.representationCustomers && date.representationCustomers[0]}
				/>
				<CardLine
					label={t('representation.location')}
					value={date.representationAddress?.representationLieu && date.representationAddress.representationLieu}
				/>
				<CardLine label={t('representation.address')}>
					{renderRepresentationAddress(date.representationAddress)}
				</CardLine>
				<CardLine
					label={t('representation.details-performer')}
					value={
						date.representationPerformers && date.representationPerformers[0]
					}
				/>
				<CardLine
					label={t('representation.qualification')}
					value={
						date.representationTypeQualification?.representationTypeQualificationLabel
					}
				/>
			</Row>
		);
	};

	return (
		<div className="main-container-artworks">
			<Container>
				<div className="text-center">
					<BeatLoader
						speedMultiplier={1}
						margin={5}
						size={20}
						color={'#125FE2'}
						loading={loading}
					/>
				</div>
				{!loading && representation && (
					<>
						<Row>
							<Col className="mb-3">
								<NavigateBackButton
									url={ROUTES.GET_EVENTS}
								/>
							</Col>
						</Row>
						<div className="d-flex justify-content-center">
							<BigCard
								header={representationHeader(
									representation.representationDate,
									representation.representationTitle,
									representation.status
								)}
								detailsInfo={representationDetails(representation)}
								worksCount={
									representation.typicalProgram.typicalProgramNumberOfWorks
								}
							>
								{representation.works && representation.works.length ? (
									representation.works.map((w, index) => (
										<Works
											key={index.toString()}
											work={w}
											id={index}
											count={representation?.works?.length}
										/>
									))) : (
									<div className="empty-data" id="empty-worklist">
										{t('programs.errors.empty-works')}
									</div>
								)}
							</BigCard>
						</div>
					</>
				)}
				{!loading && error && (
					<div className="empty-data" id="empty-reprensentation-details">
						{t('errors.generic')}
					</div>
				)}
			</Container>
		</div>
	);
};

const CardLine = (props: {
  label: string;
  value?: string;
  children?: any;
}) =>
	props.value || props.children ? (
		<div className="card-line">
			<div className="label">{props.label}</div>
			<div className="value">{props.value || props.children}</div>
		</div>
	) : (
		<></>
	);

const mapStateToProps = (state: AppPropsType) => ({
	appContext: state.appContext,
	representations: state.representations,
});

interface RepresentationDetailsProps {
  appContext: AppContextState;
  representations: RepresentationsState;
}

export default connect(mapStateToProps)(RepresentationsDetails);
