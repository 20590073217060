import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Representation, RepresentationsRequest, RepresentationsState } from '../types';
import {
	cancelRepresentation,
	fetchRepresentationCancellationReasons,
	fetchRepresentations,
	fetchRepresentationsStatutes
} from '../api/representationsAPI';

export const initialState: RepresentationsState = {
	loading: false,
	responseCode: 'OK',
	representations: [],
	representationsCount: 0,
	statutes: [],
	cancellationReasons: []
};

export const fetchRepresentationsAsync = createAsyncThunk(
	'representations/fetchRepresentations',
	async (params: RepresentationsRequest, { rejectWithValue }) => {
		try {
			return await fetchRepresentations(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const deleteRepresentationsAsync = createAsyncThunk(
	'representations/deleteRepresentation',
	async (params: any, { rejectWithValue }) => {
		try {
			const { representationId, societyId, cancellationReason } = params;
			return await cancelRepresentation(representationId, societyId, cancellationReason);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchRepresentationsStatutesAsync = createAsyncThunk(
	'representations/fetchRepresentationsStatutes',
	async (params: any, { rejectWithValue }) => {
		try {
			return await fetchRepresentationsStatutes(params.societyId);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchRepresentationCancellationReasonsAsync = createAsyncThunk(
	'representations/fetchRepresentationCancellationReasons',
	async (params: any, { rejectWithValue }) => {
		try {
			return await fetchRepresentationCancellationReasons(params.societyId);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const representationsSlice = createSlice({
	name: 'representations',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchRepresentationsAsync.pending, (state) => {
				state.loading = true;
				state.responseCode = 'OK';
			})
			.addCase(fetchRepresentationsAsync.fulfilled, (state, action) => {
				return {
					...state,
					loading: false,
					responseCode: 'OK',
					representationsCount: action.payload.representationsCount || 0,
					representations:  action.payload.representations || [],
				};
			})
			.addCase(fetchRepresentationsAsync.rejected, (state, action) => {
				return {
					...state,
					// @ts-ignore
					responseCode: action.payload?.message,
					loading: false
				};
			})
			.addCase(deleteRepresentationsAsync.pending, (state, action) => {
				return {
					...state,
					responseCode: 'CANCEL_PENDING'
				};
			})
			.addCase(deleteRepresentationsAsync.fulfilled, (state, action) => {
				return {
					...state,
					responseCode: 'CANCEL_OK',
					representations: state.representations.filter((r: Representation) => r.representationId !== action.meta.arg.representationId),
					representationsCount: state.representationsCount - 1,
				};
			})
			.addCase(fetchRepresentationsStatutesAsync.fulfilled, (state, action) => {
				return {
					...state,
					statutes: action.payload
				};
			})
			.addCase(fetchRepresentationCancellationReasonsAsync.fulfilled, (state, action) => {
				return {
					...state,
					cancellationReasons: action.payload
				};
			});
	},
});

export default representationsSlice.reducer;
