import React, { useEffect, useState, useCallback } from 'react'
import './lineChart.scss'
import { Line } from 'react-chartjs-2'
import { Chart, ChartData, ChartDataset, registerables } from 'chart.js'
import {
  borderColorGrid,
  colorGrid,
  colorTicks,
} from './platformColors'
import 'chartjs-adapter-moment'

import PageStatus from '../pageStatus'
import { merge as _merge } from 'lodash-es'

Chart.register(...registerables)

interface DisplayedChartData
  extends ChartData<'line', { x: string; y: number }[], any> { }

interface TogglableItem {
  label: string,
  code: string | number
}

const LineChartComponent = <T extends unknown>({
  loadingStatus,
  inputData,
  transformerFunction,
  togglableStates,
  xOptions,
  yOptions,
  defaultMessage,
  noDataFoundMessage,
  unexpectedErrorMessage,
  width,
  height,
} : {
  inputData?: T[],
  loadingStatus?: number,
  transformerFunction: (item: T, index: number) => ChartDataset<'line', { x: string; y: number }[]>,
  togglableStates: (TogglableItem & { hidden: boolean })[],
  xOptions?: any,
  yOptions?: any,
  defaultMessage: string,
  noDataFoundMessage: string,
  unexpectedErrorMessage: string,
  width: number,
  height: number,
}) => {
  const [chartData, setChartData] = useState<DisplayedChartData>()

  const getChartDataPositionByDateRange = useCallback((): DisplayedChartData => {

    const chartData: DisplayedChartData = {
      datasets: [],
    }
    // @ts-ignore
    inputData.map((item, idx) => {
      const te = transformerFunction(item, idx);
    })

    chartData.datasets.push(
      ...(inputData || []).map(transformerFunction)
    )
    return chartData
  }, [togglableStates, inputData])

  useEffect(() => {
    setChartData(getChartDataPositionByDateRange())
  }, [inputData, togglableStates, getChartDataPositionByDateRange])
  return (
    <div className="usage-data-line-chart">
        {loadingStatus !== 200 ? (
            <div className='text-center'>
              <PageStatus
                status={loadingStatus || -1}
                defaultMessage={defaultMessage}
                noDataFoundMessage={noDataFoundMessage}
                unexpectedErrorMessage={unexpectedErrorMessage}
              />
            </div>
        ) : (
          <>
            {chartData && (
              <Line
                className={'courbe-style'}
                data={chartData}
                width={width}
                height={height}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    x: _merge(xOptions, { ticks: { ...colorTicks } }, { grid: { ...borderColorGrid, ...colorGrid } }),
                    y: _merge(yOptions, { ticks: { ...colorTicks } }, { grid: { ...borderColorGrid, ...colorGrid } }),
                  },
                }}
              />
            )}
          </>
        )}
    </div>
  )
}

export default LineChartComponent
