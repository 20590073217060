import axios, { AxiosInstance } from 'axios';
import { onConfigLoaded } from '../config';
import i18n from '../i18n/i18n';
import AuthService from '../oidcAuthentication/authService';
export let httpCaller: AxiosInstance = axios.create();

onConfigLoaded((appConfig: any) => {
	httpCaller = axios.create({
		baseURL: appConfig.API_URL,
		timeout: 30000,
		headers: {
			'X-Api-Key': appConfig.API_KEY,
			Accept: 'application/json',
			'Content-Type': 'application/json',
			language: i18n.language,
		},
		withCredentials: true,
	});
	httpCaller.interceptors.request.use(
		(request) => requestHandler(request),
		(error) => errorHandler(error)
	);
	httpCaller.interceptors.response.use(
		(response) => responseHandler(response),
		(error) => errorHandler(error)
	);
});

const requestHandler = (request: any) => {
	const authSessionStorage = getUserFromStorage();
	if(authSessionStorage && authSessionStorage.access_token) {
		request.headers['Authorization'] = authSessionStorage.access_token;
	}
	return request;
};

const responseHandler = (response: any) => {
	return response;
};

const errorHandler = (error: any) => {
	if (error?.response?.status === 401) {
		// IDAM1 signin redirect
		const authService = new AuthService();
		authService.signinRedirect();
	}
	if (
		error?.response?.status === 403
	) {
		// Forbiden page redirection
		// Fixe Me
		console.error(error);

	}
	return Promise.reject(error?.response);
};

const getUserFromStorage = () => {
	try {
		const oidcStorage = JSON.parse(<string>sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`));

		return !!oidcStorage && !!oidcStorage.access_token ? oidcStorage : undefined;
	} catch (e){
		return undefined;
	}

};
