import React from 'react';
import { useTranslation } from 'react-i18next';
import 'react-tooltip/dist/react-tooltip.css';

import './index.scss';

const STEPS_LENGTH = 3;

const WebsoStepper = (props: Props) => {
	const { handleSteps, step, currentStep } = props;
	const { t } = useTranslation();

	return (
		<>
			{step < STEPS_LENGTH &&
        <div className="w-100">
        	<div className="stepper-container">
        		<div className="stepper-block">
        			<div className={`${step === 0 && 'active'} stepper-item ${currentStep > 0 && step > 0 && 'step-done'}`}
        				onClick={() => {
        					handleSteps(0);
        				}}
        				id="stepper-0"
        				data-tooltip-content={t('page.new-program.step1.title')}>{currentStep > 0 && step > 0 ? '' : '1'}
        			</div>
        		</div>
        		<div className="stepper-line" />
        		<div className="stepper-block">
        			<div className={`${step === 1 && 'active'} stepper-item ${currentStep > 1 && step > 1 && 'step-done'}`}
        				onClick={() => {
        					if (0 < currentStep) {
        						handleSteps(1);
        					}
        				}}
        				id="stepper-1"
        				data-tooltip-content={t('page.new-program.step2.title')}>{currentStep > 1 && step > 1 ? '' : '2'}
        			</div>
        		</div>
        		<div className="stepper-line" />
        		<div className="stepper-block">
        			<div className={`${step === 2 && 'active'} stepper-item`}
        				onClick={() => {
        					if (1 < currentStep) {
        						handleSteps(2);
        					}
        				}}
        				id="stepper-2"
        				data-tooltip-content={t('page.new-program.step-three.recap')}>3
        			</div>
        		</div>
        	</div>
        </div>
			}
		</>
	);
};
export default WebsoStepper;
interface Props {
  handleSteps: (step: number) => void
  step: number
  currentStep: number
}