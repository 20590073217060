import { ChartDataset, TooltipCallbacks, TooltipItem } from 'chart.js'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { StackedBarChart } from '../../../components/stackedBarChart'
import { useTranslation } from 'react-i18next'
import {
  getCurrencyYOptions,
  getXOptions,
} from '../../../helpers/chartHelper'
import { DeepPartial } from 'chart.js/types/utils';
import {
  formatCurrencyNumber,
  formatNumber,
} from '../../../helpers/formatter'
import { PlatformColors } from '../../../components/platformColors'
import { ExploitationPeriod } from '../../../types/distributionsRoyaltiesTypes'
import {
  Accordion,
  AccordionContext,
  Card,
  ProgressBar,
  useAccordionButton,
} from 'react-bootstrap'
import { i18n } from 'i18next'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  BarColors,
  getRandomHexColor,
} from '../../../components/stackedBarChart/barColors'
import { PeriodExploitationType } from '../../../constants/constants'

interface ExploitationPeriodBoxProps {
  type: PeriodExploitationType.DSP | PeriodExploitationType.OFFER
  exploitationPeriods: ExploitationPeriod[]
}

const ExploitationPeriodBox = ({
  exploitationPeriods,
  type,
}: ExploitationPeriodBoxProps) => {
  const [isMobile, setIsMobile] = useState<boolean>()

  const { screenWidth } = useWindowDimensions()

  useEffect(() => {
    setIsMobile(screenWidth <= 768)
  }, [screenWidth])

  const { t, i18n } = useTranslation()

  const tooltipFormatNumbersCallback = (): DeepPartial<
    TooltipCallbacks<'bar'>
  > => {
    return {
      label: function (context: TooltipItem<'bar'>) {
        const {
          dataset: { label, data },
          dataIndex,
        } = context
        const { percentage } = data[dataIndex] as any

        return label + ': ' + formatNumber(i18n.language, percentage) + '%'
      },
      footer: function (context: TooltipItem<'bar'>[]) {
        const {
          parsed: { y },
        } = context[0]
        let label = `${t(
          'chart.stacked-bar.tooltip.ms-claimed-amount'
        )}: `
        if (y) {
          label += formatCurrencyNumber(i18n.language, y)
        }
        return label
      },
    }
  }

  const chartData: {
    labels: string[]
    datasets: ChartDataset<
      'bar',
      { period: string; percentage: number; amount: number; code: string }[]
    >[]
  } = useMemo(() => {
    const labels = exploitationPeriods.map(
      (exploitationPeriod) => exploitationPeriod.period
    )
    const mappedChartData: ChartDataset<
      'bar',
      { period: string; percentage: number; amount: number; code: string }[]
    >[] = []
    exploitationPeriods.forEach((exploitationPeriod) => {
      const { period, items } = exploitationPeriod

      items.forEach((item) => {
        const {
          amount: { value, percentage },
          label,
          code,
        } = item
        const index = mappedChartData.findIndex((data) => data.label === label)
        if (index >= 0) {
          mappedChartData[index].data.push({
            period: period,
            percentage: percentage,
            amount: value,
            code: code,
          })
        } else {
          mappedChartData.push({
            label: label,
            data: [
              {
                period: period,
                percentage: percentage,
                amount: value,
                code: code,
              },
            ],
            borderRadius: 4,
            hidden: false,
            backgroundColor:
              type === PeriodExploitationType.DSP
                ? BarColors[item.code] || getRandomHexColor()
                : PlatformColors[
                    mappedChartData.length % PlatformColors.length
                  ],
            parsing: {
              xAxisKey: 'period',
              yAxisKey: 'amount',
            },
          })
        }
      })
    })
    return { labels: labels, datasets: mappedChartData }
  }, [exploitationPeriods])

  const CustomToggle = ({
    eventKey,
    item,
    i18n,
  }: {
    eventKey: string
    item: ExploitationPeriod
    i18n: i18n
  }) => {
    const { activeEventKey } = useContext(AccordionContext)
    const decoratedOnClick = useAccordionButton(eventKey)
    return (
      <div className='ms-by-dsp-offer-container' onClick={decoratedOnClick}>
        <div className='d-flex'>
          <div
            className={`expand-arrow ${
              activeEventKey?.includes(eventKey) ? 'open-arrow' : ''
            }`}
          ></div>
          <div className='bar-label'>{item.period}</div>
        </div>
        <span className='bar-amount'>
          {`€ ${formatNumber(i18n.language, item.amount)}`}
        </span>
      </div>
    )
  }

  return (
    <div className={'p-3'}>
      {isMobile ? (
        <Accordion alwaysOpen>
          {exploitationPeriods
            .slice()
            .reverse()
            .map((exploitationPeriod) => (
              <Card key={exploitationPeriod.period}>
                <Card.Header>
                  <CustomToggle
                    eventKey={`${exploitationPeriod.period}`}
                    item={exploitationPeriod}
                    i18n={i18n}
                  />
                </Card.Header>
                <Accordion.Collapse eventKey={`${exploitationPeriod.period}`}>
                  <Card.Body className='padding-top-unset'>
                    {exploitationPeriod.items.map((item) => {
                      return (
                        <div key={item.code}>
                          <div className='bar-container'>
                            <span className='bar-label mobile-bar-label'>
                              {item.label}
                            </span>
                            <span className='bar-amount'>
                              {`€ ${formatNumber(
                                i18n.language,
                                item.amount.value
                              )}`}
                            </span>
                            <ProgressBar
                              now={item.amount.percentage}
                              color={'#fd54e2'}
                            />
                            <span className='bar-percent'>{`${formatNumber(
                              i18n.language,
                              item.amount.percentage
                            )}%`}</span>
                          </div>
                        </div>
                      )
                    })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
        </Accordion>
      ) : (
        <StackedBarChart<{
          period: string
          percentage: number
          amount: number
          code: string
        }>
          labels={chartData.labels}
          datasets={chartData.datasets}
          xOptions={getXOptions({
            lang: i18n.language,
            autoSkip: false,
          })}
          yOptions={getCurrencyYOptions({
            autoSkip: false,
            beginAtZero: true,
          })}
          lang={i18n.language}
          stackedBarTooltipCallbacks={tooltipFormatNumbersCallback()}
        />
      )}
    </div>
  )
}
export default ExploitationPeriodBox
