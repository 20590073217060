import React, { useState, useRef } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { SortParam } from '../../../../types';

import './index.scss';
import { useTranslation } from 'react-i18next';

export const SortLabel = (props: {
  sortParam: SortParam,
  onChange: (val: SortParam) => void,
}) => {
	const { t } = useTranslation();
	const target = useRef(null);
	const [show, setShow] = useState<boolean>(false);
	const { sortParam: { sortField, sortOrder }, onChange } = props;

	const onOptionClick = (option: SortParam) => {
		onChange(option);
	};

	const renderSortOptions = () => {
		const options: SortParam[] = [
			{
				sortField: 'representationTitle',
				sortOrder: 'asc',
				label: t('representation.sort.representationTitle-asc')
			},
			{
				sortField: 'representationTitle',
				sortOrder: 'desc',
				label: t('representation.sort.representationTitle-desc')
			},
			{
				sortField: 'startDate',
				sortOrder: 'asc',
				label: t('representation.sort.startDate-asc')
			},
			{
				sortField: 'startDate',
				sortOrder: 'desc',
				label: t('representation.sort.startDate-desc')
			},
		];
		return (
			<Popover id="sort-popover">
				<div className="sort-options">
					{ options.map(o => (
						<div
							key={`so-${o.sortField}-${o.sortOrder}`}
							className={`option ${o.sortOrder}`}
							onClick={() => onOptionClick({
								sortField: o.sortField,
								sortOrder: o.sortOrder
							})}
						>{o.label}</div>
					))}
				</div>
			</Popover>
		);
	};

	return (
		<>
			<button className="btn-sort" onClick={() => setShow(true)} ref={target}>
				{t(`representation.sort.${sortField}-${sortOrder}`)}
			</button>
			<Overlay
				target={target.current}
				placement="bottom"
				show={show}
				onHide={() => setShow(false)}
				rootClose
			>
				{ renderSortOptions() }
			</Overlay>
		</>
	);
};