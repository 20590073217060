import React, { useEffect, useState } from 'react'
import {useTranslation} from "react-i18next";
import { Autocomplete, AutocompleteItem, Loader } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'

const AutoCompleteComponent = (filterProps: AutoCompleteProps) => {
  const {t} = useTranslation()
  const [value, setValue] = useState<string>('')
  const [debounced] = useDebouncedValue(value, 200)
  const [selectItemFlag, setSelectItemFlag] = useState<boolean>(false)
  useEffect(() => {
    //function to fetch autocomplete data & update reducer autocomplete state
    if (!selectItemFlag && filterProps.onFilterChange) {
      filterProps.onFilterChange(debounced)
    }
    setSelectItemFlag(false)
  }, [debounced])

  useEffect(() => {
    if (filterProps.selectedValue !== undefined) {
      setValue(filterProps.selectedValue)
      setSelectItemFlag(true)
    }
  }, [filterProps.selectedValue])

  const handleChangeVal = (val: string) => {
    setValue(val.trimLeft())
  }
  const handleSelectItem = (item: AutocompleteItem) => {
    setSelectItemFlag(true)
    if (filterProps.onSelected) {
      filterProps.onSelected(item)
    }
  }

  return (
    <Autocomplete
      limit={100}
      filter={(value: string, item: AutocompleteItem) => {
        return true
      }}
      styles={{ rightSection: { pointerEvents: 'none' } }}
      nothingFound={
        value &&
        value.length > 0 &&
        filterProps.status &&
        filterProps.status > -1 &&
        t('search-data.no-data')
      }
      value={value}
      data={filterProps.data ? filterProps.data : []}
      onChange={handleChangeVal}
      onItemSubmit={handleSelectItem}
      rightSection={
        filterProps.status === -1 ? (
          <Loader size='1.5rem' color={'#363981'} />
        ) : (
          <span className={'filter-arrow-down cursor-pointer'} />
        )
      }
      placeholder={filterProps.textPlaceholder}
      disabled={filterProps.searchIsLoading || filterProps.disabled}
      style={
        filterProps.widthFilter !== null
          ? { width: filterProps.widthFilter }
          : { width: '280px' }
      }
      maxDropdownHeight={200}
    />
  )
}
interface AutoCompleteProps {
  selectedValue?: string
  searchIsLoading?: boolean
  titleFieldset?: string
  textPlaceholder?: string
  widthFilter?: string
  data: Array<string | AutocompleteItem>
  onSelected?: Function
  onFilterChange?: Function
  status?: number
  disabled?: boolean
}
export default AutoCompleteComponent
