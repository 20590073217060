import React from 'react';
import { AuthConsumer } from '../../oidcAuthentication/authProvider';
import LoadingSpinner from '../../components/spinner';

export const Callback = () => (
	<AuthConsumer>
		{({ signinRedirectCallback }) => {
			signinRedirectCallback();
			//return <LoadingSpinner isLoading={true}/>;
		}}
	</AuthConsumer>
);
