import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { WorksCountBadge } from '../WorksCountBadge';

import './index.scss';

interface CardDetailsProps {
  header?: JSX.Element
  detailsInfo?: JSX.Element
  children: any
  worksCount?: number;
}

const CardDetails = (props: CardDetailsProps) => {
	const { worksCount, header, detailsInfo, children } = props;
	const { t } = useTranslation();

	return (
		<div className='big-card-box'>
			{header && <>
				{header}
			</>
			}
			<Container className='card-body'>
				{
					detailsInfo && <Row className='card-border'>
					{detailsInfo}
				</Row>
				}
			</Container>
			{ worksCount ? (
				<Container className='card-body'>
					<Row className='card-border'>
						<div className='header-works'>
							<Col xs={9} sm={10} md={6} lg={7} xl={8} className='subtitle'>
								{t('set-lists.works.title')}
							</Col>

							<Col xs={3} sm={2} md={2} lg={3} xl={3} className='header-title right zero'>
								<WorksCountBadge count={worksCount} />
							</Col>
						</div>
						{children}
					</Row>
				</Container>
			) : (
				<></>
			)}

		</div>
	);
};

export default CardDetails;