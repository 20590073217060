import React from 'react';
import Overlay from '../overlay';
import { useTranslation } from 'react-i18next';

import './index.scss';

export const WorksCountBadge = (props: { count: number }) => {
	const { t } = useTranslation();
	return (
		<div className="works-badge">
			<Overlay content={t('set-lists.card.works')} placement="top">
				<span className="header-nbr-oeuvre">{props.count}</span>
			</Overlay>
		</div>
	);
};