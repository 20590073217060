import React from 'react';
import { AuthConsumer } from '../../oidcAuthentication/authProvider';
import LoadingSpinner from '../../components/spinner';

export const SilentRenew = () => (
	<AuthConsumer>
		{({ signinSilentCallback }) => {
			signinSilentCallback();
			return <LoadingSpinner isLoading={true}/>;
		}}
	</AuthConsumer>
);
