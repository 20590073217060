import { SidenavMenuItem } from '../../../types/sidenav'
import { ROUTES, ROLES } from '../../../constants';
import {
  FF_USAGE_DATA,
  FF_DISTRIBUTION_ROYALTIES,
  FF_MARKET_SHARE
} from '../../../providers/configurationProvider'
export const menuItems: SidenavMenuItem[] = [
    {
        label: 'home',
        subItems: [],
        route: ROUTES.LANDING,
        iconClass: 'icon-homepage',
        requiredRoles: ['*'],
        excludeddRoles: [],
        toggle: false
    },
      {
        label: 'live-performances-title',
        subItems: [
          {
            label: 'check-events',
            route: ROUTES.GET_EVENTS,
            iconClass: 'icon-check-events'
          },
          {
            label: 'create-setlist',
            route: ROUTES.CREATE_SETLIST,
            iconClass: 'icon-create-setlist'
          },
          {
            label: 'check-setlists',
            route: ROUTES.GET_SETLISTS,
            iconClass: 'icon-check-setlists'
          }
        ],
        requiredRoles: ['*'],
        excludeddRoles: [ROLES.WEBSO_WEB_ONLINE],
        toggle: false,
      },
      {
          label: 'analyse-title',
          subItems: [
            {
              label: 'usage-data-subtitle',
              route: ROUTES.USAGE_DATA,
              iconClass: 'imgUsageData',
              confKey: FF_USAGE_DATA
            },
            {
              label: 'distribution-royalties-subtitle',
              route: ROUTES.DISTRIBUTION_ROYALTIES,
              iconClass: 'icon-distribution-royalties',
              confKey: FF_DISTRIBUTION_ROYALTIES
            },
            {
              label: 'kpi-subtitle',
              route: ROUTES.KPI,
              iconClass: 'icon-kpi',
              confKey: FF_MARKET_SHARE
            },
          ],
          requiredRoles: [ROLES.WEBSO_WEB_ONLINE],
          excludeddRoles: [],
          toggle: false,
      },
      {
        label: 'more-title',
        subItems: [
          {
            label: 'terms-conditions-subtitle',
            route: '/partner-societies' + ROUTES.PRIVACY_COOKIES_POLICY,
            iconClass: 'icon-privacy'
          },
          {
            label: 'privacy-subtitle',
            route: 'oneThrust',
            iconClass: 'icon-cookies'
          },
        ],
        requiredRoles: ['*'],
        excludeddRoles: [],
        toggle: false
      }
]