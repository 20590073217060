import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { KPIQueryParamModel, MarketShareByClaimType, MarketShareByClaimTypeResponse, MarketShareState, MultiSelectFilterType } from '../types/marketShareTypes';
import { formatDateToYYYYMM, getFirstMonthOfQuarter, getLastMonthOfQuarter, getOffsetDate } from '../utils/dateUtils';
import { numericToAlpha2 } from 'i18n-iso-countries'
import {
	getDspsMarketShare,
	getGroupedOffersMarketShare,
	getTerritoriesMarketShare,
	getMarketKpiDeclaredRevenue,
	getMarketKpiStreamsPerCommercialModels,
	getMarketKpiNumberOfUsers,
	getMarketKpiAvgDeclaredRevenuePerUser,
	getRepertoirePerformanceTotalAmount,
	getMarketShareByCommercialModel,
	getMarketShareByClaimType
} from '../api/marketShareDataApi';

export const initialState: MarketShareState = {
	status: {
		groupedOffers: -1,
		dsps: -1,
		territories: -1,
		licenceKpis: {
			effectiveRateByCommercialModelPerUsagePeriods: -2,
			streamsPerCommercialModel: -2,
			streamsPerTerritory: -2,
			effectiveRatePerTerritories: -2,
			averageStreamsPerUser: -2,
		},
		repertoirePerformance: {
			totalAmount: -2,
			marketShareByCommercialModel: -2,
			marketShareByClaimType: -2,
		},
		marketKpis: {
			declaredRevenue: -2,
			numberOfStreams: -2,
			numberOfUsers: -2,
			avgDeclaredRevenuePerUser: -2
		}
	},
	filters: {
	  territories: [],
	  dsps: [],
	  groupedOffers: [],
	},
	repertoirePerformance: {
		totalAmount: { claimedAmount: undefined, percentage: undefined},
		marketShareByCommercialModel: [],
		marketShareByClaimType: []
	},
	licenceKpisData: {
	  streamsPerTerritory: [],
	  streamsPerCommercialModel: [],
	  effectiveRateByCommercialModelPerUsagePeriods: [],
	  effectiveRatePerTerritories: [],
	  averageStreamsPerUser: 0,
	},
	marketKpisData: {
		declaredRevenue: [],
		numberOfStreams: [],
		numberOfUsers: [],
		avgDeclaredRevenuePerUser: []
	},
	marketShareSearchParams: {
	  date: {
		startingDate: formatDateToYYYYMM(
		  getFirstMonthOfQuarter(getOffsetDate(0, 3))
		),
		endingDate: formatDateToYYYYMM(getLastMonthOfQuarter(getOffsetDate())),
	  },
	  territories: [],
	  groupedOffers: [],
	  dsps: [],
	},
	lastSearchValues: {
	  date: {
		performanceKpi: { startingDate: '', endingDate: '' },
		licenseKpi: { startingDate: '', endingDate: '' },
		marketKpi: { startingDate: '', endingDate: '' },
	  },
	  territories: [],
	  groupedOffers: [],
	  repositories: [],
	  dsps: [],
	},
};

export const fetchTerritoriesAsync = createAsyncThunk(
	'marketShare/territories/fetch',
	async (params: { mandatorId: number }, { rejectWithValue }) => {
		try {
			return await getTerritoriesMarketShare(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchDspsAsync = createAsyncThunk(
	'marketShare/dsps/fetch',
	async (params: { mandatorId: number, referential: string  }, { rejectWithValue }) => {
		try {
			return await getDspsMarketShare(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchMarketShareGroupedOffersAsync = createAsyncThunk(
	'marketShare/GroupedOffers/fetch',
	async (params: any, { rejectWithValue }) => {
		try {
			return await getGroupedOffersMarketShare(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const loadMarketKpiLastSearchFilters = createAsyncThunk(
	'marketShare/searchParams/load',
	async () => {
		return
	}
);

export const fetchMarketKpiDeclaredRevenueAsync = createAsyncThunk(
	'marketShare/marketKpi/declaredRevenue',
	async (params: KPIQueryParamModel, { rejectWithValue }) => {
		try {
			return await getMarketKpiDeclaredRevenue(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchMarketKpiStreamsPerCommercialModelsAsync = createAsyncThunk(
	'marketShare/marketKpi/streamsPerCommercialModels',
	async (params: KPIQueryParamModel, { rejectWithValue }) => {
		try {
			return await getMarketKpiStreamsPerCommercialModels(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const fetchMarketKpiNumberOfUsersAsync = createAsyncThunk(
	'marketShare/marketKpi/numberOfUsers',
	async (params: KPIQueryParamModel, { rejectWithValue }) => {
		try {
			return await getMarketKpiNumberOfUsers(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
)

export const fetchMarketKpiAvgDeclaredRevenuePerUserAsync = createAsyncThunk(
	'marketShare/marketKpi/avgDeclaredRevenuePerUser',
	async (params: KPIQueryParamModel, { rejectWithValue }) => {
		try {
			return await getMarketKpiAvgDeclaredRevenuePerUser(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
)

export const fetchRepertoirePerformanceTotalAmountAsync = createAsyncThunk(
	'marketShare/repertoirePerformance/totalAmount',
	async (params: KPIQueryParamModel, { rejectWithValue }) => {
		try {
			return await getRepertoirePerformanceTotalAmount(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
)

export const fetchMarketShareByCommercialModelAsync = createAsyncThunk(
	'marketShare/repertoirePerformance/marketShareByCommercialModel',
	async (params: KPIQueryParamModel, { rejectWithValue }) => {
		try {
			return await getMarketShareByCommercialModel(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
)

export const fetchMarketShareBClaimTypeAsync = createAsyncThunk(
	'marketShare/repertoirePerformance/marketShareByClaimType',
	async (params: KPIQueryParamModel, { rejectWithValue }) => {
		try {
			return await getMarketShareByClaimType(params);
		} catch (err) {
			return rejectWithValue(err);
		}
	}
)

export const marketShareSlice = createSlice({
	name: 'distributionRoyalties',
	initialState,
	reducers: {
		resetMarketShareState: (state: MarketShareState) => {
			return initialState
		},
		resetMarketSharesSearchParams:(state: MarketShareState) => {
			return {
				...state,
				marketShareSearchParams:{
					date: {
					  startingDate: formatDateToYYYYMM(
						getFirstMonthOfQuarter(getOffsetDate(0, 3))
					  ),
					  endingDate: formatDateToYYYYMM(getLastMonthOfQuarter(getOffsetDate())),
					},
					territories: [],
					groupedOffers: [],
					dsps: [],
				  }
			}
		},
		updateMarketShareDateSearchParams: (state: MarketShareState, action: any) => {
			state.marketShareSearchParams.date = action.payload
		},
		updateMarketShareTerritoriesSearchParams: (state: MarketShareState, action: any) => {
			state.marketShareSearchParams.territories = action.payload
		},
		updateMarketShareGroupedOffersSearchParams: (state: MarketShareState, action: any) => {
			state.marketShareSearchParams.groupedOffers = action.payload
		},
		updateMarketShareDspsSearchParams: (state: MarketShareState, action: any) => {
			state.marketShareSearchParams.dsps = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchTerritoriesAsync.pending, (state: MarketShareState) => {
				state.status.territories = -1;
			})
			.addCase(fetchTerritoriesAsync.fulfilled, (state: MarketShareState, action: any) => {
				state.status.territories = 200
				state.filters.territories = action.payload.data?.territories ? action.payload.data.territories.map(
					(territory: MultiSelectFilterType<number>) => {
					  return {
						code: numericToAlpha2(territory.code),
						label: territory.label,
					  }
					}
				  ) : []
			})
			.addCase(fetchTerritoriesAsync.rejected, (state: MarketShareState, action: any) => {
				return {
					...state,
					status: {
						...state.status,
						territories: action.payload.statusCode
					}
				};
			})
			.addCase(fetchDspsAsync.pending, (state: MarketShareState) => {
				state.status.dsps = -1;
			})
			.addCase(fetchDspsAsync.fulfilled, (state: MarketShareState, action: any) => {
				state.status.dsps = 200
				state.filters.dsps = action.payload.data?.dsps ? action.payload.data.dsps : []
			})
			.addCase(fetchDspsAsync.rejected, (state: MarketShareState, action: any) => {
				return {
					...state,
					status: {
						...state.status,
						dsps: action.payload.statusCode
					}
				};
			})
			.addCase(fetchMarketShareGroupedOffersAsync.pending, (state: MarketShareState) => {
				state.status.groupedOffers = -1;
			})
			.addCase(fetchMarketShareGroupedOffersAsync.fulfilled, (state: MarketShareState, action: any) => {
				state.status.groupedOffers = 200
				state.filters.groupedOffers = action.payload.data?.groupedOffers ? action.payload.data.groupedOffers : []
			})
			.addCase(fetchMarketShareGroupedOffersAsync.rejected, (state: MarketShareState, action: any) => {
				return {
					...state,
					status: {
						...state.status,
						groupedOffers: action.payload.statusCode
					}
				};
			})
			.addCase(fetchMarketKpiDeclaredRevenueAsync.pending, (state: MarketShareState) => {
				state.status.marketKpis.declaredRevenue = -1
			})
			.addCase(fetchMarketKpiDeclaredRevenueAsync.fulfilled, (state: MarketShareState, action: any) => {
				state.status.marketKpis.declaredRevenue =  action.payload.data ? 200 : 204
				state.marketKpisData.declaredRevenue = action.payload.data?.declared_revenues
			})
			.addCase(fetchMarketKpiDeclaredRevenueAsync.rejected, (state: MarketShareState, action: any) => {
				state.status.marketKpis.declaredRevenue = action.payload.statusCode
			})
			.addCase(fetchMarketKpiStreamsPerCommercialModelsAsync.pending, (state: MarketShareState) => {
				state.status.marketKpis.numberOfStreams = -1
			})
			.addCase(fetchMarketKpiStreamsPerCommercialModelsAsync.fulfilled, (state: MarketShareState, action: any) => {
				state.status.marketKpis.numberOfStreams =  action.payload.data ? 200 : 204
				state.marketKpisData.numberOfStreams = action.payload.data?.streams_per_commercial_models
			})
			.addCase(fetchMarketKpiStreamsPerCommercialModelsAsync.rejected, (state: MarketShareState, action: any) => {
				state.status.marketKpis.numberOfStreams = action.payload.statusCode
			})
			.addCase(fetchMarketKpiNumberOfUsersAsync.pending, (state: MarketShareState) => {
				state.status.marketKpis.numberOfUsers = -1
			})
			.addCase(fetchMarketKpiNumberOfUsersAsync.fulfilled, (state: MarketShareState, action: any) => {
				state.status.marketKpis.numberOfUsers = action.payload.data ? 200 : 204
				state.marketKpisData.numberOfUsers = action.payload.data?.number_of_users
			})
			.addCase(fetchMarketKpiNumberOfUsersAsync.rejected, (state: MarketShareState, action: any) => {
				state.status.marketKpis.numberOfUsers = action.payload.statusCode
			})
			.addCase(fetchMarketKpiAvgDeclaredRevenuePerUserAsync.pending, (state: MarketShareState) => {
				state.status.marketKpis.avgDeclaredRevenuePerUser = -1
			})
			.addCase(fetchMarketKpiAvgDeclaredRevenuePerUserAsync.fulfilled, (state: MarketShareState, action: any) => {
				state.status.marketKpis.avgDeclaredRevenuePerUser = action.payload.data ? 200 : 204
				state.marketKpisData.avgDeclaredRevenuePerUser = action.payload.data?.averageDeclaredRevenuePerUser
			})
			.addCase(fetchMarketKpiAvgDeclaredRevenuePerUserAsync.rejected, (state: MarketShareState, action: any) => {
				state.status.marketKpis.avgDeclaredRevenuePerUser = action.payload.statusCode
			})
			.addCase(fetchRepertoirePerformanceTotalAmountAsync.pending, (state: MarketShareState) => {
				state.status.repertoirePerformance.totalAmount = -1
			})
			.addCase(fetchRepertoirePerformanceTotalAmountAsync.fulfilled, (state: MarketShareState, action: any) => {
				state.status.repertoirePerformance.totalAmount = action.payload?.data ? 200 : 204
				state.repertoirePerformance.totalAmount = action.payload?.data ? action.payload.data[0] : {claimedAmount: undefined, percentage: undefined}
			})
			.addCase(fetchRepertoirePerformanceTotalAmountAsync.rejected, (state: MarketShareState, action: any) => {
				state.status.repertoirePerformance.totalAmount = action.payload.statusCode
			})
			.addCase(fetchMarketShareByCommercialModelAsync.pending, (state: MarketShareState) => {
				state.status.repertoirePerformance.marketShareByCommercialModel = -1
			})
			.addCase(fetchMarketShareByCommercialModelAsync.fulfilled, (state: MarketShareState, action: any) => {
				state.status.repertoirePerformance.marketShareByCommercialModel = action.payload?.data ? 200 : 204
				state.repertoirePerformance.marketShareByCommercialModel = action.payload?.data ?? []
			})
			.addCase(fetchMarketShareByCommercialModelAsync.rejected, (state: MarketShareState, action: any) => {
				state.status.repertoirePerformance.marketShareByCommercialModel = action.payload.statusCode
			})
			.addCase(fetchMarketShareBClaimTypeAsync.pending, (state: MarketShareState) => {
				state.status.repertoirePerformance.marketShareByClaimType = -1
			})
			.addCase(fetchMarketShareBClaimTypeAsync.fulfilled, (state: MarketShareState, action: any) => {
				state.status.repertoirePerformance.marketShareByClaimType = action.payload.data ? 200 : 204
				const mappedObj: MarketShareByClaimType[] = []
				if (action.payload?.data) {
          action.payload.data.forEach((item: MarketShareByClaimTypeResponse) => {
            mappedObj.push({
              claimedAmount: item.claimedAmountEuros,
              percentage: item.pdm,
              claimType: item.claimType,
              periodExploit: item.periodExploit.replace('T', 'Q'),
            })
          })
				}
				state.repertoirePerformance.marketShareByClaimType = mappedObj
			})
			.addCase(fetchMarketShareBClaimTypeAsync.rejected, (state: MarketShareState, action: any) => {
				state.status.repertoirePerformance.marketShareByClaimType = action.payload.statusCode
			})
	},
});
export const {
	resetMarketSharesSearchParams,
	updateMarketShareDateSearchParams,
	updateMarketShareTerritoriesSearchParams,
	updateMarketShareGroupedOffersSearchParams,
	updateMarketShareDspsSearchParams,
	resetMarketShareState
} = marketShareSlice.actions;

export default marketShareSlice.reducer;
