import React, { useEffect, useState } from 'react'
import { Loader, Select, SelectItem } from '@mantine/core'
import {useTranslation} from "react-i18next";

const MonoSelectComponent = (filterProps: MonoSelectProps) => {
  const {t} = useTranslation()
  const [value, setValue] = useState<string | null>('')
  const [sortedData, setSortedData] = useState<string[] | SelectItem[]>([])

  useEffect(() => {
    if (
      filterProps.data &&
      filterProps.selectedValue != null &&
      value !== filterProps.selectedValue
    ) {
      if (filterProps.data.length > 0) {
        const d = !filterProps.keepInitialDataSort
          ? [...filterProps.data].sort(
              (item1: SelectItem, item2: SelectItem) => {
                return item1.label && item2.label
                  ? item1.label.localeCompare(item2.label)
                  : 1
              }
            )
          : filterProps.data
        setSortedData(d)
      }
      const val: any = filterProps.data.find(
        (data: any) => data.code.toString() === filterProps.selectedValue
      )
      val != null && val.label != null && setValue(val.label)
    }
  }, [filterProps.selectedValue, filterProps.data])

  const handleOnChange = (selectedFilter: string) => {
    setValue(selectedFilter)
    const selectedItem = filterProps.data.find(
      (data: any) => selectedFilter === data.label
    )
    if (filterProps.onSelected){
      filterProps.onSelected(selectedItem)
    }
  }

  return (
    <Select
      styles={{ rightSection: { pointerEvents: 'none' } }}
      data={sortedData}
      value={value}
      onChange={handleOnChange}
      placeholder={filterProps.textPlaceholder}
      rightSection={
        filterProps.status === -1 ? (
          <Loader size='1.5rem' color={'#363981'} />
        ) : (
          <span className={'filter-arrow-down'} />
        )
      }
      maxDropdownHeight={190}
      nothingFound={
        value &&
        value.length > 0 &&
        filterProps.status &&
        filterProps.status > -1 &&
        t('search-data.no-data')
      }
      rightSectionWidth={30}
      disabled={filterProps.searchIsLoading || filterProps.disabled}
      style={
        filterProps.widthFilter !== null
          ? { width: filterProps.widthFilter }
          : { width: '280px' }
      }
    />
  )
}
interface MonoSelectProps {
  keepInitialDataSort?: boolean
  searchIsLoading?: boolean
  textPlaceholder?: string
  widthFilter?: string
  data: Array<SelectItem>
  onSelected?: Function
  status?: number
  disabled?: boolean
  selectedValue?: string
}
export default MonoSelectComponent
