import React, { useEffect, useState } from 'react';
import { AuthConsumer } from '../../oidcAuthentication/authProvider';
import ErrorPage from '../../components/error';
import { User } from 'oidc-client/dist/oidc-client';
import { useAuthService } from '../../oidcAuthentication/hooks';
import { AppPropsType, Mandator, SisterSociety, UserData } from '../../types';
import { connect, useDispatch } from 'react-redux';
import SwitchSisterSocietieId from '../../components/switchAccount';
import {
	getMandatorsAsync,
	getSisterSocietiesAsync,
	getConfigurationsAsync,
	setProfile
} from '../../slices/appContextSlice';
import Header from '../header';

import './index.scss';
import PageLoadingSpinner from "../../components/spinner";
import WebsoSidenav from './websoSidenav';
import { ROLES } from '../../constants';
import { route } from '../../types';


const SecuredRoute =  (props: PrivateRouteProps) => {
	const { switchAccount, sisterSocities , selectedSisterSociety, mandator} = props;
	const [authState, setAuthState] = useState<number>(0);
	const dispatch = useDispatch();
	const authService = useAuthService();

	function logout(errorCode = 403) {
		setAuthState(errorCode);
	}
	useEffect(() => {
		const loadUser = async () => {
			try {
				const user: User = await authService.getUser();
				if (!user || !user.profile) {
					logout();
					return;
				}
				if (user.profile.azp !== process.env.REACT_APP_IDENTITY_CLIENT_ID) {
					logout();
					return;
				}
				if (!user.profile.user_types.includes('SisterSociety')) {
					logout();
					return;
				}
				if (!user.profile.roles.includes(ROLES.GLOBAL_SOCIETE_SOEUR)) {
					logout();
					return;
				}
				if (selectedSisterSociety == null){
					dispatch(getSisterSocietiesAsync());
				}
				if (mandator == null){
					dispatch(getMandatorsAsync());
				}

                dispatch(getConfigurationsAsync());
				dispatch(
					setProfile({
						profile: user.profile
					})
				);
				setAuthState(200);
			} catch (err) {
				logout(401);
			}
		};
		loadUser();
	}, []);

	const redirectUserWithSisterSocieties = (signinRedirect: Function) => {
		if (!!props.children && authState === 200) {
			if (!props.mandator && props.mandatorStatus > 1 && props.route?.role == ROLES.WEBSO_WEB_ONLINE) {
				return <ErrorPage errorCode={403}/>;
			}

			if (!switchAccount) {
				return (
					<>
						<WebsoSidenav/>
						<Header/>
						{props.children}
					</>

				);
			} else {
				return <SwitchSisterSocietieId/>;
			}

		} else if (authState === 401) {
			signinRedirect();
		} else {
			return <ErrorPage errorCode={authState}/>;
		}
	}

	return (
		<AuthConsumer>
			{({ isAuthenticated, signinRedirect }) => {
				if (isAuthenticated()) {
					if (authState !== 0 && sisterSocities) {
						return redirectUserWithSisterSocieties(signinRedirect)
					} else if (authState === 403) {
						return <ErrorPage errorCode={403}/>;
					} else if (authState !== 200) {
						return <ErrorPage errorCode={500}/>;
					}
				} else {
					signinRedirect();
					return <PageLoadingSpinner isLoading={true} />;
				}
			}}
		</AuthConsumer>
	);
};

const mapStateToProps = (state: AppPropsType) => ({
	switchAccount: state.appContext.switchAccount,
	sisterSocities: state.appContext.sisterSocities,
	selectedSisterSociety: state.appContext.selectedSisterSociety,
	mandator: state.appContext.mandator,
	mandatorStatus: state.appContext.mandatorStatus
});
export default connect(mapStateToProps)(SecuredRoute);


interface PrivateRouteProps {
	children?: React.ReactNode; // 👈️ for demo purposes
	switchAccount: boolean;
	sisterSocities: UserData | null;
	selectedSisterSociety: SisterSociety | null;
	mandator: number | null;
	mandatorStatus: number;
	route: route | undefined;
}

