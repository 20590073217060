export const getRandomHexColor = () => {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  const redHex = red.toString(16).padStart(2, '0');
  const greenHex = green.toString(16).padStart(2, '0');
  const blueHex = blue.toString(16).padStart(2, '0');

  return `#${redHex}${greenHex}${blueHex}`;
}

// Generate random color for non specified value
export const BarColors: {
  [key: string]: string
} = {
  VOD_DSPS_KEY: '#898281',
  OTHER_DSPS_KEY: '#D4D3D3',
  SFY: '#28CC5F',
  YOUTUBE: '#FF0000',
  DZR: '#FF6C01',
  FCE: '#19AFFF',
  AMZ: '#44B6E8',
  QOBUZ: '#C3C2C6',
  WIMP: '#520F41',
  YOUSI: '#00D296',
  GOO: '#FFC32A',
  APPLEMUSIC: '#FD5B73',
  "7DI": "#A82534",
  ANGHA: getRandomHexColor(),
  BEA: "#99CC00",
  CAM: "#7F6347",
  CHD: "#699B9C",
  DOR: "#F2AF01",
  ECI: getRandomHexColor(),
  FRE: "#CD1E25",
  GIS: "#5A35E0",
  IDOL: getRandomHexColor(),
  JNG: getRandomHexColor(),
  LUV: getRandomHexColor(),
  CCM: "#DA3162",
  MVR: "#BF2A25",
  MIXCL: getRandomHexColor(),
  MMW: getRandomHexColor(),
  NAPSTER: "#4056FF",
  ORA: "#FF7900",
  PPC: "#0C070E",
  RAK: "#BF1818",
  RECISIO: "#06914F",
  SOUNDCLD: "#F87510",
  SOUTR: "#E04B4D",
  SMI: "#8748E8",
  TSL: "#151650",
  TRAX: "#40A0FF",
  VEVO: "#000000",
  WAK: "#E3474B",
  ZOU: getRandomHexColor(),
}

export const offerBarColors: {
  [key: string]: string
} = {
  381: '#F094D0',
  Single: '#F094D0',

  382: '#0FA3B1',
  Family: '#0FA3B1',

  NULL: '#6C757D',
  Others: '#6C757D',

  383: '#FFC32A',
  Partner: '#FFC32A',

  384: '#5256B7',
  Student: '#5256B7',

  463: '#B39FD0',
  SVOD: '#B39FD0',

  466: '#20C997',
  'Streaming VOD': '#20C997',

  421: '#822E81',
  'Ad funded': '#822E81',

  465: '#C41C89',
  'Download VOD': '#C41C89',

  401: '#FF784F',
  Download: '#FF784F',
}