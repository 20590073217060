import i18n from 'i18next';
import { merge as _merge } from 'lodash-es'
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ExportPageEN from './en/translations.json';
import ExportPageFR from './fr/translations.json';
import distributionRoyaltiesEN from './en/distribution-royalties.json'
import distributionRoyaltiesFR from './fr/distribution-royalties.json'
import kpiFR from './fr/kpi.json'
import kpiEN from './en/kpi.json'
import usageDataEN from './en/usage-data.json'
import usageDataFR from './fr/usage-data.json'
import statusEN from './en/status.json'
import statusFR from './fr/status.json'
import licenseKpiEN from './en/license-kpi.json'
import licenseKpiFR from './fr/license-kpi.json'
import marketKpiEN from './en/market-kpi.json'
import marketKpiFR from './fr/market-kpi.json'
import repertoirePerformanceEN from './en/repertoire-performance.json'
import repertoirePerformanceFR from './fr/repertoire-performance.json'

const defaultLanguage = 'en';
const availableLangs = ['fr', 'en'];
const detectionOptions = {
	// order and from where user language should be detected
	order: ['querystring'],

	// keys or params to lookup language from
	lookupQuerystring: 'lang',
	//lookupCookie: 'i18next',
	lookupLocalStorage: 'lang',
	//lookupSessionStorage: 'i18nextLng',
	lookupFromPathIndex: 0,
	lookupFromSubdomainIndex: 0,

	// cache user language on
	//caches: ['localStorage', 'cookie'],
	//excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

	// optional expire and domain for set cookie
	//cookieMinutes: 10,
	//cookieDomain: 'sacem.fr',

	// optional htmlTag with lang attribute, the default is:
	htmlTag: document.documentElement,

	// optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
	//cookieOptions: { path: '/', sameSite: 'strict' }
};

// the translations
const resources = {
	en: {
		translation: _merge(ExportPageEN, usageDataEN, statusEN, distributionRoyaltiesEN, kpiEN, licenseKpiEN, marketKpiEN, repertoirePerformanceEN)
	},
	fr: {
		translation: _merge(ExportPageFR, usageDataFR, statusFR, distributionRoyaltiesFR, kpiFR, licenseKpiFR, marketKpiFR, repertoirePerformanceFR)
	},
};

const tFunctionPromise = i18n
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init(
		{
			fallbackLng: defaultLanguage,
			whitelist: availableLangs,
			supportedLngs: availableLangs,
			interpolation: { escapeValue: false },
			resources,
			lng:'en', // default lang
			keySeparator: '.', // to support nested translations
			detection: detectionOptions,
			debug: process.env.NODE_ENV === 'development',
			lowerCaseLng: true,
		},
		(err, t) => {
			if (err) console.error(err);
		}
	);


export default i18n;
