import React from 'react'
import {
  CellObject,
  JSON2SheetOpts,
  utils,
  writeFile,
  Range as XLSRange,
} from 'xlsx-js-style'

import './index.scss'

const ExportButton = ({
  getData,
  getMerge,
  exportType,
  titleExport,
  opts,
  colOptions,
}: {
  getData?: () => CellObject[][]
  getMerge?: () => XLSRange[]
  titleExport?: string
  exportType: string
  opts?: JSON2SheetOpts
  colOptions?: { wch?: number }[]
}) => {

  const exportData = () => {
    if (getData == null) {
      return
    }

    const ws = utils.aoa_to_sheet(getData(), opts)
    if (getMerge != null) {
      ws['!merges'] = getMerge()
    }
    if (colOptions != null) {
      ws['!cols'] = colOptions
    }
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'Data')
    const date = new Date().toISOString().split('T')[0].split('-').join('')
    writeFile(wb, `${date}_${exportType}.xlsx`)
  }

  return (
    <button
      className='fa fa-download fa-lg fa-inverse export-button'
      onClick={exportData}
      title={titleExport ?? "export"}
    />
  )
}

export default ExportButton
