import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {Link, useSearchParams} from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import PageLoadingSpinner from '../../components/spinner/';
import {resetProgramNew, selectTypicalPrograms} from '../../slices/setListsSlice';
import { selectDrafts } from '../../slices/draftsSlice';
import { selectedSociety } from '../../slices/appContextSlice';
import { fetchTypicalProgramsAsync } from '../../slices/setListsSlice';
import { fetchDraftsAsync } from '../../slices/draftsSlice';
import { useAppSelector } from '../../store/hooks';
import {
	DraftsState,
	ProgramParam,
	SisterSociety,
	TypicalProgram,
	TypicalProgramFilterSort,
	typicalProgramFilterSortDefault,
	TypicalProgramParam, WorkList
} from '../../types';
import SetListCard from './components/setListCard';
import DraftCard from './components/drafts';
import Filter from '../../components/filter';
import { useTranslation } from 'react-i18next';
import FilterToggle from '../../components/FilterToggle';
import { setBreadcrumb } from '../../slices/appContextSlice';
import Pagination from '../../components/pagination';
import { DEFAULT_SIZE_PER_PAGE, ROLES } from '../../constants';
import { ROUTES } from '../../constants';

import './index.scss';
import useRoleRedirect from '../../hooks/useRoleRedirect';

export default function SetListPage() {
	useRoleRedirect(ROLES.WEBSO_WEB_ONLINE);
	const typicalPrograms = useAppSelector(selectTypicalPrograms);
	const drafts:DraftsState = useAppSelector(selectDrafts);
	const society:SisterSociety|null = useAppSelector(selectedSociety);
	const [searchParams] = useSearchParams();
	const [draftMode, setDraftMode] = useState(searchParams.get('draft_mode') === 'true');
	const [page, setPage] = useState(1);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [filter,setFilter] = useState<TypicalProgramFilterSort>(typicalProgramFilterSortDefault);

	const [showToast, setShowToast] = useState(!!typicalPrograms.newProgram.typicalProgramId);
	const [idTypicalProgramForToast] = useState(typicalPrograms.newProgram.typicalProgramId);
	const [titleTypicalProgramForToast] = useState(typicalPrograms.newProgram.worksListTitle);

	const default_typicalProgramsParam: TypicalProgramParam = {
		pagination: {
			page,
			size: DEFAULT_SIZE_PER_PAGE
		},
		societyId: society ? society.sisterSocietyId.toString() : ''
	};

	useEffect(() => {
		const programsParam: ProgramParam = {
			pagination: {
				page,
				size: DEFAULT_SIZE_PER_PAGE
			},
			societyId: society ? society.sisterSocietyId.toString() : ''
		};
		programsParam['filter'] = filter;
		if (typicalPrograms.responseCode === 'DELETE_OK') {
			const totalPages = drafts.worksListsCount < DEFAULT_SIZE_PER_PAGE ? 1 :
				Math.ceil(drafts.worksListsCount / DEFAULT_SIZE_PER_PAGE);
			if (page < totalPages) {
				dispatch(fetchDraftsAsync(programsParam));
			} else if (typicalPrograms.typicalProgramsCount === 0 && drafts.worksListsCount > 0) {
				setPage(page - 1);
			}
		}
	}, [typicalPrograms.responseCode]);

	useEffect(() => {
		setFilter(typicalProgramFilterSortDefault);
		if (draftMode) {
			dispatch(fetchDraftsAsync(default_typicalProgramsParam));
		} else {
			// dispatch(resetProgramNew())
			dispatch(fetchTypicalProgramsAsync(default_typicalProgramsParam));
		}
	}, [draftMode]);

	useEffect(() => {
		dispatch(fetchDraftsAsync(default_typicalProgramsParam));
		dispatch(fetchTypicalProgramsAsync(default_typicalProgramsParam));
		dispatch(setBreadcrumb({
			title: t('breadcrumb.list-setLists'),
			buttonAction: ROUTES.CREATE_SETLIST,
			buttonLabel: 'set-lists.actions.add-setList',
			showHome: true
		}));
		dispatch(resetProgramNew())
	}, []);

	const getItemListSet = (typicalProgram: TypicalProgram) => {
		return <div key={typicalProgram.typicalProgramId}> <SetListCard typicalProgram = {typicalProgram}/></div>;
	};

	const getItemDraft = (work: WorkList) => {
		return <div key={work.worksListId}><DraftCard  work={work}/></div>;
	};

	const getNbCards = (loading: boolean) => (
		<div className="d-flex align-items-center">
			<span
				className='nb-card'>{draftMode && !loading ? drafts.worksListsCount : typicalPrograms.typicalProgramsCount}</span>
			<span
				className='text-label'>{(draftMode && !loading ? t('set-lists.drafts-nb-card-title') : t('set-lists.setlist-nb-card-title')) +
				(!draftMode && typicalPrograms.typicalProgramsCount > 1 || draftMode && drafts.worksListsCount > 1 ? 's' : '')}</span>
		</div>
	);

	const renderCreationToast = () => (
		<div className="tp-toast success">
			<p
				dangerouslySetInnerHTML={{
					__html: t('toast.success-create-program')
						.replace('{x}', `${titleTypicalProgramForToast}`)
				}}
			/>
			<div className="actions">
				<Link to={`${ROUTES.CREATE_EVENT}?programId=${idTypicalProgramForToast}`}>
					{t('form.btn.associated-date')}
				</Link>
				<button
					className="close-btn"
					onClick={() => {
						setShowToast(false)
					}}
				/>
			</div>

		</div>
	)
	const onPageClick = (page: number) => {
		const typicalProgramsParam: TypicalProgramParam = {
			filter,
			pagination: {
				page: page,
				size: DEFAULT_SIZE_PER_PAGE
			},
			societyId: society ? society.sisterSocietyId.toString() : ''
		};
		setPage(page);
		if (draftMode) {
			dispatch(fetchDraftsAsync(typicalProgramsParam));
		} else {
			dispatch(fetchTypicalProgramsAsync(typicalProgramsParam));
		}
	};

	const onFilter = (typicalProgramTitle: string) => {
		const filter: TypicalProgramFilterSort = {
			title: typicalProgramTitle
		};
		setFilter(filter);
		setPage(1);
		const typicalProgramsParam: TypicalProgramParam = {
			filter,
			pagination: {
				page: page,
				size: DEFAULT_SIZE_PER_PAGE
			},
			societyId: society ? society.sisterSocietyId.toString() : ''
		};
		if (draftMode) {
			dispatch(fetchDraftsAsync(typicalProgramsParam));
		} else {
			dispatch(fetchTypicalProgramsAsync(typicalProgramsParam));
		}
	};

	const onReinitialize = () => {
		if (draftMode) {
			dispatch(fetchDraftsAsync(default_typicalProgramsParam));
		} else {
			dispatch(fetchTypicalProgramsAsync(default_typicalProgramsParam));
		}
	};

	function noResults() {
		return typicalPrograms.reason !== '0' && <div className="text-center pt-4 text-label">{t('set-lists.empty')}</div>;
	}

	return (
		<div className="webso-setlist-page">
				<Filter filterTitle={t('set-lists.filter.filter-program-title')}
				onSearch={onFilter}
				onReinitialize={onReinitialize}
				reInitializeFields={draftMode}/>
			{!typicalPrograms.loading ? <Container>
				{ showToast && (
					renderCreationToast()
				) }
				<div className="d-flex flex-column flex-md-row justify-content-between my-3">
					{getNbCards(typicalPrograms.loading)}
					<FilterToggle
						items={[
							{
								label: t('set-lists.toggle.setlist'),
								icon: 'setlist',
								active: !draftMode,
								onSelect: () => setDraftMode(false)
							},
							{
								label: t('set-lists.toggle.draft'),
								icon: 'draft',
								active: draftMode,
								onSelect: () => setDraftMode(true)
							}
						]}
					/>
				</div>

				{draftMode ?
					drafts.worksListsCount ? drafts.worksLists.map(item => getItemDraft(item)) : noResults()
					:
					typicalPrograms.typicalProgramsCount ? typicalPrograms.typicalPrograms.map(item => getItemListSet(item)) : noResults()
				}

				<Row>
					<Pagination
						elementsPerPage={DEFAULT_SIZE_PER_PAGE}
						currentPage={page}
						totalElements={draftMode ? drafts.worksListsCount : typicalPrograms.typicalProgramsCount}
						onPageClick={onPageClick}
					/>
				</Row>
			</Container> : <PageLoadingSpinner isLoading={true} />}
		</div>

	);
}
