import React , { CSSProperties } from 'react'
// import { Container } from 'react-bootstrap'
import {Trans, useTranslation} from 'react-i18next'
import './pageStatus.scss'
import BeatLoader from 'react-spinners/BeatLoader'

interface IPageStatus {
  status: number
  defaultMessage: string
  noDataFoundMessage: string
  unexpectedErrorMessage: string
  contentHeight?: string
}

const PageStatus = ({
  status,
  defaultMessage,
  noDataFoundMessage,
  unexpectedErrorMessage,
  contentHeight
}: IPageStatus) => {
  const divStyle: CSSProperties = {
    height: contentHeight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute'
  }
  if(!contentHeight){
    divStyle.position = 'relative'
    divStyle.height = '100%'
  }
  const { t, i18n } = useTranslation();
  contentHeight = contentHeight ? contentHeight: '100%'
  return (
    < div className="page-status">
      {status === -1 && (
          <div
            style={divStyle}
          >
            <BeatLoader
              speedMultiplier={1}
              size={15}
              color='#563D7C'
              loading={true}
            />            
          </div>
      )}
      {status === -2 && (
          <div className='alert-text overflow-hidden'>
            <div className='alert-default'></div>
            <Trans
              i18nKey={defaultMessage} //if we pass a translation key
            />
          </div>
      )}
      {status === 204 && (
          <div className='alert-text'>
            <div className='alert-error'></div>

            <Trans
              i18nKey={noDataFoundMessage} //if we pass a translation key
            />
          </div>
      )}
      {status === 500 && (
          <div className='alert-text'>
            <div className='alert-error'></div>
            <Trans
              i18nKey={unexpectedErrorMessage} //if we pass a translation key
              components={{ mailto: <a />, bold: <strong /> }}
            />
          </div>
      )}

      {status !== 500 && status !== 204 && status !== -1 && status !== -2 && (
          <div className='alert-text'>
            <div className='alert-error'></div>
            <Trans
              i18nKey={unexpectedErrorMessage} //if we pass a translation key
              components={{ mailto: <a />, bold: <strong /> }}
            />
          </div>
      )}
    </div>
  )
}
export default PageStatus
