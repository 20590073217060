import React, { ReactElement } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/types';
import './index.scss';

import './index.scss';

interface Props{
    content: string
		className?: string
    placement: Placement | undefined
    children: ReactElement
}

const popover = (content: string, className: string | undefined) => {
	return (
		<Popover id="popover-basic" style={{ zIndex: '1' }}>
			<Popover.Body>
				<div className={className || ''}>
					{content}
				</div>
			</Popover.Body>
		</Popover>
	);
};

export const OverlayCustom = (props: Props) => {

	const { content, children, placement, className } = props;
	return (
		<OverlayTrigger trigger={['hover', 'focus']} placement={placement} overlay={popover(content, className)}>
			{children}
		</OverlayTrigger>
	);
};

export default OverlayCustom;
