import { t } from 'i18next'
import React, { useCallback, useEffect } from 'react'
import { Container, ListGroup } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

import {
  fetchDspsAsync,
  fetchMarketShareGroupedOffersAsync,
  fetchTerritoriesAsync,
  updateMarketShareDateSearchParams,
  updateMarketShareTerritoriesSearchParams,
  updateMarketShareDspsSearchParams,
  updateMarketShareGroupedOffersSearchParams,
  resetMarketSharesSearchParams,
  fetchMarketKpiDeclaredRevenueAsync,
  fetchMarketKpiStreamsPerCommercialModelsAsync,
  fetchMarketKpiNumberOfUsersAsync,
  fetchMarketKpiAvgDeclaredRevenuePerUserAsync,
  fetchRepertoirePerformanceTotalAmountAsync,
  fetchMarketShareByCommercialModelAsync,
  fetchMarketShareBClaimTypeAsync,
} from '../../../slices/marketShareSlice'

import { KpisTabs, canFetchFiltersDataHandler } from '../kpisHelper'

import { allowedStatus } from '../../../components/topStatusBar'
import FiltersComponent from '../../../components/filtersComponent'
import { SelectItem } from '@mantine/core'
import { KPIQueryParamModel, MultiSelectFilterType } from '../../../types/marketShareTypes'
import ApplyButtonComponent from '../../../components/filtersComponent/applyButtonComponent'
import { subDays, subMonths } from 'date-fns'
import './kpis-header.scss'
import iconReset from './../../../assets/img/icon-reset-new.svg';
import { FF_MARKET_SHARE } from '../../../providers/configurationProvider'
import { setToasterState, fetchRepertoiresAsync, setBreadcrumb, setSelectedRepertoire, resetSelectedRepertoires } from '../../../slices/appContextSlice'

const MarketShareFilters = ({
  activeTabIndex,
}: {
  activeTabIndex?: number
}) => {
  const dispatch = useAppDispatch()

  //mandator ID required to filter oscar data
  const mandatorId = useAppSelector((state) => state.appContext.mandator)
  const societyId = useAppSelector((state) => state.appContext.selectedSisterSociety.sisterSocietyId)

  //territories states
  const territoriesList = useAppSelector(
    (state) => state.marketShare.filters.territories
  )
  const territoryListStatus = useAppSelector(
    (state) => state.marketShare.status.territories
  )
  const territoriesSelectedList = useAppSelector(
    (state) => state.marketShare.marketShareSearchParams.territories
  )
  //dsps states
  const dspsList = useAppSelector((state) => state.marketShare.filters.dsps)
  const dspsListStatus = useAppSelector(
    (state) => state.marketShare.status.dsps
  )
  const dspsSelectedList = useAppSelector(
    (state) => state.marketShare.marketShareSearchParams.dsps
  )
  //grouped offers states
  const groupedOffersList = useAppSelector(
    (state) => state.marketShare.filters.groupedOffers
  )
  const groupedOffersListStatus = useAppSelector(
    (state) => state.marketShare.status.groupedOffers
  )
  const groupedOffersSelectedList = useAppSelector(
    (state) => state.marketShare.marketShareSearchParams.groupedOffers
  )

  //repositories states
  const repositoriesList = useAppSelector(
    (state) => state.appContext.repertoires.data
  )
  const repositoriesListStatus = useAppSelector(
    (state) => state.appContext.repertoires.status
  )
  const repositoriesSelectedList = useAppSelector(
    (state) => state.appContext.selectedRepertoire
  )
  //selected filters
  const dspList = useAppSelector(
    (state) => state.marketShare.marketShareSearchParams.dsps
  )
  const territories = useAppSelector(
    (state) => state.marketShare.marketShareSearchParams.territories
  )
  const offers = useAppSelector(
    (state) => state.marketShare.marketShareSearchParams.groupedOffers
  )

  const startDate = useAppSelector(
    (state) => state.marketShare.marketShareSearchParams.date.startingDate
  )
  const endDate = useAppSelector(
    (state) => state.marketShare.marketShareSearchParams.date.endingDate
  )

  const prepareQueryParams = (): KPIQueryParamModel => {
    let queryParams: KPIQueryParamModel = {
      startDate,
      endDate,
      societyId
    }


    if (dspList.length > 0) {
      queryParams.dsps = dspList
    }

    if (territories.length > 0) {
      queryParams.territories = territories
    }

    if (offers.length > 0) {
      queryParams.offers = offers
    }

    if (repositoriesSelectedList.length > 0) {
      queryParams.repertoires = repositoriesSelectedList
    }

    return queryParams
  }

  const listGroupClassName = 'flex-fill d-flex justify-content-center p-1'

  const handleResetFilters = () => {
    dispatch(resetMarketSharesSearchParams())
    dispatch(resetSelectedRepertoires())
  }
  const preparePianoAnlyticsFilters: MarketSharePianoAnalyticsParams = {
    click_chapter2: t(`paths-tag-labels.kpis-tabs.${activeTabIndex}`),
    dsps:
      dspsSelectedList.length > 0
        ? dspsSelectedList.map((selectedDsp) => {
            return dspsList
              .find((dsp) => {
                return dsp.code === selectedDsp
              })
              ?.label.toUpperCase()
          })
        : ['ALL'],
    territories:
      territoriesSelectedList.length > 0
        ? territoriesSelectedList.map((selecteTerr) => {
            return territoriesList
              .find((terr) => {
                return terr.code === selecteTerr
              })
              ?.label.toUpperCase()
          })
        : ['ALL'],
  }

  //handlers
  const handleDateChange = useCallback(
    (date: any) => {
      dispatch(
        updateMarketShareDateSearchParams(date)
      )
    },
    [dispatch]
  )

  const handleTerritoryChange = useCallback(
    (selectedItems: any) => {
      dispatch(
        updateMarketShareTerritoriesSearchParams(selectedItems.map((item) => item.code))
      )
    },
    [dispatch]
  )
  const handleDspsChange = useCallback(
    (selectedItems: any) => {
      dispatch(
        updateMarketShareDspsSearchParams(selectedItems.map((item) => item.code))
      )
    },
    [dispatch]
  )
  const handleGroupedOffersChange = useCallback(
    (selectedItems: any) => {
      dispatch(
        updateMarketShareGroupedOffersSearchParams(
          selectedItems.map((item) => item.code)
        )
      )
    },
    [dispatch]
  )
  const handleRepositoriesChange = useCallback(
    (selectedItems: any) => {
      dispatch(setSelectedRepertoire(selectedItems.map((item: {code: string, label: string}) => item.code).join()))
    },
    [dispatch]
  )
  useEffect(() => {
    if (!mandatorId) {
      return
    }
    if (
      canFetchFiltersDataHandler(
        territoryListStatus,
        dspsListStatus,
        groupedOffersListStatus
      )
    ) {
      dispatch(fetchTerritoriesAsync({ mandatorId }))
      dispatch(fetchDspsAsync({ mandatorId, referential: 'DSR' }))
      dispatch(fetchMarketShareGroupedOffersAsync({}))
      dispatch(fetchRepertoiresAsync({ societyId }))
    }
  }, [
    dispatch,
    mandatorId,
    territoryListStatus,
    dspsListStatus,
    groupedOffersListStatus,
    societyId
  ])

  const filtersStatusArray = [
    territoryListStatus,
    dspsListStatus,
    repositoriesListStatus,
    groupedOffersListStatus,
  ]

  const filtersError = !filtersStatusArray?.every((status) => {
    return allowedStatus.includes(status)
  })

  useEffect(() => {
    dispatch(
      setToasterState({
        status: filtersError,
        nature: 'error',
        messageTranslationKey: 'errorPage.500.message',
      })
    )
    return () => {
      dispatch(
        setToasterState({
          status: false,
        })
      )
    }
  }, [dispatch, filtersError])

  useEffect(() => {
    dispatch(setBreadcrumb({
      title: t('breadcrumb.kpi'),
      showHome: true,
      featKey: FF_MARKET_SHARE
    }));
  }, []);

  const globalStatus = [
    ...filtersStatusArray,
  ]

  const contractStartDate = useAppSelector((state) => state.appContext.mandatorStartDate)
  const searchButtonFlag = () => {
    switch (activeTabIndex) {
      case KpisTabs['licence-kpi']: {
        return [
          ...filtersStatusArray,
        ].includes(-1)
      }
      case KpisTabs['repertoire-performance']: {
        return [
          ...filtersStatusArray,
        ].includes(-1)
      }
      case KpisTabs['market-kpi']: {
        return [
          ...filtersStatusArray
        ].includes(-1)
      }
      default:
        return false
    }
  }

  const searchHandler = () => {
    if (!mandatorId) {
      return
    }

    const queryParams: KPIQueryParamModel = prepareQueryParams()

    switch (activeTabIndex) {
      case KpisTabs['repertoire-performance']: {
        dispatch(fetchRepertoirePerformanceTotalAmountAsync(queryParams))
        dispatch(fetchMarketShareByCommercialModelAsync(queryParams))
        dispatch(fetchMarketShareBClaimTypeAsync(queryParams))
       break
     }

      case KpisTabs['licence-kpi']: {
        //make call here
        break
      }
      case KpisTabs['market-kpi']: {
        dispatch(fetchMarketKpiDeclaredRevenueAsync(queryParams))
        dispatch(fetchMarketKpiStreamsPerCommercialModelsAsync(queryParams))
        dispatch(fetchMarketKpiNumberOfUsersAsync(queryParams))
        dispatch(fetchMarketKpiAvgDeclaredRevenuePerUserAsync(queryParams))
        break
      }
      default:
        return
    }
  }

  const getMinDate = (): Date => {
    // contractStartDate format: "YYYY-MM-DD"
    const minDate = subMonths(new Date(), 36)

    if (!contractStartDate) return minDate
    const contractStartDateObject = new Date(contractStartDate)
    return contractStartDateObject < minDate ? minDate : contractStartDateObject
  }

  return (
    <Container fluid style={{ padding: '0px' }}>
      <ListGroup horizontal={'lg'} className={'w-100'}>
        <ListGroup.Item className={listGroupClassName}>
          <FiltersComponent
            statusArray={globalStatus}
            textPlaceholder={`${t('market-share.filters.period.header')}`}
            handleComponentChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            maxDate={subDays(new Date(), 1)}
            minDate={getMinDate()}
            filterType={'quarterDateRange'}
            widthFilter={'100%'}
          />
        </ListGroup.Item>
        <ListGroup.Item className={listGroupClassName}>
          <FiltersComponent
            statusArray={globalStatus}
            textPlaceholder={`${t('filters.providers-placeholder')}`}
            data={dspsList.map((item: any) => {
              const mappedData: SelectItem = {
                label: item.label,
                value: item.label,
                code: item.code,
              }
              return mappedData
            })}
            filterType={'multiSelect'}
            handleComponentChange={handleDspsChange}
            status={dspsListStatus}
            selectedValues={dspsSelectedList}
            widthFilter={'100%'}
            disabled={false}
          />
        </ListGroup.Item>
        <ListGroup.Item
          className={listGroupClassName}
          style={{ borderLeft: '0' }}
        >
          <FiltersComponent
            statusArray={globalStatus}
            textPlaceholder={`${t('filters.territories-placeholder')}`}
            data={territoriesList.map((item: MultiSelectFilterType<string>) => {
              const mappedData: SelectItem = {
                label: item.label,
                value: item.label,
                code: item.code,
              }
              return mappedData
            })}
            filterType={'multiSelect'}
            handleComponentChange={handleTerritoryChange}
            status={territoryListStatus}
            selectedValues={territoriesSelectedList}
            widthFilter={'100%'}
          />
        </ListGroup.Item>
        <ListGroup.Item className={listGroupClassName}>
          <FiltersComponent
            statusArray={globalStatus}
            textPlaceholder={`${t('filters.grouped-offers-placeholder')}`}
            data={groupedOffersList.map(
              (item: MultiSelectFilterType<string>) => {
                const mappedData: SelectItem = {
                  label: item.label,
                  value: item.label,
                  code: item.code,
                }
                return mappedData
              }
            )}
            filterType={'multiSelect'}
            handleComponentChange={handleGroupedOffersChange}
            status={groupedOffersListStatus}
            selectedValues={groupedOffersSelectedList}
            widthFilter={'100%'}
          />
        </ListGroup.Item>
        <ListGroup.Item className={listGroupClassName}>
          <FiltersComponent
            statusArray={globalStatus}
            textPlaceholder={
              activeTabIndex === 2
                ? ''
                : `${t('filters.repositories-placeholder')}`
            }
            data={repositoriesList.map(
              (item: MultiSelectFilterType<string>) => {
                const mappedData: SelectItem = {
                  label: item.label,
                  value: item.label,
                  code: item.code,
                }
                return mappedData
              }
            )}
            filterType={'multiSelect'}
            status={repositoriesListStatus}
            selectedValues={repositoriesSelectedList}
            widthFilter={'100%'}
            disabled={activeTabIndex === 2}
            handleComponentChange={handleRepositoriesChange}
          />
        </ListGroup.Item>
        <ListGroup.Item
          className={
            'flex-column text-center justify-content-end p-1 px-2 align-items-end border-start-0'
          }
        >
         <button className="kpi-reset-btn" onClick={handleResetFilters}>
          {iconReset && <img src={iconReset} alt="reset"/>}
          </button>
        </ListGroup.Item>
        <ListGroup.Item
          className={
            'flex-column text-center justify-content-end p-1 px-2 align-items-end border-start-0'
          }
        >
          <ApplyButtonComponent
            primeAnalyticsFiltersObject={preparePianoAnlyticsFilters}
            onClickHandler={searchHandler}
            disabled={searchButtonFlag()}
            tagLabel='paths-tag-labels.kpis-title'
          />
        </ListGroup.Item>
      </ListGroup>
    </Container>
  )
}

export default MarketShareFilters
declare interface MarketSharePianoAnalyticsParams {
  click_chapter2: string
  dsps: Array<string | undefined>
  territories: Array<string | undefined>
}
