import React, { useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { DraftsState, SisterSociety, WorkList } from '../../../../types';
import { WorksCountBadge } from '../../../../components/WorksCountBadge';
import Overlay from '../../../../components/overlay';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ConfirmationModal from '../../../../components/confirmationModal';
import { useDispatch } from 'react-redux';
import { deleteWorkListAsync, selectDrafts, setReason } from '../../../../slices/draftsSlice';
import { useAppSelector } from '../../../../store/hooks';
import { selectedSociety } from '../../../../slices/appContextSlice';
import { setNewProgram } from '../../../../slices/setListsSlice';
import moment from 'moment';
import { ROUTES } from '../../../../constants';

interface propType {
  work: WorkList
}

const DraftCard = (props: propType) => {
	const { work } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

	const society:SisterSociety|null = useAppSelector(selectedSociety);
	const draftState: DraftsState = useAppSelector(selectDrafts);

	return (
		<div className="draftCard">
			<ConfirmationModal
				open={openConfirmationModal}
				content={t('drafts.delete-confirmation-msg')}
				confirmLabel={t('modal.yes')}
				onValidate={()=> {
					dispatch(deleteWorkListAsync(
						{ worksListId: work.worksListId,
							societyId: society?.sisterSocietyId }
					));
					setOpenConfirmationModal(false);
				}}
				onClose={() => setOpenConfirmationModal(false)}
			/>
			<ConfirmationModal
				open={draftState.reason === 'DELETE_ERROR'}
				content={t('errors.error-occure-try-again')}
				confirmLabel={t('modal.yes')}
				onValidate={()=> {
					dispatch(deleteWorkListAsync(
						{ worksListId: work.worksListId,
							societyId: society?.sisterSocietyId }
					));
					setOpenConfirmationModal(false);
				}}
				onClose={() => dispatch(setReason(''))}
				withAction={false}
				isErrorModal={true}
			/>
			<div className="d-flex justify-content-between col-12 align-items-center">
				<div className='col-9'>
					<label>{work.worksListTitle}</label>
				</div>
				<div className='col-3'>
					<div className='d-flex justify-content-between'>

						<WorksCountBadge count={work.worksListNumberOfWorks !== undefined ? work.worksListNumberOfWorks : 0} />

						<div className='Separate-vertical'/>

						<Overlay content={t('actions.delete')} placement="top">
							<div className='on-hover icon-delete' onClick={()=>{
							 	setOpenConfirmationModal(true);
							}}/>
						</Overlay>

						<Button className="button-finalize"
							onClick={()=>{
								dispatch(setNewProgram({
									worksListId: work.worksListId,
									worksListTitle: work.worksListTitle,
									workListOwner: '',
									worksListStartDate: moment(work.worksListStartDate).format('Y-MM-DD'),
									worksListTypeCode: 'AI'
								}));
								navigate(ROUTES.CREATE_SETLIST);
							}}>
							{t('actions.finalize')}</Button>
					</div>
				</div>
			</div>

		</div>
	);
};

export default DraftCard;