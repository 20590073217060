import React from 'react';
import { useNavigate } from 'react-router-dom';

import './index.scss';

interface Props {
    url?: string
    goBackProps?: () => void
}

const NavigateBackButton = (props: Props) => {
	const { goBackProps, url } = props;
	const navigate = useNavigate();
	const urlBackDefault = () => url ? navigate(url) : navigate(-1);

	return (
		<div className='hide-on-mobile'>
			<div className="back-btn-div" onClick={() => goBackProps ? goBackProps() : urlBackDefault()}
			>
				<button className="back-btn"
				/>
				<label>Back</label>
			</div>
		</div>
	);
};

export default NavigateBackButton;