import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { t } from 'i18next'
import countries from 'i18n-iso-countries'
import { Skeleton } from '@mantine/core'

import BoxSectionComponent from '../../components/boxSectionComponent'
import { sortCatalogs } from '../../slices/distributionsRoyaltiesSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import ExploitationTerritories from './boxes/ExploitationTerritories'
import { statusMessages } from '../../utils/statusMessages'
import { AmountPerTerritory } from '../../types/distributionsRoyaltiesTypes'
import useWindowDimensions from '../../hooks/useWindowDimensions'

import ExploitationTerritoriesMobile from './boxes/ExploitationTerritoriesMobile'
import DistributionWorks from './boxes/DistributionWorksBox'
import TotalAmoutBox from './boxes/TotalAmountBox'
import RightsTypesBox from './boxes/RightsTypesBox'
import CataloguesBox from './boxes/CataloguesBox'
import ExploitationPeriodsBox from './boxes/ExploitationPeriodsBox'
import { PeriodExploitationType } from '../../constants/constants'

const DistributionContent = () => {
  const [isMobile, setIsMobile] = useState<boolean>()

  const { screenWidth } = useWindowDimensions()

  useEffect(() => {
    setIsMobile(screenWidth < 900)
  }, [screenWidth])

  const dispatch = useAppDispatch()

  const currentBalance = useAppSelector(
    (state) => state.distributionRoyalties.content.currentBalance
  )
  const currentBalanceStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.amount
  )

  const distributionsWorks = useAppSelector(
    (state) => state.distributionRoyalties.content.distributionWorks
  )

  const distributionWorksStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.works
  )
  const distributionsStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.distributions
  )

  const societyName = useAppSelector((state) => state.appContext.selectedSisterSociety.name)

  const previousSearchParams = useAppSelector(
    (state) => state.distributionRoyalties.prevSearchParams
  )

  //rightsTypes
  const rightsTypes = useAppSelector(
    (state) => state.distributionRoyalties.content.rightsTypes
  )
  const rightsTypesStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.rightsTypes
  )

  const rightsTypesTotalAmount = useAppSelector(
    (state) => state.distributionRoyalties.content.rightsTypesTotal
  )

  //catalogues
  const catalogues = useAppSelector(
    (state) => state.distributionRoyalties.content.catalogues
  )

  const catalguesStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.catalogues
  )

  //exploitation territories
  const exploirationTerritoriesData = useAppSelector(
    (state) => state.distributionRoyalties.content.exploitationTerritories
  )

  const distributionExloitationTerritoriesStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.exploitationTerritories
  )

  //exploitation periods
  const offerExploitationPeriods = useAppSelector(
    (state) => state.distributionRoyalties.content.offerExploitationPeriods
  )
  const offerExploitationPeriodsStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.offerExploitationPeriods
  )
  const dspExploitationPeriods = useAppSelector(
    (state) => state.distributionRoyalties.content.dspExploitationPeriods
  )

  const dspExploitationPeriodsStatus = useAppSelector(
    (state) => state.distributionRoyalties.status.dspExploitationPeriods
  )

  const [showSkeleton, setShowSkeleton] = useState<boolean>(true)

  useEffect(() => {
    if (!showSkeleton) {
      return
    }
    if (distributionsStatus === 200) {
      setShowSkeleton(false)
    }
  }, [distributionsStatus])

  const onCataloguesAmountSort = (isMoneySortActive: boolean) => {
    let sortedCatalogsData = catalogues
    if (!isMoneySortActive) {
      sortedCatalogsData = [...catalogues].sort((a, b) => a.generatedAmount - b.generatedAmount);
    } else {
      sortedCatalogsData = [...catalogues].sort((a, b) => b.generatedAmount - a.generatedAmount);
    }
    dispatch(sortCatalogs(sortedCatalogsData))

  }
  const onCataloguesAlphaNumSort = (isAlphaNumSortActive: boolean) => {
    let sortedCatalogsDataByName = catalogues
    if (isAlphaNumSortActive) {
      sortedCatalogsDataByName = [...catalogues].sort((a, b) => a.name.localeCompare(b.name));
    } else {
      sortedCatalogsDataByName = [...catalogues].sort((a, b) => b.name.localeCompare(a.name));
    }
    dispatch(sortCatalogs(sortedCatalogsDataByName))
  }

  const disabledDistributionWorksSwitch = distributionsStatus === -1
  return (
    <Container className='distribution-content'>
      <Row className='justify-content-center p-block-13'>
        <Col xl={6} md={8}>
          <div className='data-filters-reminder'>
            <span>
              <span className='fw-500'>
                {societyName}
              </span>
              {` ${t('distribution-royalties.paid-out')} `}
              <span className='fw-500'>
                {previousSearchParams.distribution}
              </span>
            </span>
          </div>
        </Col>
        <Col xl={6} md={8}>
          {showSkeleton ? (
            <Skeleton
              visible={true}
              className='skeleton-100'
            />
          ) : (
            <TotalAmoutBox
              currentBalance={currentBalance}
              currentBalanceStatus={currentBalanceStatus}
            />
          )}
        </Col>
      </Row>
      <Row className='justify-content-center'>
        <Col md={4}>
          <div className='my-2 box-sizing'>
            {showSkeleton ? (
              <Skeleton
                visible={true}
                className='skeleton'
              />
            ) : (
              <BoxSectionComponent
                title={t(
                  'distribution-royalties.boxes.mechanical-performing.title'
                )}
                subtitle={t(
                  'distribution-royalties.boxes.mechanical-performing.subtitle'
                )}
                bodyContent={
                  <RightsTypesBox
                    rightsTypes={rightsTypes}
                    rightsTypesTotalAmount={rightsTypesTotalAmount}
                  />
                }
                status={{
                  statusCode: rightsTypesStatus,
                  defaultMessage: statusMessages.defaultMessage,
                  noDataFoundMessage: statusMessages.noDataFoundMessage,
                  unexpectedErrorMessage: statusMessages.unexpectedErrorMessage,
                }}

                overflowY='hidden'
              />
            )}
          </div>
        </Col>
        <Col md={4}>
          <div className='my-2 box-sizing'>
            {showSkeleton ? (
              <Skeleton
                visible={true}
                className='skeleton'
              />
            ) : (
              <BoxSectionComponent
                title={t('distribution-royalties.boxes.works.title')}
                subtitle={t('distribution-royalties.boxes.works.subtitle')}
                headerSwitchText={t(
                  'distribution-royalties.boxes.works.switch-text'
                )}
                status={{
                  statusCode: distributionWorksStatus,
                  defaultMessage: statusMessages.defaultMessage,
                  noDataFoundMessage: statusMessages.noDataFoundMessage,
                  unexpectedErrorMessage: statusMessages.unexpectedErrorMessage,
                }}
                disabled={disabledDistributionWorksSwitch}
                bodyContent={<DistributionWorks works={distributionsWorks} />}
              />
            )}
          </div>
        </Col>
        <Col md={4}>
          <div className='my-2 box-sizing'>
            {showSkeleton ? (
              <Skeleton
                visible={true}
                className='skeleton'
              />
            ) : (
              <BoxSectionComponent
                title={t('distribution-royalties.boxes.catalogues.title')}
                subtitle={t('distribution-royalties.boxes.catalogues.subtitle')}
                onAlphaNumSort={onCataloguesAlphaNumSort}
                onMoneySort={onCataloguesAmountSort}
                bodyContent={<CataloguesBox catalogues={catalogues} />}
                hasAlphaNumSort
                hasMoneySort
                exclusiveSort
                status={{
                  statusCode: catalguesStatus,
                  defaultMessage: statusMessages.defaultMessage,
                  noDataFoundMessage: statusMessages.noDataFoundMessage,
                  unexpectedErrorMessage: statusMessages.unexpectedErrorMessage,
                }}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row className='justify-content-center'>
        <Col>
          <div className='my-2 box-sizing'>
            {showSkeleton ? (
              <Skeleton
                visible={true}
                className='skeleton'
              />
            ) : (
              <BoxSectionComponent
                title={t('distribution-royalties.boxes.dsp.title')}
                bodyContent={
                  <ExploitationPeriodsBox
                    type={PeriodExploitationType.DSP}
                    exploitationPeriods={
                      dspExploitationPeriods?.exploitationPeriods || []
                    }
                  />
                }
                status={{
                  statusCode: dspExploitationPeriodsStatus,
                  defaultMessage: statusMessages.defaultMessage,
                  noDataFoundMessage: statusMessages.noDataFoundMessage,
                  unexpectedErrorMessage: statusMessages.unexpectedErrorMessage,
                }}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row className='justify-content-center'>
        <Col>
          <div className='my-2 box-sizing'>
            {showSkeleton ? (
              <Skeleton
                visible={true}
                className='skeleton'
              />
            ) : (
              <BoxSectionComponent
                title={t('distribution-royalties.boxes.offers.title')}
                bodyContent={
                  <ExploitationPeriodsBox
                    type={PeriodExploitationType.OFFER}
                    exploitationPeriods={
                      offerExploitationPeriods?.exploitationPeriods || []
                    }
                  />
                }
                status={{
                  statusCode: offerExploitationPeriodsStatus,
                  defaultMessage: statusMessages.defaultMessage,
                  noDataFoundMessage: statusMessages.noDataFoundMessage,
                  unexpectedErrorMessage: statusMessages.unexpectedErrorMessage,
                }}
              />
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className='my-2 box-sizing'>
            {showSkeleton ? (
              <Skeleton
                visible={true}
                className='skeleton'
              />
            ) : (
              <BoxSectionComponent
                title={t(
                  'distribution-royalties.boxes.exploitation-territory.title'
                )}
                subtitle={t(
                  'distribution-royalties.boxes.exploitation-territory.subtitle'
                )}
                bodyContent={
                  isMobile ? (
                    <ExploitationTerritoriesMobile
                      amountsPerTerritory={
                        exploirationTerritoriesData.exploitationTerritories
                      }
                    />
                  ) : (
                    <ExploitationTerritories
                      distributionsTerritories={exploirationTerritoriesData.exploitationTerritories.map(
                        (royalty: AmountPerTerritory) => {
                          const codeTerritory = countries.numericToAlpha2(
                            royalty.territory.code
                          )

                          return codeTerritory ? codeTerritory : ''

                        }
                      )}
                      royaltiesPerTerritory={exploirationTerritoriesData.exploitationTerritories.map(
                        (royalty: AmountPerTerritory) => {
                          const codeTerritory = countries.numericToAlpha2(
                            royalty.territory.code
                          )
                          return {
                            territory_alpha2_code: codeTerritory ? codeTerritory : '',
                            distributedAmount: royalty.amount.value,
                            distributedAmountPercentage:
                              royalty.amount.percentage,
                          }
                        }
                      )}
                    />
                  )
                }
                status={{
                  statusCode: distributionExloitationTerritoriesStatus,
                  defaultMessage: statusMessages.defaultMessage,
                  noDataFoundMessage: statusMessages.noDataFoundMessage,
                  unexpectedErrorMessage: statusMessages.unexpectedErrorMessage,
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default DistributionContent
